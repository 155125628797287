import {Injectable} from '@angular/core';
import {FireStoreProvider} from '../firestore/firestore';
import * as moment from 'moment';
import firebase from "firebase/compat";

export const CHART_COLORS = {
  processedMaterial: {
    sheetThickness_mm: '#2cc158',
    sheetLength_m: '#36b3f1',
    sheetWidth_mm: '#ffca22',
    totalSheetLength_m: '#f01a1a',
    totalArea_sqm: '#e08137'
  },
  productionSteps: {
    numberOfPieces: '#2cc158',
    cuttingSteps: '#36b3f1',
    bendSteps: '#ffca22',
    pressSteps: '#f01a1a',
    rollformingSteps: '#e08137'
  }
};

// http://json-schema.org/draft-06/schema
export interface IMachineData {
  ascoStatVersion: number,
  vMobileUid: number,
  mode: string;
  duration_min: number,
  processedMaterial?: [
    {
      materialName: string,
      sheetThickness_mm: number,
      sheetLength_m: number,
      sheetWidth_mm: number,
      totalSheetLength_m: number,
      totalArea_sqm: number
    }
  ];
  productionSteps: {
    numberOfPieces?: number,
    cuttingSteps?: number,
    bendSteps?: {
      totalCount: number,
      angleCounts: number[]
    },
    pressSteps?: number,
    rollformingSteps?: number
  }
  sideLoading?: {
    tableDockings?: number,
    tablePositionings: number,
    processedSheets: number,
    drivenDistance_m: number
  },
  machineUsers?: any[],
  customer?: {
    id: number,
    erpId?: string,
    name: string
  },
  project?: {
    id: number,
    erpId?: string,
    name: string
  },
  order?: {
    id: number,
    erpId: string
  }
}

// Evaluations
export enum METRIC {
  productionSteps,
  processedMaterial
}

export interface IStatisticData {
  id: string,
  uid: string,
  version: number,
  createdAt: firebase.firestore.Timestamp,
  aggregateCount: number
  aggregateDate: string, // YYYY-MM-DD
  aggregateData: IAggregateData[] // grouped by project
}

// version 1
export interface IAggregateData {
  duration_min: number,
  processedMaterial: IAggregateMaterial [], // grouped by materialName
  productionSteps: {
    numberOfPieces: number,
    cuttingSteps: number,
    bendSteps: number, // totalCount
    pressSteps: number,
    rollformingSteps: number
  }
  sideLoading: {
    tableDockings: number,
    tablePositionings: number,
    processedSheets: number,
    drivenDistance_m: number
  }
  project: {
    id: null,
    name: null
  }
}

export interface IAggregateMaterial {
  materialName: string,
  sheetThickness_mm: number,
  sheetLength_m: number,
  sheetWidth_mm: number,
  totalSheetLength_m: number,
  totalArea_sqm: number
}

@Injectable()
export class StatisticsProvider extends FireStoreProvider {

  loadMainUserStatisticFromTo(whoami: any, from: Date, to: Date) {
    return this.colWithIds$<IStatisticData[]>(`user/${whoami.id}/statistic`, (ref: any) => ref
      .where('createdAt', '>=', from).where('createdAt', '<=', to)
      .orderBy('createdAt','asc').limit(1000));
  }

  addMachinData(machineData: IMachineData): Promise<any> {
    const id = this.db.createId();
    return this.set(`machine-data/${id}`, machineData);
  }

  aggregateInterval(interval: string, statisticRaw: IStatisticData[]): IStatisticData[] {
    let statistics: IStatisticData[] = [];
    if (interval === 'DAY') {
      statistics = statisticRaw;
    } else if (interval === 'WEEK' || interval === 'MONTH') {
      let aggregateDate = null;
      statisticRaw.forEach((s: IStatisticData) => {
        aggregateDate = moment(s.createdAt.toDate()).format(interval === 'WEEK' ? 'YYYY-WW' : 'YYYY-MM');
        let aggregateStatistik = statistics.find((ws) => moment(ws.createdAt.toDate()).format(interval === 'WEEK' ? 'YYYY-WW' : 'YYYY-MM') === aggregateDate);
        if (aggregateStatistik === undefined) {
          aggregateStatistik = this.newAggregateStatistic(s, interval, aggregateDate);
          statistics.push(aggregateStatistik);
        } else {
          this.aggregateStatisticData(aggregateStatistik, s);
        }
      });
    }
    return statistics;
  }

  aggregateData(metric: METRIC, data: IAggregateData[], dataset: string, project: any, filter: string) {
    let sumY = 0;
    data.forEach((d: IAggregateData) => {
      if (project === null) {
        if (metric === METRIC.productionSteps) {
          sumY += d.productionSteps[dataset];
        } else if (metric === METRIC.processedMaterial) {
          sumY += this.aggregateMaterial(d.processedMaterial, dataset, filter);
        }
      } else if (d.project.id === project.id) {
        if (metric === METRIC.productionSteps) {
          sumY += d.productionSteps[dataset];
        } else if (metric === METRIC.processedMaterial) {
          sumY += this.aggregateMaterial(d.processedMaterial, dataset, filter);
        }
      }
    });
    return sumY;
  }

  aggregateMaterial(material: IAggregateMaterial[], dataset: string, filter: string) {
    let sum = 0;
    material.forEach((m: IAggregateMaterial) => {
      if (filter === null) {
        sum += m[dataset];
      } else if (m.materialName === filter) {
        sum += m[dataset];
      }
    });
    return sum;
  }

  newAggregateStatistic(originStatistic: IStatisticData, interval: string, aggregateDate: string): IStatisticData {
    return {
      id: 'AGGREGATE-' + interval,
      uid: originStatistic.uid,
      version: originStatistic.version,
      createdAt: originStatistic.createdAt,
      aggregateCount: originStatistic.aggregateCount,
      aggregateDate: aggregateDate,
      // aggregateData: [...originStatistic.aggregateData] // deep copy not working
      aggregateData: this.deepCopyData(originStatistic.aggregateData)
      /*aggregateData: {
        duration_min: originStatistic.aggregateData.duration_min,
        processedMaterial: [...originStatistic.aggregateData.processedMaterial], // deep copy
        processedMaterial: [ deprecated
          {
            materialName: originStatistic.aggregateData.processedMaterial.materialName,
            sheetThickness_mm: originStatistic.aggregateData.processedMaterial.sheetThickness_mm,
            sheetLength_m: originStatistic.aggregateData.processedMaterial.sheetLength_m,
            sheetWidth_mm: originStatistic.aggregateData.processedMaterial.sheetWidth_mm,
            totalSheetLength_m: originStatistic.aggregateData.processedMaterial.totalSheetLength_m,
            totalArea_sqm: originStatistic.aggregateData.processedMaterial.totalArea_sqm
          }
        ],
        productionSteps: {
          numberOfPieces: originStatistic.aggregateData.productionSteps.numberOfPieces,
          cuttingSteps: originStatistic.aggregateData.productionSteps.cuttingSteps,
          bendSteps: originStatistic.aggregateData.productionSteps.bendSteps, // totalCount
          pressSteps: originStatistic.aggregateData.productionSteps.pressSteps,
          rollformingSteps: originStatistic.aggregateData.productionSteps.rollformingSteps
        },
        sideLoading:{
          tableDockings: originStatistic.aggregateData.sideLoading.tableDockings,
          tablePositionings: originStatistic.aggregateData.sideLoading.tablePositionings,
          processedSheets: originStatistic.aggregateData.sideLoading.processedSheets,
          drivenDistance_m: originStatistic.aggregateData.sideLoading.drivenDistance_m
        }
      }*/
    };
  }

  deepCopyData(originAggregateData: IAggregateData[]): IAggregateData[] {
    /* const newData: IAggregateData[] = [];
    originAggregateData.forEach((oad) => {
      newData.push(oad);
    });*/
    const newData = JSON.parse(JSON.stringify(originAggregateData));
    return newData;
  }

  aggregateStatisticData(aggregateStatistic: IStatisticData, dayStatistic: IStatisticData) {
    aggregateStatistic.aggregateCount += dayStatistic.aggregateCount;
    if (aggregateStatistic.version === dayStatistic.version) {
      dayStatistic.aggregateData.forEach((ds: IAggregateData ) => {
        let aggregateData = aggregateStatistic.aggregateData.find((ap: IAggregateData) => ap.project.id === ds.project.id);
        if (aggregateData === undefined) {
          aggregateData = {
            duration_min: 0,
            processedMaterial: [ // grouped by materialName
              /*{
                materialName: null,
                sheetThickness_mm: 0,
                sheetLength_m: 0,
                sheetWidth_mm: 0,
                totalSheetLength_m: 0,
                totalArea_sqm: 0
              }*/
            ],
            productionSteps: {
              numberOfPieces: 0,
              cuttingSteps: 0,
              bendSteps: 0, // totalCount
              pressSteps: 0,
              rollformingSteps: 0
            },
            sideLoading: {
              tableDockings: 0,
              tablePositionings: 0,
              processedSheets: 0,
              drivenDistance_m: 0
            },
            project: ds.project
          }
          aggregateStatistic.aggregateData.push(aggregateData);
        }
        aggregateData.duration_min += ds.duration_min ? ds.duration_min : 0;
        if (ds.processedMaterial && ds.processedMaterial.length > 0) {
          // all properties required
          ds.processedMaterial.forEach((dm) => {
            const material = aggregateData.processedMaterial.find((am: IAggregateMaterial) => am.materialName === dm.materialName);
            if (material === undefined) {
              aggregateData.processedMaterial.push({
                materialName: dm.materialName,
                sheetThickness_mm: dm.sheetThickness_mm,
                sheetLength_m: dm.sheetLength_m,
                sheetWidth_mm: dm.sheetWidth_mm,
                totalSheetLength_m: dm.totalSheetLength_m,
                totalArea_sqm: dm.totalArea_sqm
              });
            } else {
              material.sheetThickness_mm += dm.sheetThickness_mm;
              material.sheetLength_m += dm.sheetLength_m;
              material.sheetWidth_mm += dm.sheetWidth_mm;
              material.totalSheetLength_m += dm.totalSheetLength_m;
              material.totalArea_sqm += dm.totalArea_sqm;
            }
          })
        }
        // productionSteps required, properties optional
        aggregateData.productionSteps.numberOfPieces += ds.productionSteps.numberOfPieces ? ds.productionSteps.numberOfPieces : 0;
        aggregateData.productionSteps.cuttingSteps += ds.productionSteps.cuttingSteps ? ds.productionSteps.cuttingSteps : 0;
        aggregateData.productionSteps.bendSteps += ds.productionSteps.bendSteps ? ds.productionSteps.bendSteps : 0;
        aggregateData.productionSteps.pressSteps += ds.productionSteps.pressSteps ? ds.productionSteps.pressSteps : 0;
        aggregateData.productionSteps.rollformingSteps += ds.productionSteps.rollformingSteps ? ds.productionSteps.rollformingSteps : 0;
        if (ds.sideLoading) {
          aggregateData.sideLoading.tableDockings += ds.sideLoading.tableDockings; // required
          aggregateData.sideLoading.tablePositionings += ds.sideLoading.tablePositionings; // required
          aggregateData.sideLoading.processedSheets += ds.sideLoading.processedSheets; // required
          aggregateData.sideLoading.drivenDistance_m += ds.sideLoading.drivenDistance_m; // required
        }
      })
    }
    return aggregateStatistic;
  }

  // dummy data for testing only
  addTestMachineData(idx: number, data: IMachineData) {
    // optional do some data logics
    this.addMachinData(data);
  }

}
