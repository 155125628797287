<ion-header>
  <ion-toolbar>
    <ion-title><span style='font-family:"Arial",sans-serif'>ALLGEMEINE NUTZUNGSBEDINGUNGEN</span><br>
      <span style='font-family:"Arial",sans-serif'>und </span><br>
      <span style='font-family:"Arial",sans-serif'>DATENSCHUTZBESTIMMUNGEN</span></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class=WordSection1 style="margin:18.0pt;">


<!--    <h1><span style='font-family:"Arial",sans-serif'>ALLGEMEINE NUTZUNGSBEDINGUNGEN</span><br>-->
<!--      <span style='font-family:"Arial",sans-serif'>und </span><br>-->
<!--      <span style='font-family:"Arial",sans-serif'>DATENSCHUTZBESTIMMUNGEN</span></h1>-->


    <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
      style='font-size:11.0pt;font-family:"Arial",sans-serif'>Die auf diesem Endgerät
des Nutzers installierte App Variobend (im Folgenden kurz: App) wird von der ASCO
Biegetechnik GmbH, Pfaffenkogel 9, D-83483 Bischofswiesen (im Folgenden kurz: ASCO)
entwickelt und betrieben.</span></p>


    <p style='line-height:14.0pt;mso-line-height-rule:exactly'><b
      style='mso-bidi-font-weight:normal'><span style='font-size:14.0pt;font-family:
"Arial",sans-serif'>Kontaktdaten</span></b></p>

    <p><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>
      ASCO Biegetechnik GmbH<br>
      Am Pfaffenkogel 9<br>
      D–83483 Bischofswiesen<br>
      info@asco-maschinen.de<br>
      +49 (0) 8652 / 655 91–00<br>
      +49 (0) 8652 / 655 91–33</span>
    </p>

    <b style='mso-bidi-font-weight:normal'><span style='font-size:16.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-ansi-language:DE;mso-fareast-language:DE;mso-bidi-language:AR-SA'><br
      clear=all style='mso-special-character:line-break;page-break-before:always'>
</span></b>

    <h2 style='line-height:14.0pt;mso-line-height-rule:exactly'><b
      style='mso-bidi-font-weight:normal'><span style='font-size:16.0pt;font-family:
"Arial",sans-serif'>NUTZUNGSBEDINGUNGEN</span></b></h2>
    <ol>
      <li>    <p class=MsoListParagraph style='line-height:14.0pt;
mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b
        style='mso-bidi-font-weight:normal'><span style='font-size:14.0pt;font-family:
"Arial",sans-serif'>Gegenstand/Dienste</span></b></p>

        <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
          style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-bidi-font-weight:
bold'>Die ASCO App bietet ein <span class=GramE>Servicetool</span> mit dem der
gesamte Plan- und Fertigungsprozess von Profilen umgesetzt werden kann. Über
eine Zeichenoberfläche könne Profile<span style='mso-spacerun:yes'>    </span>konstruiert
und bearbeitet und der Zuschnitt berechnet werden. Profile können abgespeichert
werden sowie Biegesimulationen durchgeführt werden. Über eine <span
            class=GramE>XML Schnittstelle</span> werden die Profile an Variobend
Abkantmaschinen übertragen.</span></p></li>

      <li>    <p class=MsoListParagraph style='line-height:14.0pt;
mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b
        style='mso-bidi-font-weight:normal'><span style='font-size:14.0pt;font-family:
"Arial",sans-serif'>Leistungsumfang</span></b></p>

        <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
          style='font-size:11.0pt;font-family:"Arial",sans-serif'>Sämtliche im Rahmen
dieser App angebotenen Features stehen Kunden zur Verfügung, die einen
entsprechenden Lizenzvertrag mit ASCO abgeschlossen haben. ASCO behält sich das
Recht vor, den Leistungsumfang der App, sowie die dargebotenen Dienstleistungen
ohne Ankündigung und ohne Angabe von Gründen zu verändern oder zu ergänzen.</span></p></li>

      <li><p class=MsoListParagraph style='text-align:justify;
line-height:14.0pt;mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b
        style='mso-bidi-font-weight:normal'><span style='font-size:14.0pt;font-family:
"Arial",sans-serif'>Gebühren und Zahlung</span></b></p>

        <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-bidi-font-weight:bold'>Sie stimmen zu, dass Sie alle anfallenden,
unbestrittenen Gebühren für die Nutzung der Dienste zahlen, wie auf der
Rechnung angegeben. Ihnen obliegt die Verantwortung, uns oder einem unserer
Vertriebspartner korrekte und aktuelle Abrechnungs-, Kontakt- und
Zahlungsangaben zur Verfügung zu stellen. Sie stimmen zu, dass wir Schritte
unternehmen können, um die Gültigkeit Ihrer Zahlungsmethode zu verifizieren,
und Ihre Kreditkarte mit allen Beträgen, die für die Nutzung der Dienste
angefallen sind, belasten bzw. wir Ihnen diese in Rechnung stellen. Sie stimmen
zu, dass Ihre Kreditkarteninformationen und die zugehörigen persönlichen Daten
zur Zahlungsabwicklung und zur Verhinderung betrügerischer Aktivitäten an
Dritte weitergegeben werden. Wenn wir feststellen, dass Ihre Zahlungsangaben
fehlerhaft oder nicht aktuell sind, und Sie für Gebühren und
Überziehungsgebühren verantwortlich sind, die uns entstehen, wenn wir Ihre
Kreditkarte für Zahlungen belasten möchten, können wir die Dienste jederzeit
aussetzen oder beenden. Wir behalten uns das Recht vor, den Preis für Dienste
jederzeit nach der anfänglichen Laufzeit zu ändern. Preisänderungen treten zu
Ihrem nächsten Abrechnungszeitraum in Kraft. Wir unterrichten Sie über
Preisänderungen durch Veröffentlichungen auf unserer Website,
E-Mail-Nachrichten, Angebote oder Rechnungen.</span></p></li>

      <li>  <p class=MsoListParagraph style='text-align:justify;
line-height:14.0pt;mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b
        style='mso-bidi-font-weight:normal'><span style='font-size:14.0pt;font-family:
"Arial",sans-serif'>Kostenlose Dienste und Testversionen</span></b></p>


        <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-bidi-font-weight:bold'>Ihr Recht auf den Zugriff und die Nutzung aller
kostenlosen Dienste wird für keinen Zeitraum garantiert, und wir behalten uns
das Recht vor, in unserem alleinigen und ausschließlichen Ermessen die
Verwendung von kostenlosen oder Basisversionen eines Dienstes für eine
Einzelperson oder eine Gruppe von Personen zu beenden. Wenn Sie die Dienste zu
Testzwecken oder im Rahmen einer Werbeaktion („Testzeitraum“) einsetzen, endet
Ihr Testzeitraum und der Zugriff auf die Dienste (zu dem in Ihrer Bestellung
für den Testzeitraum angegebenen Termin oder, falls kein Datum angegeben ist,
30 Tage nach dem anfänglichen Zugriff auf die Dienste oder bei Wechsel zu einem
kostenpflichtigen Abonnement. Nach Ablauf des Testzeitraums können die Dienste,
sofern nicht von Ihnen gekündigt, automatisch fortgeführt werden. Sie sind in
diesem Fall dafür verantwortlich, entsprechend den in der Bestellung
festgelegten Gebühren für die Dienste zu bezahlen. Während des Testzeitraums
stellen wir die Dienste „wie besehen“ und ohne Gewährleistung oder Anspruch auf
Schadenersatz bereit, soweit dies gesetzlich zulässig ist. Davon abgesehen
gelten alle anderen Bestimmungen. Wir behalten uns das Recht vor, Produkttests
oder Werbeangebote zu jedem Zeitpunkt und ohne vorherige Ankündigung zu ändern
oder abzubrechen.</span></p></li>

      <li><p class=MsoListParagraph style='text-align:justify;
line-height:14.0pt;mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b
        style='mso-bidi-font-weight:normal'><span style='font-size:14.0pt;font-family:
"Arial",sans-serif'>Beta-Dienste</span></b></p>

        <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-bidi-font-weight:bold'>Wir bieten Ihnen möglicherweise den Zugriff auf
Beta-Dienste an, die vor der allgemeinen Freigabe bereitgestellt werden, aber
wir geben keinerlei Garantien, dass diese Dienste allgemein zugänglich gemacht
werden („Beta-Dienste“). Sie verstehen und stimmen zu, dass die Beta-Dienste
Bugs, Fehler und andere Defekte enthalten können und die Nutzung der
Beta-Dienste auf eigenes Risiko erfolgt. Wir sind nicht verpflichtet,
technischen Support anzubieten, und wir können die Bereitstellung der
Beta-Dienste jederzeit in alleinigem Ermessen und ohne vorherige Ankündigung
einstellen. Diese Beta-Dienste werden „wie besehen“ angeboten und wir
schließen, soweit unter anwendbarem Recht gestattet, sämtliche
Gewährleistungen, Garantien, Schadenersatz und Bedingungen, seien diese
ausdrücklich, implizit, gesetzlich oder anderweitig festgelegt, aus. Wenn Sie
Beta-Dienste verwenden, stimmen Sie zu, dass Sie von uns damit in Zusammenhang
stehende Korrespondenz und Updates erhalten, und erkennen an, dass eine
Verweigerung solcher Mitteilungen zur Kündigung Ihres Zugriffs auf die
Beta-Dienste führen kann. Wenn Sie Feedback zu Beta-Diensten liefern, stimmen
Sie zu, dass wir Eigentümer des uns zur Verfügung gestellten Feedbacks sind.
Nur in Bezug auf die Beta-Dienste haben diese Bedingungen Vorrang vor allen
anderen Bestimmungen des Vertrags, jedoch nur soweit dies zur Behebung
eventueller Konflikte erforderlich ist.</span></p></li>

      <li><p class=MsoListParagraph style='text-align:justify;
line-height:14.0pt;mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b
        style='mso-bidi-font-weight:normal'><span style='font-size:14.0pt;font-family:
"Arial",sans-serif'>Änderung an Diensten</span></b></p>


        <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-bidi-font-weight:bold'>Wir behalten uns das Recht vor, Funktionen unserer
Dienste zu erweitern oder zu ändern, werden aber die Kernfunktionalität nicht
wesentlich reduzieren oder die Bereitstellung der Dienste nicht einstellen, es
sei denn, wir teilen Ihnen dies zuvor schriftlich mit. Möglicherweise bieten
wir neben unseren Standarddiensten oder gegen zusätzliche Kosten zusätzliche
Funktionalität oder Verbesserungen von Premiumfunktionen an. Alle zusätzlichen
Dienste, die Sie bestellen, unterliegen diesen Bedingungen.</span></p></li>

      <li><p class=MsoListParagraph style='line-height:14.0pt;
mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b
        style='mso-bidi-font-weight:normal'><span style='font-size:14.0pt;font-family:
"Arial",sans-serif'>Haftungsbeschränkung</span></b></p>

        <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
          style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-bidi-font-weight:
bold'>Die Nutzung dieser App erfolgt auf eigenes Risiko. ASCO, sowie deren
jeweilige Geschäftsführer, leitende Angestellte, Mitarbeiter oder Vertreter
haften nicht für direkte oder indirekte Verluste oder Schäden aus oder in
Verbindung mit Ihrem Zugriff auf diese App. Gleiches gilt für die Nutzung oder
auch die Unmöglichkeit der Nutzung dieser App sowie für Ihr Vertrauen in die
Informationen dieser App. Ohne Beschränkungen für das Vorangegangene lehnt ASCO
die Haftung für mögliche Fehler oder Unterlassungen im Inhalt dieser App ab.
Dies gilt im Besonderen auch für Hinweise auf Produkte und Dienstleistungen von
ASCO. Es handelt sich hierbei um eine umfassende Haftungsbeschränkung, deren
Gültigkeit sich auf alle Verluste und auf Schäden aller Art erstreckt, ob
direkte oder indirekte, allgemeine, spezielle, zufällige, folgerichtige,
exemplarische oder andere. Diese Haftungsbeschränkung erstreckt sich auch
unbegrenzt auf Verluste von Daten, Einkünften oder Gewinnen.</span></p>

        <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
          style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-bidi-font-weight:
bold'>Die oben genannte Haftungsbeschränkung gilt nicht für Haftung für Vorsatz
und grobe Fahrlässigkeit; für Fälle leichter Fahrlässigkeit gilt, dass die
Haftung für die Verletzung wesentlicher Vertragspflichten (Kardinalpflichten,
d.h. Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages
überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner
regelmäßig vertraut) auf diejenigen Schäden beschränkt ist, mit deren
Entstehung im Rahmen des jeweiligen Vertragsverhältnisses typischerweise
gerechnet werden muss (vertragstypisch vorhersehbare Schäden).</span></p>

        <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
          style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-bidi-font-weight:
bold'>Eine Haftung nach dem Produkthaftungsgesetz und nach sonstigen zwingenden
gesetzlichen Haftungsbestimmungen bleibt unberührt.</span></p></li>

      <li><p class=MsoListParagraph style='text-align:justify;
line-height:14.0pt;mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b
        style='mso-bidi-font-weight:normal'><span style='font-size:14.0pt;font-family:
"Arial",sans-serif'>Markenrechte</span></b></p>


        <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-bidi-font-weight:bold'>Alle in dieser App aufgeführten und verwendeten
Marken, Dienstleistungsmarken und Logos (die „Marken“) sind eingetragene oder
nicht-eingetragene Warenzeichen von ASCO, eines ihrer verbundenen Unternehmen
oder Dritter, die ihre Markenzeichen in Lizenz an ASCO oder eines ihrer
verbundenen Unternehmen vergeben haben.</span></p>


        <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-bidi-font-weight:bold'>Ohne vorheriges schriftliches Einverständnis von ASCO
dürfen keinerlei Marken kopiert, abgebildet oder anderweitig verwendet werden.</span></p>


        <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-bidi-font-weight:bold'>Ihre zwingenden gesetzlichen Rechte bleiben
unberührt.</span></p>

        <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-bidi-font-weight:bold'>Sie erkennen an, dass alle Eigentumsrechte, Titel
und Ansprüche hinsichtlich der Dienste, unser Name, das Logo oder andere Marken
(zusammen als „ASCO-Marken“ bezeichnet) sowie alle verwandten geistigen
Urheberrechte bei uns verbleiben. Dazu gehören u. a. alle Änderungen,
Erweiterungen, abgeleiteten Werke und Upgrades. Sie stimmen zu, dass Sie keine
Marke, Dienstleistungsmarke, keinen Handels- oder Firmennamen, Domänennamen
oder <span class=SpellE>Social</span>-Media-Kontennamen oder -Handle nutzen
oder registrieren, die ganz oder teilweise zu den ASCO-Marken gehören oder
diesen ähnlich sind. </span></p>


        <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
          style='font-size:11.0pt;font-family:"Arial",sans-serif'>ASCO übernimmt dabei keine
Haftung für Datenverlust oder Beschädigungen am jeweiligen Endgerät.</span></p></li>

      <li><p class=MsoListParagraph style='line-height:14.0pt;
mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b><span style='font-size:14.0pt;font-family:
"Arial",sans-serif'>Schlussbestimmungen</span></b></p>


        <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
          style='font-size:11.0pt;font-family:"Arial",sans-serif'>Sofern Teile oder
einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht
mehr oder nicht vollständige entsprechen, bleiben die übrigen Teile in ihrem
Inhalt und ihrer Gültigkeit davon unberührt.</span></p>

        <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
          style='font-size:11.0pt;font-family:"Arial",sans-serif'>Anstelle einer unwirksamen
Bestimmung oder bei Entstehen einer Lücke kommt eine angemessene, rechtlich
zulässige Regelung zur Anwendung, die dem am nächsten kommt, was nach Sinn und
Zweck dieser Nutzungsbedingungen bei Kenntnis der Teilnichtigkeit oder Lücke
gewollt gewesen wäre.</span></p></li>

</ol>






    <b style='mso-bidi-font-weight:normal'><span style='font-size:16.0pt;
font-family:"Arial",sans-serif;mso-fareast-font-family:"Times New Roman";
mso-ansi-language:DE;mso-fareast-language:DE;mso-bidi-language:AR-SA'><br
      clear=all style='mso-special-character:line-break;page-break-before:always'>
</span></b>


    <h2 style='line-height:14.0pt;mso-line-height-rule:exactly'><b
      style='mso-bidi-font-weight:normal'><span style='font-size:16.0pt;font-family:
"Arial",sans-serif'>DATENSCHUTZBESTIMMUNGEN</span></b></h2>


    <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-bidi-font-weight:bold'>Wir nehmen den Schutz Ihrer persönlichen Daten sehr
ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend <span
        class=GramE>der gesetzlichen Datenschutzvorschriften</span> sowie dieser Datenschutzerklärung.</span></p>

    <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-bidi-font-weight:bold'>Die App speichert und verarbeitet Daten
ausschließlich im Sinne der DSGVO sowie des Datenschutz Anpassungsgesetzes
2018.</span></p>


    <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Es
werden nur solche personenbezogenen Daten erhoben, die für die Durchführung und
Abwicklung der Leistungen von ASCO erforderlich sind oder die uns freiwillig
vom Nutzer zur Verfügung gestellt wurden.</span></p>

    <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Personenbezogene
Daten sind alle Daten, die Einzelangaben über persönliche oder sachliche
Verhältnisse enthalten, beispielsweise Name, Anschrift, Emailadresse, Telefonnummer,
Geburtsdatum, Alter, Geschlecht, Videoaufzeichnungen, Fotos, sowie biometrische
Daten wie etwa Fingerabdrücke.</span></p>


    <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Sämtliche
bei ASCO verarbeitete Daten werden vom Endgerät verschlüsselt an ASCO
übertragen. </span></p>

<ol start="10">
  <li><p class=MsoListParagraph style='line-height:14.0pt;
mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:14.0pt;font-family:"Arial",sans-serif'>Datenerhebung und -verarbeitung</span></b></p>


    <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
      style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-bidi-font-weight:
bold'>Beim Erststart der App wird ein applikationsbezogenes, anonymes
Kennzeichen (sog. <span class=SpellE>AppID</span>) erzeugt. Mit dieser ID sind
keinerlei personenbezogene Daten verbunden und ermöglicht ASCO die Anlage eines
Profils mit ortsbezogenen Abonnements. Mit der App-ID werden Daten über den
Gerätetyp, das Betriebssystem und die App-Version, sowie Geokoordinaten, eine
Ortsbezeichnung und die plattformabhängige Registrierungs-ID des Smartphones
verknüpft.</span></p>

    <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
      style='font-size:11.0pt;font-family:"Arial",sans-serif;mso-bidi-font-weight:
bold'>Beim Abruf von Daten erfolgt eine direkte Kommunikation zwischen dem Endgerät
und den Servern. Die Kommunikation erfolgt durch eine end-<span class=SpellE>to</span>-end-Verschlüsselung.
Dabei wird die anonymisierte IP-Adresse des Endgerätes in Logdaten erfasst.</span></p></li>

  <li><p class=MsoListParagraph style='line-height:14.0pt;
mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:14.0pt;font-family:"Arial",sans-serif'>Einbindung von Diensten
und Inhalten Dritter</span></b></p>


    <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
      style='font-size:11.0pt;font-family:"Arial",sans-serif'>Sofern in der App
Inhalte von Webseiten Dritter dargestellt werden, ist die Haftung von ASCO
ausgeschlossen, ASCO prüft keine Inhalte von externen Links.</span></p></li>

  <li><p class=MsoListParagraph style='line-height:14.0pt;
mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:14.0pt;font-family:"Arial",sans-serif'>Kontakte</span></b></p>


    <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
      style='font-size:11.0pt;font-family:"Arial",sans-serif'>Die App greift auf
keine Kontaktdaten auf dem Endgerät zu.</span></p></li>

  <li><p class=MsoListParagraph style='line-height:14.0pt;
mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b><span style='font-size:14.0pt;font-family:
"Arial",sans-serif'>Übermittlung von Daten an Dritte</span></b></p>


    <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
      style='font-size:11.0pt;font-family:"Arial",sans-serif'>Zur Erfüllung des
Vertrages ist es möglicherweise auch erforderlich, das Daten der Nutzer an
Dritte (<span class=SpellE>zB</span> Dienstleister, derer sich ASCO bedient)
weiterzuleiten. Eine Weiterleitung der Daten erfolgt ausschließlich auf
Grundlage der DSGVO, insbesondere zur Erfüllung des Auftrags oder aufgrund der
vorherigen Einwilligung.</span></p>

    <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
      style='font-size:11.0pt;font-family:"Arial",sans-serif'>Im Falle einer
gesetzlichen Verpflichtung oder nach behördlicher oder gerichtlicher Anordnung
ist ASCO gezwungen, die Daten an die entsprechende Behörde/das entsprechende
Gericht weiterzugeben.</span></p></li>

  <li><p class=MsoListParagraph style='line-height:14.0pt;
mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b><span style='font-size:14.0pt;font-family:
"Arial",sans-serif'>Formulardaten und Kommentar</span></b></p>


    <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
      style='font-size:11.0pt;font-family:"Arial",sans-serif'>Wenn App-Nutzer
Kommentare oder Formulareinträge hinterlassen, werden die eingegeben Daten und
ihre IP-Adressen gespeichert. Das erfolgt zur Sicherheit, falls jemand
widerrechtliche Inhalte verfasst. Formulardaten (<span class=SpellE>z.b.</span>
von Kontaktformularen oder Support Anfragen werden zum Zwecke der
Kontaktaufnahme erhoben, verarbeitet und gespeichert.</span></p></li>

  <li><p class=MsoListParagraph style='line-height:14.0pt;
mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:14.0pt;font-family:"Arial",sans-serif'>Zugriff auf Kamera</span></b></p>


    <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
      style='font-size:11.0pt;font-family:"Arial",sans-serif'>Die App aktiviert die
Kamera des jeweiligen Endgeräts erst nachdem der Nutzer die Verwendung der
Kamera ausgelöst hat. </span></p></li>

  <li><p class=MsoListParagraph style='line-height:14.0pt;
mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:14.0pt;font-family:"Arial",sans-serif'>Fotos und Bildergalerie</span></b></p>


    <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
      style='font-size:11.0pt;font-family:"Arial",sans-serif'>Die App greift auf
gespeicherte Fotos und Bildergalerien erst nach Aufforderung durch den Nutzer
zu. Der Nutzer nimmt zur Kenntnis, dass Fotos, welche mit der Kamera des
Endgeräts aufgenommen worden sind, allenfalls von Dritten (beispielsweise:
iCloud und andere Cloud-Diensteanbieter) und Apps auf dem Endgerät gelesen und
verarbeitet werden können. </span></p></li>

  <li><p class=MsoListParagraph style='line-height:14.0pt;
mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:14.0pt;font-family:"Arial",sans-serif'>Geräteberechtigungen</span></b></p>


    <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
      style='font-size:11.0pt;font-family:"Arial",sans-serif'>Die App nutzt
ausschließlich die vom Betriebssystem des Endgeräts bereitgestellten Funktionen
(APIs) in der vom Betriebssystem vorgeschriebenen Weise.</span></p></li>

  <li><p class=MsoListParagraph style='line-height:14.0pt;
mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:14.0pt;font-family:"Arial",sans-serif'>Internetverbindung</span></b></p>


    <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
      style='font-size:11.0pt;font-family:"Arial",sans-serif'>Die App benötigt einen
Zugriff auf das Internet, um Daten direkt abzurufen und dem Nutzer
bereitzustellen. </span></p>


    <p style='line-height:14.0pt;mso-line-height-rule:exactly'><span
      style='font-size:11.0pt;font-family:"Arial",sans-serif'>Die App übermittelt
abgesehen davon ausschließlich in jenem Rahmen, wie er in der
Datenschutzerklärung dargestellt ist. </span></p></li>

  <li><p class=MsoListParagraph style='text-align:justify;
line-height:14.0pt;mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:14.0pt;font-family:"Arial",sans-serif'>Ihre Rechte</span></b></p>



    <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-bidi-font-weight:bold'>Sie haben das Recht auf Auskunft, Berichtigung,
Löschung und Einschränkung der Verarbeitung der personenbezogenen Daten sowie
das Recht auf Datenübertragbarkeit.</span></p>

    <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-bidi-font-weight:bold'>Sie haben das Recht auf Widerruf eventuell erteilter
Einwilligungen zur Verarbeitung Ihrer personenbezogenen Daten. Die
Rechtmäßigkeit der Verarbeitung der personenbezogenen Daten bis zum Widerruf
wird durch den Widerruf nicht berührt.</span></p>

    <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-bidi-font-weight:bold'>Sie haben das Recht auf Widerspruch gegen die
Verarbeitung Ihrer personenbezogenen Daten zum Zweck der Direktwerbung. Im Fall
des Widerspruchs werden Ihre personenbezogenen Daten nicht mehr zum Zwecke der
Direktwerbung verarbeitet.</span></p>

    <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif;
mso-bidi-font-weight:bold'>Weiters haben Sie das Recht auf Beschwerde bei der
Aufsichtsbehörde (Bayrisches Landesamt für Datenschutzaufsicht; Postfach 22 12
19, 80502 München</span></p></li>

  <li><p class=MsoListParagraph style='text-align:justify;
line-height:14.0pt;mso-line-height-rule:exactly;mso-list:l1 level1 lfo5'><b><span style='font-size:14.0pt;font-family:
"Arial",sans-serif'>Sonstiges</span></b><span style='font-size:11.0pt;
font-family:"Arial",sans-serif'></span></p>


    <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Sollte
eine Bestimmung dieser Nutzungsbedingungen unwirksam sein oder werden, so
bleibt die Rechtswirksamkeit der übrigen Bestimmungen hiervon unberührt.</span></p>


    <p style='text-align:justify;line-height:14.0pt;mso-line-height-rule:
exactly'><span style='font-size:11.0pt;font-family:"Arial",sans-serif'>Die
unwirksame Bestimmung soll durch die Bestimmung ersetzt werden, die der
unwirksamen Bestimmung wirtschaftlich am nächsten kommt. Dasselbe gilt für
eventuelle Regelungslücken.</span></p></li>



</ol>
  </div>

</ion-content>
