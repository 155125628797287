import {Component, Input} from '@angular/core';
import {Platform} from '@ionic/angular';
import 'fabric-with-gestures';

declare let fabric: any;

@Component({
  selector: "machine-canvas",
  templateUrl: "machine-canvas.html",
  styleUrls: ['machine-canvas.scss']
})
export class MachineCanvasComponent {

  public canvas: any;
  private width: number = 150;
  private height: number = 150;
  private canvasWidth: any;
  private canvasHeight: any;
  @Input("id") public id: number;
  @Input("path") public path: string;
  private profileColor = "#EF9348"; // #3D4A50

  constructor(public platform: Platform) {
  }

  ngAfterViewInit() {

    this.canvasWidth = 200;
    this.canvasHeight = 200;
    let canvas = new fabric.StaticCanvas(this.id + "-canvas");
    canvas.setHeight(this.canvasHeight);
    canvas.setWidth(this.canvasWidth);

    if (this.path && this.path !== null) {
      let path = new fabric.Path(this.path, {
        top: 0,
        left: 0,
        selectable: false,
        hasControls: false,
        hasBorders: false,
        hoverCursor: "default",
        fill: "",
        stroke: this.profileColor,
        strokeWidth: 1,
        strokeLineJoin: "round"
      });
      canvas.add(path);
      let scaleW = this.canvasWidth / path.width / 1.1;
      let scaleH = this.canvasHeight / path.height / 1.1;

      let scale = scaleH;
      if (scaleH > scaleW) {
        scale = scaleW;
      }

      path.strokeWidth = 12 / scale / 4;

      path.scaleX = scale;
      path.scaleY = scale;
      canvas.centerObject(path);
      canvas.renderAll();
    }
  }

}
