import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the FilterProjectsPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'filterProjects',
})
export class FilterProjectsPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  itemsRet: any;

  transform(items: any, filter: any) {
    this.itemsRet = items;
    if (items == null) {
      return [];
    }
    if(filter == 0) {
      this.itemsRet = this.itemsRet.filter(item => {
        if(item.isCustomerRoot) {
          return true
        } else {
          return false
        }
      })
    }
    if(filter == 1) {
      this.itemsRet = this.itemsRet.filter(item => {
        if(item.isProjectRoot) {
          return true
        } else {
          return false
        }
      })
    }
    if(filter == 2) {
      this.itemsRet = this.itemsRet.filter(item => {
        if(item.orderId) {
          return true
        } else {
          return false
        }
      })
    }
    return this.itemsRet;
  }
}
