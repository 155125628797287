import { Component, ViewChild } from '@angular/core';
import { NavController, NavParams, ModalController, ToastController } from '@ionic/angular';

@Component({
  selector: 'page-folder-details',
  templateUrl: 'folder-details.html',
  styleUrls: ['folder-details.scss'],
})
export class FolderDetailsPage {

  profileName: any;
  title: any;
  numberOfPiecesToManufacture: any;
  paintPosition: any;
  materialName: any;
  materialThickness: any;
  width: any;
  folder: Boolean = true;
  import: Boolean = false;
  parent: string = null;
  @ViewChild("name", {}) name;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public viewCtrl: ModalController,
    public toastCtrl: ToastController) {
    this.title = navParams.get("title");
    this.folder = navParams.get("folder");
    this.import = navParams.get("import");
    this.parent = navParams.get("parent");
  }

  ngOnInit() {
    console.log('ngOnInit FolderDetailsPage');

    setTimeout(() => {
      this.name.setFocus();
    }, 500);

  }

  async save() {
    if (!this.profileName || this.profileName.length < 3) {
      this.toastCtrl.create({
        message: 'Bitte mindesten 3 Buchstaben eingeben.',
        duration: 3000,
        position: 'bottom'
      }).then(toast=>toast.present());

    } else {
      let data = {
        'name': this.profileName,
        'parent': this.parent,
        'numberOfPiecesToManufacture': this.numberOfPiecesToManufacture,
        'paintPosition': this.paintPosition,
        'materialName': this.materialName,
        'materialThickness': this.materialThickness,
        'width': this.width,
      };
      await this.viewCtrl.dismiss(data);
    }

  }

  async cancel() {
    await this.viewCtrl.dismiss();
  }

}
