import { NgModule } from '@angular/core';
import { StatusPipe } from './status/status';
import { StatusColorPipe } from './status-color/status-color';
import { OwnFolderPipe } from './own-folder/own-folder';
import { OwnFolderColorPipe } from './own-folder-color/own-folder-color';
import { SearchNamePipe } from './search-name/search-name';
import { TranslateModule } from '@ngx-translate/core';
import { OrderfolderPipe } from './orderfolder/orderfolder';
import { GroupByPipe} from "./groupby/groupby";
import {FilterProjectsPipe} from "./filter-projects/filter-projects";

@NgModule({
	declarations: [StatusPipe,
    StatusColorPipe,
    OwnFolderPipe,
    OwnFolderColorPipe,
    SearchNamePipe,
    FilterProjectsPipe,
    OrderfolderPipe,
    GroupByPipe],
	imports: [
        TranslateModule.forChild()
    ],
	exports: [StatusPipe,
    StatusColorPipe,
    OwnFolderPipe,
    OwnFolderColorPipe,
    SearchNamePipe,
    FilterProjectsPipe,
    OrderfolderPipe,
    GroupByPipe]
})
export class PipesModule {}
