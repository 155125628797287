import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';

/**
 * Generated class for the AgbPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-agb',
  templateUrl: 'agb.html',
  styleUrls: ['./agb.scss']
})
export class AgbPage {

  constructor(public navCtrl: NavController) {
  }

  ngOnInit() {
    console.log('ngOnInit AgbPage');
  }

}
