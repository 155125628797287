import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentChangeAction
} from '@angular/fire/compat/firestore';
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable()
export class FireStoreProvider {

  constructor(
    public db: AngularFirestore
  ) {
  }

  public col<T>({ ref, queryFn }: { ref: CollectionPredicate<T>; queryFn?: any }): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.db.collection<T>(ref, queryFn) : ref;
  }

  public doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.db.doc<T>(ref) : ref;
  }

  public colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
    return this.col({ ref, queryFn })
      .snapshotChanges()
      .pipe(
        map((actions: Array<DocumentChangeAction<T>>) => {
          return actions.map((a: DocumentChangeAction<T>) => {
            const data: any = a.payload.doc.data() as T;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  public set<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    return this.doc(ref).set(
      {
        ...data
      },
      { merge: true }
    );
  }

}
