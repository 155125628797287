<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start" class="ion-no-margin ion-no-padding">
      <!--     do not remove or icons will be deleted from build: menu-outline menu-sharp search-sharp close-sharp -->
      <ion-button fill="solid" color="primary" class="ion-no-margin message-btn" (click)="goBack()">
        <ion-badge *ngIf="getMessagesCount() > 0" color="danger">{{getMessagesCount()}}</ion-badge>
        <ion-icon name="arrow-back" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'PROJECTS'| translate}}</ion-title>
    <ion-buttons slot="end" class="ion-no-margin ion-no-padding">
      <ion-button *ngIf="cloudOnline" fill="solid" color="primary">
        <ion-icon name="cloud-done" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button *ngIf="cloudSync" fill="solid" color="primary">
        <ion-icon name="cloud-upload" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button *ngIf="cloudOffline" fill="solid" color="primary">
        <ion-icon name="cloud-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="metainfo ion-padding">
    <ion-row class="searchbar-row">
      <ion-col size="12" size-sm="12" size-md="12" size-lg="4" class="searchbar-col">
        <ion-searchbar type="text" debounce="200" class="searchbartop" [(ngModel)]="searchString" [placeholder]="'SEARCH' | translate" (ionChange)="this.searchProjects()"></ion-searchbar>
      </ion-col>
      <ion-col size="12" size-sm="6" size-md="6" size-lg="4">
        <ion-item>
          <ion-label>{{'FILTER'| translate}}</ion-label>
          <ion-select [(ngModel)]="filter" [okText]="'OK' | translate" [cancelText]="'ABORT' | translate">
            <ion-select-option value="-1">{{'NOFILTER'| translate}}</ion-select-option>
            <ion-select-option value="0">{{'CUSTOMER'| translate}}</ion-select-option>
            <ion-select-option value="1">{{'PROJECT'| translate}}</ion-select-option>
            <ion-select-option value="2">{{'ORDER'| translate}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ng-container *ngIf="!searchString" class="ion-padding">
    <div class="ion-padding">
    <ion-card class="lastUsed">
      <ion-icon name="time-outline"></ion-icon>
      <ion-label class="ion-padding">{{'LASTUSED' | translate}}</ion-label>
    </ion-card>
  <ion-list lines="none" >
    <ng-container  *ngFor="let project of (lastUsedProjects | filterProjects:filter)">
      <ion-card button="true" class="ion-padding" (click)="openFolder(project)">
        <ion-toolbar>
          <ion-card-subtitle>{{project.isCustomerRoot ? project.name : project.customerName | uppercase}}</ion-card-subtitle>
          <ion-card-title>{{project.isprojectRoot ? project.name : project.projectName | uppercase}}</ion-card-title>
          <ion-buttons slot="end">
            <ion-button class="cardBtn" fill="solid" color="primary" (click)="offlineUseProject($event, project)">
              <ion-icon name="cloud-download-outline"></ion-icon>
            </ion-button>
          </ion-buttons>

        </ion-toolbar>

        <ion-card-content>{{project.orderErpId ? project.orderErpId : (project.orderId > 0 ? project.orderId.toString() : '')  | uppercase}}
          {{project.description}}
        </ion-card-content>
      </ion-card>
    </ng-container>
  </ion-list>
    </div>
  </ng-container>

  <ion-list lines="none" class="ion-padding">
    <ng-container *ngIf="searchString">
    <ng-container *ngFor="let project of (profileProvider.project | filterProjects:filter)">
<!--      <ng-container *ngFor="let project of profileProvider.project">-->
      <ion-card button="true" class="ion-padding" (click)="openFolder(project)">
        <ion-toolbar>
          <ion-card-subtitle>{{project.isCustomerRoot ? project.name : project.customerName | uppercase}}</ion-card-subtitle>
          <ion-card-title>{{project.isprojectRoot ? project.name : project.projectName | uppercase}}</ion-card-title>
          <ion-buttons slot="end">
            <ion-button class="cardBtn" fill="solid" color="primary" (click)="offlineUseProject($event, project)">
              <ion-icon name="cloud-download-outline"></ion-icon>
            </ion-button>
          </ion-buttons>

        </ion-toolbar>

        <ion-card-content>{{project.orderErpId ? project.orderErpId : (project.orderId > 0 ? project.orderId.toString() : '')  | uppercase}}
          {{project.description}}
        </ion-card-content>
      </ion-card>
    </ng-container>
    </ng-container>
  </ion-list>

</ion-content>
