import {Component, Injector} from '@angular/core';
import {AlertController, MenuController, NavController, ToastController} from '@ionic/angular';
import {AuthProvider} from '../../shared/providers/auth/auth';
import {Storage} from '@ionic/storage';
import {Platform} from '@ionic/angular';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {ProfilesProvider} from "../../shared/providers/profiles/profiles";
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {QrCodeProvider} from '../../shared/providers/qrcode/qrcode';
import {QRScanner, QRScannerStatus} from "@awesome-cordova-plugins/barcode-scanner/ngx";

@Component({
  selector: 'page-signin',
  templateUrl: './signin.html',
  styleUrls: ['./signin.scss']
})
export class SignInPage {

  public loggedIn: boolean = false;
  public toast: any;
  public native: boolean = false;
  public isScanning: boolean = false;
  public development: boolean = false;
  private qrScanner: QRScanner = null;
  private backHandler: any = null;
  private scanSub: any = null;

  constructor(
    private injector: Injector,
    private route: ActivatedRoute,
    private router: Router,
    private navCtrl: NavController,
    private profileProvider: ProfilesProvider,
    private alertCtrl: AlertController,
    private menu: MenuController,
    private auth: AuthProvider,
    private toastCtrl: ToastController,
    private storage: Storage,
    private platform: Platform,
    private translate: TranslateService,
    private qrCodeProvider: QrCodeProvider)
  {
    this.native = this.platform.is('cordova');
    this.development = !environment.production;
    try {
      if(this.platform.is('cordova'))
        this.qrScanner = injector.get<QRScanner>(QRScanner);
    } catch (e) {
      this.debug(e);
    }
  }

  ngOnInit() {
    this.storage.get('whoami').then((whoami) => {
      if (whoami)
        this.loggedIn = true;
    });
  }

  ionViewDidLeave(){
    window.removeEventListener('popstate', this.backHandler, true);
    document.removeEventListener("backbutton", this.backHandler, false);

    if(this.qrScanner)
      this.qrScanner.destroy();
  }

  signUp() {
    if(this.native || this.development) {
      this.navCtrl.navigateForward('register');
    } else {
      this.qrCodeProvider.showStores();
    }
  }

  openLogin() {
    this.navCtrl.navigateForward('login');
  }

  invite() {
    this.navCtrl.navigateForward('invite');
  }

  scanQR() {
    if(!navigator.onLine)
      return;
    if(this.qrScanner) {
      this.qrScanner.getStatus().then((status)=>{
        console.log("QRScanner status:");
        console.log(status);
      });

      this.qrScanner.prepare()
        .then((status: QRScannerStatus) => {
          console.log("QRScanner prepared:");
          console.log(status);
          if (status.denied || status.restricted) {
            // camera permission was permanently denied
            // you must use QRScanner.openSettings() method to guide the user to the settings page
            // then they can grant the permission from there
            console.log('Permission denied. Open settings?');
            this.qrCodeProvider.acquirePermission(this.qrScanner);
          } else /*if (status.authorized)*/ {
            // camera permission was granted
            this.isScanning = true;
            if(!this.backHandler) {
              this.backHandler = this.closeCamera.bind(this);
            }
            window.removeEventListener('popstate', this.backHandler, true);
            window.addEventListener('popstate', this.backHandler, true);
            document.removeEventListener("backbutton", this.backHandler, false);
            document.addEventListener("backbutton", this.backHandler, false);

            // start scanning
            this.scanSub = this.qrScanner.scan().subscribe((text: string) => {
              console.log('Scanned something', text);
              this.closeCamera();
              if (text.indexOf('register') !== -1) {
                // register?code=xxx&master=xxx&company=xxx;
                const idx1 = text.indexOf('code=');
                const idx2 = text.indexOf('master=');
                const idx3 = text.indexOf('company=');
                if (idx1 !== -1) {
                  const code = text.substring(idx1 + 5, idx2 - 1);
                  const master = +text.substring(idx2 + 7, idx3 - 1);
                  const company = text.substring(idx3 + 8);
                  this.registerQRcode(code, master, company);
                } else {
                  console.log('invalid qr code');
                  this.qrCodeProvider.showInvalidQR();
                }
              } else {
                console.log('invalid qr code');
                this.qrCodeProvider.showInvalidQR();
              }
            });

            this.hideForCamera(true);
            this.qrScanner.resumePreview();
            this.qrScanner.show();

          }
          // else  {
          //   // permission was denied, but not permanently. You can ask for permission again at a later time.
          //   console.log('Permission denied. Will ask again when required.');
          //   console.log(status);
          // }
        })
        .catch((e: any) => console.log('Error is', e));
    } else {
      if (this.development) {
        // for web testing !!!
        this.registerQRcode('Testcode', 50, 'AZ Informatik');
      } else {
        this.qrCodeProvider.showStores();
      }
    }
  }

  closeCamera() {
    this.isScanning = false;
    this.hideForCamera(false);
    if (this.scanSub)
      this.scanSub.unsubscribe(); // stop scanning
    if (this.qrScanner) {
      this.qrScanner.pausePreview(); // hide camera preview
      this.qrScanner.hide();
    }

    window.removeEventListener('popstate', this.backHandler, true);
    document.removeEventListener("backbutton", this.backHandler, false);
  }

  hideForCamera(hide: boolean) {
    const hideContent = document.getElementsByClassName("hideForCamera");
    for (let n = 0; n < hideContent.length; n++) {
      const el = <HTMLElement>hideContent[n];
      el.style.opacity = hide ? "0" : "1";
    }
  }

  registerQRcode(code: string, master:number, company: string = null) {
    console.log('registerQRcode', code, master, company);
    let navExtas: NavigationExtras = {state: {code: code, master: master, company: company}}
    this.navCtrl.navigateRoot('register', navExtas);
  }

  async debug(text: string){
    const confirm = await this.alertCtrl.create({
      header: 'DEBUG',
      message: text,
      buttons: [
        {
          text: this.translate.instant("OK"),
          handler: () => {
          }
        }
      ]
    });
    await confirm.present();
  }

}
