import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the OwnFolderColorPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'ownFolderColor',
})
export class OwnFolderColorPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: any, ...args) {
    if (value.code && value.code.length > 0) {
      return "primary";
    } else {
      return "success";
    }
  }
}
