import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ToastController} from '@ionic/angular';
import { Observable } from 'rxjs';
// import { File } from '@ionic-native/file';
import { ProfilesProvider } from '../../providers/profiles/profiles';
import { RequestProvider } from '../request/request';
import { TranslateService } from '@ngx-translate/core';

declare var require: any;

/*
  Generated class for the ExportImportProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class ExportImportProvider {

  items: Observable<any[]>;
  error: any = "";
  constructor(
    public request: RequestProvider,
    public db: AngularFirestore,
    public profileProvider: ProfilesProvider,
    // public file: File,
    public trans: TranslateService,
    private toastCtrl: ToastController,
  ) {
    //console.log('Hello ExportImportProvider Provider');
    this.trans.get("REQUIRED").subscribe((res: string) => {
      this.error = res;
    });
  }

  export(item) {
    let check = this.checkItems(item);
    if(check > 1) {
      this.toastCtrl.create({
        message: this.error,
        duration: 5000,
        position: 'middle',
      }).then(toast=>toast.present());
      return 1;
    }
    this.profileProvider.active = item;
    item.status = 1;
    item.changes = 0;
    item.adminSeeIt = 0;
    this.profileProvider.save();
    let obj = this.profileProvider.active;
    obj.folderInfos = this.profileProvider.currentFolder
    this.request.post('auth/profile/create', obj, true).subscribe(data => {
      console.log(data);
    }, err => {
      console.log(err);
      console.log("ERROR ");
    }, () => console.log("TOKEN FINISH"));
  }

  makeTemplate(item) {
    this.profileProvider.active = item;
    item.status2 = 'template';
    item.status = 0;
    item.changes = 0;
    item.adminSeeIt = 0;
    this.profileProvider.save();
  }

  checkItems(item) {
    /*this.active.numberOfPiecesToManufacture.splice(this.index, 1)
    this.active.paintPosition.splice(this.index, 1)
    this.active.paint.splice(this.index, 1)
    this.active.materialName.splice(this.index, 1)
    this.active.materialThickness.splice(this.index, 1)
    this.active.width.splice(this.index, 1) */
    for (let i = 0; i < item.numberOfPiecesToManufacture.length; i++) {
      if (item.numberOfPiecesToManufacture[i] < 1) {
        return 2;
      }
    }

    // for (let i = 0; i < item.paintPosition.length; i++) {
      if (!item.paintColor || item.paintColor == "") {
        return 3;
      }
    // }

    // for (let i = 0; i < item.paintPosition.length; i++) {
      if (!item.paintPosition || item.paintPosition == "") {
        return 4;
      }
    // }

    // for (let i = 0; i < item.materialName.length; i++) {
      if (!item.materialName || item.materialName == "" ) {
        return 5;
      }
    // }

    // for (let i = 0; i < item.materialThickness.length; i++) {
      if (item.materialThickness <= 0 || item.materialThickness > 4.0) {
        return 6;
      }
    // }

    for (let i = 0; i < item.width.length; i++) {
      if (item.width[i] <= 0) {
        return 7;
      }
    }

    if (!item.name) {
      return 8;
    }

    return 1;
  }

  ok() {
    let item = this.profileProvider.active;
    item.status = 4;
    item.changes = 0;
    this.profileProvider.save();
    let obj = {id: item.id, status: 4};
    this.request.post('auth/updateProfile',obj,true).subscribe(data => {
      console.log(data);
    }, err => {
      console.log(err);
    })
  }

  import(item): any {
    let profiles = null;
    let xmlItem = item.csv2;
    if (xmlItem !== undefined && xmlItem !== null) {
      xmlItem = xmlItem.toString();
      xmlItem = xmlItem.replace(/\\/g, "");
      xmlItem = xmlItem.replace(/\"/g, "");
      const jsonItem = this.parseXml(xmlItem);
      if (jsonItem !== undefined && jsonItem !== null && jsonItem.XMLImport !== undefined) {
        profiles = jsonItem.XMLImport.Profile;
      }
    }
    return profiles;
  }

  importAndConvert(item): any {
    const jsonProfiles = this.import(item);
    if (jsonProfiles !== null && jsonProfiles.length > 0) {
      this.profileProvider.jsonItem = jsonProfiles[0];
      //Refresh Metadata
      item.numberOfPiecesToManufacture = [];
      item.width = [];
      if (jsonProfiles[0].ProfileName)
        item.name = jsonProfiles[0].ProfileName[0];
      if (jsonProfiles[0].LockTogether)
        item.lockTogether = jsonProfiles[0].LockTogether[0] && jsonProfiles[0].LockTogether[0] !== "false";
      if (jsonProfiles[0].MaterialThickness)
        item.materialThickness = jsonProfiles[0].MaterialThickness[0];

      item.materialName = jsonProfiles[0].MaterialName;

      if (jsonProfiles[0].PaintPosition)
        switch (jsonProfiles[0].PaintPosition[0]) {
          case "bottom":
          case "down":
            item.paintPosition = "down";
            break;
          case "top":
            item.paintPosition = "top";
            break;
          case "none":
          case "colorPositionNone":
            item.paintPosition = "none";
            break;
          default:
            item.paintPosition = undefined;
            break;
        }
      if (jsonProfiles[0].PaintColor)
        item.paintColor = jsonProfiles[0].PaintColor[0];

      let idx = 0;
      for (let jsonProfile of jsonProfiles) {
        if (jsonProfile.SheetLength)
          item.width.push(jsonProfile.SheetLength[0] * 1000); // convert from m to mm
        if (jsonProfile.NumberOfPiecesToManufacture)
          item.numberOfPiecesToManufacture.push(jsonProfile.NumberOfPiecesToManufacture[0]);
        idx++;
      }
      return item;
    } else {
      return null;
    }
  }

  parseXml(xmlStr): any {
    var result;
    var parser = require('xml2js');
    parser.Parser().parseString(xmlStr, (e, r) => {result = r});
    return result;
  }

  addUser() {

  }

  exportRequest() {
    let item = this.profileProvider.active;
    item.status = 4;
    item.changes = 0;
    this.profileProvider.save();
  };


  buildXML(item) {
    var xml = '<?xml version="1.0" standalone="yes"?>';
    xml += '<XMLImport>';
    xml += '<Profile>';
    xml += '<ProfileName>' + item.name + '</ProfileName>';
    if (item.width) {
      xml += '<SheetLength>' + item.width + '</SheetLength>';
    } else {
      xml += '<SheetLength>0</SheetLength>';
    }
    if (item.paintPosition) {
      xml += '<PaintPosition>' + item.paintPosition + '</PaintPosition>';
    } else {
      xml += '<PaintPosition></PaintPosition>';
    }

    xml += '<TotalConicity>0</TotalConicity>';
    if (item.materialName) {
      xml += '<MaterialName>' + item.materialName + '</MaterialName>';
    } else {
      xml += '<MaterialName></MaterialName>';
    }

    if (item.numberOfPiecesToManufacture) {
      xml += '<NumberOfPiecesToManufacture>' + item.numberOfPiecesToManufacture + '</NumberOfPiecesToManufacture>';
    } else {
      xml += '<NumberOfPiecesToManufacture>1</NumberOfPiecesToManufacture>';
    }

    if (item.materialThickness) {
      xml += '<MaterialThickness>' + item.materialThickness + '</MaterialThickness>';
    } else {
      xml += '<MaterialThickness>0</MaterialThickness>';
    }

    if (item.cut) {
      xml += '<Width>' + item.cut + '</Width>';
    } else {
      xml += '<Width>0</Width>';
    }
    xml += '<AngleCorrection>0</AngleCorrection>';
    xml += '<LockTogether>item.lockTogether</LockTogether>';

    xml += this.loadSections(item);
    xml += '</Profile>';
    xml += '</XMLImport>';

    return xml;
  }


  loadSections(item) {
    let xml = "";

    if (typeof item.data != 'undefined' && item.data.length > 1) {
      for (let i = 1; i < item.data.length; i++) {
        xml += "<ProfileSection>";
        xml += "<ProfileSectionID>" + i + "</ProfileSectionID>";
        xml += "<SectionType>profile_edge</SectionType>";
        xml += "<Conicity>item.data[i].distance.conicity</Conicity>";
        xml += "<ProfileEdge>"
        xml += "<ProfileSectionID>" + i + "</ProfileSectionID>"
        xml += "<HemMode>none</HemMode>"
        xml += "<HemDirection>down</HemDirection>"
        if (item.data[i].angle.angleRound && item.data[i].angle.angleRound == 360) {
          xml += "<IsWaterFold>true</IsWaterFold>"
        } else {
          xml += "<IsWaterFold>false</IsWaterFold>"
        }
        xml += "<Length>" + item.data[i].distance.distance + "</Length>"
        if (item.data[i].angle.angleRound) {
          if (item.data[i].angle.angleRaw > 180) {
            xml += "<Angle>" + Math.round((item.data[i].angle.angleRaw - 180) / 5) * 5 * -1 + "</Angle>"
          } else {
            xml += "<Angle>" + Math.round(item.data[i].angle.angleRaw / 5) * 5 + "</Angle>"
          }
        } else {
          xml += "<Angle>0</Angle>";
        }

        xml += "</ProfileEdge>"
        xml += "</ProfileSection>"
      }
    }

    return xml;
  }




}
