import {Component, OnInit} from '@angular/core';
import {NavParams, PopoverController} from '@ionic/angular';

@Component({
  selector: "tooltip",
  templateUrl: "tooltip.html",
  styleUrls: ['tooltip.scss']
})
export class TooltipComponent implements OnInit {

  public info: string;

  constructor(public navParams: NavParams,
              private popoverController: PopoverController) {
  }

  ngOnInit(): void {
    this.info = this.navParams.get('info');
  }

  nextSimulation(): void {
    this.popoverController.dismiss({stop: false});
  }

  stopSimulation(): void {
    this.popoverController.dismiss({stop: true});
  }

}
