import {Component, ViewChild} from '@angular/core';
import {ModalController, NavController, NavParams, ToastController} from '@ionic/angular';
import {AuthProvider} from '../../providers/auth/auth';
import {AddressProvider} from '../../providers/addresses/addresses';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: "page-address",
  templateUrl: "address.html",
  styleUrls: ['./address.scss']
})
export class AddressPage {

  uid: any = null;
  email: any = null;
  name: any = null;
  profile: any;
  whoami: any;
  addresses: any = [];
  address: any = null;
  public addressOptions = {
    header: this.trans.instant('USERNAME')
  };
  @ViewChild("mail", {}) mailField;

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public viewCtrl: ModalController,
    public toastCtrl: ToastController,
    private auth: AuthProvider,
    private trans: TranslateService,
    private addressProvider: AddressProvider
  ) {
    this.profile = navParams.get("profile");
    this.whoami = navParams.get("whoami");
    this.addressProvider.loadAddresses(this.whoami).subscribe((_addresses) => this.addresses = [..._addresses]);
  }

  ngOnInit() {
    setTimeout(() => {
      this.mailField.setFocus();
    }, 500);
  }

  async share() {
    if (this.address !== null) {
      this.fillFromAddress();
    } else if (!this.email || this.email.length < 3 || this.email.indexOf('@') === -1) {
      this.toastCtrl.create({
        message: this.trans.instant("ADDRESS-NO-VALID-EMAIL"),
        duration: 3000,
        position: 'bottom'
      }).then(toast=>toast.present());
      return;
    }
    if (this.uid === null && this.email !== null) {
      let n = this.addresses.findIndex((address) => address.email === this.email);
      if(n >= 0) {
        this.address = this.addresses[n];
        this.fillFromAddress();
      } else {
        let wait = await this.auth.emailUserId(this.email);
        if (wait && wait.error) {
          let data = {
            'uid': null,
            'name': null,
            'email': this.email
          };
          await this.viewCtrl.dismiss(data);
          return;
        } else {
          const address = {
            id: null,
            uid: wait.ret.id,
            email: this.email,
            name: wait.ret.name ? wait.ret.name + " (" + this.email + ")" : this.email
          }
          setTimeout(() => {
            this.addressProvider.addAddress(this.whoami, address);
          }, 10);
          this.email = address.email;
          this.name = address.name;
          this.uid = address.uid;
        }
      }
    }
    if (this.uid !== null && this.email !== null) {
      let data = {
        'uid': this.uid,
        'name': this.name,
        'email': this.email
      };
      await this.viewCtrl.dismiss(data);
    }
  }

  private fillFromAddress() {
    this.email = this.address.email;
    this.name = this.address.name;
    this.uid = this.address.uid;
  }

  async cancel() {
    await this.viewCtrl.dismiss();
  }

}
