<ion-content class="ion-text-center">
  <ion-grid>
    <ion-row class="ion-margin-top">
      <ion-col>
          <img src="assets/imgs/logo.svg" width="400" class="ion-margin-top">
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-md="4" offset-md="4">
        <form id="invite" [formGroup]="inviteForm">
          <ion-item lines="none" class="ion-no-padding">
            <ion-label position="stacked">{{'YOUR-NAME'| translate}}</ion-label>
            <ion-input type="text" placeholder="{{'NAME'| translate}}" name="name"
                       [formControl]="inviteForm.controls['name']"
                       [ngClass]="!inviteForm.valid && !inviteForm.controls['name'].valid
                       && inviteForm.controls['name'].touched ?'missing':''">
            </ion-input>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding ion-padding-top">
            <ion-label position="stacked">{{'INVITE-RESPONSE'| translate}}</ion-label>
            <ion-input type="email" placeholder="{{'EMAIL'| translate}}" name="from"
                       [formControl]="inviteForm.controls['from']"
                       [ngClass]="!inviteForm.valid && !inviteForm.controls['from'].valid
                       && inviteForm.controls['from'].touched ?'missing':''">
            </ion-input>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding ion-padding-top">
            <ion-label position="stacked">{{'INVITE-TO'| translate}}</ion-label>
            <ion-input type="email" placeholder="{{'EMAIL'| translate}}" name="to"
                       [formControl]="inviteForm.controls['to']"
                       [ngClass]="!inviteForm.valid && !inviteForm.controls['to'].valid
                       && inviteForm.controls['to'].touched ?'missing':''">
            </ion-input>
          </ion-item>
        </form>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button color="primary" [disabled]="!inviteForm.valid" (click)="inviteSubmit()">
          {{'SUBMIT'| translate}}
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-md="4" offset-md="4">
        <a button class="ion-margin-bottom" href="signin">{{'BACK'| translate}}</a>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
