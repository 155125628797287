<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start" class="ion-no-margin">
      <ion-menu-button fill="solid" color="light"></ion-menu-button>
    </ion-buttons>
    <ion-title>{{'SETTINGS'| translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="hideForCamera" [hidden]="isScanning">
  <ion-item class="ion-margin-top">
   <ion-grid>
     <ion-row class="ion-align-items-center">
       <ion-col>{{"LANG" | translate}}</ion-col>
       <ion-col>
         <ion-button *ngIf="settings.lang == 'de'" fill="solid" expand="block" color="primary" (click)="lang('de')">
           <u>{{ "GERMAN" | translate}}</u>
         </ion-button>
         <ion-button *ngIf="settings.lang != 'de'" fill="solid" expand="block" color="medium" (click)="lang('de')">
           {{ "GERMAN" | translate}}
         </ion-button>
       </ion-col>
       <ion-col>
         <ion-button *ngIf="settings.lang == 'en'" fill="solid" expand="block" color="primary" (click)="lang('en')">
           <u>{{ "ENGLISCH" | translate}}</u>
         </ion-button>
         <ion-button *ngIf="settings.lang != 'en'"fill="solid" expand="block" color="medium" (click)="lang('en')">
           {{ "ENGLISCH" | translate}}
         </ion-button>
       </ion-col>
     </ion-row>
   </ion-grid>
  </ion-item>
  <ion-item *ngIf="(isMaster && !hasSubUser) && (development || native)">
    <ion-grid>
      <ion-row class="ion-align-items-center">
        <ion-col>{{"JOIN-TEAM" | translate}}</ion-col>
        <ion-col>
          <ion-button  fill="solid" color="primary" expand="block" (click)="scanQR()">
            <ion-icon name="qr-code-outline" class="ion-padding-horizontal"></ion-icon>
            <ion-label>{{"QR-CODE-SCAN"| translate}}</ion-label>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
  <ion-item lines="none" class="email-setting">
    <ion-grid>
      <ion-row>
        <ion-col>{{"USERNAME" | translate}}</ion-col>
        <ion-col>{{email}}</ion-col>
        <ion-col>
          <a href="http://backend.variobend.de/dashboard/password/reset">{{'RESETPASSWORD'| translate}}</a>
        </ion-col>
      </ion-row>
    </ion-grid>
  <ion-item lines="none">
  </ion-item>
    <ion-button slot="end" fill="solid" color="primary" expand="block" (click)="deleteAccount()" style="margin-right: 10px">
      <ion-icon name="trash" class="ion-padding-horizontal"></ion-icon>
      <ion-label>{{"DELETE-ACCOUNT"| translate}}</ion-label>
    </ion-button>
  </ion-item>
</ion-content>
<ion-content class="cameraBackground" [hidden]="!isScanning">
  <ion-toolbar color="transparent">
    <ion-buttons slot="end" class="ion-no-margin">
      <ion-button size="large" fill="solid" color="light" (click)="closeCamera()">
        <ion-icon name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-content>
<ion-footer >
  <ion-toolbar color="dark" class="ion-no-padding ion-no-margin">
    <ion-buttons slot="start" class="ion-no-padding ion-no-margin">
      <ion-button fill="solid" color="primary" class="ion-no-margin">Version: 3.0.0</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
