import { Component } from '@angular/core';
import { NavController, ToastController} from '@ionic/angular';
import { MessagesProvider} from '../../shared/providers/messages/messages';
import { Storage} from '@ionic/storage';
import { TranslateService} from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { AuthProvider} from '../../shared/providers/auth/auth';
import { MailProvider} from '../../shared/providers/mails/mails';
import { ProfilesProvider } from '../../shared/providers/profiles/profiles';
import { first, timeout} from 'rxjs/operators';
import { Utils } from "../../shared/providers/utils/utils";

@Component({
  selector: "page-messages",
  templateUrl: "messages.html",
  styleUrls: ['./messages.scss']
})
export class MessagesPage {

  public messages: any[] = [];
  public whoami: any = null;
  public subuser: any = null;
  public toast: any;

  constructor(
    public navCtrl: NavController,
    private storage: Storage,
    private messagesProvider: MessagesProvider,
    private mailProvider: MailProvider,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    private auth: AuthProvider,
    private profileProvider: ProfilesProvider) {
  }

  ngOnInit() {
    this.storage.get('whoami').then(async (whoami) => {
      if(whoami && whoami.id) {
        this.whoami = whoami;
        this.subuser = await this.storage.get('subuser');
        this.messages.length = 0;
        this.messagesProvider.loadMessages(this.subuser ? this.subuser : whoami).subscribe(
          async (_messages) => {
            if (_messages.length === 0) {
              await (await this.toastCtrl
                .create({
                  message: this.translate.instant("NO-MESSAGES"),
                  duration: 3000,
                  position: "bottom"
                }))
                .present();
            } else {
              this.messages.length = 0;
              this.messages.push(..._messages);
            }
          });
        this.messagesProvider.loadMessagesByEmail(this.subuser ? this.subuser : this.whoami).subscribe(
          async (_messages) => {
            if (_messages.length > 0) {
              this.messages.push(..._messages);
            }
          });
      }
    });
  }

  public ionViewWillLeave() {
    const newMessages = this.messages.filter((m) => m.status === 'new');
    newMessages.forEach((m: any) => {
      m.status = 'checked';
      if (m.uid === null) {
        m.uid = this.subuser ? this.subuser.id : this.whoami.id;
      }
      this.messagesProvider.update(m);
    });
    Utils.newMessages = 0;
  }

  public timeToMoment(timestamp: any) {
    return moment(new Date(timestamp));
  }

  public async inviteCodeRequest(message: any) {
    this.toast = this.toastCtrl.create({
      message: this.translate.instant("CODE-REQUEST-DONE"),
      duration: 3000,
      position: 'middle',
    }).then(toast => toast.present());
    let wait = await this.auth.inviteCode(this.whoami);
    if (wait && wait.error) {
      this.toast = this.toastCtrl.create({
        message: wait.error.error,
        duration: 3000,
        position: 'middle',
      }).then(toast => toast.present());
    } else {
      const link = environment.appLink + '/register?code=' + wait.ret.code + '&email=' + message.fromEmail;
      console.log(link);
      const mail: { id: any, uid: string, from: string, to: string; template: any  } =
        {
          id: null,
          uid: this.whoami.id,
          from: environment.fromEmail,
          to: message.fromEmail,
          template: {
            name: 'CODE-REQUEST-ACCEPTED',
            data: {
              from: message.toEmail,
              link: link
            }
          },
        };
      await this.mailProvider.addMail(mail);
      message.status = 'accepted';
      this.messagesProvider.update(message);
    }
  }

  public rejectCodeRequest(message: any) {
    this.toast = this.toastCtrl.create({
      message: this.translate.instant("CODE-REQUEST-DONE"),
      duration: 3000,
      position: 'middle',
    }).then(toast => toast.present());
    const mail: { id: any, uid: string, from: string, to: string; template: any  } =
      {
        id: null,
        uid: this.whoami.id,
        from: environment.fromEmail,
        to: message.fromEmail,
        template: {
          name: 'CODE-REQUEST-REJECTED',
          data: {
            from: message.toEmail
          }
        },
      };
    this.mailProvider.addMail(mail);
    message.status = 'rejected';
    this.messagesProvider.update(message);
  }

  public async importProfile(message: any) {
    this.profileProvider.isProjectSource = false;
    let folderId = null;
    if (message.data !== null) {
      const folders = await this.profileProvider.loadFolderByName(this.whoami, message.fromName).pipe(first(), timeout(2000)).toPromise();
      if (folders && folders.length > 0) {
        folderId = folders[0].id;
        this.profileProvider.currentFolder = folders[0];
      } else {
        folderId = await this.profileProvider.addNamedFolder(this.whoami, message.fromName);
      }
      message.data.status = 0; // change modus or not?
      message.data.folderPath = folderId;
      await this.profileProvider.addSharedProfile(this.whoami, folderId, message.data);
      message.status = 'imported';
      message.data = null;
      this.messagesProvider.update(message);
      this.toast = this.toastCtrl.create({
        message: this.translate.instant("SHARE-PROFILE-DONE"),
        duration: 3000,
        position: 'middle',
      }).then(toast => toast.present());
      this.navCtrl.navigateForward('profiles'); // go to profiles or not?
    }
  }

}
