import { AuthProvider } from './../../providers/auth/auth';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";


/**
 * Generated class for the OwnFolderPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'ownFolder',
})
export class OwnFolderPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  customerTrans: any;
  ownTrans: any;
  name: any;
  static cache: any
  constructor(public trans: TranslateService,public auth: AuthProvider) {
    this.trans.get("CUSTOMER").subscribe((res: string) => {
      this.customerTrans = res;
    });
    this.trans.get("OWN").subscribe((res: string) => {
      this.ownTrans = res;
    });
    if(!OwnFolderPipe.cache)
      OwnFolderPipe.cache = new Object();
  }

  async transform(value: any, ...args) {

    //console.log(value);
    if (value.code && value.code.length > 0) {
      if(value.code in OwnFolderPipe.cache)
        return OwnFolderPipe.cache[value.code];
      let name = await this.auth.getName({code: value.code});
      if (name) {
        //console.log("FINISH");
        OwnFolderPipe.cache[value.code] = name;
        return name;
      } else {
        return value.code;
      }

    } else {
      return this.ownTrans;
    }

  }
}
