import { Injectable } from '@angular/core';
import { RequestProvider } from '../request/request';
import { Storage } from '@ionic/storage';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Observable } from 'rxjs';
import { switchMap} from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

/*
  Generated class for the AuthProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class AuthProvider {

  public master: any = false;
  public isB2B: any = false;
  public isPM: any = false;
  public user: Observable<any>;

  constructor(public request: RequestProvider,
              private storage: Storage,
              private fireAuth: AngularFireAuth
  ) {
    this.user = this.fireAuth.authState.pipe<any>(
      switchMap((user) => {
        if (user) {
          return of(user);
        } else {
          return of(null);
        }
      })
    );
  }

  getName(values): Promise<any> {
      return new Promise((resolve, reject) => {
      this.request.post('auth/name', values, false).subscribe(data => {
        //console.log(data);
        resolve(data.ret.name);
      }, err => {
        resolve(false);
      }, () => console.log("NAME FINISH"));
    });
  }

  getToken(values, userAgent): Promise<any> {
    return new Promise((resolve, reject) => {
      values.device = userAgent;
      this.request.post('auth/login', values, false).subscribe(data => {
        //console.log(data);
        let uid = null;
        if (data && !data.error && data.access_token && data.uid) {
          //Mainuser
          this.storage.set("deviceMaster", data.deviceMaster);
          this.master = true;
          localStorage.setItem("token", data.access_token);
          this.storage.set("b2b", data.b2b);
          this.storage.set("expires", data.expires_at);
          uid = data.uid;
        } else if (data && !data.error && data.ret.id && data.ret.code && data.ret.uid && data.ret.access_token) {
          //Subuser
          this.storage.set("device", data.ret.id);
          this.storage.set("whoami", data.ret.user);
          this.storage.set("master", false);
          this.storage.set("code", data.ret.code);
          localStorage.setItem("token", data.ret.access_token);
          this.storage.set("expires", data.ret.expires_at);
          this.getCodeUser(data.ret.code, null).then((subuser)=>{
            this.storage.set("subuser", subuser.ret.user);
          });
          this.master = false;
          uid = data.ret.uid;
        }
        //Firebase authentication
        if (!data.error && uid !== null) {
          this.request.post('auth/customToken', {'uid': uid}, true).subscribe(firebase => {
            const customToken = firebase.ret.customToken.join('.');
            //console.log(customToken);
            this.fireAuth.signInWithCustomToken(customToken).then(() => {
              console.log('Firebase verified token');
              this.storage.set("firebase2", customToken);
              resolve(data);
            }).catch(
              function (error) {
                console.log(error.message);
                const err = {error: "Error...."};
                resolve(err);
                console.log("CUSTOM TOKEN ERR");
              });
          })
        } else {
          resolve(data);
        }
      }, err => {
        err.error = { error: "Error...." };
        resolve(err);
        console.log("TOKEN ERR");
        console.log(err);
      }, () => console.log("TOKEN FINISH"));
    });
  }

  setToken(access_token: any, expires_at: any, deviceMaster: any, uid: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let savedDevice = await this.storage.get("deviceMaster");
      let savedCustomToken = await this.storage.get("firebase2");
      if(savedDevice != deviceMaster)
        this.storage.set("deviceMaster", deviceMaster);
      /*this.storage.get('code').then((code) => {
        if (code && data.master) {
          this.master = data.master;
        }
      });*/
      localStorage.setItem("token", access_token);
      this.storage.set("expires", expires_at);
      //Firebase authentication
      if(this.fireAuth.currentUser)
        console.log('Firebase already logged in');
      else{
        if(savedDevice == deviceMaster && savedCustomToken){
          this.fireAuth.signInWithCustomToken(savedCustomToken).then(() => {
            console.log('Firebase verified token');
            resolve(savedCustomToken);
          }).catch(
            function (error) {
              this.request.post('auth/customToken', {'uid': uid}, true).subscribe(firebase => {
                const customToken = firebase.ret.customToken.join('.');
                //console.log(customToken);
                this.fireAuth.auth.signInWithCustomToken(customToken).then(() => {
                  console.log('Firebase verified token');
                  this.storage.set("firebase2", customToken);
                  resolve(customToken);
                }).catch(
                  function (error) {
                    console.log(error.message);
                    const err = {error: "Error...."};
                    console.log("CUSTOM TOKEN ERR");
                    reject(err);
                  });
              });
            }.bind(this));
        }
        else {
          this.request.post('auth/customToken', {'uid': uid}, true).subscribe(firebase => {
            const customToken = firebase.ret.customToken.join('.');
            //console.log(customToken);
            this.fireAuth.signInWithCustomToken(customToken).then(() => {
              console.log('Firebase verified token');
              this.storage.set("firebase2", customToken);
              resolve(customToken);
            }).catch(
              function (error) {
                console.log(error.message);
                const err = {error: "Error...."};
                console.log("CUSTOM TOKEN ERR");
                reject(err);
              });
          });
        }
      }
    });
  }

  whoami(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.request.get('auth/user', true).subscribe(data => {
        this.storage.set('whoami', data);
        if(data!=null){
          this.isB2B = data.b2b > 0;
          this.isPM = data.pm > 0;
        }
        resolve(data);
      }, err => {
        console.log("Whoami ERR");
        err.error = { error: "Error...." };
        resolve(false);
      }, () => console.log("user FINISH"));
    });
  }

  licenseInfo(uid: any, username: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.request.post('auth/licenseInfo', {uid: uid}, true).subscribe(licenseInfo => {
        licenseInfo.username = username;
        console.log(licenseInfo);
        this.storage.set('license', licenseInfo);
        resolve(licenseInfo);
      }, err => {
        console.log("License ERR");
        err.error = { error: "Error...." };
        resolve(null);
      }, () => console.log("License FINISH"));
    });
  }

  registerMain(form): Promise<any> {
    return new Promise((resolve, reject) => {
      this.request.post('auth/registerMain', form, true).subscribe(data => {
        resolve(data);
      }, err => {
        err.error = { error: "Error...." };
        resolve(err);
      }, () => console.log("registerMain FINISH"));
    });
  }

  registerSub(form): Promise<any> {
    return new Promise((resolve, reject) => {
      this.request.post('auth/registerSub', form, true).subscribe(data => {
        resolve(data);
      }, err => {
        err.error = { error: "Error...." };
        resolve(err);
      }, () => console.log("registerSub FINISH"));
    });
  }

  getCode(form, userAgent): Promise<any> {
    return new Promise((resolve, reject) => {
      form.device = userAgent;
      this.request.post('auth/code', form, true).subscribe(data => {
        console.log("GET CODE");
        console.log(data);
        resolve(data);
      }, err => {
        err.error = { error: "Error...."};
        resolve(err);
      }, () => console.log("code FINISH"));
    });
  }

  getCodeUser(code, device): Promise<any> {
    return new Promise((resolve, reject) => {
      this.request.post('auth/codeUser', {code: code, device: device}, true).subscribe(data => {
        console.log("GET CODEUSER");
        console.log(data);
        resolve(data);
      }, err => {
        err.error = { error: "Error...."};
        resolve(err);
      }, () => console.log("CODEUSER FINISH"));
    });
  }

  emailUserId(email): Promise<any> {
    return new Promise((resolve, reject) => {
      this.request.post('auth/emailUserId', {email: email}, true).subscribe(data => {
        console.log(data);
        resolve(data);
      }, err => {
        err.error = { error: "Error...."};
        resolve(err);
      }, () => console.log("USERID FINISH"));
    });
  }

  inviteCode(whoami: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.request.post('auth/inviteCode', {uid: whoami.id}, true).subscribe(data => {
        console.log("INVITE CODE");
        console.log(data);
        resolve(data);
      }, err => {
        console.log("inviteCode ERR");
        err.error = { error: "Error...." };
        resolve(false);
      }, () => console.log("InviteCode FINISH"));
    });
  }

  // registerCode(form): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this.request.post('auth/registerCode', form, true).subscribe(data => {
  //       console.log("GET CODE");
  //       console.log(data);
  //
  //       if (!data.error && data.ret.id && data.ret.user) {
  //         this.storage.set("device", data.ret.id);
  //         this.storage.set("whoami", data.ret.user);
  //         this.storage.set("master", false);
  //         this.master = false;
  //       }
  //       resolve(data);
  //     }, err => {
  //       console.log("Whoami ERR");
  //       err.error = { error: "Error...." };
  //       resolve(err);
  //     }, () => console.log("Whoami FINISH"));
  //   });
  // }

  checkDevice(device, isMaster = false): Promise<any> {
    return new Promise((resolve, reject) => {
      this.request.post('auth/checkCode', isMaster ? { deviceMaster: device } : { device: device }, true).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      }, () => console.log("checkCode FINISH"));
    });
  }

  deleteDevice(device, isMaster = false): Promise<any> {
    return new Promise((resolve, reject) => {
      console.log(device);
      this.request.post('auth/deleteCode', isMaster ? { deviceMaster: device } : { device: device }, true).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      }, () => console.log("deleteCode FINISH"));
    });
  }

  deleteAccount(whoami: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.request.post('auth/deleteAccount', {uid: whoami.id}, true).subscribe(data => {
        resolve(data);
      }, err => {
        console.log("deleteAccount ERR");
        err.error = { error: "Error...." };
        resolve(false);
      }, () => console.log("deleteAccount FINISH"));
    });
  }

  masterToSubUser(values): Promise<any> {
    return new Promise((resolve, reject) => {
      this.request.post('auth/masterToSubUser', values, true).subscribe(data => {
        resolve(data);
      }, err => {
        err.error = { error: "Error...." };
        resolve(err);
      }, () => console.log("masterToSubUser FINISH"));
    });
  }

  logout() {
    this.fireAuth.signOut();
  }
}
