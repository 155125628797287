<ion-app>
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list lines="none" class="ion-no-padding">
          <ion-item class="ion-text-center">
            <ion-menu-button fill="solid" color="medium"></ion-menu-button>
          </ion-item>
          <ion-menu-toggle auto-hide="false" *ngFor="let p of pages; let i = index">
            <ion-item *ngIf="p.url !== null && !(p.url=='machines' && !isMaster()) && p.url!='logout'" routerDirection="forward" [routerLink]="[p.url]" class="menu-item" color="primary" lines="none" detail="false">
              <ion-label>{{ p.title | translate | uppercase}}</ion-label>
              <ion-badge *ngIf="p.url=='messages' && messagesCount() > 0" color="danger">{{messagesCount()}}</ion-badge>
            </ion-item>
<!--            <ion-item *ngIf="p.url === null" class="menu-item" color="primary" lines="none" detail="false" (click)="deleteAccount()">-->
<!--              <ion-label>{{ p.title | translate | uppercase}}</ion-label>-->
<!--            </ion-item>-->
            <ion-item *ngIf="p.url=='logout'" class="menu-item" color="primary" lines="none" detail="false" (click)="showLogout()">
              <ion-label>{{ p.title | translate | uppercase}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
</ion-app>
