<ion-header class="ion-no-border">
  <ion-toolbar color="dark">
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-no-padding">
        <ion-col size-xs="1" size-xl="1" class="ion-no-padding">
          <ion-menu-button fill="solid" color="medium"></ion-menu-button>
          <ion-badge *ngIf="getMessagesCount() > 0" color="danger">{{getMessagesCount()}}</ion-badge>
        </ion-col>
        <ion-col size-xs="8" offset-xs="1"  size-xl="8" offset-xl="1" class="ion-no-padding ion-text-center">
          <img src="assets/imgs/logo.svg" width="400" class="ion-margin-top">
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center ion-align-self-center ion-justify-content-center">
  <div class="toplevel-div">
    <ion-grid>
      <ion-row>
        <ion-col size-md="4" offset-md="4">
          <ion-button color="primary" expand="block" rel="noreferrer" target="_system" (click)="openNews()">
            <div class="dashboard-button">
            <ion-icon name="newspaper-outline" class="ion-padding-horizontal"></ion-icon>
            {{'NEWS'| translate}}
            </div>
          </ion-button>
        </ion-col>
        <ion-col size-md="4" offset-md="4">
          <ion-button color="primary" expand="block" (click)="openDrawing()">
            <div class="dashboard-button">
            <ion-icon name="create-sharp" class="ion-padding-horizontal"></ion-icon>
            {{'DRAW'| translate}}
            </div>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-md="4" offset-md="4" (click)="showMessage()">
          <ion-button  color="primary" [disabled]="!loggedIn" expand="block" (click)="openProfiles()">
            <div class="dashboard-button">
            <ion-icon name="file-tray-stacked-sharp" class="ion-padding-horizontal"></ion-icon>
            {{'PROFILE2'| translate}}
            </div>
          </ion-button>
        </ion-col>
        <ion-col size-md="4" offset-md="4">
          <ion-button *ngIf="!loggedIn" color="primary" expand="block" (click)="openSignIn()">
            <div class="dashboard-button">
            <ion-icon name="log-in-outline" class="ion-padding-horizontal"></ion-icon>
            {{'SIGNIN'| translate}}
            </div>
          </ion-button>
          <ion-button *ngIf="loggedIn && !pm" color="primary" expand="block" (click)="showLogout()">
            <div class="dashboard-button">
              <ion-icon name="log-out-outline" class="ion-padding-horizontal"></ion-icon>
              {{'LOGOUT'| translate}}
            </div>
          </ion-button>
          <ion-button *ngIf="pm" color="primary" expand="block" (click)="openProjects()">
            <div class="dashboard-button">
              <ion-icon name="home" class="ion-padding-horizontal"></ion-icon>
              {{'PROJECTS'| translate}}
            </div>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
