<ion-header>
  <ion-toolbar color="dark">
    <ion-title>{{'DRAW'| translate}}</ion-title>
    <ion-buttons slot="end" class="ion-no-margin">
      <ng-container *ngIf="!isB2B()">
        <ion-button #infoBtn fill="solid" color="primary" class="ion-no-margin borderright" (click)="information($event)">
          <ion-icon name="information-circle"></ion-icon>&nbsp;{{'INFO'| translate}}
        </ion-button>
        <ng-container *ngIf="isWaiting()">
          <ion-button fill="solid" color="primary" class="ion-no-margin borderright" (click)="corrected()">
            <ion-icon name="thumbs-up-outline"></ion-icon>
            <span class="ion-hide-sm-down">&nbsp;{{'CORRECTED'| translate}}</span>
          </ion-button>
          <ion-button fill="solid" color="primary" class="ion-no-margin borderright" (click)="declined()">
            <ion-icon name="thumbs-down-sharp"></ion-icon>
            <span class="ion-hide-sm-down">&nbsp;{{'DECLINED'| translate}}</span>
          </ion-button>
        </ng-container>
        <ng-container *ngIf="isReleaseable()">
          <ion-button fill="solid" color="primary" class="ion-no-margin borderright" (click)="ok()">
            <ion-icon name="thumbs-up-sharp"></ion-icon>
            <span class="ion-hide-sm-down">&nbsp;{{'RELEASE'| translate}}</span>
          </ion-button>
          <ion-button fill="solid" color="primary" class="ion-no-margin borderright" (click)="declined()">
            <ion-icon name="thumbs-down-sharp"></ion-icon>
            <span class="ion-hide-sm-down">&nbsp;{{'DECLINED'| translate}}</span>
          </ion-button>
        </ng-container>
        <ion-button #cancelBtn fill="solid" color="primary" class="ion-margin-start" (click)="cancel($event)">
          <ion-icon name="close-circle"></ion-icon>
          <span class="ion-hide-sm-down">&nbsp;{{'CLOSE'| translate}}</span>
        </ion-button>
      </ng-container>
      <ng-container *ngIf="isB2B()">
        <ion-button fill="solid" color="primary" class="ion-margin-start" (click)="cancel($event)">
          <ion-icon name="checkmark-circle"></ion-icon>
          <span class="ion-hide-sm-down">&nbsp;{{'DONE'| translate}}</span>
        </ion-button>
      </ng-container>
    </ion-buttons>
  </ion-toolbar>
  <ion-grid *ngIf="showInfo" class="infobox grid-padding" id="profileInfo">
    <ion-row *ngIf="!isB2B()" class="ion-align-items-center row-padding">
      <ion-col size="6" size-sm="6" size-md="6" size-lg="6">
        <h4 class="fwd"> Profil {{this.profileProvider.index  + 1}}
          <ng-container *ngIf="this.profileProvider.active.width?.length  > 1">
            / {{this.profileProvider.active.width?.length}}
          </ng-container>
        </h4>
      </ion-col>
      <ion-col size="6" size-sm="6" size-md="6" size-lg="6" class="navi ion-text-right">
        <ion-button fill="solid" color="primary" (click)="this.profileProvider.addProfile()">
          <ion-icon name="add-circle"></ion-icon>
        </ion-button>
        <ng-container *ngIf="this.profileProvider.active.width?.length > 1">
          <ng-container *ngIf=" this.profileProvider.index > 0">
            <ion-button fill="solid" color="primary" (click)="this.profileProvider.prevProfile()">
              <ion-icon name="caret-back-outline"></ion-icon>
            </ion-button>
          </ng-container>
          <ng-container *ngIf="this.profileProvider.active.width?.length -1  > this.profileProvider.index">
            <ion-button fill="solid" color="primary" (click)="this.profileProvider.nextProfile()">
              <ion-icon name="caret-forward-outline"></ion-icon>
            </ion-button>
          </ng-container>
          <ion-button fill="solid" color="primary" (click)="this.profileProvider.trash()">
            <ion-icon name="trash"></ion-icon>
          </ion-button>
        </ng-container>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center">
      <ng-container *ngIf="!isB2B()">
        <ion-col size="6" size-sm="6" size-md="4" size-lg="4" class="form-col">
          <ion-label>{{'NAME'| translate | uppercase}}</ion-label>
          <ion-input #nameInput type="text" [(ngModel)]="this.profileProvider.active.name" (ngModelChange)="saveinfos()"
                     (ionFocus)="checkFocus('name')" (keyup.enter)="closeKeyboard()">
          </ion-input>
        </ion-col>
      </ng-container>
      <ng-container *ngIf="!isB2B('drawInfo')">
        <ion-col size="6" size-sm="6" size-md="2" size-lg="2" class="form-col">
          <ion-label>{{'CUT'| translate | uppercase}}</ion-label>
          <ion-input type="number" [(ngModel)]="this.profileProvider.active.cut" readonly>
          </ion-input>
        </ion-col>
        <ion-col size="6" size-sm="6" size-md="2" size-lg="2" class="form-col">
          <ion-label>{{'SHEETLENGTH'| translate | uppercase}}</ion-label>
          <ion-input #sheetlengthInput type="number" inputmode="decimal" min="0" [(ngModel)]="this.profileProvider.active.width[this.profileProvider.index]"
                     [ngClass]="this.profileProvider.active.width[this.profileProvider.index] <= 0 ?'missing':''"
                     (ngModelChange)="saveinfos()" (ionFocus)="checkFocus('length')" (keyup.enter)="closeKeyboard()" [disabled]="isB2B()">
          </ion-input>
        </ion-col>
      </ng-container>
      <ng-container *ngIf="!isB2B()">
        <ion-col size="6" size-sm="6" size-md="2" size-lg="2" class="form-col">
          <ion-label>{{'NUMBEROFPIECES'| translate | uppercase}}</ion-label>
          <ion-input #noofpiecesInput [(ngModel)]="this.profileProvider.active.numberOfPiecesToManufacture[this.profileProvider.index]"
                     [ngClass]="this.profileProvider.active.numberOfPiecesToManufacture[this.profileProvider.index] < 1 ?'missing':''"
                     type="number" inputmode="decimal" min="0" (ngModelChange)="saveinfos()"
                     (ionFocus)="checkFocus('noOfPieces')"
                     (keyup.enter)="closeKeyboard()">
          </ion-input>
        </ion-col>
        <ion-col size="0" size-sm="0" size-md="2" size-lg="2" class="form-col hidden-sm hidden-xs">
          <div></div>
        </ion-col>
        <ion-col size="6" size-sm="6" size-md="4" size-lg="4" class="form-col">
          <ion-label>{{'MATERIAL'| translate | uppercase}}</ion-label>
          <ion-select [(ngModel)]="this.profileProvider.active.materialID"
                      [ngClass]="!this.profileProvider.active.materialID ? 'missing' : ''"
                      (ngModelChange)="saveinfos(1)"
                      placeholder="{{'SELECT'| translate}}"
                      [okText]="'OK' | translate" [cancelText]="'ABORT' | translate">
            <ion-select-option [value]="material.id" *ngFor="let material of materials">
              {{ material.name }}
            </ion-select-option>
          </ion-select>
        </ion-col>
        <ion-col size="6" size-sm="6" size-md="2" size-lg="2" class="form-col">
          <ion-label>{{'MATERIALTHICKNESS'| translate | uppercase}}</ion-label>
          <ion-input #thicknessInput
                     [(ngModel)]="this.profileProvider.active.materialThickness"
                     [ngClass]="(this.profileProvider.active.materialThickness <= 0 ||
                               4.0 < this.profileProvider.active.materialThickness) ? 'missing':''"
                     [disabled]="this.thicknessDisabled"
                     type="number" inputmode="decimal" min="0" (ngModelChange)="saveinfos()" (ionFocus)="checkFocus('thickness')"  (keyup.enter)="closeKeyboard()">
          </ion-input>
        </ion-col>
        <ion-col size="6" size-sm="6" size-md="2" size-lg="2" class="form-col">
          <ion-label>{{'COLOR'| translate | uppercase}}</ion-label>
          <ion-select [(ngModel)]="this.profileProvider.active.paintColor"
                      (ngModelChange)="saveinfos(5)"
                      [okText]="'OK' | translate" [cancelText]="'ABORT' | translate">
            <ion-select-option [disabled]="this.optionNoColorDisabled" value="none">{{'NONE'| translate}}</ion-select-option>
            <ion-select-option [value]="color.name" *ngFor="let color of this.canvas.colors">
              {{ color.name }}
            </ion-select-option>
          </ion-select>
        </ion-col>
      </ng-container>
      <ng-container *ngIf="!isB2B('drawInfo')">
        <ion-col size="6" size-sm="6" size-md="2" size-lg="2" class="form-col">
          <ion-label>{{'VARNISH'| translate | uppercase}}</ion-label>
          <ion-select  [(ngModel)]="this.profileProvider.active.paintPosition"
                       (ngModelChange)="saveinfos(4)">
            <ion-select-option value="none" [disabled]="this.optionNoColorDisabled">{{'NONE'| translate}}</ion-select-option>
            <ion-select-option value="top">{{'TOP'| translate}}</ion-select-option>
            <ion-select-option value="down">{{'BOTTOM'| translate}}</ion-select-option>
          </ion-select>
        </ion-col>
      </ng-container>
      <ng-container *ngIf="!isB2B()">
        <ion-col size="6" size-sm="6" size-md="2" size-lg="2" class="form-col">
          <ion-item class="form-checkbox">
            <ion-checkbox size="large" [(ngModel)]="this.profileProvider.active.lockTogether" (ngModelChange)="saveinfos()"></ion-checkbox>
            <ion-label class="ion-padding-start ion-text-wrap">{{'LOCKTOGETHER'| translate | uppercase}}</ion-label>
          </ion-item>
        </ion-col>
      </ng-container>
    </ion-row>
  </ion-grid>
</ion-header>
<ion-content class="no-scroll no-bounce">
  <canvas-board [showPopover]="showPopover" (showTooltip)="handlePopover($event)" [angle]="angle" [distance]="distance" [drawEditBtn]="drawEditBtn" [editDrawBtn]="editDrawBtn"></canvas-board>
  <img src='{{ logo() }}' class="watermark"/>
</ion-content>
<ion-footer >
  <ion-toolbar color="dark" *ngIf="canvas && (canvas.changeValues || canvas.changeValues2)" class="tbFooter">
    <div class="tollbar-inputs">
      <ion-grid>
        <ion-row *ngIf="canvas.changeValues">
          <ion-col>
            <ion-button class="plusminus" fill="clear" strong="true" *ngIf="canvas.currentValue > 0 || canvas.currentValue < 0" (click)="canvas.flipSign()">±</ion-button>
            <ion-input #angle type="number" inputmode="decimal" min="0" max="180" class="angle ion-text-right" [(ngModel)]="canvas.currentValue"
                       (ionFocus)="checkFocus('angle')" (ionChange)="canvas.changeAngle()" (keyup.enter)="canvas.closeInput()"
                       placeholder="{{'ANGEL'| translate}}" debounce="100" step="5">
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="canvas.changeValues2">
          <ion-col size="12" size-sm="6" size-md="6" size-lg="6">
            <ion-input #distance type="number" inputmode="decimal" min="1" max="1250" class="ion-text-right" [(ngModel)]="canvas.currentValue2"
                       (ionChange)="canvas.changeDistance()" (ionFocus)="checkFocus('distance')" (keyup.enter)="canvas.closeInput()"
                       placeholder="{{'EDGELENGTH'| translate}}" debounce="100" step="5">
            </ion-input>
          </ion-col>
          <ion-col size="4" size-sm="3" size-md="2" size-lg="1" class="ion-text-end ion-padding-end">
            <ion-label class="toolbar-label">{{'CONICITY'| translate}}</ion-label>
          </ion-col>
          <ion-col size="8" size-sm="3" size-md="4" size-lg="5">
            <ion-button class="plusminus" fill="clear" strong="true" *ngIf="canvas.currentValue3 > 0 || canvas.currentValue3 < 0" (click)="canvas.flipSign3()">±</ion-button>
            <ion-input #conicity type="number" inputmode="decimal" class="ion-text-right" [(ngModel)]="canvas.currentValue3"
                       (ionChange)="canvas.changeConicity()" (ionFocus)="checkFocus('conicity')" (keyup.enter)="canvas.closeInput()"
                       placeholder="{{'CONICITY'| translate}}" debounce="100">
            </ion-input>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </ion-toolbar>
  <ion-toolbar color="dark" class="tbFooter">
    <ion-buttons slot="start">
      <ng-container *ngIf="canvas && canvas.modus == 'edit'">
        <ion-button #editEditBtn class="iconaktiv" (click)="canvas.changeModus('edit', $event)">
          <ion-icon name="build" size="default" class="ion-padding-horizontal"></ion-icon>
          <span class="ion-hide-sm-down">{{'EDIT'| translate}}</span>
        </ion-button>
        <ion-button #drawEditBtn class="light" color="light" (click)="canvas.changeModus('draw', $event)">
          <ion-icon name="pencil-sharp" size="default" class="ion-padding-horizontal"></ion-icon>
          <span class="ion-hide-sm-down">{{'DRAW'| translate}}</span>
        </ion-button>
      </ng-container>
      <ng-container *ngIf="canvas && canvas.modus == 'draw'">
        <ion-button  #editDrawBtn color="light" class="light" (click)="canvas.changeModus('edit', $event)">
          <ion-icon name="build" size="default" class="ion-padding-horizontal"></ion-icon>
          <span class="ion-hide-sm-down">{{'EDIT'| translate}}</span>
        </ion-button>
        <ion-button  #drawDrawBtn class="iconaktiv" (click)="canvas.changeModus('draw', $event)">
          <ion-icon name="pencil-sharp" size="default" class="ion-padding-horizontal"></ion-icon>
          <span class="ion-hide-sm-down">{{'DRAW'| translate}}</span>
        </ion-button>
        <ion-button #backDrawBtn color="light" (click)="canvas.backTurn()" *ngIf="canvas.showUndo">
          <ion-icon name="arrow-undo" size="default" class="ion-padding-horizontal"></ion-icon>
          <span class="ion-hide-sm-down">{{'DELETE'| translate}}</span>
        </ion-button>
        <ion-button #editbarDrawBtn color="light" (click)="canvas.editBarAction(4)" *ngIf="canvas?.points?.length > 2">
          <ion-icon name="git-compare" size="default" class="ion-padding-horizontal"></ion-icon>
          <span class="ion-hide-sm-down">{{'CHANGEPOINT'| translate}}</span>
        </ion-button>
      </ng-container>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button #zoomInBtn color="light" (click)="canvas.zoomWithButton(true, $event)">
        <ion-icon name="add-circle" size="default"></ion-icon>
      </ion-button>
      <ion-button #zoomOutBtn color="light"(click)="canvas.zoomWithButton(false, $event)">
        <ion-icon name="remove-circle" size="default"></ion-icon>
      </ion-button>
      <ion-button color="light" (click)="canvas.showActionMenu($event)">
        <ion-icon name="ellipsis-vertical" size="default"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
