import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'pdf-view',
  templateUrl: './pdf-view.component.html',
  styleUrls: ['./pdf-view.component.scss']
})
export class PdfViewComponent implements OnInit {

  public data: any;
  @ViewChild('pdfViewer', {}) private pdfViewer: any;

  constructor(private navParams: NavParams,
              private modalCtrl: ModalController)
  {
  }

  public ngOnInit() {
    this.data = this.navParams.get('data');
    this.pdfViewer.pdfSrc = this.data.file;
    this.pdfViewer.refresh();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
