<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start" class="ion-no-margin">
      <ion-menu-button fill="solid" color="light"></ion-menu-button>
    </ion-buttons>
    <ion-title>{{'CONDITIONS'| translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-margin ion-padding">
  <h1>ASCO GmbH LICENSE TERMS</h1>
  <h2>VARIOBEND APPLICATION</h2>
  <hr/>
  These license terms are an agreement between ASCO GmbH (or based on where you live, one of its affiliates) and you.
  Please read them. They apply to the application named above, which includes the media on which you received it, if
  any. The terms also apply to any ASCO.
  <ul>
    <li>updates,</li>
    <li>supplements</li>
    <li>Internet-based services, and</li>
    <li>support services</li>
  </ul>

  for this software, unless other terms accompany those items. If so, those terms apply.<br/>
  <b>By using the Application, you accept these terms. If you do not accept them, do not use the software.</b>
  <hr/>
  <b>If you comply with these license terms, you have the rights below.</b><br/>

  <ol>
  <li><b>INSTALLATION AND USE RIGHTS.</b> You may install and use one number of copies of the software on your devices.</li>
  <li><b>SCOPE OF LICENSE.</b> The software is licensed, not sold. This agreement only gives you some rights to use the
  software. ASCO reserves all other rights. Unless applicable law gives you more rights despite this limitation, you may
  use the software only as expressly permitted in this agreement. In doing so, you must comply with any technical
  limitations in the software that only allow you to use it in certain ways. You may not
  <ul>
    <li>disclose the results of any benchmark tests of the software to any third party without ASCO prior written approval;</li>
    <li>work around any technical limitations in the application;</li>
    <li>reverse engineer, decompile or disassemble the application, except and only to the extent that applicable law
    expressly permits, despite this limitation;</li>
    <li>make more copies of the software than specified in this agreement or allowed by applicable law, despite this
    limitation;</li>
    <li>publish the application for others to copy;</li>
    <li>rent, lease or lend the application;</li>
    <li>transfer the application or this agreement to any third party; or</li>
    <li>use the application for commercial software hosting services.</li>
    </ul>
  </li>
  <li><b> BACKUP COPY.</b> You may make one backup copy of the application. You may use it only to reinstall the software.</li>
  <li><b> DOCUMENTATION.</b> Any person that has valid access to your computer or internal network may copy and use the
  documentation for your internal, reference purposes.</li>
  <li><b> EXPORT RESTRICTIONS.</b> The application is subject to German export laws and regulations. You must comply with all
  domestic and international export laws and regulations that apply to the software. These laws include restrictions on
  destinations, end users and end use.</li>
  <li><b>SUPPORT SERVICES.</b> Because this application is “as is,” we may not provide support services for it.</li>
  <li><b>ENTIRE AGREEMENT.</b> This agreement, and the terms for supplements, updates, Internet-based services and support
  services that you use, are the entire agreement for the software and support services.</li>
  <li><b> LEGAL EFFECT.</b> This agreement describes certain legal rights. You may have other rights under the laws of your
  country. You may also have rights with respect to the party from whom you acquired the software. This agreement does
  not change your rights under the laws of your country if the laws of your country do not permit it to do so.</li>
  <li><b> DISCLAIMER OF WARRANTY. The software is licensed “as-is.” You bear the risk of using it. ASCO gives no express
  warranties, guarantees or conditions. You may have additional consumer rights under your local laws which this
  agreement cannot change. To the extent permitted under your local laws, ASCO excludes the implied warranties of
  merchantability, fitness for a particular purpose and non-infringement.</b></li>
  <li><b> LIMITATION ON AND EXCLUSION OF REMEDIES AND DAMAGES. You can recover from ASCO and its suppliers only direct
  damages up to U.S. $5.00. You cannot recover any other damages, including consequential, lost profits, special,
  indirect or incidental damages.</b>
  This limitation applies to
  <ul>
  <li> anything related to the application, services, content (including code) on third party Internet sites, or third
  party programs; and</li>
  <li>claims for breach of contract, breach of warranty, guarantee or condition, strict liability, negligence, or other
  tort to the extent permitted by applicable law.</li>
  </ul>

  It also applies even if ASCO knew or should have known about the possibility of the damages. The above limitation or
  exclusion may not apply to you because your country may not allow the exclusion or limitation of incidental,
  consequential or other damages.
  </li>
  </ol>

</ion-content>
