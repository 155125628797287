import { Component, NgZone, OnInit } from '@angular/core';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { SettingsPage } from '../pages/settings/settings';
import { HelpPage } from '../pages/help/help';
import { AgbPage } from '../pages/agb/agb';
import { TranslateService } from '@ngx-translate/core';
import { Languages } from "../shared/providers/languages/languages";
import { DashboardPage } from "../pages/dashboard/dashboard";
import { FcmProvider } from '../shared/providers/fcm/fcm';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { AuthProvider } from '../shared/providers/auth/auth';
import { MachinesPage } from "../pages/machines/machines";
import { MessagesPage} from '../pages/messages/messages';
import * as moment from 'moment';
import { LicensePage } from "../pages/license/license";
import { Utils } from 'src/shared/providers/utils/utils';
import { AuthGuard } from './auth-guard.service';
import { StatisticsPage } from '../pages/statistics/statistics';
import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  // public master: any = 0;
  public pages: Array<{ title: string, url: string, component: any, value: any }>;
  private processedIntent: boolean = false;
  private minimizeApp: boolean = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private storage: Storage,
    private translate: TranslateService,
    private authProvider: AuthProvider,
    private fcmProvider: FcmProvider,
    private navCtrl: NavController,
    private alertController: AlertController,
    private zone: NgZone,
    private authGuard: AuthGuard)
  {
    this.translate.setDefaultLang('de');

    this.storage.get('showScreen').then(showScreen => {
      if (showScreen == 2)
        this.storage.set("showScreen", 1);
    });

    // init translation
    this.storage.get("settings").then((val) => {
      if (val && val.lang && Languages.exists(val.lang))
        this.translate.use(val.lang);
      else if (Languages.exists(this.translate.getBrowserLang()))
        this.translate.use(this.translate.getBrowserLang());
      else
        this.translate.use('en');
      if (this.translate.currentLang === 'de') {
        moment.updateLocale('en', {
          relativeTime: {
            future: 'in %s',
            past: 'vor %s',
            s: '%d Sekunden',
            m: '1 Minute',
            mm: '%d Minuten',
            h: 'eine Stunde',
            hh: '%d Stunden',
            d: '1 Tag',
            dd: '%d Tage',
            M: '1 Monat',
            MM: '%d Monate',
            y: '1 Jahr',
            yy: '%d Jahre'
          }
        });
      }
    });

    const api = this.platform.getQueryParam('api');

    this.pages = [];
    if (api === undefined || api === '' || api === null)
    {
      this.pages.push({ title: "DASHBOARD", url:'dashboard', component: DashboardPage, value: null});
      this.pages.push({ title: "MACHINES", url: 'machines', component: MachinesPage, value: null});
      this.pages.push({ title: "MESSAGES", url: 'messages', component: MessagesPage, value: null});
      this.pages.push({ title: "STATISTICS", url: 'statistics', component: StatisticsPage, value: null});
      // this.pages.push({ title: "USERNAME", url: null, component: null, value: null});
      this.pages.push({ title: "LICENSEINFO", url: 'license', component: LicensePage, value: null});
      this.pages.push({ title: "SETTINGS", url: 'settings', component: SettingsPage, value: null});
      // this.pages.push({ title: "DELETE-ACCOUNT", url: null, component: null, value: null});
      this.pages.push({ title: "HELP", url:'help', component: HelpPage, value: null});
      this.pages.push({ title: "CONDITIONS", url:'agb', component: AgbPage, value: null});
      this.pages.push({ title: "LOGOUT", url: 'logout', component: null, value: null});
    }
  }

  public async ngOnInit() {
    this.initializeApp();
    this.authProvider.user.subscribe(async (user) => {
      if (user) {
        console.log('has user');
        setTimeout(() => {
          this.fcmProvider.getToken().then(() => {
            this.fcmProvider.listenToNotifications();
          });
        }, 5000);
      }
    });

    setTimeout(async ()=>{
      await this.storage.ready();
      this.storage.get("deviceMaster").then(devMaster=>{
        if(devMaster)
          this.authProvider.master = devMaster > 0;
      });
    },100);
  }

  public initializeApp() {
    this.platform.ready().then(async () => {
      if (this.platform.is('cordova')) {
        // do cordova stuff
        this.splashScreen.hide();

        /* deprecated
        if (this.platform.is('android')
          && (<any>window).cordova
          && (<any>window).plugins
          && (<any>window).plugins.webintent) {

          // handle app links in emails etc.
          (<any>window).plugins.webintent.onNewIntent((uri) => {
            console.log('new intent', uri);
            if (uri) {
              let target = uri.substr(uri.lastIndexOf(Utils.PREFIX) + Utils.PREFIX.length);
              this.navCtrl.navigateForward(target, {preserveQueryParams: true});
            }
          });
        } */
        if ((<any>window).cordova && (<any>window).plugins && (<any>window).plugins.universalLinks) {
          (<any> window).plugins.universalLinks.subscribe(null, async (eventData) => {
            console.log('universalLinks', eventData.url);
            if(eventData.url.indexOf('/register?') !== -1 && eventData.params && eventData.params !== null) {
              console.log('registerQRcode', eventData.params.code, eventData.params.master, eventData.params.company);
              let navExtas: NavigationExtras = {state: {code: eventData.params.code, email: eventData.params.email, master: +eventData.params.master, company: eventData.params.company}}
              this.navCtrl.navigateRoot('register', navExtas);
            } else {
              this.navCtrl.navigateRoot('dashboard');
            }
          });
        }

      }
    });
  }

  public isMaster(){
    return this.authProvider.master;
  }

  public isPM(){
    return this.authProvider.isPM;
  }

  public messagesCount() {
    return Utils.newMessages;
  }

  public deleteAccount() {
    this.storage.get('whoami').then(async (whoami) => {
      if (whoami) {
        const alert = await this.alertController.create({
          header: this.translate.instant("DELETE-ACCOUNT"),
          subHeader: this.translate.instant("DELETE-ACCOUNT-SUBHEADER"),
          buttons: [
            {
              text: this.translate.instant('DELETE-ACCOUNT-NO'),
            },
            {
              text: this.translate.instant('DELETE-ACCOUNT-YES'),
              cssClass: 'danger',
              handler: async () => {
                console.log('delete account', whoami);
                const response = await this.authProvider.deleteAccount(whoami);
                if(response && response.error) {
                  const alert = await this.alertController.create({
                    header: this.translate.instant("ATTENTION"),
                    message: this.translate.instant("DELETE-ACCOUNT-ERROR"),
                    buttons: ['OK']
                  });
                  alert.present();
                } else {
                  const alert = await this.alertController.create({
                    header: this.translate.instant("ATTENTION"),
                    message: this.translate.instant("DELETE-ACCOUNT-SUCCESS"),
                    buttons: ['OK']
                  });
                  alert.present();
                  this.navCtrl.navigateRoot('logout');
                }
              }
            }
          ],
          cssClass: 'variobendDialog'
        });
        alert.present();
      } else {
        this.navCtrl.navigateRoot('login');
      }
    });
  }

  async showLogout() {
    const confirm = await this.alertController.create({
      header: this.translate.instant("LOGOUT"),
      message: this.translate.instant("REMOVEDEVICE"),
      buttons: [
        {
          text: this.translate.instant("ABORT"),
          handler: () => {
            console.log('Disagree clicked');
          }
        },
        {
          text: this.translate.instant("LOGOUT"),
          handler: () => {
            this.navCtrl.navigateRoot('logout');
          }
        }
      ]
    });
    await confirm.present();
  }
}
