
export class Utils {

  static groupBy(collection, property): any {
    return collection.reduce(function (acc, obj) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  };

  public static isB2B: boolean;
  public static hasB2BLicense: boolean;
  public static customerId: any;
  public static autosave: any;
  public static readonly PREFIX: string = "pp.variobend.de/";
  public static newMessages: any;
  public static b2bInstance: any; // e.g. Environment.shopConfig.s1

}
