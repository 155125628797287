import { Component } from '@angular/core';
import { AlertController, NavController, ToastController} from '@ionic/angular';
import { ActivatedRoute, Router} from '@angular/router';
import { AuthProvider } from '../../shared/providers/auth/auth';
import { UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { TranslateService} from '@ngx-translate/core';
import { environment} from "../../environments/environment";
import { MailProvider} from '../../shared/providers/mails/mails';

@Component({
  selector: 'page-register',
  templateUrl: 'register.html',
  styleUrls: ['./register.scss']
})
export class RegisterPage {

  registerForm: UntypedFormGroup;
  name: any;
  email: any;
  company: any;
  code: any = null;
  password: any;
  confirmation: any;
  toast: any;
  userAgent: any = "";
  blocked: boolean = false;
  private loggedIn: boolean = false;
  private master: number = null;
  private isMaster: boolean = false;
  private whoami: any = null;
  private hasSubUser: boolean = false;
  private licenseInfo: {
    username: string,
    licenseDate: any,
    contributing: string,
    devices: number,
    contributorLicences: number
    contributorDevices: number
    contributors: Array<{ name: string, email: string, lastActivity: any }>
  } = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public navCtrl: NavController,
    private formBuilder: UntypedFormBuilder,
    private auth: AuthProvider,
    private toastCtrl: ToastController,
    public platform: Platform,
    public alertController: AlertController,
    private storage: Storage,
    private device: Device,
    private mailProvider: MailProvider,
    private translate: TranslateService)
  {

    this.userAgent = navigator.userAgent;
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        const form = this.router.getCurrentNavigation().extras.state.form;
        if (form && form.code)
          this.code = form.code;
        let code = this.router.getCurrentNavigation().extras.state.code;
        if (code)
          this.code = code;
        const email = this.router.getCurrentNavigation().extras.state.email;
        if (email)
          this.email = email;
        const master = this.router.getCurrentNavigation().extras.state.master;
        if (master)
          this.master = master;
        const company = this.router.getCurrentNavigation().extras.state.company;
        if (company)
          this.company = company;
      } else {
        const code = params.code;
        if (code)
          this.code = code;
        const email = params.email;
        if (email)
          this.email = email;
        const master = params.master;
        if (master)
          this.master = master;
        const company = params.company;
        if (company)
          this.company = company;
      }
    });
    this.registerForm = this.formBuilder.group({
      company: [this.company !== undefined && this.company !== null ? this.company : '', Validators.required],
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password:  ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      confirmation: ['', Validators.compose([Validators.required, Validators.minLength(4)])],
      code: ['', this.code !== null ? Validators.required : null],
    }, {validator: RegisterPage.passwordsMatch});
  }

  async ionViewWillEnter() {
    this.whoami = await this.storage.get('whoami');
    if (this.whoami && this.whoami.id) {
      this.loggedIn = true;
      this.email = this.whoami.email;
      this.name = this.whoami.name;
      this.storage.get("deviceMaster").then(master => {
        if (master && master > 0) {
          this.isMaster = true;
        }
      });
      this.licenseInfo = await this.storage.get('license');
      if (this.licenseInfo && this.licenseInfo.contributors && this.licenseInfo.contributors.length > 0) {
        this.hasSubUser = true;
      }
    } else {
      this.licenseInfo = null;
    }
  }

  static passwordsMatch(cg: UntypedFormGroup): {[err: string]: any} {
    let password = cg.get('password');
    let confirmation = cg.get('confirmation');
    let rv: {[error: string]: any} = {};
    if ((password.touched || confirmation.touched) && password.value !== confirmation.value) {
      rv['passwordMismatch'] = true;
    }
    return rv;
  }

  markFormControlsAsTouched(): void {
    this.registerForm.controls['company'].markAsTouched();
    this.registerForm.controls['name'].markAsTouched();
    this.registerForm.controls['email'].markAsTouched();
    this.registerForm.controls['confirmation'].markAsTouched();
    if (this.code !== null) {
      this.registerForm.controls['code'].markAsTouched();
    }
  }

  async registerSubmit() {
    this.blocked = true;
    setTimeout(()=>{this.blocked = false;},3000);
    if (this.registerForm.valid) {
      if(!navigator.onLine){
        this.toastCtrl.create({
          message: 'Offline',
          duration: 3000,
          position: 'middle',
        }).then(toast=>{this.toast = toast; toast.present();});
        return;
      }
      // register via QR code allready logged in
      if (this.loggedIn && this.master !== undefined && this.master !== null && this.master > 0) {
        if (this.hasSubUser) {
          // not possible, go to dashboard
          const confirm = await this.alertController.create({
            header: this.translate.instant("JOIN-TEAM"),
            message: this.translate.instant("JOIN-TEAM-INVALID"),
            buttons: [
              {
                text: this.translate.instant("OK"),
                handler: async () => {
                  this.navCtrl.navigateRoot('dashboard');
                }
              }
            ]
          });
          await confirm.present();
        } else {
          // change Team ?
          const confirm = await this.alertController.create({
            header: this.translate.instant("JOIN-TEAM"),
            message: this.translate.instant("JOIN-TEAM-MESSAGE", {company: this.company}),
            buttons: [
              {
                text: this.translate.instant("OK"),
                handler: async () => {
                  console.log('change team');
                  await this.joinTeam();
                }
              },
              {
                text: this.translate.instant("CANCEL"),
                handler: () => {
                  this.navCtrl.navigateRoot('dashboard');
                }
              }
            ]
          });
          await confirm.present();
        }
      } else {
        let wait;
        // console.log(this.registerForm.value);
        if (this.code !== null) {
          wait = await this.auth.registerSub(this.registerForm.value);
        } else {
          let reg = {
            email: this.registerForm.value.email,
            password: this.registerForm.value.password,
            name: this.registerForm.value.name,
            company: this.registerForm.value.company,
            key: this.getKey(this.registerForm.value.email) // email + uuid
          }
          if (this.platform.is("cordova")) {
            wait = await this.auth.registerMain(reg);
          } else {
            wait = {error: {error: "Not available in web version!"}};
          }
        }
        this.markFormControlsAsTouched();
        if (wait && wait.error) {
          this.toast = this.toastCtrl.create({
            message: wait.error.error,
            duration: 3000,
            position: 'middle',
          }).then(toast => toast.present());
        } else {
          this.toast = this.toastCtrl.create({
            message: this.translate.instant("REGISTERED"),
            duration: 3000,
            position: 'middle',
          }).then(toast => toast.present());
          this.loginSubmit();
        }
      }
    }
  }

  async loginSubmit() {
    if(this.registerForm.valid) {
      let wait = await this.auth.getToken({email: this.registerForm.value.email, password: this.registerForm.value.password}, this.userAgent);
      if (wait && wait.error) {
        this.toastCtrl.create({
          message: wait.error.error,
          duration: 3000,
          position: 'middle',
        }).then(toast=>{ this.toast = toast; toast.present();});
      } else {
        let my = await this.auth.whoami();
        this.storage.set("user", my);

        const mail: { id: any, uid: string, from: string, to: string; template: any  } =
          {
            id: null,
            uid: my.id,
            from: environment.fromEmail,
            to: this.registerForm.value.email,
            template: {
              name: 'FREE-TEST-PERIOD-WELCOME',
              data: {
                to: this.registerForm.value.name
              }
            },
          };
        await this.mailProvider.addMail(mail);

        this.storage.get('firstrun').then((data) => {
          this.navCtrl.navigateRoot('dashboard');
        });
      }
    }
  }

  private getKey(email: string): string {
    if (this.platform.is('cordova')) {
      return this.device.uuid;
    } else {
      // currently dev environment only, hash from email and platform
      console.log(this.platform.platforms().join('-'));
      const key = {email: email, hardware_id: this.platform.platforms().join('-')};
      const hash = Array.from(JSON.stringify(key))
        .reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0);
      console.log('key: ' + hash);
      return hash.toString();
    }
  }

  private async joinTeam() {
    // change to subuser
    let wait = await this.auth.masterToSubUser({user: this.whoami.id, code: this.code, master: this.master});
    if (wait && wait.error) {
      this.toast = this.toastCtrl.create({
        message: wait.error.error, duration: 5000, position: 'bottom',
      }).then(toast => toast.present());
    } else {
      this.storage.set('device', wait.ret.device.id);
      this.storage.set("master", false);
      this.storage.remove('deviceMaster');
      this.whoami.user_id = this.master;
      this.storage.set("subuser", this.whoami);
      this.storage.set("code", this.code);
      this.isMaster = false;
      let wait2 = await this.auth.getToken({email: this.registerForm.value.email, password: this.registerForm.value.password}, this.userAgent);
      if (wait2 && wait2.error) {
        this.toastCtrl.create({
          message: wait.error.error,
          duration: 3000,
          position: 'middle',
        }).then(toast=>{ this.toast = toast; toast.present();});
      } else {
        const my = await this.auth.whoami();
        this.storage.set("user", my);
        this.licenseInfo = await this.auth.licenseInfo(this.whoami.id, this.whoami.email);
        this.toast = this.toastCtrl.create({
          message: this.translate.instant("JOIN-TEAM-SUCCESS", {company: this.company}),
          duration: 3000,
          position: 'middle',
        }).then(toast => toast.present());
        this.navCtrl.navigateRoot('dashboard');
      }
    }
  }

}
