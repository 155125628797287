import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { TooltipComponent } from '../../components/tooltip/tooltip';
import { PopoverController}  from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {Utils} from "../utils/utils";

@Injectable()
export class TooltipProvider {

  public static START_TOOLTIP: string = 'tooltip_start';
  public static DRAW_TOOLTIPS: string = 'tooltip_draw';
  public static EDIT_TOOLTIPS: string = 'tooltip_edit';
  public static FINISH_TOOLTIPS: string = 'tooltip_finish';

  public show: boolean = true;

  constructor(
    private storage: Storage,
    public translate: TranslateService,
    public popoverCtrl: PopoverController
  ) {
  }

  async showPopover(page: string, element: string, event?:any, timeout: number = 4000, nextElement: any = null ) {
    if (Utils.isB2B)
      return false;
    let open = false;
    if (event && event !== null) {
      event.stopPropagation();
    }
    let showPagePopOver = await this.storage.get(page);
    if (showPagePopOver !== undefined && showPagePopOver === false) {
      if (nextElement && nextElement !== null) {
        setTimeout(() => {
          nextElement.click();
        }, 300);
      }
      return false;
    } else {
      const popover = await this.popoverCtrl.create({
        component: TooltipComponent,
        event: event,
        cssClass: 'tooltip',
        componentProps: {
          info: this.translate.instant(element + '-TOOLTIP')
        },
        mode: 'ios',
        translucent: true
      });
      popover.onDidDismiss().then((result) => {
        open = false;
      });
      if (timeout) {
        setTimeout(() => {
          if (open) {
            popover.dismiss({stop: false});
          }
        }, timeout);
      }
      open = true;
      await popover.present();
      const {data} = await popover.onWillDismiss();
      if (data) {
        if (data.stop) {
          this.show = false;
          this.storage.set(page, false);
          return false;
        } else {
          if (nextElement && nextElement !== null) {
            setTimeout(() => {
              nextElement.click();
            }, 300);
          }
          return true;
        }
      } else {
        return true;
      }
    }
  }

}
