<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start" class="ion-no-margin">
      <ion-menu-button fill="solid" color="light"></ion-menu-button>
    </ion-buttons>
    <ion-title>{{'MACHINES'| translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="hideForCamera" [hidden]="isScanning">
  <ion-item lines="none">
    <ion-label class="ion-text-center">{{'LINKEDMACHINES'|translate}}</ion-label>
  </ion-item>
  <ion-card *ngFor="let machine of this.machinesList">
    <ion-card-header>
      <ion-item lines="none">
        <div class="status" [ngStyle]="(machine.online) ? {background: 'var(--ion-color-success'} : { background: 'var(--ion-color-danger'}"></div>
        <ion-label>{{ machine.friendlyName }}</ion-label>
        <ion-button color="primary" (click)="deleteMachine(machine.id)">
          <ion-icon name="trash-outline"></ion-icon>
        </ion-button>
      </ion-item>
    </ion-card-header>
    <ion-card-content *ngIf="machine.online">
      <ion-item lines="none">
        <div slot="start">{{ machine.activeMode}}</div>
        <div *ngIf="machine.numberOfPiecesToBeDone !== null" slot="end">{{ machine.numberOfPiecesToBeDone | number : '1.0-2'}}</div>
      </ion-item>
      <ion-item lines="none">
        <div *ngIf="machine.geometry !== null" slot="start" class="profile">
          <machine-canvas [id]="machine.id" [path]="machine.geometry"></machine-canvas>
        </div>
        <div *ngIf="machine.timeStamp !== null" slot="end">{{ machine.timeStamp | amTimeAgo }}</div>
      </ion-item>
    </ion-card-content>
  </ion-card>
  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center">
        <ion-button color="primary" class="ion-align-items-center ion-margin-top ion-text-center ion-align-self-center ion-justify-content-center" (click)="scanQR()" [disabled]="!isMaster">
          <ion-icon mode="md" name="add"></ion-icon>
          <ion-label>{{'ADDMACHINE'| translate}}</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-content class="cameraBackground" [hidden]="!isScanning">
  <ion-toolbar color="transparent">
    <ion-buttons slot="end" class="ion-no-margin">
      <ion-button size="large" fill="solid" color="light" (click)="closeCamera()">
        <ion-icon name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-content>
