<ion-header class="ion-no-border">
  <ion-toolbar color="dark">
    <ion-buttons slot="start" class="ion-no-margin">
      <ion-menu-button fill="solid" color="light"></ion-menu-button>
    </ion-buttons>
    <ion-title>{{'LICENSEINFO'| translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding ion-margin ion-align-items-center">

  <ion-card>
    <ion-card-header>
      <ion-card-subtitle>{{ 'USERNAME' | translate}}</ion-card-subtitle>
      <ion-card-title>{{username}}</ion-card-title>
     </ion-card-header>
  </ion-card>

  <ng-container *ngIf="licenseInfo !== null" class="info-item" lines="none" detail="false">
      <ng-container *ngIf="licenseInfo.contributing !== null">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle>{{ 'CONTRIBUTING' | translate}}</ion-card-subtitle>
            <ion-card-title>{{licenseInfo.contributing}}</ion-card-title>
          </ion-card-header>
        </ion-card>
      </ng-container>
      <ng-container>
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle>{{ 'LICENSE-DATE' | translate}}</ion-card-subtitle>
            <ion-card-title>{{licenseInfo.licenseDate | date: 'dd.MM.yyyy'}}</ion-card-title>
          </ion-card-header>
        </ion-card>
      </ng-container>
      <ng-container *ngIf="licenseInfo.devices !== null">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle>{{ 'DEVICES' | translate}}</ion-card-subtitle>
            <ion-card-title>{{licenseInfo.devices}}</ion-card-title>
          </ion-card-header>
        </ion-card>
      </ng-container>
      <ng-container *ngIf="licenseInfo.contributorLicences !== null">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle>{{ 'CONTRIBUTOR-LICENCES' | translate}}</ion-card-subtitle>
            <ion-card-title>{{licenseInfo.contributorLicences}}</ion-card-title>
          </ion-card-header>
        </ion-card>
      </ng-container>
      <ng-container *ngIf="licenseInfo.contributorDevices !== null">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle>{{ 'CONTRIBUTOR-DEVICES' | translate}}</ion-card-subtitle>
            <ion-card-title>{{licenseInfo.contributorDevices}}</ion-card-title>
          </ion-card-header>
        </ion-card>
      </ng-container>
      <ng-container *ngIf="licenseInfo.contributors && licenseInfo.contributors.length > 0">
        <ion-card>
          <ion-card-header>
            <ion-card-subtitle style="padding-bottom: 5px;">{{ 'CONTRIBUTORS' | translate}}</ion-card-subtitle>
          </ion-card-header>
          <ion-item *ngFor="let contributor of licenseInfo.contributors">
            <div class="contributor">
              <div>{{contributor.name}}</div>
              <small>{{contributor.email}}</small>
            </div>
          </ion-item>
        </ion-card>
      </ng-container>
      <ion-card *ngIf="qrCodeLink !== null">
        <ion-card-header>
          <ion-card-subtitle style="padding-bottom: 5px;">
            {{'QR-CODE-INFO'| translate}}
            <span *ngIf="qrCode !== null">({{ qrCode }})</span>
          </ion-card-subtitle>
        </ion-card-header>
        <ion-card-content class="ion-text-center">
          <qrcode [qrdata]="qrCodeLink" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
        </ion-card-content>
      </ion-card>
      <ion-grid *ngIf="isMaster">
        <ion-row>
          <ion-col class="ion-text-center">
            <ion-button color="primary" class="ion-align-items-center ion-margin-top ion-text-center ion-align-self-center ion-justify-content-center" (click)="createQR()">
              <ion-icon mode="md" name="qr-code" class="ion-margin-end"></ion-icon>
              <ion-label>{{'QR-NEW-USER'| translate}}&#42;</ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-text-center">
              <ion-label style="font-size: smaller">&#42;{{'QR-NEW-USER-INFO'| translate}}</ion-label>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ng-container>
</ion-content>
