<ion-content class="ion-text-center">
  <ion-grid>
    <ion-row class="ion-margin-top">
      <ion-col>
        <img src="assets/imgs/logo.svg" width="400" class="ion-margin-top">
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-md="4" offset-md="4">
        <form id="login" [formGroup]="registerForm">
          <ion-item lines="none" class="ion-no-padding">
            <ion-label position="stacked">{{'LOGIN'| translate}}</ion-label>
            <ion-input type="email" name="email" [(ngModel)]="email"
              [formControl]="registerForm.controls['email']" placeholder="{{'EMAIL'| translate}}"
              [ngClass]="!registerForm.valid && !registerForm.controls['email'].valid &&
              registerForm.controls['email'].touched ?'missing':''">
            </ion-input>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding">
            <ion-label position="stacked">{{'COMPANY'| translate}}</ion-label>
            <ion-input type="text" name="company" [(ngModel)]="company"
              [formControl]="registerForm.controls['company']" placeholder="{{'COMPANY-DESCRIPTION'| translate}}"
              [ngClass]="!registerForm.valid && !registerForm.controls['company'].valid
              && registerForm.controls['company'].touched ?'missing':''">
            </ion-input>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding">
            <ion-label position="stacked">{{'NAME'| translate}}</ion-label>
            <ion-input type="text" name="name" [(ngModel)]="name"
              [formControl]="registerForm.controls['name']" placeholder="{{'YOUR-NAME'| translate}}"
              [ngClass]="!registerForm.valid && !registerForm.controls['name'].valid
              && registerForm.controls['name'].touched ?'missing':''">
            </ion-input>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding">
            <ion-label position="stacked">{{'PASSWORD'| translate}}</ion-label>
            <ion-input type="password" placeholder="" name="password" [(ngModel)]="password"
              [formControl]="registerForm.controls['password']" placeholder="***************"
              [ngClass]="!registerForm.valid && !registerForm.controls['password'].valid
              && registerForm.controls['password'].touched ?'missing':''">
            </ion-input>
          </ion-item>
          <ion-item lines="none" class="ion-no-padding">
            <ion-label position="stacked">{{'CONFIRMATION'| translate}}</ion-label>
            <ion-input type="password" placeholder="" name="confirmation" [(ngModel)]="confirmation"
                       [formControl]="registerForm.controls['confirmation']" placeholder="***************"
                       [ngClass]="!registerForm.valid && registerForm.controls['confirmation'].touched ?'missing':''">
            </ion-input>
          </ion-item>
          <ion-item *ngIf="code !== null" lines="none" class="ion-no-padding">
            <ion-label position="stacked">{{'CODE'| translate}}</ion-label>
            <ion-input type="text" placeholder="" name="code" readonly [(ngModel)]="code"
              [formControl]="registerForm.controls['code']">
            </ion-input>
          </ion-item>
        </form>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="ion-margin">
          <ion-button color="primary"[disabled]="!registerForm.valid || blocked" (click)="registerSubmit()">
            {{'REGISTER'| translate}}
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-md="4" offset-md="4">
        <a button class="ion-margin-bottom" href="signin">{{'BACK'| translate}}</a>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>
