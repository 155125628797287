<ion-header class="ion-no-border">
  <ion-toolbar color="dark">
    <ion-buttons slot="start" class="ion-no-margin">
      <ion-menu-button fill="solid" color="light"></ion-menu-button>
    </ion-buttons>
    <ion-title>{{'MESSAGES'| translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding ion-margin ion-align-items-center">

  <ion-card *ngFor="let message of messages">
    <ion-card-header>
      <ion-item lines="none">
        <ion-label>{{message.title}}</ion-label>
        <small>{{ timeToMoment(message.sort) | amTimeAgo}}</small>
        <ion-badge *ngIf="message.status === 'new'" slot="end" color="danger">
          {{'STATUS-' + message.status.toUpperCase() | translate | uppercase}}
        </ion-badge>
        <ion-badge *ngIf="message.status === 'accepted'" slot="end" color="success">
          {{'STATUS-' + message.status.toUpperCase() | translate | uppercase}}
        </ion-badge>
        <ion-badge *ngIf="message.status === 'rejected'" slot="end" color="dark">
          {{'STATUS-' + message.status.toUpperCase() | translate | uppercase}}
        </ion-badge>
        <ion-badge *ngIf="message.status === 'imported'" slot="end" color="success">
          {{'STATUS-' + message.status.toUpperCase() | translate | uppercase}}
        </ion-badge>
      </ion-item>
    </ion-card-header>
    <ion-card-content>
      <ion-item lines="none">
        <span>{{message.message}}</span>
        <div *ngIf="message.type === 'codeRequest' && (message.status !== 'accepted' && message.status !== 'rejected')" slot="end">
          <a (click)="inviteCodeRequest(message)">{{'CODE-REQUEST-INVITE'| translate}}</a>
          &nbsp;{{'ACTION-OR'| translate}}&nbsp;
          <a (click)="rejectCodeRequest(message)">{{'CODE-REQUEST-REJECT'| translate}}</a>
        </div>
        <div *ngIf="message.type === 'shareProfile' && message.data !== null" slot="end">
          <a (click)="importProfile(message)">{{'SHARE-PROFILE-IMPORT'| translate}}</a>
        </div>
      </ion-item>
    </ion-card-content>
  </ion-card>

</ion-content>
