import {Injectable} from '@angular/core';
import {FireStoreProvider} from '../firestore/firestore';


@Injectable()
export class MessagesProvider extends FireStoreProvider {

  loadMessages(whoami: any) {
    return this.colWithIds$<any>('messages', (ref: any) => ref.where('uid', '==', whoami.id)
      .orderBy('sort','desc'));
  }

  loadMessagesByEmail(whoami: any) {
    return this.colWithIds$<any>('messages', (ref: any) => ref.where('toEmail', '==', whoami.email)
      .where('uid', '==', null)
      .orderBy('sort','desc'));
  }

  loadNewMessages(whoami: any) {
    return this.colWithIds$<any>('messages', (ref: any) => ref.where('uid', '==', whoami.id)
      .where('status', '==', 'new')
      .orderBy('sort','desc'));
  }

  addMessage(message: any): Promise<any> {
    message.id = this.db.createId();
    console.log('new message ' + message.id);
    return this.set(`messages/${message.id}`, message);
  }

  update(message: any) {
    this.db.doc<any>('messages/' + message.id).update(message);
  }

}
