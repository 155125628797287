import { Component } from '@angular/core';
import {ModalController, NavController} from "@ionic/angular";

@Component({
  selector: "page-changelog",
  templateUrl: "changelog.html",
  styleUrls: ['./changelog.scss']
})
export class ChangelogPage {

  constructor(public navCtrl: NavController,
              private modalCtrl: ModalController) {
  }

  ngOnInit() {
    console.log("ngOnInit ChangelogPage");
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
