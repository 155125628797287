import { Injectable } from '@angular/core';
import { RequestProvider } from '../request/request';
/*
  Generated class for the VersionProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class VersionProvider {

  version: any = 26;
  statisticVersion: number = 1.0;
  reload: any = false;

  constructor( private request: RequestProvider) {
    //console.log('Hello VersionProvider Provider');
  }

  getVersion(status = false): Promise<any> {
    return new Promise((resolve, reject) => {
      this.request.get("auth/version", true).subscribe(data => {
        console.log('API version ' + data.version);
        if (data.version > this.version) {
          this.reload = true;
          resolve(true);
        } else {
          this.reload = false;
          resolve(true);
        }
      });
    });
  }

}
