import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the StatusColorPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'statusColor',
})
export class StatusColorPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: any, ...args) {
    if (value == 0) {
      return "primary"
    }
    if (value == 1) {
      return "orange"
    }
    if (value == 2) {
      return "green"
    }
    if (value == 3) {
      return "lightblue"
    }
    if (value == 4) {
      return "lightgreen"
    }

    if (value == 5) {
      return "red"
    }

    if (value == 6) {
      return "green"
    }
  }
}
