<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start" class="ion-no-margin ion-no-padding">
<!--     do not remove or icons will be deleted from build: menu-outline menu-sharp search-sharp close-sharp -->
      <ion-button fill="solid" color="primary" class="ion-no-margin message-btn" (click)="goBack()">
        <ion-badge *ngIf="getMessagesCount() > 0" color="danger">{{getMessagesCount()}}</ion-badge>
        <ion-icon name="arrow-back" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'PROFILE'| translate}}</ion-title>
    <ion-buttons slot="end" class="ion-no-margin ion-no-padding">
      <ng-container color="primary" class="ion-no-padding" *ngIf="!profileProvider.isProjectSource && (folderList.length > 0 || (master && !inFolder))">
        <ion-button fill="solid" color="primary" class="notify-btn" *ngIf="folderList.length > 0" (click)="deleteFolder()">
          <ion-icon name="trash" slot="icon-only"></ion-icon> {{'DELETE'| translate}}
        </ion-button>
        <ion-button fill="solid" color="primary" class="notify-btn" *ngIf="master && !inFolder && profileProvider.flatcount > 0" (click)="flatModus()">
          <ion-badge color="danger">{{profileProvider.flatcount}}</ion-badge>
          <ion-icon name="information-circle" slot="icon-only"></ion-icon>
        </ion-button>
      </ng-container>
      <ng-container *ngIf="!profileProvider.isProjectSource && list.length > 0">
        <ion-item color="primary">
          <ion-icon slot="start" color="light" name="move"></ion-icon>
          <ion-label>{{'MOVE'| translate}}</ion-label>
          <ion-select (ionChange)="moveFolder($event)" [okText]="'OK' | translate" [cancelText]="'ABORT' | translate">
            <ng-container *ngFor="let profile of profileProvider.profilesFire|async">
              <ion-select-option value="{{profile.id}}">{{profile.name}}</ion-select-option>
            </ng-container>
          </ion-select>
        </ion-item>
      </ng-container>
      <ion-button *ngIf="cloudOnline" fill="solid" color="primary">
        <ion-icon name="cloud-done" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button *ngIf="cloudSync" fill="solid" color="primary">
        <ion-icon name="cloud-upload" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button *ngIf="cloudOffline" fill="solid" color="primary">
        <ion-icon name="cloud-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="my-overlay" [hidden]="!isLastProjectsPopoverOpen">
  <div class="my-overlaycontent">
    <ng-container *ngFor="let project of availableOrderProjects|async">
      <ion-card *ngIf="project.orderId > 0" button="true" class="ion-padding" (click)="addItemToProject(project)">
        <ion-toolbar>
          <ion-card-subtitle>{{project.customerName | uppercase}}</ion-card-subtitle>
          <ion-card-title>{{project.projectName | uppercase}}</ion-card-title>
        </ion-toolbar>
        <ion-card-content>{{project.orderErpId ? project.orderErpId : (project.orderId > 0 ? project.orderId.toString() : '')  | uppercase}}
          {{project.description}}
        </ion-card-content>
      </ion-card>
    </ng-container>
  <ion-button size="default" color="primary" fill="clear" class="ion-no-padding ion-no-margin ion-align-self-center" (click)="dismissOverlay()">
    <ion-label>{{'CANCEL'| translate}}</ion-label>
  </ion-button>
  </div>
</div>

<ion-content>
  <ion-grid class="metainfo ion-padding">
    <ion-row *ngIf="!flat" class="searchbar-row">
      <ion-col size="12" size-sm="12" size-md="12" size-lg="4" class="searchbar-col">
        <ion-searchbar type="text" debounce="200" class="searchbartop" [(ngModel)]="searchString" [placeholder]="'SEARCH' | translate"></ion-searchbar>
      </ion-col>
      <ion-col size="12" size-sm="6" size-md="6" size-lg="4">
        <ion-item>
          <ion-label>{{'FILTER'| translate}}</ion-label>
          <ion-select [(ngModel)]="filter" [okText]="'OK' | translate" [cancelText]="'ABORT' | translate">
            <ion-select-option value="-1">{{'NOFILTER'| translate}}</ion-select-option>
            <ion-select-option value="0">{{'FILTER1'| translate}}</ion-select-option>
            <ion-select-option value="1">{{'FILTER2'| translate}}</ion-select-option>
            <ion-select-option value="2">{{'FILTER3'| translate}}</ion-select-option>
            <ion-select-option value="3">{{'FILTER4'| translate}}</ion-select-option>
            <ion-select-option value="4">{{'FILTER5'| translate}}</ion-select-option>
            <ion-select-option value="5">{{'FILTER6'| translate}}</ion-select-option>
            <ion-select-option value="6">{{'FILTER7'| translate}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
      <ion-col size="12" size-sm="6" size-md="6" size-lg="4">
        <ion-item>
          <ion-label>{{'SORT'| translate}}</ion-label>
          <ion-select [(ngModel)]="status" (ionChange)="onChangeStatus()" [okText]="'OK' | translate" [cancelText]="'ABORT' | translate">
            <ion-select-option value="1">{{'SORT1'| translate}}</ion-select-option>
            <ion-select-option value="2">{{'SORT2'| translate}}</ion-select-option>
            <ion-select-option value="3">{{'SORT3'| translate}}</ion-select-option>
            <ion-select-option value="4">{{'SORT4'| translate}}</ion-select-option>
            <ion-select-option value="5">{{'SORT5'| translate}}</ion-select-option>
            <ion-select-option value="6">{{'SORT6'| translate}}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid>
    <ion-row class="actionbar">
      <ion-col *ngIf="folderTree && folderTree.length > 0" class="breadcrumbs">
        <ion-item lines="none" color="none">
          <ion-button *ngIf="master || !profileProvider.isProjectSource" size="default" color="primary" fill="clear" class="ion-no-padding ion-no-margin" (click)="reset()">
            <ion-label>{{'BREADCRUMB'| translate}}</ion-label>
          </ion-button>
          <ng-container *ngFor="let folder of folderTree">
            {{ ' | ' }}
            <ion-button size="default" color="primary" fill="clear" class="ion-no-padding ion-no-margin" (click)="goBackToFolder(folder)">
              <ion-label>{{ folder.name }}</ion-label>
            </ion-button>
          </ng-container>
        </ion-item>
      </ion-col>
      <ion-col *ngIf="!flat" class="addButtons">
        <ion-item lines="none" color="none">
          <ion-button *ngIf="inFolder && ownFolder && (!profileProvider.isProjectSource || profileProvider.currentFolder.orderId)" size="default" slot="end" color="primary" expand="block" (click)="presentActionCreate()">
            <ion-icon mode="md" name="add"></ion-icon>
            <ion-label>{{'NEWPROFILE'| translate}}</ion-label>
          </ion-button>
          <ion-button *ngIf="!profileProvider.isProjectSource && ((!inFolder && !inSubFolder) || (inFolder && ownFolder && !profileProvider.currentFolder.templates))" size="default" slot="end" color="primary" expand="block" (click)="addFolder()">
            <ion-icon mode="md" name="add"></ion-icon>
            <ion-label>{{'NEWFOLDER'| translate}}</ion-label>
          </ion-button>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-list lines="none" class="ion-padding" *ngIf="!inFolder && !flat">
    <ion-grid>
      <ion-row>

        <!-- Root Folder -->
        <ng-container *ngFor="let profile of profileProvider.folder ">
          <ion-col [hidden]="isSubfolder(profile) || isTreeFolder(profile)" size="12" size-sm="6" size-md="6" size-lg="4" size-xl="3">
            <div class="folder"  *ngIf="profile.typ == 'folder'" (click)="openFolder(profile)">
              <div class="mark ion-no-padding" *ngIf="ownFolder || !inSubFolder" [ngClass]="this.folderList.includes(profile.id) ? 'active' : 'none'" (click)="selectFolder(profile, $event)">
                <ion-icon name="checkmark"></ion-icon>
              </div>
              <div class="title">{{profile.name}}</div>
              <ion-img src="assets/imgs/folderlicon.svg"></ion-img>
              <ng-container *ngIf="master">
                <ion-badge color="{{profile | ownFolderColor}}">{{profile | ownFolder | async}}</ion-badge>
              </ng-container>
            </div>
          </ion-col>
        </ng-container>

        <!-- Virtual Folder-->
        <ng-container *ngFor="let profile of profileProvider.profileFolderVirtual">
          <ion-col size="12" size-sm="6" size-md="6" size-lg="4" size-xl="3">
            <div class="folder" (click)="openSubUserFolder(profile)">
              <div class="title"></div>
              <ion-img src="assets/imgs/folderlicon.svg"></ion-img><br>
              <ng-container *ngIf="master">
                <ion-badge color="{{profile | ownFolderColor}}">{{profile | ownFolder | async}}</ion-badge>
              </ng-container>
            </div>
          </ion-col>
        </ng-container>

      </ion-row>
    </ion-grid>
  </ion-list>

  <ion-list lines="none" class="ion-padding" *ngIf="inFolder && !flat">
    <ion-grid>
      <ion-row>

        <!-- Tree Folder -->
        <ng-container *ngFor="let profile of profileProvider.folder ">
          <ion-col *ngIf="profile.typ == 'folder' && isParentFolder(profile)" size="12" size-sm="6" size-md="6" size-lg="4" size-xl="3">
            <div class="folder" (click)="openFolder(profile)">
              <div class="mark ion-no-padding" *ngIf="ownFolder || !inSubFolder" [ngClass]="this.folderList.includes(profile.id) ? 'active' : 'none'" (click)="selectFolder(profile, $event)">
                <ion-icon name="checkmark"></ion-icon>
              </div>
              <div class="title">{{profile.name}}</div>
              <ion-img src="assets/imgs/folderlicon.svg"></ion-img>
              <ng-container *ngIf="master">
                <ion-badge color="{{profile | ownFolderColor}}">{{profile | ownFolder | async}}</ion-badge>
              </ng-container>
            </div>
          </ion-col>
        </ng-container>

        <ng-container *ngFor="let profile of (profileProvider.profilesItems | async | searchName:filter:searchString )">
          <ng-container *ngIf="ownFolder || profile.status >= 1">
            <ion-col *ngIf="profile.typ != 'folder'" size="12" size-sm="6" size-md="6" size-lg="4" size-xl="3">
              <div class="folder" (click)="presentActionSheet(profile)">
                <div *ngIf="profile.status < 1" class="mark ion-no-padding" [ngClass]="this.list.includes(profile.id) ? 'active' : 'none'" (click)="select(profile, $event)">
                  <ion-icon mode="ios" name="checkmark"></ion-icon>
                </div>
                <div class="title">{{profile.name}}</div>
                <preview [item]="profile"></preview>
                <ion-badge color="{{profile.status | statusColor}}"> {{profile.status | status}}</ion-badge>
                <div class="triangle"></div>
              </div>
            </ion-col>
          </ng-container>
        </ng-container>
      </ion-row>

    </ion-grid>
  </ion-list>


  <ion-list lines="none" class="ion-padding" *ngIf="master && flat ">
    <ion-grid>
      <ion-row>
        <ng-container *ngFor="let profile of profileProvider.profilesflat">
        <ng-container *ngIf="profile.status == 1">
          <ion-col *ngIf="profile.typ != 'folder'" size="12" size-sm="6" size-md="6" size-lg="4" size-xl="3">
              <div class="folder" (click)="presentActionSheet(profile)">
                <div class="title">{{profile.name}} </div>
                <preview [item]="profile"></preview>
                <ion-badge class="custombadge" color="{{profile.status | statusColor}}"> {{profile.status | status}} </ion-badge>
                <div></div>
                <ion-badge class="custombadge" color="{{profile | ownFolderColor}}">{{profile | ownFolder | async}}<br>{{profile.folderPathName}}</ion-badge>
                <div class="triangle"></div>
              </div>
            </ion-col>
        </ng-container>
        </ng-container>
      </ion-row>
    </ion-grid>
  </ion-list>

</ion-content>
