import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {Inject, Injectable} from '@angular/core';
import { backend } from '../../../environments/environment';

export interface IRequestOptions {
  body?: any;
  headers?: HttpHeaders | { [header: string]: string | Array<string> };
  observe?: any;
  params?: HttpParams | { [param: string]: string | Array<string> };
  reportProgress?: boolean;
  responseType?: "json";
  withCredentials?: boolean;
}

/*
  Generated class for the RequestProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class RequestProvider {

  private serverAddress = backend.url;

  constructor(
    //public http: Http
    @Inject(HttpClient) protected http: HttpClient
  ) {
  }

  getHeaders(accessToken) {
    return new HttpHeaders(accessToken
      ? {'Content-Type': 'application/json','Authorization': 'Bearer ' + localStorage.getItem("token")}
      : {'Content-Type': 'application/json'});
  }

  getOptions(accessToken) {
    const options: IRequestOptions = { headers: this.getHeaders(accessToken), responseType: 'json'};
    return options;
  }

  getUrl(url) {
    return this.serverAddress + url;
  }

  post(url, data, accessToken = true) : any {
    // @ts-ignore
    return this.http.post(
      this.getUrl(url),
      data,
      this.getOptions(accessToken)
    ).pipe();
  }

  get(url, accessToken = true) : any {
    // @ts-ignore
    return this.http.get(this.getUrl(url), this.getOptions(accessToken)).pipe();
  }

  put(url, data, accessToken = true) : any {
    // @ts-ignore
    return this.http.post(this.getUrl(url), data, this.getOptions(accessToken)).pipe();
  }

  delete(url, accessToken = true) : any {
    // @ts-ignore
    return this.http.delete(this.getUrl(url), this.getOptions(accessToken)).pipe();
  }

}
