import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {
  NavController,
  Platform,
  LoadingController,
  ToastController
} from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AuthProvider } from "../shared/providers/auth/auth";
import { TranslateService } from "@ngx-translate/core";
import { ProfilesProvider } from "../shared/providers/profiles/profiles";
import { Utils } from 'src/shared/providers/utils/utils';
import { environment } from '../environments/environment';
import { ExportImportProvider } from 'src/shared/providers/export-import/export-import';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private platform: Platform,
              private nav: NavController,
              private auth: AuthProvider,
              private storage: Storage,
              private loadingCtrl: LoadingController,
              private translate: TranslateService,
              private profileProvider: ProfilesProvider,
              private exportImport: ExportImportProvider,
              private toastCtrl: ToastController
              ) {
    profileProvider.exportImport = exportImport; // workaround circular dependency
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // do logout
    if (state.url.includes('logout')) {
      this.doLogout();
      return false;
    }

    // api stuff
    const api = this.platform.getQueryParam('api');
    if (api != null && api !== undefined && api !== '')
    {// B2B interface
      if(state.url.includes('draw'))
        this.storage.get("customerId").then((id)=>{if(this.IsCustomerIdInvalid(id)) this.clearStorageAndLoadLoginPage();});
      else {
        // login and load endpoint
        const access_token = this.platform.getQueryParam('access_token');
        if (access_token !== undefined && access_token !== '') {
          return this.apiB2B(api, access_token);
        } else {
          // b2b login failed
          this.clearStorageAndLoadLoginPage();
        }
      }
    }
    else if(state.url.includes('profile') || state.url.includes('projects'))
    {
        return this.checkLoggedIn(true);
    }
    else if (state.url.includes('draw') &&
             this.profileProvider.code === "")
    {
        this.nav.navigateRoot('dashboard');
        return false;
    }


    return true;
  }

  private IsCustomerIdInvalid(customerId) {
    return customerId === undefined || customerId === null || customerId === '' || customerId === 'null';
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  async checkLoggedIn(doQuery: boolean): Promise<boolean> {

    await this.storage.ready();
    let userProm = this.storage.get('user');
    let whoamiProm = this.storage.get('whoami');
    let deviceMasterProm = this.storage.get('deviceMaster');
    let deviceProm = this.storage.get('device');
    let codeProm = this.storage.get('code');

    let user = await userProm;
    let whoami = await whoamiProm;
    let deviceMaster = await deviceMasterProm;
    let device = await deviceProm;
    let code = await codeProm;

    if(!whoami || !whoami.id)
    {
      this.clearStorageAndLoadLoginPage();
      return false;
    }

    if (user && deviceMaster) {
      return !doQuery || this.check(deviceMaster, true);
    } else if (device) {
      return !doQuery || this.check(device, false, code);
    } else {
      this.clearStorageAndLoadLoginPage();
      return false;
    }
  }

  private clearStorageAndLoadLoginPage() {
    this.clearStorage();
    this.nav.navigateRoot('dashboard' );
  }


  async apiB2B(api: any, access_token: any): Promise<any>{
    let error = await this.loginB2B(access_token);
    const customerId = await this.storage.get("customerId");
    console.log('Endpoint ' + api + ' ' + customerId);
    if (error === true || this.IsCustomerIdInvalid(customerId)) {
      this.clearStorageAndLoadLoginPage();
    } else if (api === 'createNew') {
      await this.createNewB2B();
    } else if (api === 'loadTemplate') {
      await this.loadTemplateB2B();
    } else if (api === 'loadDrawing') {
      await this.loadDrawingB2B();
    }

    return false;
  }

  async loginB2B(token: any): Promise<any> {
    console.log('b2bLogin');
    return new Promise(async (resolve, reject) => {
      const expires_at = this.platform.getQueryParam('expires_at');
      const deviceMaster = this.platform.getQueryParam('deviceMaster');
      const uid = this.platform.getQueryParam('uid');
      const customerId = this.platform.getQueryParam('customerId');
      const autosave = this.platform.getQueryParam('autosave');
      const shopInstance = this.platform.getQueryParam('i');
      /* console.log('*** deviceMaster: ' + deviceMaster + ' ***');
      console.log('*** uid: ' + uid + ' ***');
      console.log('*** customerId: ' + customerId + ' ***');
      console.log('*** expires_at: ' + expires_at + ' ***');
      console.log('*** shopInstance: ' + shopInstance + ' ***');
      */
      if (deviceMaster !== undefined && deviceMaster !== '' && uid !== undefined && uid !== '' && customerId !== undefined && customerId !== '') {
        const wait = await this.auth.setToken(token, expires_at, deviceMaster, uid);
        if (wait.error) {
          reject(wait.error.error);
        } else {
          const my = await this.auth.whoami();
          this.storage.set("user", my);
          this.storage.set("customerId", customerId);
          Utils.customerId = customerId;
          Utils.autosave = autosave;
          if (shopInstance !== undefined && shopInstance !== null) {
            const shopConfig = environment.shopConfig.find((s) => s.i === shopInstance);
            if (shopConfig) {
              if (!environment.production) {
                console.log('shop instance', shopConfig);
              }
              this.storage.set("shop", shopConfig);
              Utils.isB2B = true;
              Utils.b2bInstance = shopConfig;
              resolve(false);
            } else {
              console.error('unsupported shop instance', shopInstance);
              reject('unsupported shop instance');
            }
          } else {
            console.error('undefined shop instance');
            reject('undefined shop instance');
          }
        }
      } else {
        console.log('Missing parameter!');
        resolve(true);
      }
    });
  }

  async createNewB2B() {
    const customerId = await this.storage.get("customerId");
    const {
      materialId,
      colorName,
      sheetlength,
      numberOfPiecesToManufacture,
      materialName,
      materialThickness,
      colorPosition,
      lockTogether
    } = this.getQueryParms();

    // console.log('Endpoint createNew ' + customerId);
    if (customerId !== undefined && customerId !== null) {
      this.storage.get('whoami').then(async (whoami) => {
        this.profileProvider.loadFolderB2B(whoami, customerId).subscribe(
          (folder) => {
            if (folder.length === 0) {
              this.profileProvider.addNamedFolder(whoami, customerId);
            } else {
              // currently only one folder per customer
              this.profileProvider.currentFolder = folder[0];
              var d = new Date();
              let data = {
                name: "Profile " + d.toLocaleDateString() + " " + d.toLocaleTimeString(),
                // master: this.master,
                customerId: customerId,
                templateId: null, // will be set to itself
                status2: 'derived'
              };
              this.profileProvider.create(data).then(() => {
                this.profileProvider.modifyActive(materialId,
                                                  materialName,
                                                  materialThickness,
                                                  colorName,
                                                  colorPosition,
                                                  sheetlength,
                                                  numberOfPiecesToManufacture,
                                                  lockTogether);
                this.nav.navigateRoot('draw');
              });
            }
          });
      });
    } else {
      this.nav.navigateRoot('login');
    }
  }

  private getQueryParms() {
    const materialId = this.platform.getQueryParam('materialId');
    const colorName = this.platform.getQueryParam('colorName');
    const sheetlength = this.platform.getQueryParam("sheetlength");
    const numberOfPiecesToManufacture = this.platform.getQueryParam("numberOfPiecesToManufacture");
    const materialName = this.platform.getQueryParam("materialName");
    const materialThickness = this.platform.getQueryParam("materialThickness");
    const colorPosition = this.platform.getQueryParam("colorPosition");
    const lockTogether = this.platform.getQueryParam("lockTogether");
    return {
      materialId,
      colorName,
      sheetlength,
      numberOfPiecesToManufacture,
      materialName,
      materialThickness,
      colorPosition,
      lockTogether
    };
  }

  async loadTemplateB2B() {
    const customerId = await this.storage.get("customerId");
    const profileId = this.platform.getQueryParam('profileId');
    const {
      materialId,
      colorName,
      sheetlength,
      numberOfPiecesToManufacture,
      materialName,
      materialThickness,
      colorPosition,
      lockTogether
    } = this.getQueryParms();

    if (profileId !== undefined && profileId !== null && customerId !== undefined && customerId !== null) {
      this.storage.get('whoami').then(async (whoami) => {
        this.profileProvider.loadFolderB2B(whoami, customerId).subscribe(
          async (folder) => {
            if (folder.length === 0) {
              await this.profileProvider.addNamedFolder(whoami, customerId);
            } else {
              // currently only one folder per customer
              this.profileProvider.currentFolder = folder[0];
            }
            this.profileProvider.loadTemplateFolderB2B(whoami).subscribe(
              async (templateFolder) => {
                if (templateFolder.length === 0) {
                  console.log('No template folder found!');
                  this.nav.navigateRoot('login');
                } else {
                  // currently only one templatefolder per mandant
                  const folderId = templateFolder[0].id;
                  this.profileProvider.loadTemplateB2B(whoami, folderId, profileId).subscribe(
                    async (template) => {
                      if (template.length === 0) {
                        console.log('Template ' + profileId + ' not found!');
                        this.nav.navigateRoot('login');
                      } else {
                        this.profileProvider.duplicateB2B(template[0], customerId, materialId, colorName).then(() => {
                          this.profileProvider.modifyActive(materialId,
                                                            materialName,
                                                            materialThickness,
                                                            colorName,
                                                            colorPosition,
                                                            sheetlength,
                                                            numberOfPiecesToManufacture,
                                                            lockTogether);
                          this.nav.navigateRoot('draw');
                        });
                      }
                    });
                }
              });

          });
      });
    } else {
      // console.log('Missing parameter!');
      this.nav.navigateRoot('login');
    }
  }



  async loadDrawingB2B() {
    const customerId = await this.storage.get("customerId");
    const profileId = this.platform.getQueryParam('profileId');
    const {
      materialId,
      colorName,
      sheetlength,
      numberOfPiecesToManufacture,
      materialName,
      materialThickness,
      colorPosition,
      lockTogether
    } = this.getQueryParms();

    if (profileId !== undefined && profileId !== null && customerId !== undefined && customerId !== null) {
      this.storage.get('whoami').then(async (whoami) => {
        this.profileProvider.loadFolderB2B(whoami, customerId).subscribe(
          async (folder) => {
            if (folder.length === 0) {
              console.log('Folder ' + customerId + ' not found!');
            } else {
              // currently only one folder per customer
              this.profileProvider.currentFolder = folder[0];
              let subscription = this.profileProvider.loadDrawingB2B(whoami, this.profileProvider.currentFolder.id, profileId).subscribe(
                async (drawing) => {
                  if (drawing === null || drawing == undefined) {
                    console.log('Drawing ' + profileId + ' not found!');
                    this.nav.navigateRoot('login');
                  } else {
                    const data = drawing.data();
                    const id = drawing.id;
                    this.profileProvider.active = { id, ...data };
                    this.profileProvider.modifyActive(materialId,
                                                      materialName,
                                                      materialThickness,
                                                      colorName,
                                                      colorPosition,
                                                      sheetlength,
                                                      numberOfPiecesToManufacture,
                                                      lockTogether);
                    this.nav.navigateRoot('draw');
                  }
                  subscription.unsubscribe();
                });
            }
          });
      });
    } else {
      // console.log('Missing parameter!');
      this.nav.navigateRoot('login');
    }
  }


  doLogout() {
    this.storage.get('device').then(async (device) => {
      if (device) {
        await this.delete(device, false);
        this.clearStorage();
      }
    });
    this.storage.get('deviceMaster').then(async (deviceMaster) => {
      if (deviceMaster) {
        await this.delete(deviceMaster, true);
        this.clearStorage();
      }
    });

    this.loadingSpinner();
  }

  clearStorage() {
    this.auth.logout();
    this.storage.set('user', null);
    this.storage.set('device', null);
    this.storage.set('deviceMaster', null);
    // this.storage.set('customerId', null);
    this.storage.set('settings', null);
    this.storage.set("code", null);
    this.storage.set("linewidth", null);
    this.storage.set("storkeStyle", null);
    this.storage.set("stehfalzStyle", null);
    this.storage.set("activeStyle", null);
    this.storage.set("firebase", null);
    this.storage.set("expires", null);
    this.storage.set("whoami", null);
    this.storage.set("license", null);
    this.storage.set("profiles", null);
    this.storage.set("master", null);
    localStorage.setItem("token", null);
    this.storage.set("firebase", null);
    this.storage.set("firebase2", null);
    this.storage.set("expires", null);
    this.storage.set("customerId", null);
    this.storage.set("b2b", null);
    this.storage.set("shop", null);
    localStorage.clear();
    this.storage.clear();
  }

  async check(device, isMaster = false, code = null) : Promise<boolean> {
    if (navigator.onLine) {
      let wait = await this.auth.checkDevice(device, isMaster);
      let deviceInvalid = (wait == null || wait.ret == undefined || wait.ret.ok == undefined || !wait.ret.ok);
      if(isMaster && deviceInvalid) {
        const customerId = await this.storage.get("customerId");
        deviceInvalid = customerId === undefined;
      }
      let licenseInvalid = false;

      let wait2 = null;
      if (isMaster) {
        wait2 = await this.auth.whoami();
        licenseInvalid = wait2 == null || wait2.email == undefined;
      } else if (code) {
        wait2 = await this.auth.getCodeUser(code, device); // check license
        licenseInvalid = (wait2 == null || wait2.ret == undefined || wait2.ret.ok == undefined || !wait2.ret.ok);
      }
      if(licenseInvalid && wait2)
        console.log(wait2);

      if (deviceInvalid || licenseInvalid) {
        this.toastCtrl.create({
          message: deviceInvalid ? this.translate.instant("DEVICEINVALID") : this.translate.instant("LICENSEINVALID"),
          duration: 5000,
          position: 'middle',
        }).then(toast=>toast.present());
        this.clearStorageAndLoadLoginPage();
        return false;
      }
    }

    return true;
  }

  async delete(device, isMaster = false) {
    await this.auth.deleteDevice(device, isMaster);
    return true;
  }

  async loadingSpinner() {
    let loading = await this.loadingCtrl.create({
      message: "Logout",
      showBackdrop: false
    });

    await loading.present();
    setTimeout(async () => {
      await this.storage.clear();
      try {
        await this.nav.navigateRoot('dashboard');
      }
      finally {
        window.location.reload();
        loading.dismiss();
      }
    }, 1500);
  }


}
