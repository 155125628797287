import { Pipe, PipeTransform } from '@angular/core';

/**
 * Generated class for the SearchNamePipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'searchName',
})
export class SearchNamePipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  itemsRet: any;
  
  transform(items: any, filter: any, searchName: string) {
    this.itemsRet = items;
    if (items == null) {
      return [];
    }
    if (searchName == null && searchName.length == 0  && filter == 0) {
      return items;
    }
    if(filter > -1) {
      this.itemsRet = this.itemsRet.filter(item => {
        if(item.status == filter) {
          return true
        } else {
          return false
        }
      })
    }
    if (searchName && searchName.length > 0) { 
      this.itemsRet = this.itemsRet.filter(item => {
        return item.name.toLowerCase().indexOf(searchName) > -1;
      });
    }
    return this.itemsRet; 
  }
}
