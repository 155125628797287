import {Component, OnInit} from '@angular/core';
import {NavParams} from '@ionic/angular';

@Component({
  selector: "more-options",
  templateUrl: "more-options.html",
  styleUrls: ['more-options.scss']
})
export class MoreOptionsComponent implements OnInit {

  public buttons: [];

  constructor(public navParams: NavParams) {
  }

  ngOnInit(): void {
    this.buttons = this.navParams.get('buttons');
  }

}
