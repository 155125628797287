import {Component } from '@angular/core';
import {AlertController, MenuController, NavController, Platform, ToastController} from '@ionic/angular';
import {AuthProvider } from '../../shared/providers/auth/auth';
import {Storage } from '@ionic/storage';
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ProfilesProvider} from "../../shared/providers/profiles/profiles";
import {MessagesProvider} from '../../shared/providers/messages/messages';
import {Utils } from 'src/shared/providers/utils/utils';
import {InAppBrowser, InAppBrowserOptions} from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
  selector: 'page-dashboard',
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.scss']
})
export class DashboardPage {

  loggedIn: boolean = false;
  pm: boolean = false;

  toast: any;
  processedIntent = false;
  minimizeApp: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public navCtrl: NavController,
    private profileProvider: ProfilesProvider,
    private alertCtrl: AlertController,
    private menu: MenuController,
    private auth: AuthProvider,
    private toastCtrl: ToastController,
    private storage: Storage,
    private translate: TranslateService,
    private messagesProvider: MessagesProvider,
    private platform: Platform,
    private iab: InAppBrowser) {
  }

  ngOnInit() {
    this.storage.get('whoami').then(async (whoami) => {
      if(whoami && whoami.id) {
        const subuser = await this.storage.get('subuser');
        this.messagesProvider.loadNewMessages(subuser ? subuser : whoami).subscribe(
          async (_messages) => {
            if (_messages.length > 0) {
              Utils.newMessages = _messages.length;
            }
          });
      }
    });
  }

  ionViewWillEnter() {
    this.platform.ready().then(async () => {
      if (this.platform.is('cordova')) {
        if (this.platform.is('android')
          && (<any>window).cordova
          && (<any>window).plugins) {

          /* probably deprecated ? Can't understand why it's necessary.

          if ((<any>window).plugins.webintent) {
            if (!this.processedIntent) {
              console.log('initApp + processIntent');
              (<any>window).plugins.webintent.getUri((uri) => {
                if (uri && !this.processedIntent) {
                  console.log(uri);
                  this.processedIntent = true;
                  console.log('processing startup intent uri');
                  let target = uri.substr(uri.lastIndexOf(Utils.PREFIX) + Utils.PREFIX.length);
                  this.navCtrl.navigateRoot(target, {preserveQueryParams: true});
                }
              });
            }
          }*/

          // back button -> minimize functionality for android
          if ((<any>window).plugins.appMinimize) {
            this.platform.backButton.subscribeWithPriority(1, (next) => {
              if ((<any>window).plugins.appMinimize) {
                  if (this.minimizeApp)
                    (<any>window).plugins.appMinimize.minimize();
                  else {
                    this.minimizeApp = true;
                    //setTimeout(()=>{
                      this.toastCtrl.create({
                        message: this.translate.instant("PRESSAGAINTOEXIT"),
                        duration: 3000,
                        position: "bottom"
                      }).then(t => t.present());
                    //}, 500);
                    setTimeout(() => {
                      this.minimizeApp = false;
                    }, 3000);
                  }
              }
              next();
            });
          }
        }
      }
    });
    this.storage.get('whoami').then((whoami) => {
      if (whoami) {
        this.loggedIn = true;
        this.pm = whoami.pm > 0;
      }
    });
  }

  ionViewWillLeave(){
    this.platform.backButton.observers.pop();
  }

  openDrawing() {
    this.profileProvider.isProjectSource = false;
    this.profileProvider.currentFolder = {};
    this.profileProvider.active = {typ:'profile'};
    this.profileProvider.loadData();
    // if(!this.loggedIn) {
      this.profileProvider.create({});
    // }
    this.navCtrl.navigateForward('draw');
  }

  openNews() {
    if (this.platform.is('cordova')) {
      const options: InAppBrowserOptions = {
        toolbar: 'yes',
        location: 'no',
        zoom: 'no',
        enableViewportScale: 'yes',
        usewkwebview: 'yes',
        closebuttoncolor: '#F9F9F9',
        closebuttoncaption: this.translate.instant('CLOSE'),
      }
      const browser = this.iab.create('https://www.facebook.com/variobend', '_blank', options);
      browser.show();
    } else {
      window.open('https://www.facebook.com/variobend', '_blank');
    }
  }

  async showLogout() {
    const confirm = await this.alertCtrl.create({
      header: this.translate.instant("LOGOUT"),
      message: this.translate.instant("REMOVEDEVICE"),
      buttons: [
        {
          text: this.translate.instant("ABORT"),
          handler: () => {
            console.log('Disagree clicked');
          }
        },
        {
          text: this.translate.instant("LOGOUT"),
          handler: () => {
            this.navCtrl.navigateRoot('logout');
          }
        }
      ]
    });
    await confirm.present();
  }

  getMessagesCount(){
    return Utils.newMessages;
  }

  async showMessage() {
    if(!this.loggedIn){
      await( await this.toastCtrl
        .create({
          message: this.translate.instant("LICENSEREQUIRED"),
          duration: 5000,
          position: "bottom"
        }))
    .present();
    }
  }

  openProfiles() {
    this.profileProvider.isProjectSource = false;
    this.navCtrl.navigateForward('profiles');
  }

  openProjects() {
    this.profileProvider.isProjectSource = true;
    this.navCtrl.navigateForward('projects');
  }

  openSignIn() {
    this.navCtrl.navigateForward('signin');
  }

}
