
<ion-content class="bluebg">
<!--  <ion-slides #slides pager="true" (ionSlideNextStart)="nextStart()">-->

<!--    <ion-slide *ngFor="let idx of [0,1,2,3,4]">-->
<!--      <img src='assets/imgs/slider_{{idx}}.png' class="slide-image"/>-->
<!--      <div class="tour-container">-->
<!--        <ion-button *ngIf="idx !== 3" color="primary" size="large" (click)="nextClick()" class="tour-button">-->
<!--          {{ 'NEXT' | translate}}-->
<!--          <ion-icon slot="end" name="arrow-forward"></ion-icon>-->
<!--        </ion-button>-->
<!--        <ion-button *ngIf="idx === 3" color="primary" size="large" (click)="startApp()" class="tour-button">-->
<!--          {{ 'NEXT' | translate}}-->
<!--          <ion-icon  slot="end" name="arrow-forward"></ion-icon>-->
<!--        </ion-button>-->
<!--      </div>-->
<!--    </ion-slide>-->

<!--  </ion-slides>-->
</ion-content>
