import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { NavController, Platform, ToastController} from '@ionic/angular';
import firebase from "firebase/compat/app";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Storage } from '@ionic/storage';
import {TranslateService} from "@ngx-translate/core";

type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable({
  providedIn: 'root'
})
export class FcmProvider {

  constructor(
    private firebaseNative: FirebaseX,
    private platform: Platform,
    private storage: Storage,
    private afMessaging: AngularFireMessaging,
    private db: AngularFirestore,
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private translate: TranslateService
  ) {
    try {
      const messaging = firebase.messaging();
      messaging.onMessage = messaging.onMessage.bind(messaging);
    } catch (e) {
    }
  }

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  public async getToken() {
    // const serviceWorkerRegistration = await navigator.serviceWorker.getRegistration();
    let token: string;
    let user:any = null;
    await this.storage.ready();
    const subuser = await this.storage.get('subuser');
    if(subuser){
      user = subuser;
    } else {
      const mainuser = await this.storage.get('whoami');
      user = mainuser;
    }
    if ((window as any).cordova) {
      if (this.platform.is('android')) {
        token = await this.firebaseNative.getToken();
        console.log('android', token);
      }
      if (this.platform.is('ios')) {
        token = await this.firebaseNative.getToken();
        console.log('ios', token);
        await this.firebaseNative.grantPermission();
      }
      return this.saveTokenToFirestore(user, token);
    } else {
      console.log('getToken');
      this.afMessaging.requestPermission.subscribe(
        () => { console.log('permission granted'); },
        (error) => { console.error(error); },
      );
      return this.afMessaging.requestToken.subscribe((_token) => {
        token = _token;
        return this.saveTokenToFirestore(user, token);
      });
    }
  }

  public listenToNotifications() {
    if ((window as any).cordova) {
      if(!this.firebaseNative.hasPermission()){
        this.toastCtrl.create({
          message: this.translate.instant("NOPUSHPERMISSION"),
          duration: 10000,
          position: 'bottom',
        }).then(toast => toast.present());
      }

      return this.firebaseNative.onMessageReceived().subscribe((message) => {
        console.log(JSON.stringify(message));
        if (message) {
          if(message.tap || message.title === undefined || message.title === null || message.body === undefined || message.body === null)
            this.navCtrl.navigateForward('messages');
          else
            this.showNotificationToast(message.title, message.body);
        } else if (this.platform.is('ios')) {
            // always go to messages disabled to avoid data loss while drawing
            // this.navCtrl.navigateForward('messages');
        }
      });
    } else {
      return this.afMessaging.messages
        .subscribe((message) => {
          console.log('message: ', JSON.stringify(message));
          const notification: any = (message as any).notification;
          this.showNotificationToast(notification.title, notification.body);
        });
    }
  }

  private showNotificationToast(title: string, body: string) {
    this.toastCtrl.create({
      message: title + '\n' + body,
      duration: 5000,
      position: 'bottom',
      buttons: [
        {
          side: 'end',
          text: this.translate.instant("OK"),
          handler: () => {
            console.log('Notification clicked. Loading messages.');
            this.navCtrl.navigateForward('messages');
          }
        }
      ]
    }).then(toast => toast.present());
  }

  private async saveTokenToFirestore(user: any, token: string) {
    // alert('saveTokenToFirestore: ' + JSON.stringify(user));
    if (user && user.id && token) {
      const docData = {
        token,
        uid: user.id,
        platforms: this.platform.platforms()
      };
      console.log('register token ', docData.uid);
      const id = docData.uid + '-' + docData.platforms.join('-');
      return this.set(`devices/${id}`, docData);
    }
  }

  private set<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const timestamp = this.timestamp;
    return this.doc(ref).set(
      {
        ...data,
        createdAt: timestamp
      },
      { merge: true }
    );
  }

  private doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.db.doc<T>(ref) : ref;
  }

}
