import { Component, Input } from '@angular/core';
import 'fabric-with-gestures';
import { Platform } from '@ionic/angular';

declare let fabric: any;

@Component({
  selector: "preview",
  templateUrl: "preview.html",
  styleUrls: ['preview.scss']
})
export class PreviewComponent {
  canvas: any;
  id: any = 5;
  width: any = 150;
  height: any = 150;
  canvasWidth: any;
  canvasHeight: any;
  zoomLevel: any;
  centerPoint: any;
  @Input("item") profile: any;

  constructor(public platform: Platform) {
  }

  ngAfterViewInit() {
    let canvasWidth = 215;
    if (this.platform.width() > 1024) {
      canvasWidth = (this.platform.width()/4) - 50;
    } else if(this.platform.width() > 720 ) {
      canvasWidth = 180;
    } else {
      canvasWidth = 260;
    }

    //console.log(this.profile);
    this.canvasWidth = canvasWidth;
    this.canvasHeight = 160;
    setTimeout(function () {
      // timeout is required because otherwise the rerender was not showing up, the canvas remains empty
      this.canvas = new fabric.StaticCanvas("canvas" + this.profile.id + "");
      this.canvas.setHeight(this.canvasHeight);
      this.canvas.setWidth(this.canvasWidth);
      if (this.profile.path) {
        let path = new fabric.Path(this.profile.path, {
          top: 0,
          left: 0,
          selectable: false,
          hasControls: false,
          hasBorders: false,
          hoverCursor: "default",
          fill: "",
          stroke: "#3D4A50",
          strokeWidth: 1,
          strokeLineJoin: "round"
        });
        this.canvas.add(path);
        let scaleW = this.canvasWidth / path.width / 1.1;
        let scaleH = this.canvasHeight / path.height / 1.1;
        let scale = scaleH;
        if (scaleH > scaleW) {
          scale = scaleW;
        }
        path.strokeWidth = 12 / scale / 4;
        path.scaleX = scale;
        path.scaleY = scale;
        this.canvas.centerObject(path);
        this.canvas.renderAll();
      }
    }.bind(this), 1);
  }
}
