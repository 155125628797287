<ion-header>
  <ion-toolbar color="dark" >
    <ion-buttons>
      <ion-button fill="solid" color="primary" (click)="dismiss()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollX="false" scrollY="false">
    <div class="content-wrapper">
        <ng2-pdfjs-viewer #pdfViewer
                          [externalWindow]="false"
                          [downloadFileName]="'Variobend.pdf'"
                          [openFile]="true"
                          [showSpinner]="true"
                          [viewBookmark]="false"
                          [download]="true">
        </ng2-pdfjs-viewer>
    </div>
</ion-content>
