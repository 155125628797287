<canvas id="canvas" class="no-scroll"></canvas>
<threed-preview (threedView)="handleThreedView($event)"></threed-preview>

<div id="svgImage" *ngIf="showSVG" class="svg" [innerHTML]="svg"></div>
<ng-container *ngIf="showSVG">
  <canvas id="canvasPDF" class="no-scroll"></canvas>
</ng-container>

<!--<ion-footer >-->

<!--  <ion-toolbar color="dark" *ngIf="changeValues || changeValues2" class="top-toolbar">-->
<!--    <div class="tollbar-inputs">-->
<!--      <ion-grid>-->
<!--        <ion-row *ngIf="changeValues">-->
<!--          <ion-col>-->
<!--            <ion-button class="plusminus" fill="clear" strong="true" *ngIf="currentValue > 0 || currentValue < 0" (click)="flipSign()">±</ion-button>-->
<!--            <ion-input #angle type="number" inputmode="decimal" min="0" max="180" class="angle ion-text-right" [(ngModel)]="currentValue"-->
<!--                       (ionFocus)="checkFocus('angle')" (ionChange)="changeAngle()" (keyup.enter)="closeInput()"-->
<!--                       placeholder="{{'ANGEL'| translate}}" debounce="100" step="5">-->
<!--            </ion-input>-->
<!--          </ion-col>-->
<!--        </ion-row>-->
<!--        <ion-row *ngIf="changeValues2">-->
<!--          <ion-col size="12" size-sm="6" size-md="6" size-lg="6">-->
<!--            <ion-input #distance type="number" inputmode="decimal" min="1" max="1250" class="ion-text-right" [(ngModel)]="currentValue2"-->
<!--                       (ionChange)="changeDistance()" (ionFocus)="checkFocus('distance')" (keyup.enter)="closeInput()"-->
<!--                       placeholder="{{'EDGELENGTH'| translate}}" debounce="100" step="5">-->
<!--            </ion-input>-->
<!--          </ion-col>-->
<!--          <ion-col size="4" size-sm="3" size-md="2" size-lg="1" class="ion-text-end ion-padding-end">-->
<!--            <ion-label class="toolbar-label">{{'CONICITY'| translate}}</ion-label>-->
<!--          </ion-col>-->
<!--          <ion-col size="8" size-sm="3" size-md="4" size-lg="5">-->
<!--            <ion-button class="plusminus" fill="clear" strong="true" *ngIf="currentValue3 > 0 || currentValue3 < 0" (click)="flipSign3()">±</ion-button>-->
<!--            <ion-input #conicity type="number" inputmode="decimal" class="ion-text-right" [(ngModel)]="currentValue3"-->
<!--                      (ionChange)="changeConicity()" (ionFocus)="checkFocus('conicity')" (keyup.enter)="closeInput()"-->
<!--                       placeholder="{{'CONICITY'| translate}}" debounce="100">-->
<!--            </ion-input>-->
<!--          </ion-col>-->
<!--        </ion-row>-->
<!--      </ion-grid>-->
<!--    </div>-->
<!--  </ion-toolbar>-->

<!--  <ion-toolbar color="dark">-->
<!--    <ion-buttons slot="start">-->
<!--      <ng-container *ngIf="modus == 'edit'">-->
<!--        <ion-button #editEditBtn class="iconaktiv" (click)="changeModus('edit', $event)">-->
<!--          <ion-icon name="build" size="default" class="ion-padding-horizontal"></ion-icon>-->
<!--          <span class="ion-hide-sm-down">{{'EDIT'| translate}}</span>-->
<!--        </ion-button>-->
<!--        <ion-button #drawEditBtn class="light" color="light" (click)="changeModus('draw', $event)">-->
<!--          <ion-icon name="pencil-sharp" size="default" class="ion-padding-horizontal"></ion-icon>-->
<!--          <span class="ion-hide-sm-down">{{'DRAW'| translate}}</span>-->
<!--        </ion-button>-->
<!--      </ng-container>-->
<!--      <ng-container *ngIf="modus == 'draw'">-->
<!--        <ion-button  #editDrawBtn color="light" class="light" (click)="changeModus('edit', $event)">-->
<!--          <ion-icon name="build" size="default" class="ion-padding-horizontal"></ion-icon>-->
<!--          <span class="ion-hide-sm-down">{{'EDIT'| translate}}</span>-->
<!--        </ion-button>-->
<!--        <ion-button  #drawDrawBtn class="iconaktiv" (click)="changeModus('draw', $event)">-->
<!--          <ion-icon name="pencil-sharp" size="default" class="ion-padding-horizontal"></ion-icon>-->
<!--          <span class="ion-hide-sm-down">{{'DRAW'| translate}}</span>-->
<!--        </ion-button>-->
<!--        <ion-button #backDrawBtn color="light" (click)="backTurn()" *ngIf="showUndo">-->
<!--          <ion-icon name="arrow-undo" size="default" class="ion-padding-horizontal"></ion-icon>-->
<!--          <span class="ion-hide-sm-down">{{'DELETE'| translate}}</span>-->
<!--        </ion-button>-->
<!--        <ion-button #editbarDrawBtn color="light" (click)="editBarAction(4)" *ngIf="points.length > 2">-->
<!--          <ion-icon name="git-compare" size="default" class="ion-padding-horizontal"></ion-icon>-->
<!--          <span class="ion-hide-sm-down">{{'CHANGEPOINT'| translate}}</span>-->
<!--        </ion-button>-->
<!--      </ng-container>-->
<!--    </ion-buttons>-->
<!--    <ion-buttons slot="end">-->
<!--      <ion-button #zoomInBtn color="light" (click)="zoomWithButton(true, $event)">-->
<!--        <ion-icon name="add-circle" size="default"></ion-icon>-->
<!--      </ion-button>-->
<!--      <ion-button #zoomOutBtn color="light"(click)="zoomWithButton(false, $event)">-->
<!--        <ion-icon name="remove-circle" size="default"></ion-icon>-->
<!--      </ion-button>-->
<!--      <ion-button color="light" (click)="showActionMenu($event)">-->
<!--        <ion-icon name="ellipsis-vertical" size="default"></ion-icon>-->
<!--      </ion-button>-->
<!--    </ion-buttons>-->
<!--  </ion-toolbar>-->
<!--</ion-footer>-->
