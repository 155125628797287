<ion-header>
  <ion-toolbar color="dark">
    <ion-title>{{title}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list class="ion-no-padding ion-margin-top">
    <ion-item lines="none" *ngIf="import">
      <ion-label color="primary" position="stacked">File</ion-label>
      <ion-input type="file"></ion-input>
    </ion-item>
    <ion-item lines="none" *ngIf="!import">
      <ion-label color="primary" position="stacked">Name</ion-label>
      <ion-input #name type="text" [(ngModel)]="profileName" (keyup.enter)="save()" (keyup.escape)="cancel()"></ion-input>
    </ion-item>
  </ion-list>
  <div class="ion-margin-top ion-text-center">
    <ion-button fill="solid" color="danger" (click)="cancel()">{{'ABORT'| translate}}</ion-button>
    <ion-button fill="solid" color="success" (click)="save()">{{'SAVE'| translate}}</ion-button>
  </div>
</ion-content>
