<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start" class="ion-no-margin">
      <ion-menu-button fill="solid" color="light"></ion-menu-button>
    </ion-buttons>
    <ion-title>{{'HELP'| translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding ion-margin">
  <video width="320" height="240" controls playsinline autoplay>
    <source src="assets/videos/Produce.mp4" >
    Your browser does not support the video tag.
  </video>
</ion-content>
