import { Component, ViewChild } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
// import { ProfilesPage } from '../profiles/profiles';


@Component({
  selector: 'page-tutorial',
  templateUrl: './tutorial.html',
  styleUrls: ['./tutorial.scss']
})
export class TutorialPage {

  // @ViewChild(IonSlides, {}) slides: IonSlides;

  constructor(public navCtrl: NavController, public menu: MenuController,
              public storage: Storage) {
  }

  ngOnInit() {
    console.log('ngOnInit TutorialPage');
  }

  ionViewDidEnter() {
    this.menu.enable(false);
  }

  ionViewDidLeave() {
    this.menu.enable(true);
  }

  ionViewWillEnter() {
    // this.slides.update();
  }

  nextClick() {
    //this.slides.isEnd().then((isEnd)=>{
    //  if (isEnd)
    //    this.startApp();
    //  else
    //    this.slides.slideNext();
    //});
  }

  nextStart() {
    //this.slides.isEnd().then((isEnd)=>{
    //  if (isEnd)
    //    this.startApp();
    //});
  }

  startApp() {
    this.navCtrl.navigateRoot('profiles').then(() => {
      this.storage.set('hasSeenTutorial', 'true');
    })
  }

}
