import {ElementRef, Injectable, NgZone, OnDestroy} from '@angular/core';
import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {Platform} from '@ionic/angular';
import {SpriteText2D, textAlign} from 'three-text2d';

@Injectable()
export class ThreedProvider implements OnDestroy {

  public canvas: HTMLCanvasElement;
  public camera: THREE.PerspectiveCamera;
  public renderer: THREE.WebGLRenderer;
  public scene: THREE.Scene;
  public controls: OrbitControls;
  public width: number;
  public height: number;
  private totalConicalOffset: number = 0;
  private readonly defaultEdgeWidth = 1000.0;
  private trafoMat: THREE.Matrix4 = new THREE.Matrix4();
  private colors: any[] = [];
  private drawingColors = ['#adadad', '#7e7e7e']; // default drawing colors
  private paintingColors = ['#920f0e', '#780f0e']; // default surface painting colors
  private paintPosition = null;
  private paintColor = null;

  constructor(private ngZone: NgZone,
              private platform: Platform) {
  }

  public ngOnDestroy(): void {
    if (this.controls !== undefined && this.controls !== null) {
      this.controls.dispose();
    }
    if (this.renderer !== undefined && this.renderer !== null) {
      this.renderer.dispose();
    }
  }

  public createScene(canvas: ElementRef<HTMLCanvasElement>, width: number, height: number, controls: boolean): void {
    this.canvas = canvas.nativeElement;
    this.width = width;
    this.height = height;

    this.renderer = new THREE.WebGLRenderer({alpha: true, antialias: true, canvas: this.canvas});
    this.renderer.setSize(width, height);

    this.camera = new THREE.PerspectiveCamera(75, this.width / this.height, 1, 10000);
    this.camera.position.set(-1000, 200, 400);
    this.camera.lookAt(0, 0, 0);

    if (controls) {
      this.addControls();
    } else if (this.controls !== undefined && this.controls !== null) {
      this.controls.dispose();
    }

    this.scene = new THREE.Scene();
    this.scene.background = null;
  }

  private fitCameraToProfile() {

    const box = new THREE.Box3();
    let allObjects = this.scene.children;
    for (const object of allObjects) box.expandByObject(object);

    const center = box.getCenter(new THREE.Vector3());
    const size = box.getSize(new THREE.Vector3());
    const maxSize = Math.max(size.x,size.y,size.z);

// here we must check if the screen is horizontal or vertical, because camera.fov is
// based on the vertical direction.
    const aspect = this.camera.aspect > 1 ? 1.0 : this.camera.aspect;
    const endDistance = 1.2 * maxSize / (2 * Math.atan(Math.PI * this.camera.fov / 360) * aspect);

    let direction = new THREE.Vector3(-1000, 200, 400)
      .normalize()
      .multiplyScalar(endDistance);
    this.camera.near = endDistance / 1000;
    this.camera.far = endDistance * 100;
    this.camera.updateProjectionMatrix();

    this.camera.position.copy(center).add(direction);
    this.camera.lookAt(center);
    if(this.controls)
      this.controls.target.copy(center);
  }

  public setColors(colors: any) {
    this.colors = colors;
  }

  public animate(profile: any):void {
    let lineCount = 0;
    let painting = false;
    this.totalConicalOffset = 0;
    this.trafoMat = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(1,0,0), THREE.Math.degToRad(90)); // align coordinate systems
    this.trafoMat.multiply(new THREE.Matrix4().makeTranslation(-500,0,0)); // translate profile into center for better control when tilting
    this.scene.remove.apply(this.scene, this.scene.children);

    if (profile && profile.data !== undefined && profile.data !== null && profile.data.length > 1) {
      // surface painting colors
      if (profile.paintPosition !== null && profile.paintPosition !== 'none' && profile.paintColor !== null && profile.paintColor !== 'none') {
        painting = true;
        this.paintPosition = profile.paintPosition;
        this.paintColor = profile.paintColor;
        if (this.colors && this.colors.length > 0) {
          const colorValue = this.colors.find((c) => c.name == profile.paintColor);
          if (colorValue) {
            this.paintingColors[0] = colorValue.rgb;
            this.paintingColors[1] = this.shadeColor(colorValue.rgb, -20);
          }
        }
      }
      // drawing lines
      profile.data.forEach((el) => {
        if (el.distance && el.angle) {
          // console.log('Linenr. ' + lineCount);
          this.addPlane(lineCount, el.distance, el.angle, painting);
          lineCount++;
        }
      });
    }
    this.fitCameraToProfile();
    this.render();
  }

  public addControls() {
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.rotateSpeed = 1.0;
    this.controls.zoomSpeed = 1.2;
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.25;
    this.controls.enableZoom = true;
    this.controls.enablePan = true;
    this.controls.addEventListener('change', this.render.bind(this));
  }

  public resize(width: number, height:number): void {
    this.renderer.setSize(width, height);
  }

  private render(): void {
    // console.log('Camera position: ' + this.camera.position.x + '/' + this.camera.position.y + '/' +this.camera.position.z);
    this.renderer.render(this.scene, this.camera);
  }

  private animateZone(): void {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('resize', () => {
        this.resize(this.platform.width(), this.platform.height());
      });
    })
  }

  private addPlane(idx: number, distance: any, angle: any, painting: boolean) {
    // console.log('Plane: distance = ' + distance.distance + ', angle = ' + angle.angle + ', pP = ' + angle.pP);

    // calc transformation matrix for this shape
    let rotateBendAngle = new THREE.Matrix4().makeRotationAxis(new THREE.Vector3(this.defaultEdgeWidth,this.totalConicalOffset, 0).normalize(), THREE.Math.degToRad(angle.angle * angle.pP));
    this.trafoMat.multiply(rotateBendAngle);

    // create conical shape
    const currentConicalOffset = distance.conicity ?  distance.conicity : 0;
    let edgeWidth = Math.sqrt(this.defaultEdgeWidth*this.defaultEdgeWidth+this.totalConicalOffset*this.totalConicalOffset);
    let conicalEdgeLength = distance.distance + currentConicalOffset;
    const shape = new THREE.Shape();
    shape.moveTo(0,0);
    shape.lineTo(this.defaultEdgeWidth, this.totalConicalOffset);
    shape.lineTo(this.defaultEdgeWidth, this.totalConicalOffset + conicalEdgeLength);
    shape.lineTo(0, distance.distance);
    shape.lineTo(0, 0);
    const shapeGeometry = new THREE.ShapeGeometry(shape);

    // place shape (rotate and translate)
    shapeGeometry.applyMatrix(this.trafoMat);

    // scale the text smaller to make it appear more clear when zooming in
    const scaleTextMat = new THREE.Matrix4().makeScale(0.2,0.2,0.2);

    // create annotation for length of edge
    let spriteOptionsEdge = { align: textAlign.center,  font: '28px Arial', fillStyle: '#000000' , antialias: true };
    let textEdge = new SpriteText2D(distance.distance.toString() + " mm" + (currentConicalOffset != 0 ?"\n(" + (currentConicalOffset>0?"+":"") + currentConicalOffset + ')' : ""), spriteOptionsEdge);
    let textEdgeBack = new SpriteText2D(conicalEdgeLength + " mm", spriteOptionsEdge);
    let posTextEdge = new THREE.Vector3(0, distance.distance / 2.0,0).applyMatrix4(this.trafoMat);
    let posTextEdgeBack = new THREE.Vector3(this.defaultEdgeWidth, this.totalConicalOffset + conicalEdgeLength / 2.0,0).applyMatrix4(this.trafoMat);
    textEdge.applyMatrix(new THREE.Matrix4().makeTranslation(posTextEdge.x - 10, posTextEdge.y, posTextEdge.z).multiply(scaleTextMat));
    textEdgeBack.applyMatrix(new THREE.Matrix4().makeTranslation(posTextEdgeBack.x + 10, posTextEdgeBack.y, posTextEdgeBack.z).multiply(scaleTextMat));

    // create annotation for bend angle
    let spriteOptionsAngle = { align: textAlign.center,  font: '24px Arial', fillStyle: '#444444' , antialias: true };
    let textAngle = new SpriteText2D(angle.angle + "°", spriteOptionsAngle);
    let textAngleBack = new SpriteText2D(angle.angle + "°", spriteOptionsAngle);
    let posTextAngle = new THREE.Vector3(0, 0,0).applyMatrix4(this.trafoMat);
    let posTextAngleBack = new THREE.Vector3(this.defaultEdgeWidth, this.totalConicalOffset,0).applyMatrix4(this.trafoMat);
    textAngle.applyMatrix(new THREE.Matrix4().makeTranslation(posTextAngle.x - 10, posTextAngle.y, posTextAngle.z).multiply(scaleTextMat));
    textAngleBack.applyMatrix(new THREE.Matrix4().makeTranslation(posTextAngleBack.x + 10, posTextAngleBack.y, posTextAngleBack.z).multiply(scaleTextMat));

    // remember certain values for next shape
    let delta = new THREE.Vector3(0, distance.distance,0);
    let newOffset = delta.applyMatrix4(this.trafoMat);
    this.trafoMat.setPosition(newOffset);
    this.totalConicalOffset += currentConicalOffset;

    // apply color
    if (painting) {
      const frontMaterial = new THREE.MeshBasicMaterial({
        color: this.paintPosition === 'top' ? this.drawingColors[idx % 2] : this.paintingColors[idx % 2], side: THREE.FrontSide
      });
      const frontMesh = new THREE.Mesh(shapeGeometry, frontMaterial);
      const backMaterial = new THREE.MeshBasicMaterial({
        color: this.paintPosition === 'top' ? this.paintingColors[idx % 2] : this.drawingColors[idx % 2], side: THREE.BackSide
      });
      const backMesh = new THREE.Mesh(shapeGeometry, backMaterial);
      const group = new THREE.Group();
      group.add(frontMesh);
      group.add(backMesh);
      this.scene.add(group);
    } else {
      const material = new THREE.MeshBasicMaterial({color: this.drawingColors[idx % 2], side: THREE.DoubleSide});
      const mesh = new THREE.Mesh(shapeGeometry, material);
      this.scene.add(mesh);
    }

    // add dimensioning
    this.scene.add(textEdge);
    this.scene.add(textEdgeBack);
    if(idx > 0) {
      this.scene.add(textAngle);
      this.scene.add(textAngleBack);
    }

  }

  private shadeColor(color, amount) {
    return '#' + color.replace(/^#/, '')
      .replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount))
        .toString(16)).substr(-2));
  }

}
