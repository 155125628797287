<ion-header>
  <ion-toolbar color="dark">
    <ion-title>{{'SHARE-PROFILE-HEADER' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list class="ion-no-padding ion-margin-top">
    <ion-item lines="none" >
      <ion-label color="primary" position="stacked">{{'SHARE-PROFILE-SUBHEADER' | translate}}</ion-label>
      <ion-input #mail type="email" placeholder="{{'SHARE-PROFILE-PLACEHOLDER' | translate}}"
                 [(ngModel)]="email" (keyup.enter)="address = null; share()" (keyup.escape)="cancel()"></ion-input>
    </ion-item>
    <ion-item lines="none">
      <ion-label color="primary" position="stacked">{{'SHARE-PROFILE-ADDRESSES' | translate}}</ion-label>
      <ion-select [(ngModel)]="address" (ngModelChange)="share()" [interfaceOptions]="addressOptions"
                  placeholder="{{'SELECT'| translate}}"
                  [okText]="'OK' | translate" [cancelText]="'ABORT' | translate">
        <ion-select-option [value]="address" *ngFor="let address of addresses">
          {{ address.name}}
        </ion-select-option>
      </ion-select>
    </ion-item>
  </ion-list>
  <div class="ion-margin-top ion-text-center">
    <ion-button fill="solid" color="danger" (click)="cancel()">{{'ABORT' | translate}}</ion-button>
    <ion-button fill="solid" color="success" (click)="share()">{{'SHARE' | translate}}</ion-button>
  </div>
</ion-content>
