import { Component } from '@angular/core';
import { NavController, ToastController} from '@ionic/angular';
import { Storage} from '@ionic/storage';
import { TranslateService} from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AuthProvider} from '../../shared/providers/auth/auth';

@Component({
  selector: "page-license",
  templateUrl: "license.html",
  styleUrls: ['./license.scss']
})
export class LicensePage {

  public whoami: any = null;
  public username: string;
  public isMaster: boolean = false;
  public qrCode: string = null;
  public qrCodeLink: string = null;
  public licenseInfo: {
    username: string,
    licenseDate: any,
    contributing: string,
    devices: number,
    contributorLicences: number
    contributorDevices: number
    contributors: Array<{ name: string, email: string, lastActivity: any }>
  } = null;
  public toast: any;

  constructor(
    public navCtrl: NavController,
    private storage: Storage,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    private authProvider: AuthProvider) {
  }

  ngOnInit() {
    this.storage.get('deviceMaster').then((dev) => {
      if (dev && dev > 0) {
        this.isMaster = true;
        this.qrCodeLink = null;
      }
    });
  }

  ionViewWillEnter() {
    this.userInfo();
  }

  public async userInfo() {
    console.log('userInfo');
    this.whoami = await this.storage.get('whoami');
    if (this.whoami && this.whoami.id) {
      this.licenseInfo = await this.storage.get('license');
      if (this.licenseInfo === null) {
        let uid = null;
        const code = await this.storage.get('code');
        if (code) {
          const device = this.storage.get('device');
          const subUser = await this.authProvider.getCodeUser(code, device);
          if (subUser && subUser.ret.ok) {
            uid = subUser.ret.user.id;
            this.username = subUser.ret.user.email;
          }
        } else {
          uid = this.whoami.id;
          this.username = this.whoami.email;
        }
        this.licenseInfo = await this.authProvider.licenseInfo(uid, this.username);
      } else {
        this.username = this.licenseInfo.username;
      }
    } else {
      this.licenseInfo = null;
      this.username = this.translate.instant("ANONYMOUS");
    }
  }

  public async createQR() {
    if (!this.isMaster || !navigator.onLine) {
      return;
    }
    let wait = await this.authProvider.inviteCode(this.whoami);
    if (wait && wait.error) {
      this.toast = this.toastCtrl.create({
        message: wait.error.error,
        duration: 3000,
        position: 'middle',
      }).then(toast => toast.present());
    } else if (wait.ret.code !== null) {
      this.qrCode = wait.ret.code;
      this.qrCodeLink = environment.appLink + '/register?code=' + this.qrCode + '&master=' + this.whoami.id + '&company=' + this.whoami.company;
      console.log(this.qrCodeLink);
    }
  }

}
