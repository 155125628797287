import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';

/**
 * Generated class for the HelpPage page.
 *
 * See https://ionicframework.com/docs/components/#navigation for more info on
 * Ionic pages and navigation.
 */

@Component({
  selector: 'page-help',
  templateUrl: 'help.html',
  styleUrls: ['./help.scss']
})
export class HelpPage {

  constructor(public navCtrl: NavController) {
  }

  ngOnInit() {
    console.log('ngOnInit HelpPage');
  }

}
