import {ChangeDetectorRef, Component} from '@angular/core';
import {
  NavController,
  Platform,
  ModalController,
  LoadingController,
  ActionSheetController,
  AlertController
} from '@ionic/angular';
import { ProfilesProvider } from '../../shared/providers/profiles/profiles';
import { VersionProvider } from '../../shared/providers/version/version';
import { ExportImportProvider } from '../../shared/providers/export-import/export-import';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from "@ionic/storage";
import { DomSanitizer } from '@angular/platform-browser'
import { AuthProvider } from "../../shared/providers/auth/auth";
import { TooltipProvider } from '../../shared/providers/tooltip/tooltip';
import { Utils } from 'src/shared/providers/utils/utils';
import { MessagesProvider } from '../../shared/providers/messages/messages';

@Component({
  selector: "page-projects",
  templateUrl: "projects.html",
  styleUrls: ['projects.scss']
})
export class ProjectsPage {

  lastUsedProjects: any[] = [];
  whoami: any = null;
  subuser: any = null;
  filter: any = -1;
  searchString: string = null;
  newMessages: number = 0;

  cloudOffline: boolean=false;
  cloudSync: boolean=false;
  cloudOnline: boolean=false;
  onOfflineHandler: any;

  constructor(
    public navCtrl: NavController,
    public profileProvider: ProfilesProvider,
    public modalCtrl: ModalController,
    public toastCtrl: ToastController,
    public tooltipProvider: TooltipProvider,
    private storage: Storage,
    public actionSheetCtrl: ActionSheetController,
    public exportImport: ExportImportProvider,
    public loadingCtrl: LoadingController,
    public versionProvider: VersionProvider,
    public alertController: AlertController,
    private sanitizer: DomSanitizer,
    public trans: TranslateService,
    public platform: Platform,
    private auth: AuthProvider,
    private messagesProvider: MessagesProvider,
    private changeDetectionRef: ChangeDetectorRef
  ) {

    this.onOfflineHandler = this.onOffline.bind(this);

    this.storage.get('whoami').then(async (whoami) => {
      this.whoami = whoami;
      if(whoami && whoami.id) {
        this.subuser = await this.storage.get('subuser');
        this.messagesProvider.loadNewMessages(this.subuser ? this.subuser : this.whoami).subscribe(
          async (_messages) => {
            if (_messages.length >= 0) {
              Utils.newMessages = _messages.length;
            }
          });
      }
    });
  }

  async ionViewDidEnter() {
    this.platform.backButton.subscribeWithPriority(1000, () => {
      this.modalCtrl.getTop().then(overlay => {
        if (overlay)
          overlay.dismiss();
        else
          this.alertController.getTop().then(overlay2 => {
            if (overlay2)
              overlay2.dismiss();
            else
              this.actionSheetCtrl.getTop().then(overlay3 => {
                if (overlay3)
                  overlay3.dismiss();
                else
                  history.back();
              });
          });
      });
    });

    let firebase2 = await this.storage.get("firebase2");
    if (firebase2 == undefined || firebase2 == null || firebase2 == 'undefined' || firebase2 == 'null')
      this.clearStorageAndLoadLoginPage();

    this.storage.get("whoami").then((whoami)=>{
      this.storage.get("subuser").then((subuser)=>{
        let pm = false;
        if(whoami) pm = whoami.pm;
        if(subuser) pm = pm || subuser.pm;
        if(!pm) this.clearStorageAndLoadLoginPage()
      });
    });

    this.lastUsedProjects = await this.storage.get("lastUsedProjects");
    if(!this.lastUsedProjects)
      this.lastUsedProjects = [];
    if(this.lastUsedProjects.length > 20){
      this.lastUsedProjects = this.lastUsedProjects.slice(0, 19);
    }

    if (navigator.onLine) {
      if (this.profileProvider.hasPendingWrites) {
        this.setCloudIcon("cloud-upload");
      } else {
        this.setCloudIcon("cloud-done");
      }
    } else {
      this.setCloudIcon("cloud-outline");
    }

    window.removeEventListener('offline', this.onOfflineHandler, true);
    window.addEventListener('offline', this.onOfflineHandler, true);
    window.removeEventListener('online', this.onOfflineHandler, true);
    window.addEventListener('online', this.onOfflineHandler, true);

  }

  ionViewWillLeave(){
    this.platform.backButton.observers.pop();
    this.storage.set("lastUsedProjects", this.lastUsedProjects);
  }

  setCloudIcon(cloud: any):void {
    switch (cloud) {
      case "cloud-outline":
        this.cloudOnline=false;
        this.cloudSync=false;
        this.cloudOffline=true;
        break;
      case "cloud-upload":
        this.cloudOffline=false;
        this.cloudOnline=false;
        this.cloudSync=true;
        break;
      case "cloud-done":
        this.cloudSync=false;
        this.cloudOffline=false;
        this.cloudOnline=true;
    }
  }

  getMessagesCount(){
    return Utils.newMessages;
  }

  ngOnDestroy(): void {
    window.removeEventListener('offline', this.onOfflineHandler, true);
    window.removeEventListener('online', this.onOfflineHandler, true);
  }

  ionViewDidLeave(){
    // window.removeEventListener('popstate', this.backHandler, true);
    window.removeEventListener('offline', this.onOfflineHandler, true);
    window.removeEventListener('online', this.onOfflineHandler, true);
  }

  onOffline(){
      if (navigator.onLine) {
        this.setCloudIcon("cloud-upload");
        this.recheckHasPendingWrites();
      } else
      {
        this.setCloudIcon("cloud-outline");
      };
  }

  recheckHasPendingWrites(){
    setTimeout(() => {
      this.setCloudIcon(this.profileProvider.hasPendingWrites ? "cloud-upload" : "cloud-done");
      if(this.profileProvider.hasPendingWrites) {
        this.profileProvider.loadData();
        this.recheckHasPendingWrites();
      }
    },3000);
  }

  clearStorageAndLoadLoginPage(){
    this.auth.logout();
    this.storage.set('user', null);
    this.storage.set('device', null);
    this.storage.set('settings', null);
    this.storage.set("code", null);
    this.storage.set("linewidth", null);
    this.storage.set("storkeStyle", null);
    this.storage.set("stehfalzStyle", null);
    this.storage.set("activeStyle", null);
    this.storage.set("firebase", null);
    this.storage.set("expires", null);
    this.storage.set("whoami", null);
    this.storage.set("profiles", null);
    this.storage.set("master", null);
    localStorage.setItem("token", null);
    this.storage.set("firebase", null);
    this.storage.set("firebase2", null);
    this.storage.set("expires", null);
    this.storage.set("customerId", null);
    this.storage.set("b2b", null);
    this.storage.set("shop", null);
    this.storage.set("lastUsedProjects", []);
    this.storage.clear();
    this.navCtrl.navigateRoot('dashboard');
  }



  goBack(){
    // console.log('going back');
    // console.log('history len' + history.length);
    // console.log('history state' + history.state);
    history.back();
  }

  errorAlert(message: string) {
    this.alertController.create({
      header: this.trans.instant("ATTENTION"),
      subHeader: this.trans.instant("UNEXPECTEDERROR"),
      message: message,
      buttons: ['Cancel']
    }).then(alert=>alert.present());
  }

  searchProjects(){
    this.profileProvider.loadProjects(this.searchString);

    setTimeout(()=>{this.changeDetectionRef.detectChanges();},500);
  }

  offlineUseProject(event, project: any){
    event.stopPropagation();
    this.profileProvider.downloadAllProfilesInProject(project.customerName, project.projectName, project.orderId);
  }

  openFolder(project:any){
    this.profileProvider.isProjectSource = true;
    this.profileProvider.currentFolder = project;
    this.profileProvider.loadData();

    let existing = this.lastUsedProjects.find(p=>p.id==project.id);
    if(this.searchString && !existing)
      this.lastUsedProjects.unshift(project);

    this.navCtrl.navigateForward('profiles');
  }
}
