<div class="container">
<!--  <div *ngIf="!isBoardOpen" class="action" (click)="togglePreview($event)">-->
<!--    <ion-icon [name]="isPreviewOpen ? 'chevron-forward-outline' : 'chevron-back-outline'"></ion-icon>-->
<!--  </div>-->
  <div (click)="openBoard($event)">
    <canvas #threedPreview [hidden]="!isPreviewOpen && !isBoardOpen" [ngClass]="isBoardOpen ? 'board-canvas' : 'preview-canvas'"></canvas>
    <div *ngIf="isBoardOpen" class="profileImg">
<!--      <div class="action" (click)="togglePreview($event)">-->
<!--        <ion-icon [name]="isPreviewOpen ? 'chevron-forward-outline' : 'chevron-back-outline'"></ion-icon>-->
<!--      </div>-->
      <div #profilePreview [hidden]="!isPreviewOpen" class="profile-canvas" [innerHTML]="profileImg" (click)="closeBoard($event)"></div>
    </div>
  </div>
</div>
