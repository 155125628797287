import {ChangeDetectorRef, Component} from '@angular/core';
import {
  NavController,
  Platform,
  ModalController,
  LoadingController,
  ActionSheetController,
  AlertController
} from '@ionic/angular';
import { ProfilesProvider } from '../../shared/providers/profiles/profiles';
import { VersionProvider } from '../../shared/providers/version/version';
import { FolderDetailsPage } from '../folder-details/folder-details';
import { ExportImportProvider } from '../../shared/providers/export-import/export-import';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from "@ionic/storage";
import { DomSanitizer } from '@angular/platform-browser'
import { AuthProvider } from "../../shared/providers/auth/auth";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { TooltipProvider } from '../../shared/providers/tooltip/tooltip';
import { Utils } from 'src/shared/providers/utils/utils';
import { MessagesProvider } from '../../shared/providers/messages/messages';
import { AddressPage } from '../../shared/components/address/address';
import { environment } from '../../environments/environment';
import { MailProvider } from '../../shared/providers/mails/mails';
import { Observable, Subject } from 'rxjs';


@Component({
  selector: "page-profiles",
  templateUrl: "profiles.html",
  styleUrls: ['profiles.scss']
})
export class ProfilesPage {
  inFolder: any = false;
  inSubFolder: boolean = false;
  title: any = "Profilverwaltung";
  folder: any = [];
  loading: any;
  inMaster: any = true;
  master: any = false;
  b2b: any = false;
  pm: boolean = false;
  whoami: any = null;
  subuser: any = null;
  ownFolder: any = false;
  status: any = 1;
  list: any = [];
  folderList: any = [];
  currentFolder: any;
  filter: any = -1;
  searchString: any = "";
  flat: any = false;
  toastOpen: Boolean = false;
  listObj: any = [];
  folderListObj: any = [];
  os: any;
  close: any;
  addresses: any = [];
  newMessages: number = 0;
  isLastProjectsPopoverOpen: boolean = false;
  lastUsedProjects: any[] = [];
  lastUsedProjectOrders: any[] = [];
  availableOrderProjects: Subject<any> = new Subject<any>();
  itemToAddToOrder: any;

  newVersionTrans: any = "";
  closeTrans: any = "";
  updateTrans: any = "";
  createProfileTrans: any = "";
  createFolderTrans: any = "";
  profileAdminTrans: any = "";
  openMsg1: any = "";
  openMsg2: any = "";
  changeTrans: any = "";
  checkFreeTrans: any = "";
  correctionTrans: any = "";
  checkTrans: any = "";
  exportaspicTrans: any = "";
  shareAsImg: any = "";
  duplicateTrans: any = "";
  makeTemplateTrans: any = "";
  deleteTrans: any = "";
  abortTrans: any = "";
  profileTrans: any = "";
  pleaseWaitTrans: any = "";
  moveTrans: any = "";
  newPngTrans: any = "";
  downloadTrans: any = "";
  okTrans: any = "";
  notShowing: any = "";
  subtitle: any = "";
  licenseInvalid: any = "";
  tooManyDevices: any = "";
  deviceInvalid: any = "";
  shareTrans: any = "";
  val: any;
  cloudOffline: boolean=false;
  cloudSync: boolean=false;
  cloudOnline: boolean=false;
  folderTree: any[] = [];
  breadCrumbs: string;
  private _htmlProperty: string = "Gehe zu <span name=\"share\" style=\"display:inline-block;font-family:Ionicons;\" class=\"icon  ion-ios-share-outline\"></span> und <strong>Zum Home - Bildschirm </strong>";
  private _htmlProperty2: string = "Go to <span name=\"share\" style=\"display:inline-block;font-family:Ionicons;\" class=\"icon  ion-ios-share-outline\"></span> and <strong>Add to Homescreen </strong>";
  gotoDraw: boolean=false;
  backHandler: any;
  onOfflineHandler: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public navCtrl: NavController,
    public profileProvider: ProfilesProvider,
    public modalCtrl: ModalController,
    public toastCtrl: ToastController,
    public tooltipProvider: TooltipProvider,
    private storage: Storage,
    public actionSheetCtrl: ActionSheetController,
    public exportImport: ExportImportProvider,
    public loadingCtrl: LoadingController,
    public versionProvider: VersionProvider,
    // public file: File,
    public alertController: AlertController,
    private sanitizer: DomSanitizer,
    public trans: TranslateService,
    public platform: Platform,
    private auth: AuthProvider,
    private messagesProvider: MessagesProvider,
    private mailProvider: MailProvider
  ) {

    this.backHandler = this.backFolder.bind(this);
    this.onOfflineHandler = this.onOffline.bind(this);

    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.close = this.router.getCurrentNavigation().extras.state.close;
      }
    });

    this.trans.get("NEWVERSION").subscribe((res: string) => {
      this.newVersionTrans = res;
    });
    this.trans.get("LOAD").subscribe((res: string) => {
      this.closeTrans = res;
    });
    this.trans.get("UPDATE").subscribe((res: string) => {
      this.updateTrans = res;
    });
    this.trans.get("CREATEPROFILE").subscribe((res: string) => {
      this.createProfileTrans = res;
    });
    this.trans.get("CREATEFOLDER").subscribe((res: string) => {
      this.createFolderTrans = res;
    });
    this.trans.get("PROFILADMIN").subscribe((res: string) => {
      this.profileAdminTrans = res;
    });
    this.trans.get("OPENPROFILEMSGNOTCHANGE").subscribe((res: string) => {
      this.openMsg1 = res;
    });
    this.trans.get("ERRORNOPROFILEFOUND").subscribe((res: string) => {
      this.openMsg2 = res;
    });
    this.trans.get("EDIT").subscribe((res: string) => {
      this.changeTrans = res;
    });
    this.trans.get("CHECKFREE").subscribe((res: string) => {
      this.checkFreeTrans = res;
    });
    this.trans.get("CORRECTION").subscribe((res: string) => {
      this.correctionTrans = res;
    });
    this.trans.get("CHECK").subscribe((res: string) => {
      this.checkTrans = res;
    });
    this.trans.get("EXPORTASPIC").subscribe((res: string) => {
      this.exportaspicTrans = res;
    });
    this.trans.get("SHARE-IMG").subscribe((res: string) => {
      this.shareAsImg = res;
    });
    this.trans.get("DUPLICATE").subscribe((res: string) => {
      this.duplicateTrans = res;
    });
    this.trans.get("MAKETEMPLATE").subscribe((res: string) => {
      this.makeTemplateTrans = res;
    });
    this.trans.get("DELETE").subscribe((res: string) => {
      this.deleteTrans = res;
    });
    this.trans.get("ABORT").subscribe((res: string) => {
      this.abortTrans = res;
    });
    this.trans.get("PROFILE2").subscribe((res: string) => {
      this.profileTrans = res;
    });
    this.trans.get("PLEASEWAIT").subscribe((res: string) => {
      this.pleaseWaitTrans = res;
    });
    this.trans.get("MOVE").subscribe((res: string) => {
      this.moveTrans = res;
    });
    this.trans.get("DELETE").subscribe((res: string) => {
      this.deleteTrans = res;
    });
    this.trans.get("NEWPNG").subscribe((res: string) => {
      this.newPngTrans = res;
    });
    this.trans.get("DOWNLOAD").subscribe((res: string) => {
      this.downloadTrans = res;
    });
    this.trans.get("OK").subscribe((res: string) => {
      this.okTrans = res;
    });
    this.trans.get("NOTSHOW").subscribe((res: string) => {
      this.notShowing = res;
    });
    this.trans.get("LICENSEINVALID").subscribe((res: string) => {
      this.licenseInvalid = res;
    });
    this.trans.get("TOOMANYDEVICES").subscribe((res: string) => {
      this.tooManyDevices = res;
    });
    this.trans.get("DEVICEINVALID").subscribe((res: string) => {
      this.deviceInvalid = res;
    });
    this.trans.get("SHARE-PROFILE").subscribe((res: string) => {
      this.shareTrans = res;
    });
    this.storage.get("b2b").then((data) => {this.b2b = data;});
    this.storage.get('whoami').then(async (whoami) => {
      this.whoami = whoami;
      this.pm = whoami.pm > 0;
      if(whoami && whoami.id) {
        this.subuser = await this.storage.get('subuser');
        this.messagesProvider.loadNewMessages(this.subuser ? this.subuser : this.whoami).subscribe(
          async (_messages) => {
            if (_messages.length >= 0) {
              Utils.newMessages = _messages.length;
            }
          });
      }
    });
  }

  async ionViewDidEnter() {
    this.platform.backButton.subscribeWithPriority(1000, () => {
      this.modalCtrl.getTop().then(overlay => {
        if (overlay)
          overlay.dismiss();
        else
          this.alertController.getTop().then(overlay2 => {
            if (overlay2)
              overlay2.dismiss();
            else
              this.actionSheetCtrl.getTop().then(overlay3 => {
                if (overlay3)
                  overlay3.dismiss();
                else
                  history.back();
              });
          });
      });
    });

    let firebase2 = await this.storage.get("firebase2");
    if (firebase2 == undefined || firebase2 == null || firebase2 == 'undefined' || firebase2 == 'null')
      this.clearStorageAndLoadLoginPage();

    this.storage.get("code").then(code => {
      if(code) {
        this.master = false;
      } else {
        this.master = true;
      }
      if (this.profileProvider.currentFolder &&
          this.profileProvider.currentFolder.id &&
          this.profileProvider.currentFolder.id.length > 0 &&
          this.currentFolder != this.profileProvider.currentFolder.id) {
        this.openFolder(this.profileProvider.currentFolder);
      } else {
        this.profileProvider.loadData();
        if(this.flat)
          setTimeout(()=>{if(this.profileProvider.flatcount <= 0) this.flat = false;}, 1000);
      }

      // if (this.profileProvider.currentFolder && this.close == 1) {
      //   this.openFolder(this.profileProvider.currentFolder);
      // }
      if(!this.platform.is('cordova'))
        this.checkVersionData();

      this.os = this.platform.platforms();
      this.storage.get("settings").then((val) => {
        this.val = val;
        this.setSub();
        this.storage.get('showScreen').then(showScreen => {
          if (!showScreen || showScreen == 1) {
            if (!this.isRunningStandalone() && this.os.includes('mobile') && (this.platform.is('cordova') == false)) {
              this.alertController.create({
                header: 'Webapp',
                subHeader: this.subtitle,
                message: <any>this.htmlProperty(),
                buttons: [
                  {
                    text: this.okTrans,
                    handler: () => {
                      this.storage.set("showScreen", 2);
                    }
                  },
                  {
                    text: this.notShowing,
                    handler: () => {
                      this.storage.set("showScreen", 3);
                    }
                  }
                ]
              }).then(toast=>toast.present());
            }
          }
        });
      });

      if(navigator.onLine) {
        if (this.profileProvider.hasPendingWrites) {
          this.setCloudIcon("cloud-upload");
        } else {
          this.setCloudIcon("cloud-done");
        }
      } else {
        this.setCloudIcon("cloud-outline");
      }
      window.removeEventListener('popstate', this.backHandler, true);
      window.addEventListener('popstate', this.backHandler, true);

      window.removeEventListener('offline', this.onOfflineHandler, true);
      window.addEventListener('offline', this.onOfflineHandler, true);
      window.removeEventListener('online', this.onOfflineHandler, true);
      window.addEventListener('online', this.onOfflineHandler, true);
    });

    this.storage.get("b2b").then((b2b)=>{
      Utils.hasB2BLicense = b2b;
    });
  }

  ionViewWillLeave(){
    this.platform.backButton.observers.pop();
  }

  setCloudIcon(cloud: any):void {
    switch (cloud) {
      case "cloud-outline":
        this.cloudOnline=false;
        this.cloudSync=false;
        this.cloudOffline=true;
        break;
      case "cloud-upload":
        this.cloudOffline=false;
        this.cloudOnline=false;
        this.cloudSync=true;
        break;
      case "cloud-done":
        this.cloudSync=false;
        this.cloudOffline=false;
        this.cloudOnline=true;
    }
  }

  getMessagesCount(){
    return Utils.newMessages;
  }

  ngOnDestroy(): void {
    window.removeEventListener('offline', this.onOfflineHandler, true);
    window.removeEventListener('online', this.onOfflineHandler, true);
  }

  ionViewDidLeave(){
    window.removeEventListener('popstate', this.backHandler, true);
    window.removeEventListener('offline', this.onOfflineHandler, true);
    window.removeEventListener('online', this.onOfflineHandler, true);
  }

  onOffline(){
      if (navigator.onLine) {
        this.setCloudIcon("cloud-upload");
        this.recheckHasPendingWrites();
      } else
      {
        this.setCloudIcon("cloud-outline");
      };
  }

  recheckHasPendingWrites(){
    setTimeout(() => {
      this.setCloudIcon(this.profileProvider.hasPendingWrites ? "cloud-upload" : "cloud-done");
      if(this.profileProvider.hasPendingWrites) {
        this.profileProvider.loadData();
        this.recheckHasPendingWrites();
      }
    },3000);
  }

  clearStorageAndLoadLoginPage(){
    this.auth.logout();
    this.storage.set('user', null);
    this.storage.set("device", null);
    this.storage.set("settings", null);
    this.storage.set("code", null);
    this.storage.set("linewidth", null);
    this.storage.set("storkeStyle", null);
    this.storage.set("stehfalzStyle", null);
    this.storage.set("activeStyle", null);
    this.storage.set("firebase", null);
    this.storage.set("expires", null);
    this.storage.set("whoami", null);
    this.storage.set("profiles", null);
    this.storage.set("master", null);
    localStorage.setItem("token", null);
    this.storage.set("firebase", null);
    this.storage.set("firebase2", null);
    this.storage.set("expires", null);
    this.storage.set("customerId", null);
    this.storage.set("b2b", null);
    this.storage.set("shop", null);
    this.storage.clear();
    this.navCtrl.navigateRoot('dashboard');
  }

  isSubfolder(item): boolean {
    return (this.profileProvider.code !== item.code && !this.inSubFolder && !this.profileProvider.isProjectSource);
  }

  isTreeFolder(item): boolean {
    return (item.parent && item.parent !== null);
  }

  isParentFolder(item): boolean {
    return (this.currentFolder && this.currentFolder !== null && item.parent && item.parent !== null && this.currentFolder === item.parent);
  }

  public reset() {
    if(this.folderTree && this.folderTree.length > 0) {
      let goBackCount = this.folderTree.length + (this.inFolder && !this.ownFolder ? 1 : 0);
      history.go(-goBackCount);
    }
    this.inFolder = false;
    this.inSubFolder = false;
    this.ownFolder = false;
    this.profileProvider.currentFolder = null;
    this.currentFolder = null;
    this.profileProvider.profileFolderVirtual = null;
    this.breadCrumbs = '';
    this.folderTree = [];
    this.list = [];
    this.listObj = [];
    this.folderList = [];
    this.profileProvider.loadData();
  }

  public setBreadCrumbs(item?: any) {
    this.breadCrumbs = '';
    if (!this.inFolder) {
      this.folderTree = [];
    }
    if (item && this.folderTree && this.folderTree.length > 0) {
      const level = this.folderTree.findIndex((node) => node.id === item.id);
      if (level !== -1) {
        this.folderTree.splice(level + 1, (this.folderTree.length - 1) - level);
      }
    }
  }

  isRunningStandalone() {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }

  public htmlProperty() {
    if (this.val && this.val.lang && this.val.lang == 'en') {
      return this.sanitizer.bypassSecurityTrustHtml(this._htmlProperty2);
    } else {
      return this.sanitizer.bypassSecurityTrustHtml(this._htmlProperty);
    }
  }

  public setSub() {
    if (this.val && this.val.lang && this.val.lang == 'en') {
      this.subtitle = "Add this web app to your <strong>Homescreen</strong> and use it like a normal app."
    } else {
      this.subtitle = "Füge diese Web-App zu deinem <strong>Homescreen</strong> hinzu und nutze sie wie eine normale App."
    }
  }

  async checkVersionData() {
    await this.versionProvider.getVersion();
    if (this.versionProvider.reload) {
      this.reloadApp();
    }
  }

  reloadApp(reload = false) {
    let toast = this.toastCtrl.create({
      message: this.newVersionTrans,
      // cancel: true,
      // cancelButtonText: this.closeTrans,
      position: "bottom",
      buttons: [{
        text: this.closeTrans,
        role: 'cancel'
      }]
    }).then((toast)=> {
      toast.onDidDismiss().then(() => {
        this.versionProvider.reload = false;
        this.toastOpen = false;
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      });
      toast.present();
    });
  }

  loadingSpinner() {
    let loading = this.loadingCtrl.create({
      message: this.updateTrans,
      showBackdrop: false
    }).then((loading)=>{
      loading.present();
      setTimeout(() => {
        loading.dismiss();
      }, 5000);
    })

  }

  async create() {
    await this.presentLoadingCustom();
    var d = new Date();
    let data = {
      name: "Profile " + d.toLocaleDateString() + " " + d.toLocaleTimeString(),
      master: this.master
    };
    await this.profileProvider.create(data);
    this.loading.dismiss();
    this.navCtrl.navigateForward('draw');
  }

  import() {
    let pointModal = this.modalCtrl.create({
        component: FolderDetailsPage,
        componentProps: {
          title: this.createProfileTrans,
          folder: true,
          import: true,
          master: this.master
        }//,
        //{ enableBackdropDismiss: false }
      }
    ).then((pointModal)=>{
      pointModal.onDidDismiss().then(data => {
        console.log("IMPORT");
        //this.exportImport.import();
      });
      pointModal.present();
    });

  }

  addFolder() {
    let pointModal = this.modalCtrl.create({
      component: FolderDetailsPage,
      componentProps: {
        name: name,
        title: this.createFolderTrans,
        folder: true,
        master: this.master,
        parent: this.currentFolder
        }//,
        //{ enableBackdropDismiss: false }
      }
    ).then((pointModal) => {
      pointModal.onDidDismiss().then(data => {
        let name = "";
        if (data && data.data && data.data.name) {
          name = data.data.name;
          this.profileProvider.addFolder(name, data.data.parent);
        }
      });
      pointModal.present();
    });
  }

  goBackToFolder(item: any){
    this.list = [];
    this.listObj = [];
    this.folderList = [];
    this.folderListObj = [];
    this.currentFolder = item.id;
    if (item && this.folderTree && this.folderTree.length > 0) {
      let level = this.folderTree.findIndex((node) => node.id === item.id);
      let goBackCount = this.folderTree.length - 1 - level;
      if (goBackCount > 0) {
        history.go(-goBackCount);
      }
    }
  }

  openFolder(item: any, isNavBack: boolean = false) {
    this.inFolder = true;
    this.inSubFolder = false;
    this.list = [];
    this.listObj = [];
    this.folderList = [];
    this.folderListObj = [];
    this.profileProvider.currentFolder = item;
    this.currentFolder = item.id;
    this.profileProvider.openFolderLoad(this.status);
    this.ownFolder = !(item.code && this.master);
    this.folderTree.push(item);
    this.setBreadCrumbs();
    if (!isNavBack && (!this.profileProvider.isProjectSource || this.master))
      history.pushState(item.id, document.title, location.href);
  }

  openFolderB2B(item) {
    this.inFolder = true;
    this.inSubFolder = false;
    this.profileProvider.currentFolder = item;
    this.currentFolder = item.id;
    this.ownFolder = true;
  }

  openSubUserFolder(item, breadcrumb: boolean = true, isNavBack: boolean = false) {
    this.inFolder = false;
    this.ownFolder = false;
    this.inSubFolder = true;
    this.profileProvider.loadData(item.code);
    this.profileProvider.profileFolderVirtual = null;
    if(!breadcrumb)
      this.setBreadCrumbs(); // we navigated back into subuserfolder. clear breadcrumbs and reinit foldertree
    else if (!isNavBack)
      history.pushState(item.code, document.title, location.href);
  }

  onChangeStatus() {
    if (this.inFolder) {
      this.profileProvider.openFolderLoad(this.status);
    }
    this.profileProvider.sortFolder(this.status);
  }

  backFolder(evArg = null) {
    // console.log('backhandler');
    // console.log(this.folderTree);
    if (this.folderTree && this.folderTree.length > 1) {
      // load parent folder
      const folder = this.folderTree.pop();
      const parent = this.folderTree.pop();
      this.openFolder(parent, true);
    } else if (this.inFolder && !this.ownFolder) {
      // load sub users root folder
      this.openSubUserFolder(this.profileProvider.currentFolder, false, true);
    } else {
      // load current users root folder
      this.folderTree = [];
      this.reset();
    }
    this.setBreadCrumbs();
  }

  goBack(){
    // console.log('going back');
    // console.log('history len' + history.length);
    // console.log('history state' + history.state);
    history.back();
  }

  resetFolder() {
    this.title = this.profileAdminTrans;
    this.inFolder = false;
    this.inSubFolder = false;
    this.profileProvider.folderActive = -1;
    this.list = [];
    this.listObj = [];
    this.folder = [];
    this.folderListObj = [];
  }

  openDetailBox(item) {}

  errorAlert(message: string) {
    this.alertController.create({
      header: this.trans.instant("ATTENTION"),
      subHeader: this.trans.instant("UNEXPECTEDERROR"),
      message: message,
      buttons: ['Cancel']
    }).then(alert=>alert.present());
  }

  openProfile(item, screen = false, showInfo = false, share = false) {
    //Working on XML
    // TODO: verify!
    try {
      if (item.csv2) {
        let importedItem = this.exportImport.importAndConvert(item);
        if (importedItem)
          item = importedItem;
        else {
          this.alertController.create({
            header: this.trans.instant("ATTENTION"),
            subHeader: this.trans.instant("PROFILEERROR"),
            message: this.trans.instant("PROFILEIMPORTERROR"),
            buttons: ['OK']
          }).then(alert => alert.present());
          ;
        }
      }

      item.csv2 = null;

      if(item.lockTogether === "false")
        item.lockTogether = 0;

      this.profileProvider.index = 0;
      if ( (item.status == 0 || item.status == 3)
        || (item.status == 1 && this.master)
        || (screen) ) {
        this.profileProvider.active = item;
        if(this.profileProvider.active.changes == undefined)
          this.profileProvider.active.changes = 0;
        // this.profileProvider.active.makeScreenshot = screen;
        let navExtra: NavigationExtras = {state: {showInfo: showInfo, share: share, export: screen}}
        this.navCtrl.navigateForward('draw', navExtra);
      } else {
        this.toastCtrl.create({
          message: this.openMsg1,
          duration: 3000
        }).then((toast) => toast.present());
      }
    } catch (e) {
      this.errorAlert(e.toString());
    }
  }

  presentActionSheet(item) {
    //this.openProfile(item);
    let buttons = [];
    if ((item.status == 0 || (item.status == 1 && !this.master)) && this.ownFolder) {
      buttons.push({
        text: this.changeTrans, // bearbeiten
        handler: () => {
          this.openProfile(item);
        }
      });
      if(item.status == 0) {
        buttons.push({
          text: this.checkFreeTrans, // zur Produktion freigeben
          handler: () => {
            let openProfile = this.exportImport.export(item);
            if(openProfile == 1)
            {
              this.openProfile(item, false, true);
            }
          }
        });
      }
      // buttons.push({ text: 'Information bearbeiten', handler: () => { this.openDetailBox(item) } })
    }

    if (item.status == 3 && this.ownFolder) {
      buttons.push({
        text: this.changeTrans, // Status korrigiert: bearbeiten
        handler: () => {
          this.openProfile(item);
        }
      });
    }
    else if (item.status == 3 && !this.ownFolder) {
      buttons.push({
        text: this.correctionTrans, // Status korrigiert: korrigieren
        handler: () => {
          this.openProfile(item);
        }
      });
    }
    else if (item.status == 1 && this.master) {
      buttons.push({
        text: this.checkTrans, // Status under review: überprüfen
        handler: () => {
          this.openProfile(item);
        }
      });
    }
    if(!this.platform.is('cordova')) // pdf export only for pwa
      buttons.push({
        text: this.exportaspicTrans,
        handler: () => {
          this.openProfile(item, true);
        }
      });
    if(this.platform.is('cordova'))
      buttons.push({
        text: this.shareAsImg, // Als Bild teilen
        handler: () => {
          this.openProfile(item, false, false, true);
        }
      });
    buttons.push({
      text: this.shareTrans, // Profil teilen
      handler: () => {
        this.shareProfile(item);
      }
    });
    if (this.ownFolder && !this.flat) {
      buttons.push({
        text: this.duplicateTrans, // duplizieren
        handler: () => {
          this.profileProvider.duplicate(item);
        }
      });
      if ((Utils.hasB2BLicense || this.pm) && this.master && this.profileProvider.currentFolder.templates && item.status2 != 'derived' && item.status2 != 'template') {
        buttons.push({
          text: this.makeTemplateTrans, // zu vorlage machen
          handler: () => {
            this.exportImport.makeTemplate(item);
          }
        });
      }
    }

    if(this.pm){
      buttons.push({
        text: this.trans.instant("ADDTOORDER"),
        handler: () => {
          this.presentChooseProject(item);
        }
      });
    }

    if (item.status == 0 || (item.status == 5 && this.ownFolder)) {
      buttons.push({
        text: this.deleteTrans,
        handler: () => {
          this.profileProvider.delete(item);
        }
      });
    }
    buttons.push({ text: this.abortTrans, role: "cancel", cssClass: 'variobend-action-sheet-cancel' });

    this.actionSheetCtrl.create({
      header: this.profileTrans,
      cssClass: 'variobend-action-sheet',
      buttons: buttons,
      mode: 'ios'
    }).then(actionSheet=>actionSheet.present());
  }

  async presentChooseProject(item: any){
    this.itemToAddToOrder = item;
    this.lastUsedProjects = await this.storage.get("lastUsedProjects");
    this.lastUsedProjectOrders = [];
    this.lastUsedProjects.forEach(x=>{
      if(x.orderId > 0)
        this.lastUsedProjectOrders.push(x);
    });
    let fsProjects = await this.profileProvider.loadOrderProjects(this.lastUsedProjects.map(p=>p.id));
    fsProjects.subscribe((fsProject)=>this.availableOrderProjects.next(fsProject.concat(this.lastUsedProjectOrders)));

    this.isLastProjectsPopoverOpen = true;
  }

  addItemToProject(project: any){
    this.isLastProjectsPopoverOpen = false;
    this.profileProvider.saveWithPathInProjectsKeepOriginal(project, this.itemToAddToOrder).then(()=>{
      this.folderTree = [];
      this.openFolder(project);
    });

  }

  dismissOverlay(){
    this.isLastProjectsPopoverOpen = false;
  }

  presentActionCreate() {
    this.create();
    /*let actionSheet = this.actionSheetCtrl.create({
      title: 'Profil',
      buttons: [
        {
          text: 'Neu anlegen',
          role: 'edit',
          handler: () => {
            this.create();
          }
        }, {
          text: 'Importieren',
          handler: () => {
            this.import();
          }
        }
      ]
    });
    actionSheet.present(); */
  }

  async presentLoadingCustom() {
    this.loading = await this.loadingCtrl.create({
      message: this.pleaseWaitTrans
    });

    this.loading.present();
  }

  select(item, event?: any) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let place = this.list.indexOf(item.id);
    if (place != -1) {
      this.list.splice(place, 1);
      this.listObj.splice(place, 1);
    } else {
      this.folderList = [];
      // this.folderListObj = [];
      this.list.push(item.id);
      this.listObj.push(item);
    }
  }

  selectFolder(item, event?: any) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    let place = this.folderList.indexOf(item.id);
    if (place != -1) {
      this.folderList.splice(place, 1);
      this.folderListObj.splice(place, 1);
    } else {
      this.list = [];
      this.listObj = [];
      this.folderList.push(item.id);
      this.folderListObj.push(item);
    }
  }

  async deleteFolder() {
    if (this.folderListObj.length > 0) {
      let loading = await this.loadingCtrl.create({
        message: this.deleteTrans,
        showBackdrop: false
      });
      loading.present();
      let count = this.folderListObj.length - 1;
      for (let i = 0; i < this.folderListObj.length; i++) {
        await this.profileProvider.deleteWithPath(this.folderListObj[i]);
        setTimeout(() => {
          if (i == count) {
            loading.dismiss();
          }
        }, 1000);
      }
      this.folderListObj = [];
      this.folderList = [];
    }
  }

  async moveFolder(ev?: any) {
    if (this.listObj.length > 0 && ev) {
      //const moveToFolder = this.profileProvider.folder.find((node) => node.id === ev.target.value);
      let loading = await this.loadingCtrl.create({
        message: this.moveTrans,
        showBackdrop: false
      });
      loading.present();
      let count = this.listObj.length - 1;
      for (let i = 0; i < this.listObj.length; i++) {
        await this.profileProvider.saveWithPath(ev.target.value, this.listObj[i]);
        setTimeout(() => {
          if (i == count) {
            loading.dismiss();
          }
        }, 1000);
      }
      /*if (moveToFolder !== null) {
        this.openFolder(moveToFolder);
      }*/
    }
  }

  flatModus() {
    if (this.flat) {
      this.flat = false;
    } else {
      this.flat = true;
    }
  }

  async shareProfile(item) {
    let modal = await this.modalCtrl.create({
        component: AddressPage,
        componentProps: {
          profile: item,
          whoami: this.whoami
        }
      }
    )
    await modal.present();
    const {data} = await modal.onWillDismiss();
    if (data && data !== null) {
      const message = {
        id: null,
        title: this.trans.instant("SHARE-PROFILE-TITLE"),
        message: this.trans.instant("SHARE-PROFILE-MESSAGE", {name: this.whoami.name, profileName: item.name}),
        status: 'new',
        type: 'shareProfile',
        sort: new Date().getTime(),
        uid: data.uid,
        toEmail: data.email,
        fromEmail: this.subuser ? this.subuser.email : this.whoami.email,
        fromName: this.subuser ? this.subuser.name : this.whoami.name,
        data: item
      }
      await this.messagesProvider.addMessage(message);
      if (data.uid !== null) {
        const toast = this.toastCtrl.create({
          message: this.trans.instant("PROFILE-SHARED"),
          duration: 3000,
          position: 'bottom',
        }).then(toast => toast.present());
      } else if (data.uid === null && data.email !== null) {
        const mail: { id: any, uid: string, from: string, to: string; template: any  } =
          {
            id: null,
            uid: this.whoami.id,
            from: environment.fromEmail,
            to: data.email,
            template: {
              name: 'SHARE-PROFILE-INVITE-USER',
              data: {
                from: this.subuser ? this.subuser.name : this.whoami.name,
                link: environment.appLink + '/signin'
              }
            },
          };
        await this.mailProvider.addMail(mail);
        const toast = this.toastCtrl.create({
          message: this.trans.instant("PROFILE-SHARED-UNKNOWN-USER"),
          duration: 3000,
          position: 'bottom',
        }).then(toast => toast.present());
      }
    }
    /*const alert = await this.alertController.create({
      header: this.trans.instant("SHARE-PROFILE-HEADER"),
      subHeader: this.trans.instant("SHARE-PROFILE-SUBHEADER"),
      inputs: [
        {
          name: 'value',
          placeholder: this.trans.instant("SHARE-PROFILE-PLACEHOLDER"),
          type: 'email'
        }
      ],
      buttons: [
        {
          text: this.trans.instant('ABORT'),
          role: 'cancel'
        },
        {
          text: this.trans.instant('SHARE'),
          cssClass: 'primary',
          handler: async (data) => {
            if (data && data !== null && data.value !== null) {
              let wait = await this.auth.emailUserId(data.value);
              if (wait && wait.error) {
                const toast = this.toastCtrl.create({
                  message: wait.error.error,
                  duration: 3000,
                  position: 'middle',
                }).then(toast => toast.present());
              } else {
                const message = {
                  id: null,
                  title: this.trans.instant("SHARE-PROFILE-TITLE"),
                  message: this.trans.instant("SHARE-PROFILE-MESSAGE", {name: this.whoami.name}),
                  status: 'new',
                  type: 'shareProfile',
                  sort: new Date().getTime(),
                  uid: wait.ret.id,
                  toEmail: data.value,
                  fromEmail: this.whoami.email,
                  fromName: this.whoami.name,
                  data: item
                }
                if (this.addresses.findIndex((address) => address.email === message.toEmail) === -1) {
                  const address = {
                    id: null,
                    uid: message.uid,
                    email: message.toEmail,
                    name: wait.ret.name
                  }
                  this.addressProvider.addAddress(this.whoami, address);
                }
                await this.messagesProvider.addMessage(message);
                const toast = this.toastCtrl.create({
                  message: this.trans.instant("PROFILE-SHARED"),
                  duration: 3000,
                  position: 'middle',
                }).then(toast => toast.present());
              }
            }
          }
        }
      ],
      cssClass: 'variobendDialog'
    });
    alert.present();*/
  }

}
