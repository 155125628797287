import {Component, Injector} from '@angular/core';
import {NavController, ToastController, ModalController, Platform, AlertController} from '@ionic/angular';
import {Storage } from '@ionic/storage';
import {TranslateService } from '@ngx-translate/core';
import {VersionProvider } from "../../shared/providers/version/version";
import {AuthProvider } from "../../shared/providers/auth/auth";
import {QRScanner, QRScannerStatus} from '@awesome-cordova-plugins/barcode-scanner/ngx';
import {QrCodeProvider} from '../../shared/providers/qrcode/qrcode';
import {environment} from '../../environments/environment';
import {NavigationExtras} from '@angular/router';

@Component({
  selector: "page-settings",
  templateUrl: "settings.html",
  styleUrls: ['./settings.scss']
})
export class SettingsPage {

  public linewidth: any = 5;
  public strokeStyle: any = "#CD853F";
  public stehfalzStyle: any = "#880000";
  public activeStyle: any = "#880000";
  public settings: any = 1;
  public email="";
  public isMaster: boolean = false;
  public hasSubUser: boolean = false;
  public development: boolean = false;
  public native: boolean = false;
  public isScanning: boolean = false;
  public whoami: any = null;
  public licenseInfo: {
    username: string,
    licenseDate: any,
    contributing: string,
    devices: number,
    contributorLicences: number
    contributorDevices: number
    contributors: Array<{ name: string, email: string, lastActivity: any }>
  } = null;
  private toast: any;
  private qrScanner: QRScanner = null;
  private backHandler: any = null;
  private scanSub: any = null;

  constructor(
    private injector: Injector,
    public navCtrl: NavController,
    public toastCtrl: ToastController,
    public version: VersionProvider,
    public translate: TranslateService,
    public alertController: AlertController,
    public authProvider: AuthProvider,
    private storage: Storage,
    public platform: Platform,
    private auth: AuthProvider,
    private qrCodeProvider: QrCodeProvider)
  {
    this.native = this.platform.is('cordova');
    this.development = !environment.production;
    this.storage.get("strokeStyle").then(strokeStyle => {
      if (strokeStyle) {
        this.strokeStyle = strokeStyle;
      }
    });

    this.storage.get("settings").then(val => {
      if (val) {
        this.settings = val;
      } else {
        this.settings = { lang: null, size: 1, color: 1 };
      }

      this.settings.lang = translate.currentLang;
    });

    this.storage.get("stehfalzStyle").then(stehfalzStyle => {
      if (stehfalzStyle) {
        this.stehfalzStyle = stehfalzStyle;
      }
    });

    this.storage.get("linewidth").then(linewidth => {
      if (linewidth) {
        this.linewidth = linewidth;
      }
    });

    this.storage.get("activeStyle").then(activeStyle => {
      if (activeStyle) {
        this.activeStyle = activeStyle;
      }
    });

    this.storage.get("deviceMaster").then(master => {
      if (master && master > 0) {
        this.isMaster = true;
        this.storage.get("user").then(user => {
          if(user){
            this.email = user.email;
          }
        });
      } else {
        this.storage.get("code").then(code => {
          if (code) {
            this.storage.get('device').then(async (device) => {
              if (device) {
                let wait2 = await this.auth.getCodeUser(code, device); // check license
                if(wait2 && wait2.ret.ok){
                  this.email = wait2.ret.user.email;
                }
              }
            });
          }
        });
      }
    });

    try {
      if(this.platform.is('cordova'))
        this.qrScanner = injector.get<QRScanner>(QRScanner);
    } catch (e) {
      this.debug(e);
    }
  }

  async ionViewWillEnter() {
    this.whoami = await this.storage.get('whoami');
    if (this.whoami && this.whoami.id) {
      this.licenseInfo = await this.storage.get('license');
      if (this.licenseInfo === null) {
        let uid = null;
        let username = null;
        const code = await this.storage.get('code');
        if (code) {
          const device = this.storage.get('device');
          const subUser = await this.authProvider.getCodeUser(code, device);
          if (subUser && subUser.ret.ok) {
            uid = subUser.ret.user.id;
            username = subUser.ret.user.email;
          }
        } else {
          uid = this.whoami.id;
          username = this.whoami.email;
        }
        this.licenseInfo = await this.authProvider.licenseInfo(uid, username);
      }
      if (this.licenseInfo && this.licenseInfo.contributors && this.licenseInfo.contributors.length > 0) {
        this.hasSubUser = true;
      }
    } else {
      this.licenseInfo = null;
    }
  }

  ionViewDidLeave(){
    window.removeEventListener('popstate', this.backHandler, true);
    document.removeEventListener("backbutton", this.backHandler, false);

    if(this.qrScanner)
      this.qrScanner.destroy();
  }

  save() {
    this.toastCtrl.create({
      message: this.translate.instant("DATASTORED"),
      duration: 3000,
      position: "bottom"
    }).then(t=>t.present());
    this.storage.set("linewidth", this.linewidth);
    this.storage.set("strokeStyle", this.strokeStyle);
    this.storage.set("stehfalzStyle", this.stehfalzStyle);
    this.storage.set("activeStyle", this.activeStyle);
  }

  lang(id) {
    this.settings.lang = id;
    this.translate.use(id);
    this.storage.set("settings", this.settings);
  }

  async deleteAccount() {
    if (this.whoami && this.whoami.id) {
      const alert = await this.alertController.create({
        header: this.translate.instant("DELETE-ACCOUNT"),
        subHeader: this.translate.instant("DELETE-ACCOUNT-SUBHEADER"),
        buttons: [
          {
            text: this.translate.instant('DELETE-ACCOUNT-NO'),
          },
          {
            text: this.translate.instant('DELETE-ACCOUNT-YES'),
            cssClass: 'danger',
            handler: async () => {
              console.log('delete account', this.whoami);
              const response = await this.authProvider.deleteAccount(this.whoami);
              if (response && response.error) {
                const alert = await this.alertController.create({
                  header: this.translate.instant("ATTENTION"),
                  message: this.translate.instant("DELETE-ACCOUNT-ERROR"),
                  buttons: ['OK']
                });
                alert.present();
              } else {
                const alert = await this.alertController.create({
                  header: this.translate.instant("ATTENTION"),
                  message: this.translate.instant("DELETE-ACCOUNT-SUCCESS"),
                  buttons: ['OK']
                });
                alert.present();
                this.navCtrl.navigateRoot('logout');
              }
            }
          }
        ],
        cssClass: 'variobendDialog'
      });
      alert.present();
    } else {
      this.navCtrl.navigateRoot('login');
    }
  }

  scanQR() {
    if(!navigator.onLine)
      return;
    if(this.qrScanner) {
      this.qrScanner.getStatus().then((status)=>{
        console.log("QRScanner status:");
        console.log(status);
      });

      this.qrScanner.prepare()
        .then((status: QRScannerStatus) => {
          console.log("QRScanner prepared:");
          console.log(status);
          if (status.denied || status.restricted) {
            // camera permission was permanently denied
            // you must use QRScanner.openSettings() method to guide the user to the settings page
            // then they can grant the permission from there
            console.log('Permission denied. Open settings?');
            this.qrCodeProvider.acquirePermission(this.qrScanner);
          } else /*if (status.authorized)*/ {
            // camera permission was granted
            this.isScanning = true;
            if(!this.backHandler) {
              this.backHandler = this.closeCamera.bind(this);
            }
            window.removeEventListener('popstate', this.backHandler, true);
            window.addEventListener('popstate', this.backHandler, true);
            document.removeEventListener("backbutton", this.backHandler, false);
            document.addEventListener("backbutton", this.backHandler, false);

            // start scanning
            this.scanSub = this.qrScanner.scan().subscribe((text: string) => {
              console.log('Scanned something', text);
              this.closeCamera();
              if (text.indexOf('register') !== -1) {
                // register?code=xxx&master=xxx&company=xxx;
                const idx1 = text.indexOf('code=');
                const idx2 = text.indexOf('master=');
                const idx3 = text.indexOf('company=');
                if (idx2 !== -1) {
                  const code = text.substring(idx1 + 5, idx2 - 1);
                  const master = +text.substring(idx2 + 7, idx3 - 1);
                  const company = text.substring(idx3 + 8);
                  // this.joinTeamDialog(code, master, company);
                  this.joinTeam(code, master, company);
                } else {
                  console.log('invalid qr code');
                  this.qrCodeProvider.showInvalidQR();
                }
              } else {
                console.log('invalid qr code');
                this.qrCodeProvider.showInvalidQR();
              }
            });

            this.hideForCamera(true);
            this.qrScanner.resumePreview();
            this.qrScanner.show();

          }
          // else  {
          //   // permission was denied, but not permanently. You can ask for permission again at a later time.
          //   console.log('Permission denied. Will ask again when required.');
          //   console.log(status);
          // }
        })
        .catch((e: any) => console.log('Error is', e));
    } else {
      if (this.development) {
        // for web testing !!!
        this.joinTeam('oHp5y-eU7Y8', 52, 'Musterfirma Development GmbH');
      } else {
        this.qrCodeProvider.showStores();
      }
    }
  }

  closeCamera() {
    this.isScanning = false;
    this.hideForCamera(false);
    if (this.scanSub)
      this.scanSub.unsubscribe(); // stop scanning
    if (this.qrScanner) {
      this.qrScanner.pausePreview(); // hide camera preview
      this.qrScanner.hide();
    }

    window.removeEventListener('popstate', this.backHandler, true);
    document.removeEventListener("backbutton", this.backHandler, false);
  }

  hideForCamera(hide: boolean) {
    const hideContent = document.getElementsByClassName("hideForCamera");
    for (let n = 0; n < hideContent.length; n++) {
      const el = <HTMLElement>hideContent[n];
      el.style.opacity = hide ? "0" : "1";
    }
  }

  /* deprecated because a new token is needed
  async joinTeamDialog(code: string, master: number, company: string = '?') {
    console.log('joinTeam', code, master, company);
    const confirm = await this.alertController.create({
      header: this.translate.instant("JOIN-TEAM"),
      message: this.translate.instant("JOIN-TEAM-MESSAGE", {company: company}),
      buttons: [
        {
          text: this.translate.instant("OK"),
          handler: async () => {
            console.log('change team');
            await this.joinTeam(code, master, company);
          }
        },
        {
          text: this.translate.instant("CANCEL"),
          handler: () => {
            console.log('Operation cancelled.')
          }
        }
      ]
    });
    await confirm.present();
  }*/

  async joinTeam(code: string, master: number, company: string) {
    // change to subuser
    console.log('registerQRcode', code, master, company);
    let navExtas: NavigationExtras = {state: {code: code, master: master, company: company}}
    this.navCtrl.navigateRoot('register', navExtas);
    /* deprecated because a new token is needed
    let wait = await this.auth.masterToSubUser({user: this.whoami.id, code: code, master: master});
    if (wait && wait.error) {
        this.toast = this.toastCtrl.create({
          message: wait.error.error, duration: 5000, position: 'bottom',
        }).then(toast => toast.present());
    } else {
        this.storage.set("master", false);
        this.storage.set('device', wait.ret.device.id);
        this.storage.remove('deviceMaster');
        this.whoami.user_id = master;
        this.storage.set("subuser", this.whoami);
        this.storage.set("code", code);
        let my = await this.auth.whoami();
        this.storage.set("user", my);
        this.licenseInfo = await this.authProvider.licenseInfo(this.whoami.id, this.whoami.email);
        this.isMaster = false;
        this.toast = this.toastCtrl.create({
          message: this.translate.instant("JOIN-TEAM-SUCCESS", {company: company}),
          duration: 3000,
          position: 'middle',
        }).then(toast => toast.present());
    }*/
  }

  async debug(text: string){
    const confirm = await this.alertController.create({
      header: 'DEBUG',
      message: text,
      buttons: [
        {
          text: this.translate.instant("OK"),
          handler: () => {
          }
        }
      ]
    });
    await confirm.present();
  }

}
