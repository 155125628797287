<ion-header>
  <ion-toolbar color="dark" >
    <ion-buttons>
      <ion-button fill="solid" color="primary" (click)="dismiss()">
        <ion-icon name="close-outline" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-title>Changelog</ion-title>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding ion-margin ion-align-items-center">
  <ion-list-header lines="full">Version <b>1.1.1 - 14.10.2020</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>Offline mode
      <ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
    <ion-item>Drawing interface improvements
      <ion-badge slot="end" color="primary">Optimierung</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.1.0 - 02.06.2020</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>ASANA[SHOP] - LoginB2B / Endpoint createNew
      <ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
  </ion-list>
    <ion-list-header lines="full">Version <b>1.0.82 - 13.05.2020</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>ASANA[User Management] - Password reset and confirmation
      <ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.81 - 09.03.2020</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>ASANA[Probleme mit Sprache] - Downgrade ngx_translate
      <ion-badge slot="end" color="danger">Bug</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.80 - 03.03.2020</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>ASANA[Backend Security] Firebase authentication
      <ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.71 - 26.02.2020</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>ASANA[Löschen von Ordnern und Profilen]
      <ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
    <ion-item>ASANA[Profilstatus Workflow Bug]
      <ion-badge slot="end" color="danger">Bug</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.70 - 25.02.2020</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>Design Issus
      <ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.60 - 25.02.2020</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>ASANA[Lackierung und Farbe]
      <ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
    <ion-item>ASANA[Konische Profile]
      <ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
    <ion-item>ASANA[Freigabe Workflow]
      <ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
    <ion-item>ASANA[API Call für Profilupdate] XML(csv2) import
      <ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
    <ion-item>Zahlreiche Fixes - Blauer Punkt, Scrolling, Umschlag, Zeichnen, API
      <ion-badge slot="end" color="danger">Bug</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.50 - 24.01.2020, Life Release</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>ASANA[Responsive Design optimieren] 2 Spalten Layout hinzugefügt
      <ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
    <ion-item>ASANA[Zeichenprofil verändert sich] Zahlreiche Korrektoren für Endpunktwechsel
      <ion-badge slot="end" color="danger">Bug</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.44 - 15.01.2020</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>ASANA[Zeichenprofil verändert sich] Berechnung Stehfalz
      <ion-badge slot="end" color="danger">Bug</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.43 - 14.01.2020</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>ASANA[Profildatenbank Ansicht optimieren] Virtuelle Folder Back-Navigation
      <ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.42 - 09.01.2020</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>ASANA[Zeichenprofil verändert sich] Fix Endpunktwechsel
      <ion-badge slot="end" color="danger">Bug</ion-badge>
    </ion-item>
    <ion-item>ASANA[Profildatenbank Ansicht optimieren] Virtuelle Folder für Sublizenz bei Hauptuser
      <ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.41 - 16.12.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>ASANA[Init Screen Layout] Responsive Layout Weiter-Button korrigiert
      <ion-badge slot="end" color="danger">Bug</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.40 - 10.12.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>Projektübergabe
      <ion-badge slot="end" color="primary">Administration</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.31 - 04.11.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>Endpunkt wechseln<ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
    <ion-item>Im Freigabefenster sind alle Profile doppelt, nachdem man ein anderes Profil editiert hat
      <ion-badge slot="end" color="danger">Bug</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.30 - 16.09.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>Hauptlizenz kann sich an beliebig vielen Geräten anmelden<ion-badge slot="end" color="primary">Feature</ion-badge></ion-item>
    <ion-item>Bei Profil bearbeiten: Die Buttons Info Schließen gehen ineinander über, es fehlt ein Strich: Info | Schließen
      <ion-badge slot="end" color="primary">Optimierung</ion-badge>
    </ion-item>
    <ion-item>Maßeinheiten in der Zeichenmaske fehlen (mm usw)<ion-badge slot="end" color="primary">Optimierung</ion-badge></ion-item>
    <ion-item>Übersetzung der Popups fehlt (teilweise?).<ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
    <ion-item>Verarbeitung wenn zwei Längen angegeben werden: Im PDF Export werden keine Trennzeichen angezeigt! Blechlängen
      sind nicht ausgewiesen und Blechdicke auch nicht. <ion-badge slot="end" color="primary">Optimierung</ion-badge>
    </ion-item>
    <ion-item>Im Freigabefenster sind alle Profile doppelt, nachdem man ein anderes Profil editiert hat
      <ion-badge slot="end" color="danger">Bug</ion-badge>
    </ion-item>
    <ion-item>
      Unter Freigabe steht nur der Name des Profils aber nicht von wem er hochgeladen wurde, wenn mehrere Benutzer Test
      Profile anlegen weiß man nicht mehr von wen diese stammen <ion-badge slot="end" color="primary">Optimierung</ion-badge>
    </ion-item>
    <ion-item>Passwort zurücksetzen resultiert in server error <ion-badge slot="end" color="danger">Bug</ion-badge>
    </ion-item>
    <ion-item>Pflichtfelder werden nicht rot beim verlassen sondern die Meldung kommt – bitte alle Pflichtfelder ausfüllen –
      Benutzer sieht aber nicht welche die Pflichtfelder sind <ion-badge slot="end" color="primary">Optimierung</ion-badge>
      </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.25 - 16.07.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>API fix<ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
    <ion-item>Pfeil/Farbseite werden wieder angezeigt<ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.24 - 15.07.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>Webapp Prompt<ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
    <ion-item>Sicherheitsprüfung Felder<ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
    <ion-item>Mehrere Profile<ion-badge slot="end" color="primary">Feature</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.23 - 02.07.2019</b></ion-list-header>
   <ion-list lines="none">
     <ion-item>Company Feld hinzugefügt<ion-badge slot="end" color="primary">Feature</ion-badge></ion-item>
     <ion-item>Registrierung mit Code abgeschlossen<ion-badge slot="end" color="primary">Feature</ion-badge>
     </ion-item>
     <ion-item>Slider eingebaut<ion-badge slot="end" color="primary">Feature</ion-badge>
     </ion-item>
   </ion-list>
  <ion-list-header lines="full">Version <b>1.0.22 - 01.07.2019</b></ion-list-header>
   <ion-list lines="none">
     <ion-item>Pfeil/Seite <ion-badge slot="end" color="primary">Feature</ion-badge></ion-item>
     <ion-item>Redraw Bug<ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
     <ion-item>Blechlänge<ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
     <ion-item>PDF exportieren<ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
     <ion-item>Sublizenz Login <ion-badge slot="end" color="primary">Feature</ion-badge></ion-item>
     <ion-item>AGB <ion-badge slot="end" color="primary">Feature</ion-badge></ion-item>
     <ion-item>Hilfe <ion-badge slot="end" color="primary">Feature</ion-badge></ion-item>
   </ion-list>
  <ion-list-header lines="full">Version <b>1.0.19 - 17.06.2019</b></ion-list-header>
   <ion-list lines="none">
     <ion-item>Endpunkt wechseln deaktiviert<ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
     <ion-item>Code Login -> Email registrieren<ion-badge slot="end" color="primary">Testing</ion-badge></ion-item>
   </ion-list>
  <ion-list-header lines="full">Version <b>1.0.18 - 13.06.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>TestVersion deaktiviert<ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
    <ion-item>PDF erstellen -> kein Bild mehr<ion-badge slot="end" color="primary">Optimierung</ion-badge></ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.17 - 08.06.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>Test Version für Winkel/Redraw Problem<ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.16 - 07.06.2019</b></ion-list-header>
    <ion-list lines="none">
      <ion-item>Duplikate speichern nicht die richtige ID / Probleme API<ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
    </ion-list>
  <ion-list-header lines="full">Version <b>1.0.15 - 14.05.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>Winkel 180 Grad <ion-badge slot="end" color="primary">Optimierung</ion-badge></ion-item>
    <ion-item>Profil erstellen am Ipad  <ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.14 - 14.05.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>Freigabe Center - Ordner anzeigen <ion-badge slot="end" color="primary">Optimierung</ion-badge></ion-item>
    <ion-item>Winkel  <ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.11-1.0.13 - 13.05.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>Design Updates <ion-badge slot="end" color="primary">Optimierung</ion-badge></ion-item>
    <ion-item>Ordner löschen <ion-badge slot="end" color="green">Feature</ion-badge></ion-item>
    <ion-item>Sprachen Update <ion-badge slot="end" color="primary">Optimierung</ion-badge></ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.10 - 06.05.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>Sprache - Update <ion-badge slot="end" color="primary">Optimierung</ion-badge></ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.9 - 05.05.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>Zoom verändert nicht mehr den Winkel <ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
    <ion-item>Logout - Löschen der Daten <ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.8 - 30.04.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>Winkel - Anzeige <ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
    <ion-item>Winkel ausblenden <ion-badge slot="end" color="primary">Optimierung</ion-badge></ion-item>
    <ion-item>Einstellungen aufgeräumt <ion-badge slot="end" color="primary">Optimierung</ion-badge></ion-item>
    <ion-item>Sprachfiles <ion-badge slot="end" color="primary">Optimierung</ion-badge></ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.7 - 29.04.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>Status Update via Profilverwaltung <ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
    <ion-item>Mehrere Profile in der Flatübersicht <ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.6 - 26.04.2019</b></ion-list-header>
  <ion-list lines="none">
    <ion-item>Sprachfiles<ion-badge slot="end" color="primary">Optimierung</ion-badge></ion-item>
    <ion-item>untergeordneter Benutzer => Ordner erstellen  <ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
    <ion-item>Export als Bild <ion-badge slot="end" color="primary">Optimierung</ion-badge>
    </ion-item>
  </ion-list>
  <ion-list-header lines="full">Version <b>1.0.5 - 23.04.2019</b></ion-list-header>
 <ion-list lines="none">
   <ion-item>Winkelradius<ion-badge slot="end" color="primary">Optimierung</ion-badge></ion-item>
   <ion-item>Winkel - Berechnung<ion-badge slot="end" color="primary">Optimierung</ion-badge></ion-item>
   <ion-item>Changelog eingebaut<ion-badge slot="end" color="green">Feature</ion-badge></ion-item>
   <ion-item>Export to PNG<ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
   <ion-item>Wenn nichts gezeichnet wurde -> Empty Error gefixt<ion-badge slot="end" color="danger">Bug</ion-badge></ion-item>
 </ion-list>
</ion-content>
