import { Component, Injector} from '@angular/core';
import { MenuController, NavController, Platform, ToastController } from '@ionic/angular';
import { AuthProvider } from '../../shared/providers/auth/auth';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import { InAppBrowser, InAppBrowserOptions } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'page-login',
  templateUrl: './login.html',
  styleUrls: ['./login.scss']
})
export class LoginPage {

  loginForm: UntypedFormGroup;
  codeForm: UntypedFormGroup;
  userAgent: any = "";
  showUser = true;
  showCode = true;
  toast: any;

  constructor(
    protected injector: Injector,
    private forms: FormsModule,
    private reactiveForms: ReactiveFormsModule,
    private route: ActivatedRoute,
    private router: Router,
    private navCtrl: NavController,
    private menu: MenuController,
    private formBuilder: UntypedFormBuilder,
    private auth: AuthProvider,
    private toastCtrl: ToastController,
    private storage: Storage,
    private translate: TranslateService,
    private platform: Platform,
    private iab: InAppBrowser
  ) {
    this.userAgent = navigator.userAgent;

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
    });
    this.codeForm = this.formBuilder.group({
      code: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.injector.get(ActivatedRoute).queryParams.subscribe((param) => {
      if (param) {
        if (param.code && param.code !== null) {
          this.codeForm.setValue({code: param.code});
        } else {
          this.showCode = false;
        }
        if (param.email && param.email !== null) {
          this.loginForm.setValue({email: param.email, password: ''});
        }
      }
    });
  }

  ionViewDidEnter() {
    this.menu.enable(false);
  }

  ionViewDidLeave() {
    this.menu.enable(true);
  }

  async codeSubmit() {
    if(this.codeForm.valid) {
      let wait = await this.auth.getCode(this.codeForm.value, this.userAgent);
      if (wait.error) {
        this.toast = this.toastCtrl.create({
          message: wait.error.error,
          duration: 3000,
          position: 'middle',
        }).then(toast=>toast.present());
      } else {
        let navExtas: NavigationExtras = {state: {form: this.codeForm.value, email: this.loginForm.value.email, user: this.userAgent}}
        this.navCtrl.navigateRoot('register', navExtas);
      }
    }
  }

  async loginSubmit() {
    if(!navigator.onLine){
      this.toastCtrl.create({
        message: 'Offline',
        duration: 3000,
        position: 'middle',
      }).then(toast=>{this.toast = toast; toast.present();});
      return;
    }
    if(this.loginForm.valid) {
      let wait = await this.auth.getToken(this.loginForm.value, this.userAgent);
      if (wait.error) {
        this.toastCtrl.create({
          message: wait.error.error,
          duration: 3000,
          position: 'middle',
        }).then(toast=>{this.toast = toast; toast.present();});
      } else {
        let my = await this.auth.whoami();
        this.storage.set("user", my);
        this.navCtrl.navigateRoot('dashboard');
      }
    }
  }

  resetPassword(){
    if (this.platform.is('cordova')) {
      const options: InAppBrowserOptions = {
        toolbar: 'yes',
        location: 'no',
        zoom: 'no',
        enableViewportScale: 'yes',
        usewkwebview: 'yes',
        closebuttoncolor: '#F9F9F9',
        closebuttoncaption: this.translate.instant('CLOSE'),
      }
      const browser = this.iab.create('https://backend.variobend.de/dashboard/password/reset', '_blank', options);
      browser.show();
    } else {
      location.href = "http://backend.variobend.de/dashboard/password/reset";
    }
  }

}
