import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Chart} from 'chart.js';
import {CHART_COLORS, IStatisticData, METRIC, StatisticsProvider} from '../../providers/statistics/statistics';

@Component({
  selector: 'line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})

export class LineChartComponent implements OnInit {

  @Input() public metric: METRIC; // enum Metric
  @Input() public interval: string; // DAY, WEEK, MONTH
  @Input() public statisticData: IStatisticData[] = [];
  @Input() public statisticDataSets: string[] = []; // statistics
  @Input() public project: any = null;
  @Input() public filter: string = null; // e.g. material filter
  @ViewChild('chartCanvas', {}) public chartCanvas: ElementRef;

  public options: any;
  public chart: any = null;
  public chartData: any = null;

  constructor(
    private statisticsProvider: StatisticsProvider) {
  }

  public async ngOnInit() {
    this.options = {
      responsive: true,
      legend: {
        display: true
      },
      title: {display: false},
      maintainAspectRatio: false,
      tooltips: {
        enabled: true,
        titleFontSize: 11,
        bodyFontSize: 11,
        callbacks: {
          label(tooltipItem, data) {
            return tooltipItem.yLabel;
          }
        }
      },
      // hover: {mode: null},
      animation : {
        duration: 10
      }
    };
    if (this.statisticData.length > 0 && this.statisticDataSets.length > 0) {
      // console.log('init', this.metric, this.interval, this.statisticData);
      this.prepareChartData();
      if (this.chartData.datasets.length > 0) {
        this.chart = this.createLineChart(this.chartCanvas.nativeElement, this.chartData);
      }
    } else if (this.chart !== undefined && this.chart !== null) {
      this.chart.data.labels = [];
      this.chart.data.datasets = [];
      this.chart.update();
    }
  }

  public refresh(interval: string, statisticData:IStatisticData[], project: any = null, filter: any = null) {
    this.interval = interval;
    this.statisticData = statisticData;
    this.project = project;
    this.filter = filter;
    // console.log('refresh', this.metric, this.interval, this.statisticData);
    if (this.chart !== undefined && this.chart !== null) {
      this.chart.data.labels = [];
      this.chart.data.datasets = [];
      this.chart.update();
    }
    this.prepareChartData();
    if (this.chartData.datasets.length > 0) {
      this.chart = this.createLineChart(this.chartCanvas.nativeElement, this.chartData);
    }
  }

  private prepareChartData() {
    this.chartData = {
      labels: [],
      datasets: []
    };
    this.statisticDataSets.forEach((statisticDataset) => {
      if (this.metric === METRIC.productionSteps) {
        const dataset = {
          label: statisticDataset,
          borderColor: CHART_COLORS.productionSteps[statisticDataset],
          fill : false,
          data: []
        };
        this.chartData.datasets.push(dataset);
      } else if (this.metric === METRIC.processedMaterial) {
        const dataset = {
          label: statisticDataset,
          borderColor: CHART_COLORS.processedMaterial[statisticDataset],
          fill : false,
          data: []
        };
        this.chartData.datasets.push(dataset);
      }
    })
    this.prepareDatasets();
  }

  private prepareDatasets() {
    this.statisticData.forEach((statistic) => {
      this.chartData.labels.push(statistic.aggregateDate);
      this.chartData.datasets.forEach((dataset) => {
        dataset.data.push({
          x: statistic.aggregateDate,
          y: this.statisticsProvider.aggregateData(this.metric, statistic.aggregateData, dataset.label, this.project, this.filter)
        });
      })
    })
  }

  private createLineChart(element: HTMLCanvasElement, data: any): any {
    return new Chart(element, {
      type: 'line',
      options: this.options,
      data
    });
  }

}
