import { Injectable } from '@angular/core';
import { RequestProvider } from '../request/request';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable()
export class MailProvider {

  constructor(
    public request: RequestProvider,
    public db: AngularFirestore
  ) {
  }

  addMail(mail: any): Promise<any> {
    mail.id = this.db.createId();
    return this.set(`mails/${mail.id}`, mail);
  }

  public doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.db.doc<T>(ref) : ref;
  }

  public set<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    return this.doc(ref).set(
      {
        ...data
      },
      { merge: true }
    );
  }

}
