<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col>
        <span (click)="nextSimulation()">{{info}}</span>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <a (click)="nextSimulation()">{{'OK'| translate}}</a>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        <a (click)="stopSimulation()">{{'NO-TOOLTIP'| translate}}</a>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
