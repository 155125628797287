import {Injectable} from '@angular/core';
import {FireStoreProvider} from '../firestore/firestore';

export interface IMachineData {
  id: number,
  isOnline: boolean,
  activeMode: string,
  timeStamp: number,
  activeProfile?: {
    name: string,
    geometry: string,
    numberOfPiecesDone: number,
    numberOfPiecesToBeDone: number
  }
}

@Injectable()
export class MachinesProvider extends FireStoreProvider {

  loadMachinesData(whoami: any) {
    return this.colWithIds$<IMachineData[]>(`user/${whoami.id}/machines`);
  }

  loadMachineData(whoami: any, machineId: number) {
    return this.colWithIds$<IMachineData>(`user/${whoami.id}/machines`, (ref: any) => ref
      .where('id', '>=', machineId));
  }

}
