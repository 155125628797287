import { Injectable, ChangeDetectorRef } from '@angular/core';
import { RequestProvider } from '../request/request';
import { Storage } from '@ionic/storage';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentChangeAction
} from '@angular/fire/compat/firestore';
import {combineLatest, Observable} from "rxjs";
import {map, tap, concatAll} from 'rxjs/operators';
import { Utils } from '../utils/utils';
import * as moment from 'moment';
import {addUndefinedDefaults} from '@angular-devkit/core/src/json/schema/transforms';
import { ExportImportProvider } from '../export-import/export-import';
type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;


@Injectable()
export class ProfilesProvider {

  profiles: any = [];
  profilesflat: any = [];
  hasPendingWrites: boolean = false;
  active: any = {};
  folderActive: any = -1;
  currentFolder: any = [];
  whoami: any = [];
  code: any = "";
  flatcount: any = 0;
  searchProfile: any;
  folderCount: any = [];
  index: any = 0;
  size: any = 1;
  jsonItem: any = null;
  isProjectSource: boolean = false;
  readonly sourceProfile = "/profile";
  readonly  sourceProject = "/project";

  public projectsFolder: AngularFirestoreCollection<any>;
  public projectsFire: Observable<any[]>;
  public project: any[] = [];

  public profilesFolder: AngularFirestoreCollection<any>;
  public profilesFire: Observable<any[]>;
  public profilesItemsCollect: AngularFirestoreCollection<any>;
  public profilesItems: Observable<any[]>;
  public profileFolderVirtual: any[]=[];
  public folder: any = [];
  public status: number = 1;
  public exportImport: ExportImportProvider = null;

  constructor(
    public request: RequestProvider,
    private storage: Storage,
    public db: AngularFirestore,
    // public events: Events
  ) {
    this.storage.get('code').then((code) => {
      this.code = code;
    });
  }

  getDbPath() : string {
    if(this.isProjectSource)
      return '/user/' + this.whoami.id + this.sourceProject;
    else
      return '/user/' + this.whoami.id + this.sourceProfile;
  }

  getDbProfilePath() : string {
    return '/user/' + this.whoami.id + this.sourceProfile;
  }

  getDbProjectPath() : string {
    return '/user/' + this.whoami.id + this.sourceProject;
  }

  public col<T>({ ref, queryFn }: { ref: CollectionPredicate<T>; queryFn?: any }): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.db.collection<T>(ref, queryFn) : ref;
  }

  public doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.db.doc<T>(ref) : ref;
  }

  public localChanges() {

  }

  getFolder() {
    this.profilesFire = this.profilesFolder.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    this.profilesFire.subscribe((_folder) => {
      this.folder = [..._folder];
      this.sortFolder(this.status);
    })
  }

  getItems() {
    this.profilesItems = null;
    this.profilesItems = this.profilesItemsCollect.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  loadData(subcode?:string) {
    this.hasPendingWrites = false;
    this.storage.get('whoami').then((whoami) => {
      this.storage.get('code').then(async (code) => {
        this.code = code;
        this.whoami = whoami;
        const customerId = await this.storage.get("customerId");
        if(!whoami)
          return;
        if (customerId !== undefined && customerId !== null && customerId !== 'null') {
          this.profilesFolder = this.db.collection<any>(this.getDbPath(), ref => ref.where('name', '==', customerId));
        } else {
          if (code && !this.isProjectSource) {
            this.profilesFolder = this.db.collection<any>(this.getDbPath(), ref => ref.where('code', '==', code));
          } else {
            if (subcode) {
              this.profilesFolder = this.db.collection<any>(this.getDbPath(), ref => ref.where('code', '==', subcode));
            } else {
              this.profilesFolder = this.db.collection<any>(this.getDbPath(), ref => ref.orderBy('sort'));
            }
          }
          if (!subcode) {
            this.flatcount = 0;
            this.profilesflat = [];
            this.profilesFolder.get().forEach(async (item) => {
              item.docs.map((itemobj) => {
                this.folderCount[itemobj.id] = 0;
                let subCollectionDocs = this.db.collection<any>(this.getDbPath() + '/' + itemobj.id + "/items/");
                subCollectionDocs.get().forEach(async obj => {
                  obj.docs.map(doc => {
                    const data = doc.data();
                    if (doc.metadata && doc.metadata.hasPendingWrites) {
                      this.hasPendingWrites = true;
                    }
                    if (data.status == 1) {
                      this.flatcount++;
                    }
                    this.folderCount[itemobj.id]++;
                    const id = doc.id;
                    const order = itemobj.id;
                    const folderName = itemobj.data();
                    data.folderPath = order;
                    data.folderPathName = folderName.name;
                    this.profilesflat.push({id, ...data});
                  });
                });
              });
            });
          }
          if (!code && !subcode) {
            // Group by Code
            let subfolder = [];
            this.profilesFolder.get().forEach(async (item) => {
              item.docs.map((itemobj) => {
                if (itemobj.data().code !== null) {
                  const virtualFolder = {
                    typ: "virtual",
                    sort: new Date().getTime(),
                    name: null,
                    index: this.profiles.length,
                    code: itemobj.data().code
                  };
                  subfolder.push(virtualFolder);
                }
              });
              let groupedFolder = [];
              let group = Utils.groupBy(subfolder, 'code');
              for (let g in group) {
                groupedFolder.push({code: g});
              }
              this.profileFolderVirtual = groupedFolder;
            });
          }
        }
        this.getFolder();
      });
    });
    //console.log(this.folderCount);
  }

  loadFolderB2B(whoami: any, customerId: any) {
      this.whoami = whoami;
      return this.colWithIds$<any>('/user/' + this.whoami.id + this.sourceProfile, (ref: any) => ref.where('name', '==', customerId));
  }

  loadTemplateFolderB2B(whoami: any) {
    this.whoami = whoami;
    return this.colWithIds$<any>('/user/' + this.whoami.id + this.sourceProfile, (ref: any) => ref.where('templates', '==', true));
  }

  loadTemplateB2B(whoami: any, templateId: any, profileId: any) {
    this.whoami = whoami;
    return this.colWithIds$<any>('/user/' + this.whoami.id + this.sourceProfile + '/' + templateId + '/items/', (ref: any) =>
      ref.where('id', '==', profileId).where('status2', '==', 'template'));
  }

  loadDrawingB2B(whoami: any, folderId: any, profileId: any) {
    this.whoami = whoami;
    return this.db.doc<any>('/user/' + this.whoami.id + this.sourceProfile + '/' + folderId + '/items/' + profileId).get();
  }

  loadMaterial(whoami: any) {
    if(!whoami || !whoami.id)
      return new Observable<any[]>(subscriber => subscriber.next([]));
    else
      return this.colWithIds$<any>('/user/' + whoami.id + '/material/', (ref: any) => ref.where('available', '==', true));
  }

  loadColors(whoami: any) {
    if(!whoami || !whoami.id)
      return new Observable<any[]>(subscriber => subscriber.next([]));
    else
      return this.colWithIds$<any>('/user/' + whoami.id + '/color/');
  }

  loadFolderByName(whoami: any, name: string) {
    if(this.code && !this.isProjectSource)
      return this.colWithIds$<any>(this.getDbPath(), (ref: any) => ref.where('name', '==', name).where('code', '==', this.code));
    else
      return this.colWithIds$<any>(this.getDbPath(), (ref: any) => ref.where('name', '==', name));
  }

  public colWithIds$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
    return this.col({ ref, queryFn })
      .snapshotChanges()
      .pipe(
        map((actions: Array<DocumentChangeAction<T>>) => {
          return actions.map((a: DocumentChangeAction<T>) => {
            const data: any = a.payload.doc.data() as T;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  public set<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    return this.doc(ref).set(
      {
        ...data
      },
      { merge: true }
    );
  }

  openFolderLoad(status = 1) {
    let stat = this.getStatus(status);
    if(stat.sorting == "desc") {
      this.profilesItemsCollect = this.db.collection<any>(this.getDbPath() + '/' + this.currentFolder.id + '/items/', ref => ref.orderBy(stat.field, "desc"));
    } else {
      this.profilesItemsCollect = this.db.collection<any>(this.getDbPath() + '/' + this.currentFolder.id + '/items/', ref => ref.orderBy(stat.field, "asc"));
    }
    this.getItems();
  }

  sortFolder(status: number = 1) {
    this.status = status;
    let stat = this.getStatus(status);
    if (this.folder && this.folder.length > 1) {
      this.folder = this.folder.sort((a, b) => {
        return this.sort(stat, a, b);
      });
    }
    /* inactive because the folders are not displayed using the sorting criteria
    if (this.profileFolderVirtual && this.profileFolderVirtual.length > 1) {
      this.profileFolderVirtual = this.profileFolderVirtual.sort((a, b) => {
        return this.sort(stat, a, b, true);
      });
    }*/
    if (this.profilesflat && this.profilesflat.length > 1) {
      this.profilesflat = this.profilesflat.sort((a, b) => {
        return this.sort(stat, a, b);
      });
    }
  }

  //unused
  openItemsLoadByCode(code) {
    const profilesFolder = this.db.collection<any>(this.getDbPath(), ref => ref.where('code', '==', code));
    profilesFolder.get().forEach(async (item) => {
      item.docs.map((itemobj) => {
        // console.log(itemobj.id);
        this.profilesItemsCollect = this.db.collection<any>(this.getDbPath() + '/' +itemobj.id +"/items/");
        this.getItems();
      });
    });
  }

  //unused
  openFolderLoadByCode(code) {
    this.profilesFolder = this.db.collection<any>(this.getDbPath(), ref => ref.where('code', '==', code));
    this.profilesFolder.get().forEach(async (item) => {
      item.docs.map((itemobj) => {
        // console.log(itemobj.data())
      });
    });
    this.getFolder();
  }

  async openDefaultFolder() {
    // this.loadData();
    await this.storage.get("code").then(async (code) => {
      let defaultFolder = this.db.collection<any>(this.getDbPath(), ref => ref.where('isDefault', '==', true).where('code', '==', code));
      let count = 0;
      await defaultFolder.get().forEach((item) => {
        if(item.docs && item.docs.length > 0) {
          const data = item.docs[0].data();
          const id = item.docs[0].id;
          this.currentFolder = {id, ...data};
          count++;
        }
      }).then(async () => {
          if (count == 0) {
            await this.addFolder(".", null, true);
            let newFolder = this.db.collection<any>(this.getDbPath(), ref => ref.where('isDefault', '==', true).where('code', '==', code).where('name','==','.'));
            await newFolder.get().forEach((item) => {
              if (item.docs && item.docs.length > 0) {
                const data = item.docs[0].data();
                const id = item.docs[0].id;
                this.currentFolder = {id, ...data};
              }
            });
          }
          var d = new Date();
          let data = {
            name: "Profile " + d.toLocaleDateString() + " " + d.toLocaleTimeString(),
          };
          await this.create(data);
        }
      );
    });
  }

  create(data): Promise<any> {
    return new Promise((resolve, reject) => {
      // console.log(this.currentFolder);
      this.index=0;
      if (!data.NumberOfPiecesToManufacture) {
        data.NumberOfPiecesToManufacture = [1];
      }
      if (!data.paintPosition) {
        data.paintPosition = null;
      }
      if (!data.paintColor) {
        data.paintColor = null;
      }
      if (!data.materialID) {
        data.materialID = null;//"mat_steel";
      }
      if (!data.materialName) {
        data.materialName = null;
      }
      if (!data.materialThickness) {
        data.materialThickness = null;
      }
      if(!data.paint) {
        data.paint = "none";
      }
      if (!data.width) {
        data.width = [0];
      }
      if(!data.cut) {
        data.cut = 0;
      }

      this.active = {
        typ: "profile", folder: this.folderActive, sort: new Date().getTime(),
        name: data.name, numberOfPiecesToManufacture: data.NumberOfPiecesToManufacture,
        paintPosition: data.paintPosition, paintColor: data.paintColor, materialID: data.materialID,
        materialName: data.materialName, paint: data.paint,
        materialThickness: data.materialThickness, cut: data.cut, width: data.width,
        angleCorrection: 0, lockTogether: 0, code: this.code, status: 0, path : "",
        folderPath: this.currentFolder.id
      };

      // B2B
      if (data.customerId) {
        this.active.customerId = data.customerId;
        this.active.templateId = null;
        this.active.status2 = data.status2;
      }
      //doc.add(this.active).then((docRef) => {
      //  this.active.id = docRef.id;
      //  if (data.customerId) {
      //    this.active.templateId = this.active.id; // set templateId to itself
      //  }
      //  var d = new Date();
      //  const info = data.customerId ? "B2B Profil wurde angelegt" : "Profil wurde angelegt";
      //  let history = { date: d.toLocaleDateString() + " " + d.toLocaleTimeString(), text: info, status: 0, see: 1, id: docRef.id, path: path, owner: this.currentFolder.code, for: this.currentFolder.code };
      //  let msg = this.db.collection<any>('/msg' + this.whoami.id +'/' );
      //  msg.add(history);
      //  resolve(true);
      //});

      if(this.whoami && this.whoami.id) {
        let path = this.getDbPath() + '/' + this.currentFolder.id + '/items';
        let docRef = this.db.collection<any>(path).doc(this.db.createId());
        docRef.set(this.active);
        this.active.id = docRef.ref.id;
        if (data.customerId) {
          this.active.templateId = this.active.id; // set templateId to itself
        }
      }
      resolve(true);
    });
  }

  getStatus(status = 1) {
    var sorting = "asc"
    var field = "sort"
    if(status == 2) {
      sorting = "desc"
    }
    if(status == 3) {
      field = "name"
    }
    if(status == 4) {
      field = "name"
      sorting = "desc"
    }
    if(status == 5) {
      field  = "status"
    }
    if(status == 6) {
      field = "status"
      sorting = "desc"
    }

    return { sorting: sorting, field: field };
  }

  save(orderId: number = 0) {
    if(this.isProjectSource && this.active.status <= 3){
      let folderId = null;
      if(this.active.folderPath)
        folderId=this.active.folderPath;
      if(!folderId)
        folderId=this.currentFolder.id;
      this.db.doc<any>('/user/' + this.whoami.id + '/modProject' + '/' + folderId).set({folderId: folderId, orderId: this.currentFolder && this.currentFolder.orderId > 0 ? this.currentFolder.orderId : orderId}).then(()=>{
        this.db.doc<any>('/user/' + this.whoami.id + '/modProject' + '/' + folderId + '/items/' + this.active.id).set({folderId: folderId});
      });

    }
    if(this.active.folderPath) {
      this.db.doc<any>(this.getDbPath() + '/' + this.active.folderPath + '/items/' + this.active.id).update(this.active);
      this.loadData();
    } else {
      if (this.currentFolder !== undefined && this.currentFolder !== null) {
        this.active.folderPath = this.currentFolder.id;
      }
      this.db.doc<any>(this.getDbPath() + '/' + this.currentFolder.id + '/items/' + this.active.id).update(this.active);
      this.loadData();
      this.getItems();
    }
  }

  saveWithPath(newFolderId, item): Promise<any> {
    return new Promise((resolve, reject) => {
      let doc = this.db.collection<any>(this.getDbPath() + '/' + newFolderId +"/items");
      this.active = item;
      const oldFolderPath = this.active.folderPath;
      this.active.folderPath = newFolderId;
      doc.add(this.active).then(docRef => {
        this.delete(item, oldFolderPath);
        this.active.id = docRef.id;
        this.save();
        resolve(true);
      });
    });
  }

  saveWithPathInProjectsKeepOriginal(project, item): Promise<any> {
    let newFolderId = project.id;
    let orderId = project.orderId;
    return new Promise((resolve, reject) => {
      this.isProjectSource = true;
      let doc = this.db.collection<any>(this.getDbPath() + '/' + newFolderId +"/items");
      this.active = item;
      this.active.folderPath = newFolderId;
      this.active.status = 1; // set status to under review
      doc.add(this.active).then(docRef => {
        this.active.id = docRef.id;
        this.save(orderId);
        resolve(true);
      });
    });
  }

  changeStatus(status) {
    let item = this.active;
    let obj = { id: item.id, status: status };
    this.request.post('auth/updateProfile', obj, true).subscribe(data => {
      this.loadData();
    }, err => {
      console.log(err);
    })
  }

  async addFolder(name, parent: string = null, isDefault = false): Promise<any> {
    await this.storage.get('code').then((code) => {
      this.code = code;
    });
    this.active = { typ: "folder", sort: new Date().getTime(), name: name, parent: parent, index: this.profiles.length, code: this.code };
    if(isDefault)
      this.active.isDefault = true;
    this.profiles.push(this.active);
    this.storage.set("profiles", this.profiles);
    return this.profilesFolder.add(this.active);
  }

  async addNamedFolder(whoami: any, name: any): Promise<any> {
    await this.storage.get('code').then((code) => {
      this.code = code;
    });
    this.active = {
      id: this.db.createId(),
      typ: "folder", sort: new Date().getTime(), name: name, index: this.profiles.length, code: this.code
    };
    this.profiles.push(this.active);
    this.storage.set("profiles", this.profiles);
    this.currentFolder = this.active;
    this.isProjectSource = false;
    await this.set(`user/` + whoami.id + `/profile/${this.active.id}`, this.active); // here only '/profile/' path: we never want to create a folder in the projects path!
    return this.active.id;
  }

  reset() {
    this.storage.get('profiles').then((profiles) => {
      if (profiles) {
        this.profiles = profiles;
      } else {
        this.profiles = [];
      }
    });
  }

  duplicate(item2) {
    var item = JSON.parse(JSON.stringify(item2));
    item.name = item.name + " (Copy)";
    item.status = 0;
    let doc = this.db.collection<any>(this.getDbPath() + '/' + this.currentFolder.id + '/items');
    this.active = item;
    doc.add(this.active).then((docRef) => {
      this.active.id = docRef.id;
      this.save();
    });
  }

  duplicateB2B(template: any, customerId: any, materialId: any, colorName: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // console.log(this.currentFolder);
      var d = new Date();
      var item = JSON.parse(JSON.stringify(template));
      item.name = item.name + " " +d.toLocaleDateString() + " " + d.toLocaleTimeString();
      item.customerId = customerId;
      item.templateId = template.id;
      item.status2 = 'derived';
      item.status = 0;
      item.folderPath = this.currentFolder.id;
      if(materialId)
        item.materialID = materialId;
      if(colorName)
        item.paintColor = colorName;

      let path = this.getDbPath() + '/' + this.currentFolder.id + '/items';
      let doc = this.db.collection<any>(path);
      this.active = item;

      doc.add(this.active).then((docRef) => {
        this.active.id = docRef.id;
        var d = new Date();
        let history = { date: d.toLocaleDateString() + " " + d.toLocaleTimeString(), text: "B2B Profil wurde angelegt", status: 0, see: 1, id: docRef.id, path: path, owner: this.currentFolder.code, for: this.currentFolder.code };
        let msg = this.db.collection<any>('/msg' + this.whoami.id +'/' );
        msg.add(history);
        this.save();
        resolve(true);
      });
    });
  }


  // do not call when in drawing, because it might not update the view accordingly
  modifyActive(materialId: any,
               materialName: any,
               materialThickness: any,
               colorName: any,
               colorPosition: any,
               sheetlength: any,
               numberOfPiecesToManufacture: any,
               lockTogether: any) {
    if (this.active.csv2) {
      let importedItem = this.exportImport.importAndConvert(this.active);
      if (importedItem)
        this.active = importedItem;
    }
    if (materialId)
      this.active.materialID = materialId;
    if (materialName)
      this.active.materialName = materialName;
    if (materialThickness)
      this.active.materialThickness = materialThickness;
    if (colorName)
      this.active.paintColor = colorName;
    if (colorPosition)
      this.active.paintPosition = colorPosition;
    if (sheetlength)
      this.active.width = [sheetlength];
    if (numberOfPiecesToManufacture)
      this.active.numberOfPiecesToManufacture = [numberOfPiecesToManufacture];
    if (lockTogether)
      this.active.lockTogether = lockTogether;
  }

  addSharedProfile(whoami: any, folderId: any, item: any) {
    this.isProjectSource = false;
    let doc = this.db.collection<any>('/user/' + whoami.id + '/profile/' + folderId + '/items'); // here only '/profile/' path: we never want to import an in-app-message-profile in the projects path!
    this.active = item;
    doc.add(this.active).then((docRef) => {
      this.active.id = docRef.id;
      this.save();
    });
  }

  delete(item, oldFolderPath?:string) {
    let path = this.getDbPath() + '/' + (oldFolderPath !== undefined ? oldFolderPath : item.folderPath) + '/items/' + item.id;
    // console.log(path);
    this.db.doc<any>(path).delete();
  }

  deleteWithPath(item) {
    let subCollectionDocs = this.db.collection<any>(this.getDbPath() + '/' + item.id + "/items/");
    subCollectionDocs.get().forEach(async obj => {
      obj.docs.map(profileDocument => {
        const data = profileDocument.data();
        if (data.status != 4 && data.status != 6) {
          // console.log(data.id + '/' + data.status + '/' + data.name);
          this.db.doc(this.getDbPath() + '/' + item.id + "/items/" + profileDocument.id).delete()
            .catch(error => {console.log(error)})
            .then(() => console.log(profileDocument.id + ' has been deleted'))
        }
      });
    });
    let path = this.getDbPath() + '/' + item.id;
    //console.log(path + ' has been deleted');
    this.db.doc<any>(path).delete();
  }

  addProfile() {
    // console.log(this.active);
    // console.log(this.index);
    this.active.numberOfPiecesToManufacture.push(1);
    // this.active.paintPosition.push("none");
    // this.active.paint.push("none");
    // this.active.materialID.push(null);
    // this.active.materialName.push("");
    // this.active.materialThickness.push(0);
    this.active.width.push(0);
    this.index = this.active.width.length - 1;
  }

  nextProfile() {
    this.index++;
    console.log(this.index);
    // this.events.publish("draw:paint");
  }

  prevProfile() {
    this.index--;
    console.log(this.index);
    // this.events.publish("draw:paint");
  }

  trash() {
    this.active.numberOfPiecesToManufacture.splice(this.index, 1);
    // this.active.paintPosition.splice(this.index, 1);
    // this.active.paint.splice(this.index, 1);
    // this.active.materialID.splice(this.index, 1)
    // this.active.materialName.splice(this.index, 1);
    // this.active.materialThickness.splice(this.index, 1);
    this.active.width.splice(this.index, 1);
    if(this.index > 0) {
      this.index--;
    }
    // this.events.publish("draw:paint");

  }

  finishedDrawing(png: any) {
    console.log("finishDrawing");
    if (Utils.b2bInstance !== undefined) {
      let base64 = null;
      if(png) {
        const BASE64_MARKER = ';base64,';
        const base64Index = png.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        base64 = png.substring(base64Index);
      }

      let token = sessionStorage.getItem('shopToken');
      let expires = sessionStorage.getItem('shopExpires');
      if (expires !== null) {
        const shopExpires = moment(JSON.parse(expires));
        if (moment().isAfter(shopExpires)) {
          // console.log('expired', shopExpires.toISOString());
          token = null;
          expires = null;
          sessionStorage.removeItem('shopToken');
          sessionStorage.removeItem('shopExpires');
        }
      }

      this.request.post('auth/finishedDrawing', {
        shopInstance: Utils.b2bInstance.i,
        shopToken: token !== null ? JSON.parse(token) : null,
        shopExpires: expires !== null ? JSON.parse(expires) : null,
        customerId: Utils.customerId,
        status: Utils.b2bInstance.status,
        url: Utils.b2bInstance.url,
        urlFrontend: Utils.b2bInstance.urlFrontend,
        callBack: Utils.b2bInstance.callback,
        profileId: this.active.id,
        width: this.active.cut,
        sheetlength: this.active.width,
        numberOfPiecesToManufacture: this.active.numberOfPiecesToManufacture,
        materialName: this.active.materialName,
        materialThickness: this.active.materialThickness,
        materialID: this.active.materialID,
        paintColor: this.active.paintColor,
        paintPosition: this.active.paintPosition,
        lockTogether: this.active.lockTogether,
        previewBinary: base64
      }).subscribe(data => {
          console.log('callBack', data);
          if (data.token !== undefined && data.token !== null) {
            sessionStorage.setItem('shopToken', JSON.stringify(data.token));
            let shopExpires = null;
            if (data.expires === null) {
              // currently allways null
              shopExpires = moment().add(1, 'hours'); // fallback 1 hour
            } else {
              shopExpires = moment(data.expires);
            }
            sessionStorage.setItem('shopExpires', JSON.stringify(shopExpires.valueOf()));
            // console.log('expires', shopExpires.toISOString());
          }
          if (Utils.b2bInstance.status === 1) {
            window.parent.postMessage("variobend.draw.finished", data.origin);
          }
      }, err => {
        console.log(err)
      });
    }
  }

  private sort(stat: any, a: any, b: any, virtual?: boolean): number {
    if (stat.field === 'name') {
      if (stat.sorting === 'desc' ) {
        if (virtual) {
          return a.code.toLowerCase() < b.code.toLowerCase() ? 1 : b.code.toLowerCase() < a.code.toLowerCase() ? -1 : 0;
        } else {
          return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : b.name.toLowerCase() < a.name.toLowerCase() ? -1 : 0;
        }
      } else {
        if (virtual) {
          return a.code.toLowerCase() > b.code.toLowerCase() ? 1 : b.code.toLowerCase() > a.code.toLowerCase() ? -1 : 0;
        } else {
          return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
        }
      }
    } else {
      if (stat.sorting === 'desc' ) {
        return a.sort < b.sort ? 1 : b.sort < a.sort ? -1 : 0;
      } else {
        return a.sort > b.sort ? 1 : b.sort > a.sort ? -1 : 0;
      }
    }
  }


  loadProjects(searchString: string) {
    this.storage.get('whoami').then((whoami) => {
      this.project=[];
      this.whoami = whoami;
      if (!whoami)
        return;
      if (!searchString || searchString.length < 1) {
        this.project = [];
        return;
      }
      let projectFoldersName: AngularFirestoreCollection<any> = this.db.collection<any>(
        '/user/' + this.whoami.id + this.sourceProject,
        ref =>
          ref.where('name', ">=", searchString.toUpperCase()).where('name', "<=", searchString.toUpperCase() + '\\utf8ff')

      );
      let projectFoldersOrderErpId: AngularFirestoreCollection<any> = this.db.collection<any>(
        '/user/' + this.whoami.id + this.sourceProject,
        ref =>
          ref.where('orderErpId', ">=", searchString.toUpperCase()).where('orderErpId', "<=", searchString.toUpperCase() + '\\utf8ff') // to improve search we need to prepare array with searchable strings and use array-contains
      );
      let projectFoldersProjectErpId: AngularFirestoreCollection<any> = this.db.collection<any>(
        '/user/' + this.whoami.id + this.sourceProject,
        ref =>
          ref/*.where('isprojectRoot',"==", true)*/.where('projectErpId', ">=", searchString.toUpperCase()).where('projectErpId', "<=", searchString.toUpperCase() + '\\utf8ff') // to improve search we need to prepare array with searchable strings and use array-contains
      );
      let projectFoldersCustomerErpId: AngularFirestoreCollection<any> = this.db.collection<any>(
        '/user/' + this.whoami.id + this.sourceProject,
        ref =>
          ref/*.where('isCustomerRoot',"==", true)*/.where('customerErpId', ">=", searchString.toUpperCase()).where('customerErpId', "<=", searchString.toUpperCase() + '\\utf8ff') // to improve search we need to prepare array with searchable strings and use array-contains
      );

      let projectsFireName: Observable<any[]> = projectFoldersName.snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return {id, ...data};
          });
        })
      );
      let projectsFireOrder: Observable<any[]> = projectFoldersOrderErpId.snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return {id, ...data};
          });
        })
      );
      let projectsFireProject: Observable<any[]> = projectFoldersProjectErpId.snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return {id, ...data};
          });
        })
      );
      let projectsFireCustomer: Observable<any[]> = projectFoldersCustomerErpId.snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return {id, ...data};
          });
        })
      );

      combineLatest(projectsFireCustomer, projectsFireProject, projectsFireOrder, projectsFireName).subscribe((_folder) => {
        this.project = _folder.reduce((accumulator, value) => accumulator.concat(value), []);
      });

    });
  }

  async loadOrderProjects(searchStrings: string[]) {
    let whoami = await this.storage.get('whoami');// .then((whoami) => {
      this.project=[];
      this.whoami = whoami;
      if (!whoami)
        return;
      if (!searchStrings || searchStrings.length < 1) {
        this.project = [];
        return;
      }
      let projectFoldersName: AngularFirestoreCollection<any> = this.db.collection<any>(
        '/user/' + this.whoami.id + this.sourceProject,
        ref =>
          ref.where('parent', "in", searchStrings).where('orderId', ">", 0)
      );
      let projectsFireName: Observable<any[]> = projectFoldersName.snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return {id, ...data};
          });
        })
      );

      return projectsFireName;

    //});
  }


  async downloadAllProfilesInProject(customerName: string, projectName: string, orderId: string) {
    let filterProperty='customerName';
    let filterValue=customerName;
    if(!filterValue){
      filterValue=projectName;
      filterProperty='projectName';
    }
    if(!filterValue){
      filterValue=orderId;
      filterProperty='orderId';
    }
    let snapshot = await this.db.collection<any>('/user/' + this.whoami.id + this.sourceProject, ref => ref.where('customerName', "==", customerName)).get();
    snapshot.forEach(async (item) => {
      // let itemId = item.docs.id;
      item.docs.map((itemObj) => {
        console.log(itemObj.data());
        console.log(itemObj.id);
        let itemId = itemObj.id;
        let project = {itemId, ...itemObj.data()};
        this.db.collection<any>('/user/' + this.whoami.id + this.sourceProject + '/' + itemId + '/items').get().forEach(async item => {
          item.docs.map(async itemObj => {
            let profileId = itemObj.id;
            let profile = {profileId, ...itemObj.data()};
            console.log(profile);
          });
        });
      });
    });
  }
}
