import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ThreedProvider} from '../../providers/threed/threed';
import {ProfilesProvider} from '../../providers/profiles/profiles';
import {Platform} from '@ionic/angular';

@Component({
  selector: 'threed-preview',
  templateUrl: 'threed-preview.html',
  styleUrls: ['threed-preview.scss']
})
export class ThreeDPreviewComponent implements OnInit {

  readonly defaultHeight = 150;
  readonly defaultWidth = 200;

  public isPreviewOpen: boolean = true;
  public isBoardOpen: boolean = false;
  public profileImg: any;
  @Output() public threedView: EventEmitter<{
    showThreedBoard: boolean, width: number, height: number
  }> = new EventEmitter<{ showThreedBoard: boolean, width: number, height: number }>();
  @ViewChild('threedPreview', {static: true}) public threedContainer:ElementRef<HTMLCanvasElement>;
  @ViewChild('profilePreview', {static: true}) public profileContainer:ElementRef<HTMLCanvasElement>;

  constructor(private profileProvider: ProfilesProvider,
              private platform: Platform,
              private threedService: ThreedProvider) {
  }

  public ngOnInit(): void {
    this.threedService.createScene(this.threedContainer, this.defaultWidth, this.defaultHeight, false);
  }

  ngAfterViewInit() {
    this.animate();
  }

  public animate() {
    if (this.profileProvider.active && this.profileProvider.active.typ === 'profile') {
      this.threedService.animate(this.profileProvider.active);
    }
  }

  public setColors(colors: any) {
    this.threedService.setColors(colors);
  }

  public togglePreview(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.isPreviewOpen = !this.isPreviewOpen;
  }

  public openBoard(event?) {
    if(!this.isBoardOpen){
      this.toggleBoard(event);
    }
  }

  public closeBoard(event?) {
    this.toggleBoard(event);
  }

  public toggleBoard(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.isBoardOpen) {
      this.isBoardOpen = false;
      this.threedService.createScene(this.threedContainer, this.defaultWidth, this.defaultHeight , false);
    } else if (!this.isBoardOpen) {
      this.isBoardOpen = true;
      this.threedService.createScene(this.threedContainer, this.platform.width(), this.platform.height(), true);
    }
    this.animate();
    this.threedView.emit({showThreedBoard: this.isBoardOpen, width: this.defaultWidth, height: this.defaultHeight});
  }

  public setPreviewImg(profileImg: any) {
    this.profileImg = profileImg;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (this.isBoardOpen) {
      this.threedService.resize(this.platform.width(), this.platform.height());
    }
  }

}
