<ion-header class="ion-no-border">
  <ion-toolbar color="dark">
    <ion-grid class="ion-no-padding">
      <ion-row class="ion-no-padding">
        <ion-col size-xs="1" size-xl="1" class="ion-no-padding">
          <ion-menu-button fill="solid" color="medium"></ion-menu-button>
        </ion-col>
        <ion-col size-xs="8" offset-xs="1"  size-xl="8" offset-xl="1" class="ion-no-padding ion-text-center">
          <img src="assets/imgs/logo.svg" width="400" class="ion-margin-top">
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-header>
<ion-content class=" hideForCamera ion-text-center ion-align-self-center ion-justify-content-center" [hidden]="isScanning">
  <div class="toplevel-div">
    <ion-grid>
      <ion-row>
        <ion-col size-md="4" offset-md="4">
          <ion-button color="primary" expand="block" (click)="signUp()">
            <div>
              <ion-icon name="create-outline" class="ion-padding-horizontal"></ion-icon>
              {{'REGISTER'| translate}}
            </div>
          </ion-button>
          <span>{{'SIGNUP'| translate}}</span>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="!native">
        <ion-col size-md="4" offset-md="4">
          <ion-buttons class="store" >
            <ion-button color="primary" fill="solid" expand="block" href="https://apps.apple.com/us/app/variobend/id1569194122">
              <div>
                <ion-icon name="logo-apple-appstore" class="ion-padding-horizontal"></ion-icon>
                {{'APPSTORE'| translate}}
              </div>
            </ion-button>
            <ion-button color="primary" fill="solid" expand="block" href="https://play.google.com/store/apps/details?id=de.asco.variobend">
              <div>
                <ion-icon name="logo-google-playstore" class="ion-padding-horizontal"></ion-icon>
                {{'PLAYSTORE'| translate}}
              </div>
            </ion-button>
          </ion-buttons>
          <span>{{'STORE'| translate}}</span>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="!loggedIn">
        <ion-col size-md="4" offset-md="4">
          <ion-button color="primary" expand="block" (click)="openLogin()">
            <div>
              <ion-icon name="log-in-outline" class="ion-padding-horizontal"></ion-icon>
              {{'LOGIN'| translate}}
            </div>
          </ion-button>
          <span>{{'ACCOUNT'| translate}}</span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-md="4" offset-md="4">
          <ion-button color="primary" expand="block" (click)="invite()">
            <div>
              <ion-icon name="mail-outline" class="ion-padding-horizontal"></ion-icon>
              {{'REQUEST-INVITE'| translate}}
            </div>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="native || development">
        <ion-col size-md="4" offset-md="4">
          <ion-button color="primary" expand="block" (click)="scanQR()">
            <div>
              <ion-icon name="qr-code-outline" class="ion-padding-horizontal"></ion-icon>
              {{'QR-CODE-REGISTER'| translate}}
            </div>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-md="4" offset-md="4">
          <a button class="ion-margin-bottom" href="dashboard">{{'BACK'| translate}}</a>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
<ion-content class="cameraBackground" [hidden]="!isScanning">
  <ion-toolbar color="transparent">
    <ion-buttons slot="end" class="ion-no-margin">
      <ion-button size="large" fill="solid" color="light" (click)="closeCamera()">
        <ion-icon name="close-circle"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-content>
