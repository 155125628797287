import {Component, ViewChild} from '@angular/core';
import {AlertController, LoadingController, NavController, ToastController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {TranslateService} from '@ngx-translate/core';
import {IStatisticData, StatisticsProvider} from '../../shared/providers/statistics/statistics';
import {environment} from '../../environments/environment';
import {Utils} from '../../shared/providers/utils/utils';
import * as moment from 'moment';
import {LineChartComponent} from '../../shared/components/line-chart/line-chart.component';
import {ProfilesProvider} from '../../shared/providers/profiles/profiles';


@Component({
  selector: "page-statistics",
  templateUrl: "statistics.html",
  styleUrls: ['./statistics.scss']
})
export class StatisticsPage {

  public statisticsRaw: IStatisticData[];
  public statistics: IStatisticData[];
  public whoami: any = null;
  public subuser: any = null;
  public toast: any;
  public intervalId: any = 'DAY'; // WEEK, MONTH
  public dateFrom: any = null;
  public dateTo: any = null;
  public project: any = null;
  public projects: any[] = [];
  public loading: any = null;
  public changeFilter = true;
  public now: any = null;
  public productionSteps: string[] = ['numberOfPieces','cuttingSteps', 'bendSteps','pressSteps', 'rollformingSteps'];
  public processedMaterial: string[] = ['sheetThickness_mm','sheetLength_m', 'sheetWidth_mm','totalSheetLength_m', 'totalArea_sqm'];
  public materials: any[] = [];
  public materialFilter: string = null;
  @ViewChild('productionStepsChart', {}) public productionStepsChart: LineChartComponent;
  @ViewChild('processedMaterialChart', {}) public processedMaterialChart: LineChartComponent;

  constructor(
    public navCtrl: NavController,
    private storage: Storage,
    private statisticsProvider: StatisticsProvider,
    private profileProvider: ProfilesProvider,
    private toastCtrl: ToastController,
    private alertController: AlertController,
    private loadingCtrl: LoadingController,
    private translate: TranslateService) {
  }

  ionViewWillEnter(){
    this.now = moment().toDate().toISOString();
    this.storage.get('whoami').then(async (whoami) => {
      if(whoami && whoami.id) {
        this.whoami = whoami;
        this.dateFrom = moment().subtract(1, 'year').toDate().toISOString();
        this.dateTo = moment().toDate().toISOString();
        // maybe load in ngInit only once and don't refresh
        this.loadStatistic(true);
        this.profileProvider.loadMaterial(whoami).subscribe(
          async (materials) => {
            materials.push({'id': "mat_alu", 'name': this.translate.instant('ALU'),'defaultMat': true});
            materials.push({'id': "mat_stainless", 'name': this.translate.instant('STAINLESS'),'defaultMat': true});
            materials.push({'id': "mat_copper", 'name': this.translate.instant('COPPER'),'defaultMat': true});
            materials.push({'id': "mat_steel", 'name': this.translate.instant('STEEL'),'defaultMat': true});
            if (materials.length === 0) {
              this.errorAlert(this.translate.instant("NOMATERIALS"));
            } else {
              this.materials = materials;
            }
          });
      }
    });
  }

  isProduction() {
    return environment.production;
  }

  isB2B() {
    if(Utils.isB2B && Utils.b2bInstance !== undefined && Utils.b2bInstance !== null) {
      document.body.style.setProperty('--ion-color-primary', Utils.b2bInstance.colors.primary);
      document.body.style.setProperty('--ion-color-dark', Utils.b2bInstance.colors.dark);
      document.body.style.setProperty('--ion-color-background', Utils.b2bInstance.colors.background);
    }
    return Utils.isB2B;
  }

  async filterStatistic(changeType: number) {
    if (this.changeFilter) {
      if (changeType === 1) {
        const dateFrom = moment(this.dateFrom);
        const dateTo = moment(this.dateTo);
        if (dateFrom.isAfter(dateTo)) {
          this.dateTo = dateFrom.add(1, 'day').toDate().toISOString();
        }
        this.loadStatistic(true);
      } else if (changeType === 2) {
        const dateFrom = moment(this.dateFrom);
        const dateTo = moment(this.dateTo);
        if (dateTo.isBefore(dateFrom)) {
          this.dateFrom = dateTo.subtract(1, 'day').toDate().toISOString();
        }
        this.loadStatistic(true);
      } else if (changeType === 3) {
        this.materialFilter = null;
        this.statistics = this.statisticsProvider.aggregateInterval(this.intervalId, this.statisticsRaw);
        if (this.productionStepsChart !== undefined && this.productionStepsChart !== null) {
          this.productionStepsChart.refresh(this.intervalId, this.statistics, this.project, this.materialFilter);
        }
        if (this.processedMaterialChart !== undefined && this.processedMaterialChart !== null) {
          this.processedMaterialChart.refresh(this.intervalId, this.statistics, this.project, this.materialFilter);
        }
      } else if (changeType === 4) {
        this.materialFilter = null;
        if (this.project === '') {
          this.project = null;
        }
        if (this.productionStepsChart !== undefined && this.productionStepsChart !== null) {
          this.productionStepsChart.refresh(this.intervalId, this.statistics, this.project, this.materialFilter);
        }
        if (this.processedMaterialChart !== undefined && this.processedMaterialChart !== null) {
          this.processedMaterialChart.refresh(this.intervalId, this.statistics, this.project, this.materialFilter);
        }
      }
    }
  }

  chartFilter(changeType: number) {
    if (changeType === 1) {
      this.processedMaterialChart.refresh(this.intervalId, this.statistics, this.project, this.materialFilter);
    }
  }

  async loadStatistic(refresh: boolean) {
    this.changeFilter = false;
    this.projects = [];
    // console.log('filterStatistic', moment(this.dateFrom, 'YYYY-MM-DD').format('YYYY-MM-DD'), moment(this.dateTo, 'YYYY-MM-DD').format('YYYY-MM-DD'), this.intervalId);
    this.loading = await this.loadingCtrl.create({
      message: this.translate.instant("PLEASEWAIT")
    });
    this.loading.onDidDismiss().then((result) => {
      this.changeFilter = true;
      if (this.statisticsRaw === undefined || this.statisticsRaw.length === 0) {
        this.toastCtrl.create({
          message: this.translate.instant("NO-STATISTICS"),
          duration: 3000,
          position: "bottom"
        }).then(t => t.present());
      }
    });
    setTimeout(() => {
      this.loading.dismiss();
    }, 5000);
    this.loading.present();
    this.statisticsProvider.loadMainUserStatisticFromTo(this.whoami,
      moment(this.dateFrom).subtract(1, 'day').toDate(), moment(this.dateTo).add(1, 'day').toDate()).subscribe(
      async (_statistics) => {
        this.statisticsRaw = [..._statistics];
        this.statistics = this.statisticsProvider.aggregateInterval(this.intervalId, this.statisticsRaw);
        if (refresh) {
          if (this.productionStepsChart !== undefined && this.productionStepsChart !== null) {
            this.productionStepsChart.refresh(this.intervalId, this.statistics, this.project, this.materialFilter);
          }
          if (this.processedMaterialChart !== undefined && this.processedMaterialChart !== null) {
            this.processedMaterialChart.refresh(this.intervalId, this.statistics, this.project, this.materialFilter);
          }
        }
        this.statistics.forEach((s) => {
          s.aggregateData.forEach((a) => {
            if (a.project.id !== null && this.projects.findIndex((p) => p.id === a.project.id) === -1) {
              this.projects.push(a.project);
            }
          })
        })
        this.loading.dismiss();
        console.log('load statistic data', this.statistics.length);
    });
  }

  async errorAlert(message: string) {
    const alert = await this.alertController.create({
      header: this.translate.instant("ATTENTION"),
      subHeader: this.translate.instant("UNEXPECTEDERROR"),
      message: message,
      buttons: ['Cancel']
    });
    alert.present();
  }

  // dummy data for testing only
  generateMachineData(dataCount: number) {
    console.log('*** start data generation', dataCount);
    fetch('./assets/data/machineData_uid50.json').then(async res => {
      const data = await res.json();
      for (let i = 0; i < dataCount; i++) {
        this.statisticsProvider.addTestMachineData(i, data);
      }
      console.log('*** finished generation');
    })
  }

}
