import { Component } from '@angular/core';
import { MenuController, NavController, ToastController} from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router} from "@angular/router";
import { TranslateService} from '@ngx-translate/core';
import { MessagesProvider} from '../../shared/providers/messages/messages';
import {Storage} from '@ionic/storage';
import {AuthProvider} from '../../shared/providers/auth/auth';

@Component({
  selector: 'page-invite',
  templateUrl: './invite.html',
  styleUrls: ['./invite.scss']
})
export class InvitePage {

  inviteForm: UntypedFormGroup;
  toast: any;
  whoami: any = null;

  constructor(
    private forms: FormsModule,
    private reactiveForms: ReactiveFormsModule,
    private route: ActivatedRoute,
    private router: Router,
    private navCtrl: NavController,
    private menu: MenuController,
    private formBuilder: UntypedFormBuilder,
    private toastCtrl: ToastController,
    private translate: TranslateService,
    private messagesProvider: MessagesProvider,
    private auth: AuthProvider,
    private storage: Storage
  ) {
    this.inviteForm = this.formBuilder.group({
      name: ['', Validators.required],
      from: ['', Validators.compose([Validators.required, Validators.email])],
      to: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  ngOnInit() {
    this.storage.get('whoami').then((whoami) => {
      this.whoami = whoami;
    });
  }

  ionViewDidEnter() {
    this.menu.enable(false);
  }

  ionViewDidLeave() {
    this.menu.enable(true);
  }

  async inviteSubmit() {
    if(this.inviteForm.valid) {
      let wait = await this.auth.emailUserId(this.inviteForm.value.to);
      if (wait && wait.error) {
        this.toast = this.toastCtrl.create({
          message: wait.error.error,
          duration: 3000,
          position: 'middle',
        }).then(toast => toast.present());
      } else {
        const message = {
          id: null,
          title: this.translate.instant("CODE-REQUEST-TITLE"),
          message: this.translate.instant("CODE-REQUEST-MESSAGE", {name: this.inviteForm.value.name}),
          status: 'new',
          type: 'codeRequest',
          sort: new Date().getTime(),
          uid: wait.ret.id,
          toEmail: this.inviteForm.value.to,
          fromEmail: this.inviteForm.value.from,
          fromName: this.inviteForm.value.name,
          data: null
        }
        await this.messagesProvider.addMessage(message);
        this.toast = this.toastCtrl.create({
          message: this.translate.instant("REQUESTED"),
          duration: 3000,
          position: 'bottom',
        }).then(toast => toast.present());
        this.navCtrl.navigateForward('signin');
      }
    }
  }

}
