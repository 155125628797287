import {Injectable, Injector} from '@angular/core';
import {QRScanner, QRScannerStatus} from "@awesome-cordova-plugins/barcode-scanner/ngx";

import {AlertController, Platform} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class QrCodeProvider {

  constructor(
    private injector: Injector,
    private platform: Platform,
    private alertCtrl: AlertController,
    private translate: TranslateService
  ) {
  }

  async showInvalidQR() {
    const confirm = await this.alertCtrl.create({
      header: this.translate.instant('ERROR'),
      message: this.translate.instant("INVALIDQRCODE"),
      buttons: [
        {
          text: this.translate.instant("OK"),
          handler: () => {
          }
        }
      ]
    });
    await confirm.present();
  }

  async acquirePermission(qrScanner: QRScanner) {
    const confirm = await this.alertCtrl.create({
      header: '',
      message: this.translate.instant("PERMISSIONREQUIRED"),
      buttons: [
        {
          text: this.translate.instant("CANCEL"),
          handler: () => {
            console.log('Disagree clicked');
          }
        },
        {
          text: this.translate.instant("GRANTPERMISSION"),
          handler: () => {
            qrScanner.openSettings();
          }
        }
      ]
    });
    await confirm.present();
  }

  async showStores() {
    const confirm = await this.alertCtrl.create({
      header: this.translate.instant('UNAVAILABLE'),
      message: this.translate.instant("OPENAPPINSTORE"),
      buttons: [
        {
          text: this.translate.instant("OK"),
          handler: () => {
            console.log('Opening app in store.');
            if(this.platform.is('ios'))  {
              window.open('https://apps.apple.com/us/app/variobend/id1569194122', '_system', 'location=yes');
            } else if(this.platform.is('android'))  {
              window.open('https://play.google.com/store/apps/details?id=de.asco.variobend', '_system', 'location=yes');
            }
          }
        },
        {
          text: this.translate.instant("CANCEL"),
          handler: () => {
            console.log('Operation cancelled.')
          }
        }
      ]
    });
    await confirm.present();
  }

}
