import {NgModule } from '@angular/core';
import {PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {ProfilesPage} from '../pages/profiles/profiles';
import {DrawboardPage} from '../pages/drawboard/drawboard';
import {LoginPage} from '../pages/login/login';
import {TutorialPage} from '../pages/tutorial/tutorial';
import {RegisterPage} from '../pages/register/register';
import {AgbPage} from '../pages/agb/agb';
import {ChangelogPage} from '../pages/changelog/changelog';
import {SettingsPage} from '../pages/settings/settings';
import {HelpPage} from '../pages/help/help';
import {AuthGuard} from './auth-guard.service';
import {DashboardPage} from '../pages/dashboard/dashboard';
import {MachinesPage} from '../pages/machines/machines';
import {SignInPage} from '../pages/signin/signin';
import {InvitePage} from '../pages/invite/invite';
import {MessagesPage} from '../pages/messages/messages';
import {LicensePage} from "../pages/license/license";
import {PrivacyPolicy} from '../pages/privacy-policy/privacy-policy';
import {ProjectsPage} from "../pages/projects/projects";
import {StatisticsPage} from '../pages/statistics/statistics';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'profiles',
    canActivate: [AuthGuard], // prevents activation of component, but not loading
    component: ProfilesPage
  },
  {
    path: 'draw',
    canActivate:[AuthGuard],
    component: DrawboardPage
  },
  {
    path: 'dashboard',
    canActivate:[AuthGuard],
    component: DashboardPage
  },
  {
    path: 'signin',
    canActivate: [AuthGuard],
    component: SignInPage
  },
  {
    path: 'login',
    canActivate: [AuthGuard],
    component: LoginPage
  },
  {
    path: 'invite',
    canActivate: [AuthGuard],
    component: InvitePage
  },
  {
    path: 'tutorial',
    canActivate:[AuthGuard],
    component: TutorialPage
  },
  {
    path: 'settings',
    // canActivate:[AuthGuard],
    component: SettingsPage
  },
  {
    path: 'machines',
    // canActivate:[AuthGuard],
    component: MachinesPage
  },
  {
    path: 'messages',
    canActivate:[AuthGuard],
    component: MessagesPage
  },
  {
    path: 'statistics',
    canActivate:[AuthGuard],
    component: StatisticsPage
  },
  {
    path: 'license',
    canActivate:[AuthGuard],
    component: LicensePage
  },
  {
    path: 'help',
    // canActivate:[AuthGuard],
    component: HelpPage
  },
  {
    path: 'agb',
    component: AgbPage
  },
  {
    path: 'changelog',
    component: ChangelogPage
  },
  {
    path: 'register',
    component: RegisterPage
  },
  {
    path: 'logout',
    canActivate: [AuthGuard],
    component: DashboardPage
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicy
  },
  {
    path: 'projects',
    canActivate: [AuthGuard],
    component: ProjectsPage
  },
  {
    path: '**',
    redirectTo: ''
  },

  //{
  //  path: 'test-page',
  //  loadChildren: () => import('./test-page/test-page.module').then( m => m.TestPagePageModule)
  //}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
