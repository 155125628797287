import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
/**
 * Generated class for the StatusPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: "status"
})
export class StatusPipe implements PipeTransform {
  filterTrans1: any;
  filterTrans2: any;
  filterTrans3: any;
  filterTrans4: any;
  filterTrans5: any;
  filterTrans6: any;
  filterTrans7: any;

  constructor(public trans: TranslateService) {
    this.trans.get("FILTER1").subscribe((res: string) => {
      this.filterTrans1 = res;
    });
    this.trans.get("FILTER2").subscribe((res: string) => {
      this.filterTrans2 = res;
    });
    this.trans.get("FILTER3").subscribe((res: string) => {
      this.filterTrans3 = res;
    });
    this.trans.get("FILTER4").subscribe((res: string) => {
      this.filterTrans4 = res;
    });
    this.trans.get("FILTER5").subscribe((res: string) => {
      this.filterTrans5 = res;
    });
    this.trans.get("FILTER6").subscribe((res: string) => {
      this.filterTrans6 = res;
    });
    this.trans.get("FILTER7").subscribe((res: string) => {
      this.filterTrans7 = res;
    });
  }
  /**
   * Takes a value and makes it lowercase.
   */
  transform(value: any, ...args) {
    if (value == 0) {
      return this.filterTrans1;
    }
    if (value == 1) {
      return this.filterTrans2;
    }
    if (value == 2) {
      return this.filterTrans3;
    }
    if (value == 3) {
      return this.filterTrans4;
    }
    if (value == 4) {
      return this.filterTrans5;
    }

    if (value == 5) {
      return this.filterTrans6;
    }

    if (value == 6) {
      return this.filterTrans7;  
    }
  }
}
