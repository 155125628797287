import {Component, ViewChild} from '@angular/core';
import {
  MenuController,
  NavController,
  ModalController,
  ToastController,
  // Content,
  Platform, AlertController, IonInput
} from '@ionic/angular';
import { ProfilesProvider } from '../../shared/providers/profiles/profiles';
import { ExportImportProvider } from '../../shared/providers/export-import/export-import';
import {Storage} from "@ionic/storage";
import {TranslateService} from "@ngx-translate/core";
import {CanvasBoardComponent} from "../../shared/components/canvas-board/canvas-board";
import {ActivatedRoute, Router} from "@angular/router";
import {TooltipProvider} from '../../shared/providers/tooltip/tooltip';
import {Utils} from 'src/shared/providers/utils/utils';
import {environment} from '../../environments/environment';

@Component({
  selector: "page-drawboard",
  templateUrl: "drawboard.html",
  styleUrls: ['drawboard.scss']
})
export class DrawboardPage {
  showInfo: any = false;
  share: any = false;
  code: string = null;
  // public devWidth = 0;
  @ViewChild(CanvasBoardComponent, {}) canvas: CanvasBoardComponent;
  @ViewChild('nameInput', {}) nameInput;
  @ViewChild('sheetlengthInput', {}) sheetlengthInput;
  @ViewChild('thicknessInput', {}) thicknessInput;
  @ViewChild('noofpiecesInput', {}) noofpiecesInput;
  @ViewChild('infoBtn', {}) infoBtn;
  @ViewChild('cancelBtn', {}) cancelBtn;

  @ViewChild("angle", {}) angle: IonInput;
  @ViewChild("distance", {}) distance: IonInput;
  @ViewChild("conicity", {}) conicity: IonInput;
  // @ViewChild('editEditBtn', null) editEditBtn;
  @ViewChild('drawEditBtn', {}) drawEditBtn;
  @ViewChild('editDrawBtn', {}) editDrawBtn;
  // @ViewChild('drawDrawBtn', null) drawDrawBtn;
  // @ViewChild('backDrawBtn', null) backDrawBtn;
  // @ViewChild('editbarDrawBtn', null) editbarDrawBtn;
  // @ViewChild('zoomInBtn', null) zoomInBtn;
  // @ViewChild('zoomOutBtn', null) zoomOutBtn;

  redrawTimer: any;
  materials: any[] = [];
  allColors: any[] = [];
  thicknessDisabled: any = false;
  optionNoColorDisabled: any= false;
  materialsInit: boolean=false;
  colorsInit:boolean=false;
  showPopover=true;
  isAnonymousUser: boolean=false;

  private orientationHandler = () => {
    //console.log('orientation ' + window.orientation + ', ' + this.platform.width() + 'x' + this.platform.height());
    clearTimeout(this.redrawTimer);
    this.redrawTimer = setTimeout(function(){this.canvas.onResize();}.bind(this), 500);
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public menu: MenuController, public navCtrl: NavController, public tooltipProvider: TooltipProvider,
    public modalCtrl: ModalController, public profileProvider: ProfilesProvider, public toastCtrl: ToastController,
    public translate: TranslateService, public platform: Platform, public alertController: AlertController,
    public exportImport: ExportImportProvider, /*public events: Events,*/ private storage: Storage) {
    this.storage.get('code').then((code) => {
      this.code = code;
    });
    CanvasBoardComponent.exportPDF = false;
    CanvasBoardComponent.sharePDF = false;
    this.route.queryParams.subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        if (this.router.getCurrentNavigation().extras.state.showInfo) {
          this.showInfo = this.router.getCurrentNavigation().extras.state.showInfo;
        }
        if (this.router.getCurrentNavigation().extras.state.share) {
          CanvasBoardComponent.sharePDF = this.router.getCurrentNavigation().extras.state.share;
        }
        if (this.router.getCurrentNavigation().extras.state.export) {
          CanvasBoardComponent.exportPDF = this.router.getCurrentNavigation().extras.state.export;
        }
      }
    });
    window.addEventListener('orientationchange', _ => this.orientationHandler(), false);
  }

  ngOnDestroy(): void {
    window.removeEventListener('orientationchange', _ => this.orientationHandler(), false);
  }

  ionViewWillEnter() {
    this.storage.get('whoami').then((whoami) => {
      if(!whoami || !whoami.id)
        this.isAnonymousUser = true;
      else if (this.profileProvider.currentFolder.id == undefined)
      {
        // create a new empty profile in default folder
        this.profileProvider.openDefaultFolder();
      }

      this.profileProvider.loadMaterial(whoami).subscribe(
      async (materials) => {
          materials.push({'id': "mat_alu", 'name': this.translate.instant('ALU'),'defaultMat': true});
          materials.push({'id': "mat_stainless", 'name': this.translate.instant('STAINLESS'),'defaultMat': true});
          materials.push({'id': "mat_copper", 'name': this.translate.instant('COPPER'),'defaultMat': true});
          materials.push({'id': "mat_steel", 'name': this.translate.instant('STEEL'),'defaultMat': true});
        if (materials.length === 0) {
          this.errorAlert(this.translate.instant("NOMATERIALS"));
        } else {
          this.materials = materials;
        }
          this.materialsInit = true;
          if (this.colorsInit) {
            this.initMaterialData(this.profileProvider.active.paintPosition, this.profileProvider.active.paintColor);
            this.canvas.UpdateColor();
            // workaround selection boxes not updating after loading
            setTimeout(function(){
              let paintColor = this.profileProvider.active.paintColor;
              let materialId = this.profileProvider.active.materialID;
              this.profileProvider.active.paintColor = "none";
              this.profileProvider.active.materialID = 'mat_alu';
              setTimeout(function(){
                if(paintColor)
                  this.profileProvider.active.paintColor = paintColor;
                if(materialId)
                  this.profileProvider.active.materialID = materialId;
                this.canvas.UpdateColor();
              }.bind(this), 400);
            }.bind(this), 100);
          }
        });
      this.profileProvider.loadColors(whoami).subscribe(
        async (colors) => {
          // if (colors.length === 0) {
          //   colors.push(this.translate.instant("none"));
          // }
          colors = colors.concat(this.getRalColors());
          this.allColors = colors;
          this.canvas.setColors(colors);
          this.colorsInit = true;
          if (this.materialsInit){
            this.initMaterialData(this.profileProvider.active.paintPosition, this.profileProvider.active.paintColor);
            this.canvas.UpdateColor();
            // workaround selection boxes not updating after loading
            setTimeout(function(){
              let paintColor = this.profileProvider.active.paintColor;
              let materialId = this.profileProvider.active.materialID;
              this.profileProvider.active.paintColor = "none";
              this.profileProvider.active.materialID = 'mat_alu';
              setTimeout(function(){
                this.profileProvider.active.paintColor = paintColor;
                this.profileProvider.active.materialID = materialId;
                this.canvas.UpdateColor();
              }.bind(this), 400);
            }.bind(this), 100);
          }
        });
    });
  }

  async handlePopover(_event: { showPopover: boolean, nextElement: string }) {
   this.showPopover = _event.showPopover;
   if (_event.nextElement && _event.nextElement !== null) {
     if ( _event.nextElement === 'cancelBtn') {
       this.cancelBtn.el.click();
     }
   }
  }

  ngOnInit() {
    this.showInfo = this.showInfo || Utils.isB2B;
    if(this.showInfo) {
      this.information(null);
      this.showInfo = true;
    }
  }

  async ionViewDidEnter() {
    this.menu.enable(false);

    this.canvas.calcCutCallback = this.calcCut;
    if (Utils.autosave && Utils.isB2B && this.profileProvider.active.status2 == 'derived') {
      // generate profile preview for b2b and issue finishedDrawing call
      this.canvas.showPleaseWait();
      setTimeout(async ()=> {
          this.calcCut();
          //await (await this.toastCtrl
          //  .create({
          //    message: this.translate.instant("PROFILESAVE"),
          //    duration: 3000,
          //    position: "bottom"
          //  }))
          //  .present();
          this.profileProvider.save();
          this.profileProvider.finishedDrawing(null);
        },
        50);
    } else {
      if (this.profileProvider.active && !this.profileProvider.active.data) {
        await this.tooltipProvider.showPopover(TooltipProvider.START_TOOLTIP, 'START');
      }
      this.showPopover = false;
    }



  }

  ionViewDidLeave() {
    this.menu.enable(true);
  }

  async errorAlert(message: string) {
    const alert = await this.alertController.create({
      header: this.translate.instant("ATTENTION"),
      subHeader: this.translate.instant("UNEXPECTEDERROR"),
      message: message,
      buttons: ['Cancel']
    });
    alert.present();
  }

  async information($ev) {
    this.calcCut();
    this.showInfo = !this.showInfo;
    /* unused
    if (this.showPopover) {
      if (this.canvas.modus === 'edit') {
        this.showPopover = await this.tooltipProvider.showPopover(TooltipProvider.DRAWBOARD_PAGE, 'INFO', $ev, 2000, this.canvas.drawEditBtn.el);
      } else if (this.canvas.modus === 'draw') {
        this.showPopover = await this.tooltipProvider.showPopover(TooltipProvider.DRAWBOARD_PAGE, 'INFO', $ev, 2000, this.canvas.editDrawBtn.el);
      }
    } else {
      this.calcCut();
      this.showInfo = !this.showInfo;
    }*/
  }

  calcCut(){
    if (this.profileProvider.active.data) {
      let item = this.profileProvider.active.data;
      this.profileProvider.active.cut = 0;
      for (let i = 0; i < item.length; i++) {
        if (item[i].distance)
          this.profileProvider.active.cut += item[i].distance.distance;
      }
    }
  }

  saveinfos(number = null) {
    let paintPosition = this.profileProvider.active.paintPosition;
    let paintColor = this.profileProvider.active.paintColor;
    if (number === 1) {
      // material changed
      this.initMaterialData(paintPosition, paintColor);
    } else if (number === 4) {
      // paint position changed
      if (paintPosition === "none" && paintColor && paintColor !== "none") {
        this.profileProvider.active.paintColor = "none";
      }
    } else if (number === 5) {
      // paint color changed
      if ((!paintPosition || paintPosition === "none" || Array.isArray(paintPosition)) && paintColor && paintColor !== "none")
        this.profileProvider.active.paintPosition = "top";
      if (paintColor == "none")
        this.profileProvider.active.paintPosition = "none";
    }
    if(!this.isAnonymousUser)
      this.profileProvider.save();
    if (number === 4 || number === 5 || number === 1) {
      this.canvas.UpdateColor();
      // this.events.publish("draw:line");
    }
    setTimeout(()=> {
      for (let i = 0; i < this.profileProvider.active.width.length; i++) {
        let sheetLen = this.profileProvider.active.width[i];
        if (sheetLen < 0)
          sheetLen *= -1;
        if (sheetLen < 100)
          sheetLen *= 1000;
        this.profileProvider.active.width[i] = sheetLen;
      }
    }, 4000);
  }

  private initMaterialData(paintPosition, paintColor) {

    let materialID = this.profileProvider.active.materialID;
    if ((!materialID || Array.isArray(materialID)) && Array.isArray(this.profileProvider.active.materialName) && this.profileProvider.active.materialName.length > 0) {
      // backwards compatibility
      let actualMat = this.materials.find((i) => i.id === this.profileProvider.active.materialName[0] || i.name === this.profileProvider.active.materialName[0]);
      if (actualMat) {
        materialID = actualMat.id;
        this.profileProvider.active.materialID = materialID;
      }
    }

    if (materialID) {
      const material = this.materials.find((i) => i.id === materialID);
      if (material) {
        this.profileProvider.active.materialName = material.name;
        if (material.thickness) {
          this.profileProvider.active.materialThickness = material.thickness;
          this.thicknessDisabled = true;
        } else {
          this.thicknessDisabled = false;
        }
        if (material.colorNames && material.colorNames.length > 0) {
          if (!material.colorNames.includes(this.profileProvider.active.paintColor)) {
            this.profileProvider.active.paintColor = material.colorNames[0];
            if (!paintPosition || paintPosition === "none")
              this.profileProvider.active.paintPosition = "top";
            if (this.profileProvider.active.paintColor === "none")
              this.profileProvider.active.paintPosition = "none";
          }
          this.optionNoColorDisabled = !material.colorNames.includes("none") || (Utils.isB2B && paintColor != "none");
          this.canvas.colors = this.allColors.filter(x => material.colorNames.includes(x.name));
        } else {
          // default material
          this.canvas.colors = this.allColors;
          this.optionNoColorDisabled = false;
          if (!paintPosition)
            this.profileProvider.active.paintPosition = "none";
          if (!paintColor)
            this.profileProvider.active.paintColor = "none";
        }
      } else {
        this.errorAlert(this.translate.instant("NOMATERIALS"));
      }
    }

    //this.initMaterialData(this.profileProvider.active.paintPosition, this.profileProvider.active.paintColor);
  }

  async cancel($ev) {
    if (this.showPopover) {
      await this.tooltipProvider.showPopover(TooltipProvider.FINISH_TOOLTIPS, 'SAVE', $ev);
      this.showPopover = false;
      return;
    } else if (!this.isAnonymousUser) {
      if (!this.profileProvider.active.data ||
        (this.profileProvider.active.data &&
          this.profileProvider.active.data.length < 2)) {
        await (await this.toastCtrl
          .create({
            message: this.translate.instant("PROFILEINCOMPLETE"),
            duration: 3000,
            position: "bottom"
          }))
          .present();
        if(!Utils.isB2B)
            this.profileProvider.delete(this.profileProvider.active);
      } else {
        this.canvas.showPleaseWait(Utils.isB2B && environment.production ? null : 1000);
        this.calcCut();
        await (await this.toastCtrl
          .create({
            message: this.translate.instant("PROFILESAVE"),
            duration: 3000,
            position: "bottom"
          }))
          .present();
        this.profileProvider.save();
        if ((this.profileProvider.active.status2 == 'derived' && Utils.isB2B) ||
          (this.profileProvider.active.status2 == 'template' && Utils.hasB2BLicense)) {
          let png = await this.canvas.getProfilePng();
          this.profileProvider.finishedDrawing(png);
        }
      }
    } else {
      await (await this.toastCtrl
        .create({
          message: this.translate.instant("SAVINGREQUIRESLICENSE"),
          duration: 3000,
          position: "bottom"
        }))
        .present();
    }

    if(!Utils.isB2B)
      this.navCtrl.back(); //setRoot(ProfilesPage, { close: 1 });
  }

  checkFocus(ev) {
    switch (ev) {
      case 'name':
        this.nameInput.getInputElement().then((el)=>el.select());
        break;
      case 'length':
        this.sheetlengthInput.getInputElement().then((el)=>el.select());
        break;
      case 'thickness':
        this.thicknessInput.getInputElement().then((el)=>el.select());
        break;
      case 'noOfPieces':
        this.noofpiecesInput.getInputElement().then((el)=>el.select());
        break;
      case 'angle':
        this.angle.getInputElement().then((el) => el.select());
        break;
      case 'distance':
        this.distance.getInputElement().then((el) => el.select());
        break;
      case 'conicity':
        this.conicity.getInputElement().then((el) => el.select());
        break;
      default:
        break;
    }
  }

  isWaiting(): boolean {
    let waiting = false;
    if (this.profileProvider.active.status == 1 && this.profileProvider.active.changes == 1) {
      return true;
    }
    return waiting;
  }

  isReleaseable(): boolean {
    let releaseable = false;
    if ((this.profileProvider.active.status == 1 && this.profileProvider.active.changes == 0) ||
        this.profileProvider.active.status == 3) {
      if (this.profileProvider.active.code !== undefined && this.profileProvider.active.code !== null
        && this.profileProvider.active.status == 3) {
        if (this.code !== undefined && this.code !== null) {
          return this.code === this.profileProvider.active.code;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
    return releaseable;
  }

  corrected() {
    this.canvas.showPleaseWait(1000);

    this.profileProvider.active.status = 3;
    this.profileProvider.save();
    this.navCtrl.back(); //setRoot(ProfilesPage, { close: 1 });
  }

  declined() {
    this.canvas.showPleaseWait(1000);

    this.profileProvider.active.status = 5;
    this.profileProvider.save();
    this.navCtrl.back(); //setRoot(ProfilesPage, { close: 1 });
  }

  ok() {
    this.canvas.showPleaseWait(1000);

    this.exportImport.exportRequest();
    this.navCtrl.back(); //setRoot(ProfilesPage, { close: 1 });
  }

  closeKeyboard() {
    if (document.activeElement instanceof HTMLElement) {
      (document.activeElement as HTMLElement).blur();
    }
  }
  getRalColors(){
    var ralColors={
      "1000": "#CDBA88", "1001": "#D0B084", "1002": "#D2AA6D", "1003": "#F9A800", "1004": "#E49E00", "1005": "#CB8E00", "1006": "#E29000", "1007": "#E88C00",
      "1011": "#AF804F", "1012": "#DDAF27", "1013": "#E3D9C6", "1014": "#DDC49A", "1015": "#E6D2B5", "1016": "#F1DD38", "1017": "#F6A950", "1018": "#FACA30",
      "1019": "#A48F7A", "1020": "#A08F65", "1021": "#F6B600", "1023": "#F7B500", "1024": "#BA8F4C", "1026": "#FFFF00", "1027": "#A77F0E", "1028": "#FF9B00",
      "1032": "#E2A300", "1033": "#F99A1C", "1034": "#EB9C52", "1035": "#908370", "1036": "#80643F", "1037": "#F09200", "2000": "#DA6E00", "2001": "#BA481B",
      "2002": "#BF3922", "2003": "#F67828", "2004": "#E25303", "2005": "#FF4D06", "2007": "#FFB200", "2008": "#ED6B21", "2009": "#DE5307", "2010": "#D05D28",
      "2011": "#E26E0E", "2012": "#D5654D", "2013": "#923E25", "3000": "#A72920", "3001": "#9B2423", "3002": "#9B2321", "3003": "#861A22", "3004": "#6B1C23",
      "3005": "#59191F", "3007": "#3E2022", "3009": "#6D342D", "3011": "#792423", "3012": "#C6846D", "3013": "#972E25", "3014": "#CB7375", "3015": "#D8A0A6",
      "3016": "#A63D2F", "3017": "#CB555D", "3018": "#C73F4A", "3020": "#BB1E10", "3022": "#CF6955", "3024": "#FF2D21", "3026": "#FF2A1B", "3027": "#AB273C",
      "3028": "#CC2C24", "3031": "#A63437", "3032": "#701D23", "3033": "#A53A2D", "4001": "#816183", "4002": "#8D3C4B", "4003": "#C4618C", "4004": "#651E38",
      "4005": "#76689A", "4006": "#903373", "4007": "#47243C", "4008": "#844C82", "4009": "#9D8692", "4010": "#BC4077", "4011": "#6E6387", "4012": "#6B6B7F",
      "5000": "#314F6F", "5001": "#0F4C64", "5002": "#00387B", "5003": "#1F3855", "5004": "#191E28", "5005": "#005387", "5007": "#376B8C", "5008": "#2B3A44",
      "5009": "#225F78", "5010": "#004F7C", "5011": "#1A2B3C", "5012": "#0089B6", "5013": "#193153", "5014": "#637D96", "5015": "#007CB0", "5017": "#005B8C",
      "5018": "#058B8C", "5019": "#005E83", "5020": "#00414B", "5021": "#007577", "5022": "#222D5A", "5023": "#42698C", "5024": "#6093AC", "5025": "#21697C",
      "5026": "#0F3052", "6000": "#3C7460", "6001": "#366735", "6002": "#325928", "6003": "#50533C", "6004": "#024442", "6005": "#114232", "6006": "#3C392E",
      "6007": "#2C3222", "6008": "#37342A", "6009": "#27352A", "6010": "#4D6F39", "6011": "#6C7C59", "6012": "#303D3A", "6013": "#7D765A", "6014": "#474135",
      "6015": "#3D3D36", "6016": "#00694C", "6017": "#587F40", "6018": "#61993B", "6019": "#B9CEAC", "6020": "#37422F", "6021": "#8A9977", "6022": "#3A3327",
      "6024": "#008351", "6025": "#5E6E3B", "6026": "#005F4E", "6027": "#7EBAB5", "6028": "#315442", "6029": "#006F3D", "6032": "#237F52", "6033": "#46877F",
      "6034": "#7AACAC", "6035": "#194D25", "6036": "#04574B", "6037": "#008B29", "6038": "#00B51A", "7000": "#7A888E", "7001": "#8C969D", "7002": "#817863",
      "7003": "#7A7669", "7004": "#9B9B9B", "7005": "#6C6E6B", "7006": "#766A5E", "7008": "#745E3D", "7009": "#5D6058", "7010": "#585C56", "7011": "#52595D",
      "7012": "#575D5E", "7013": "#575044", "7015": "#4F5358", "7016": "#383E42", "7021": "#2F3234", "7022": "#4C4A44", "7023": "#808076", "7024": "#45494E",
      "7026": "#374345", "7030": "#928E85", "7031": "#5B686D", "7032": "#B5B0A1", "7033": "#7F8274", "7034": "#92886F", "7035": "#C5C7C4", "7036": "#979392",
      "7037": "#7A7B7A", "7038": "#B0B0A9", "7039": "#6B665E", "7040": "#989EA1", "7042": "#8E9291", "7043": "#4F5250", "7044": "#B7B3A8", "7045": "#8D9295",
      "7046": "#7F868A", "7047": "#C8C8C7", "7048": "#817B73", "8000": "#89693E", "8001": "#9D622B", "8002": "#794D3E", "8003": "#7E4B26", "8004": "#8D4931",
      "8007": "#70452A", "8008": "#724A25", "8011": "#5A3826", "8012": "#66332B", "8014": "#4A3526", "8015": "#5E2F26", "8016": "#4C2B20", "8017": "#442F29",
      "8019": "#3D3635", "8022": "#1A1718", "8023": "#A45729", "8024": "#795038", "8025": "#755847", "8028": "#513A2A", "8029": "#7F4031", "9001": "#E9E0D2",
      "9002": "#D7D5CB", "9003": "#ECECE7", "9004": "#2B2B2C", "9005": "#0E0E10", "9006": "#A1A1A0", "9007": "#878581", "9010": "#F1ECE1", "9011": "#27292B",
      "9016": "#F1F0EA", "9017": "#2A292A", "9018": "#C8CBC4", "9022": "#858583", "9023": "#797B7A"};
    let defaultColors=[];
    for (var key in ralColors){
      defaultColors.push({id: "ral" + key, name: "RAL" + key, rgb: ralColors[key]});
    }
    return defaultColors;
  }

  logo() {
    return Utils.isB2B && Utils.b2bInstance !== undefined && Utils.b2bInstance !== null ?
      'assets/imgs/' + Utils.b2bInstance.logo : 'assets/imgs/logo_variobend.png';
  }

  isB2B(details: string = '') {
    if(Utils.isB2B && Utils.b2bInstance !== undefined && Utils.b2bInstance !== null) {
      document.body.style.setProperty('--ion-color-primary', Utils.b2bInstance.colors.primary);
      document.body.style.setProperty('--ion-color-dark', Utils.b2bInstance.colors.dark);
      document.body.style.setProperty('--ion-color-background', Utils.b2bInstance.colors.background);
      if(details == 'drawInfo') {
        return Utils.b2bInstance.hideDrawingInfo;
      }
    }
    return Utils.isB2B;
  }
}


