import { ChangelogPage } from './../pages/changelog/changelog';
import { PreviewComponent } from '../shared/components/preview/preview';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { IonicModule, IonicRouteStrategy} from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { AppComponent } from './app.component';
import { DrawboardPage } from '../pages/drawboard/drawboard';
import { TutorialPage } from '../pages/tutorial/tutorial';
import { LoginPage } from '../pages/login/login';
import { RegisterPage } from '../pages/register/register';
import { ProfilesPage } from '../pages/profiles/profiles';
import { FolderDetailsPage } from '../pages/folder-details/folder-details';
import { SettingsPage } from '../pages/settings/settings';
import { HelpPage } from '../pages/help/help';
import { AgbPage } from '../pages/agb/agb';
import { CanvasBoardComponent } from '../shared/components/canvas-board/canvas-board';
import { RequestProvider } from '../shared/providers/request/request';
import { AuthProvider } from '../shared/providers/auth/auth';
import { ProfilesProvider } from '../shared/providers/profiles/profiles';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExportImportProvider } from '../shared/providers/export-import/export-import';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from '../environments/environment';
import { CalculateProvider } from '../shared/providers/calculate/calculate';
import { PipesModule } from '../shared/pipes/pipes.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { VersionProvider } from '../shared/providers/version/version';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';
import { AppRoutingModule } from "./app-routing.module";
import { ServiceWorkerModule } from '@angular/service-worker';
import { TooltipComponent } from '../shared/components/tooltip/tooltip';
import { TooltipProvider } from '../shared/providers/tooltip/tooltip';
import { DashboardPage } from "../pages/dashboard/dashboard";
import { ThreeDPreviewComponent } from '../shared/components/threed-preview/threed-preview';
import { ThreedProvider } from '../shared/providers/threed/threed';
import { MoreOptionsComponent } from '../shared/components/more-options/more-options';
import { PdfViewComponent } from '../shared/components/pdf-view/pdf-view.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { SplashScreen} from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { MachinesPage } from '../pages/machines/machines';
import {QRScanner} from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { SignInPage } from '../pages/signin/signin';
import { InvitePage } from '../pages/invite/invite';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { MessagesPage } from '../pages/messages/messages';
import { MessagesProvider } from '../shared/providers/messages/messages';
import { MomentModule } from 'ngx-moment';
import { MailProvider } from '../shared/providers/mails/mails';
import { AddressProvider } from '../shared/providers/addresses/addresses';
import { AddressPage } from '../shared/components/address/address';
import { LicensePage} from "../pages/license/license";
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { PrivacyPolicy } from '../pages/privacy-policy/privacy-policy';
import { ProjectsPage } from "../pages/projects/projects";
import { StatisticsPage } from '../pages/statistics/statistics';
import { StatisticsProvider } from '../shared/providers/statistics/statistics';
import { LineChartComponent } from '../shared/components/line-chart/line-chart.component';
import { QRCodeModule } from 'angularx-qrcode';
import { QrCodeProvider } from '../shared/providers/qrcode/qrcode';
import { MachinesProvider } from '../shared/providers/machines/machines';
import { MachineCanvasComponent } from '../shared/components/machine-canvas/machine-canvas';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
    declarations: [
        AppComponent,
        DashboardPage,
        DrawboardPage,
        PreviewComponent,
        TooltipComponent,
        MoreOptionsComponent,
        TutorialPage,
        SignInPage,
        LoginPage,
        InvitePage,
        ProfilesPage,
        ProjectsPage,
        FolderDetailsPage,
        MachinesPage,
        SettingsPage,
        MessagesPage,
        LicensePage,
        HelpPage,
        AgbPage,
        PrivacyPolicy,
        CanvasBoardComponent,
        ThreeDPreviewComponent,
        PdfViewComponent,
        ChangelogPage,
        RegisterPage,
        AddressPage,
        StatisticsPage,
        LineChartComponent,
        MachineCanvasComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule.enablePersistence({ synchronizeTabs: true }),
        AngularFireAuthModule,
        AngularFireMessagingModule,
        BrowserAnimationsModule,
        // HttpModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        PipesModule,
        //  PinchZoomModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        PdfJsViewerModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        MomentModule,
        QRCodeModule
    ],
    bootstrap: [AppComponent],
    providers: [
        // { provide: ErrorHandler, useClass: IonicErrorHandler },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        FirebaseX,
        RequestProvider,
        AuthProvider,
        ProfilesProvider,
        TooltipProvider,
        ThreedProvider,
        ExportImportProvider,
        MessagesProvider,
        MailProvider,
        AddressProvider,
        StatisticsProvider,
        MachinesProvider,
        QrCodeProvider,
        // File,
        CalculateProvider,
        VersionProvider,
        // Cordova
        SplashScreen,
        StatusBar,
        QRScanner,
        Device,
        SocialSharing,
        InAppBrowser
    ]
})
export class AppModule {}
