
export class Languages {

  static exists(lang: any): boolean {

    if (lang) {
      let availableLangs:string[] = ['de', 'en', 'sl', 'pl'];
      return availableLangs.includes(lang);
    }

    return false;
  }

}
