<ion-content class="ion-text-center">
  <ion-grid>
    <ion-row class="ion-margin-top">
      <ion-col>
          <img src="assets/imgs/logo.svg" width="400" class="ion-margin-top">
      </ion-col>
    </ion-row>
    <ion-row *ngIf="showUser">
      <ion-col size-md="4" offset-md="4">
        <form id="login" [formGroup]="loginForm">
          <ion-item lines="none" class="ion-no-padding">
            <ion-label position="stacked">{{'LOGIN'| translate}}</ion-label>
            <ion-input type="email" placeholder="" name="email" placeholder="{{'USERNAME'| translate}}"
                       [formControl]="loginForm.controls['email']"></ion-input>
          </ion-item>
          <ion-item *ngIf="!showCode" lines="none" class="ion-no-padding ion-padding-top">
            <ion-label position="stacked">{{'PASSWORD'| translate}}</ion-label>
            <ion-input type="password" placeholder="" name="password" placeholder="***************"
                       [formControl]="loginForm.controls['password']"
                       (keyup.enter)="loginSubmit()" ></ion-input>
          </ion-item>
        </form>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="showUser && !showCode">
      <ion-col>
        <ion-button color="primary" [disabled]="!loginForm.valid" (click)="loginSubmit()">
          {{'LOGIN'| translate}}
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="showUser && !showCode">
      <ion-col size-md="4" offset-md="4">
        <a button class="ion-margin-bottom" target="_system" href="javascript:;" (click)="resetPassword()">{{'RESETPASSWORD'| translate}}</a>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="showCode">
      <ion-col size-md="4" offset-md="4">
        <form id="code" [formGroup]="codeForm">
          <ion-item lines="none" class="ion-no-padding">
            <ion-label position="stacked">{{'CODEWITH'| translate}}</ion-label>
            <ion-input type="text" placeholder="" name="code"
                       [formControl]="codeForm.controls['code']"
                       (keyup.enter)="codeSubmit()">
            </ion-input>
          </ion-item>
        </form>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="showCode">
      <ion-col>
        <ion-button color="primary" [disabled]="!codeForm.valid" (click)="codeSubmit()">
          {{'REGISTER'| translate}}
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-md="4" offset-md="4">
        <a button class="ion-margin-bottom" href="signin">{{'BACK'| translate}}</a>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
