import {Component, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {AlertController, IonInput, LoadingController, ModalController, NavController, Platform, PopoverController} from '@ionic/angular';
import {ProfilesProvider} from '../../providers/profiles/profiles';
import {TranslateService} from '@ngx-translate/core';
import 'fabric-with-gestures';
import * as THREE from 'three';
import {Storage} from "@ionic/storage";
import {jsPDF} from "jspdf";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import autoTable from "jspdf-autotable";
import 'svg2pdf.js'
import {TooltipProvider} from '../../providers/tooltip/tooltip';
import {ThreeDPreviewComponent} from '../threed-preview/threed-preview';
import {MoreOptionsComponent} from "../more-options/more-options";
import {PdfViewComponent} from '../pdf-view/pdf-view.component';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import {Utils} from "../../providers/utils/utils";

declare const pdfjsLib:any;
declare let fabric: any;

@Component({
  selector: "canvas-board",
  templateUrl: "canvas-board.html",
  styleUrls: ['canvas-board.scss']
})
export class CanvasBoardComponent {
  canvas: any;
  mouseMove: any = false;
  dashboardSize = 1500;
  starting: any = false;
  changeValues: any = false;
  changeValues2: any = false;
  isDown: any;
  currentObj: any;
  modus: any = "draw";
  editbar: any = 3;
  canvasStatus: any = 0;
  code: string = null;
  isAnonymousUser: boolean = false;

  lengthFactor: any = 1;
  showUndo: any = false;
  gridPath: any;
  cornerRadius = 15;
  zoomStart = 2;

  width: any = 150;
  height: any = 150;
  fontsize: any = 9;
  anglesize: any = 7;
  gridSize: any = 5;
  distancePP: any;
  radiusStart: any;
  strokeWidth: any;
  strokeWidthPlus: any;
  scaleX: any;
  scaleY: any;
  txtScale: any = 1;
  pullpointWidth: any;
  sizeTyp: any;
  stehAngleDistance: any = 8;
  strokeWidthGrid: any;

  iterator = -1;
  gridIterator: any;
  pointer: any = { x: 0, y: 0 };
  startPoint: any;
  currentParallelPoint: any;
  points: any = [];
  pointsTxt: any = [];
  oldPoints: any = [];

  txtObjs: any = [];
  lineObjs: any = [];
  cornerObjs: any = [];
  pointObjs: any = [];
  trapezObjs: any = [];
  angleObjs: any = [];
  currentCorner: any;
  currentTxt: any;
  currentPath: any;
  pathShadow: any;
  currentTrapez: any;
  currentStehfalz: any;
  currentAngleTxt: any;
  triangle: any;
  triangleWidth: any;
  triangleDif: any;
  triangleHeight: any;
  // ralColor: any = [];
  public colors: any[] = [];

  //Groups
  objGroup: any;

  //ZOOM VARS
  zoomLevel = 2;
  zoomLevelMin = 2;
  zoomLevelMax = 30;
  lastXTouch = 0;
  lastYTouch = 0;
  lastDownTime = 0;
  lastRotation: any;
  fingerCount = 0;
  pinch: any;
  redrawTimer: any;
  isRedrawing = false;
  centerPoint: any;
  isDragging = false;

  //EDIT MODUS
  currentIterator: any;
  currentValue: any; //angle
  currentValue2: any; // length
  currentValue3: any; //conical

  pointTest: any;

  activeObject: any;
  lastActiveObject: any;
  angleTxtPadding = 8;
  lengthTxtPadding = 6;

  profileColor = "#EF9348";
  // isProfileInfoVisible: any = false;
  showSVG: any = false;
  public static exportPDF: any = false;
  public static sharePDF: any = false;

  svg:SafeHtml;
  @Input("showPopover") showPopover: boolean = false;
  @Output() public showTooltip: EventEmitter<{
    showPopover: boolean,
    nextElement: string
  }> = new EventEmitter<{ showPopover: boolean, nextElement: string }>();
  @ViewChild(ThreeDPreviewComponent, {}) threeDPreview: ThreeDPreviewComponent;

  @Input("angle") angle: IonInput;
  @Input("distance") distance: IonInput;
  @Input("drawEditBtn") drawEditBtn;
  @Input("editDrawBtn") editDrawBtn;

  calcCutCallback: () => void;

  // @ViewChild("angle", null) angle: IonInput;
  // @ViewChild("distance", null) distance: IonInput;
  // @ViewChild("conicity", null) conicity: IonInput;
  // @ViewChild('editEditBtn', null) editEditBtn;
  // @ViewChild('drawEditBtn', null) drawEditBtn;
  // @ViewChild('editDrawBtn', null) editDrawBtn;
  // @ViewChild('drawDrawBtn', null) drawDrawBtn;
  // @ViewChild('backDrawBtn', null) backDrawBtn;
  // @ViewChild('editbarDrawBtn', null) editbarDrawBtn;
  // @ViewChild('zoomInBtn', null) zoomInBtn;
  // @ViewChild('zoomOutBtn', null) zoomOutBtn;

  constructor(private sanitizer: DomSanitizer, public platform: Platform, public profileProvider: ProfilesProvider, public alertCtrl: AlertController,
              public popoverCtrl: PopoverController, public loadingCtrl: LoadingController, public navCtrl: NavController, public translate: TranslateService,
              public tooltipProvider: TooltipProvider, public modalCtrl: ModalController, private storage: Storage, private socialSharing: SocialSharing) {
    //events.subscribe("draw:paint", () => {
    //  //console.log("REDRAW DRAW PAINT");
    //  this.reDraw();
    //});
    //events.subscribe("draw:line", () => {
    //  //console.log("REDRAW DRAW LINE");
    //  if(this.points.length > 0) {
    //    this.canvas.remove(this.triangle);
    //    this.canvas.remove(this.pathShadow);
    //    this.drawFlullLine();
    //  }
    //});
    //events.subscribe("show:profileInfo", (isShown) => {
    //  //console.log("REDRAW DRAW PAINT");
    //  this.isProfileInfoVisible = isShown;
    //});
    this.storage.get('code').then((code) => {
      this.code = code;
    });
    this.storage.get('whoami').then((whoami) => {
      if (!whoami || !whoami.id)
        this.isAnonymousUser = true;
    });
    /*if (this.platform.is('ipad') || this.platform.is('iphone')) {
      window.addEventListener('keyboardDidShow', () => {
        setTimeout(() => {
          const activeEle: any = document.activeElement;
          alert(activeEle.type);
          if (activeEle.type === 'input') {
            activeEle.scrollIntoView();
          }
        }, 200);
      });
    }*/
  }

  public setColors(colors: any) {
    this.colors = colors;
    this.threeDPreview.setColors(colors);
  }

  public UpdateColor(){
    if(this.points.length > 0) {
      this.canvas.remove(this.triangle);
      this.canvas.remove(this.pathShadow);
      this.drawFlullLine();
      /* threeD drawing */
      this.threeDPreview.animate();
    }
  }

  ngAfterViewInit() {

    let createCanvasAndRegisterHandlers = !this.canvas;
    if (createCanvasAndRegisterHandlers)
      this.canvas = new fabric.Canvas("canvas", { selection: false});

    this.setInit();
    this.canvas.setHeight(this.platform.height());
    this.canvas.setWidth(this.platform.width());

    this.centerPoint = new fabric.Point(
      this.canvas.getWidth() / 2,
      this.canvas.getHeight() / 2
    );

    this.showSVG = CanvasBoardComponent.exportPDF || CanvasBoardComponent.sharePDF; //this.profileProvider.active.makeScreenshot;
    if (!this.showSVG)
      this.drawGrid();

    this.canvasStatus = this.profileProvider.active.status;
    if (this.canvasStatus > 0) {
      this.editbar = 1;
      this.modus = "edit";
    }

    let importFromXML = this.profileProvider.jsonItem !== null;
    let profileNotEmpty = this.profileProvider.active.data && this.profileProvider.active.data.length > 0;

    if (importFromXML || profileNotEmpty) {


      if(importFromXML) {
        //console.log("Canvas calculate csv2: "+this.profileProvider.jsonItem)
        try {
          //this.profileProvider.active.paint[this.profileProvider.index] = this.profileProvider.jsonItem.Paint;
          this.points = this.convertXMLtoPoints(this.profileProvider.jsonItem);
          let originalPoints = this.points;
          this.profileProvider.jsonItem = null;
          this.reDraw(true);
          this.zoomToPath();
          this.reDraw(true);

          let idx = 0;
          for (let point of this.points) {
            this.currentIterator = idx;
            if (point.angle && idx > 1) {
              let angle = originalPoints[idx].test;
              if(angle < 0) {
                this.points[this.currentIterator].angle.pP = 1;
                this.points[this.currentIterator].angle.angleRaw = 180 - Math.abs(angle);
              }
              else {
                this.points[this.currentIterator].angle.pP = -1;
                this.points[this.currentIterator].angle.angleRaw = 180 + angle;
              }
              angle = Math.abs(angle);
              if (point.angle.stehfalz >= 0) {
                if (originalPoints[idx].IsWaterFold == 'true') {
                  this.currentValue = 0;
                } else if (angle >= 178) { // >= 178 175
                  this.currentValue = 1;
                } else if (angle >= 171) { // >= 171 165
                  this.currentValue = 2;
                } else if (angle >= 163) { // >= 163 161
                  this.currentValue = 3;
                } else if (angle >= 158) { // >= 158 155
                  this.currentValue = 4;
                } else if (angle >= 151) { // >= 151 146
                  this.currentValue = 5;
                } else if (angle >= 146) { // >= 146 143
                  this.currentValue = 6;
                } else if (angle >= 141) { // >= 141 141
                  this.currentValue = 7;
                } else {
                  this.currentValue = angle;
                }
              } else {
                this.currentValue = angle;
              }
              this.changeAngle();
            }
            if (point.distance && idx > 1 && originalPoints[idx].distance && originalPoints[idx].distance.conicity) {
              point.distance.conicity = originalPoints[idx].distance.conicity;
            }
            idx++;
          }

          this.save();
          //this.reDraw();
        } catch (e) {
          this.errorAlert(e.toString());
        }
      } else {
        this.points = this.profileProvider.active.data;

        for (let i = 0; i < this.points.length; i++) {
          this.points[i].x = this.points[i].saveX / (this.width / this.canvas.width);
          this.points[i].y = this.points[i].saveY / (this.height / this.canvas.height);
          if (this.points[i].distance) {
            this.points[i].distance.distanceRaw =
              this.points[i].distance.distance / this.lengthFactor;
          }
        }

        this.reDraw();
        this.zoomToPath();
        this.redrawTimer = setTimeout(function(){this.reDraw();}.bind(this), 50);
      }
      // this.setZoomLevel();
      // this.zoomWithButton(false);
      this.modus = "edit";
      if (this.showPopover && !Utils.isB2B) {
        setTimeout(() => { this.drawEditBtn.el.click(); }, 50);
      }
    } else {
      this.zoomLevel = this.canvas.getZoom() * this.zoomStart;
      this.setZoomLevel();
      this.reDraw();
      this.canvas.zoomToPoint(this.centerPoint, this.zoomLevel);
    }

    if (CanvasBoardComponent.exportPDF || CanvasBoardComponent.sharePDF) {//(this.profileProvider.active.makeScreenshot) {
      this.loadingCtrl.create({
        message: this.translate.instant("PLEASEWAIT")
      }).then((loading) => {
        loading.present();

        setTimeout(() => {
          try {
            this.exportProfile();
          } finally {
            loading.dismiss();
            this.goTo();
          }
        }, 2000);
      });

    }

    if(createCanvasAndRegisterHandlers) {
      this.gestures();
      this.onSelections();
    }
  }

  async exportProfile() {
    if (this.currentPath) {
      // create svg
      this.profileProvider.active.image = this.canvas.toSVG({
        suppressPreamble: true,
        viewbox: {
          x: (this.currentPath.left - this.currentPath.width * 0.1),
          y: (this.currentPath.top - this.currentPath.height * 0.1),
          width: (this.currentPath.width + 0.2 * this.currentPath.width),
          height: (this.currentPath.height + 0.2 * this.currentPath.height),
        }
      });

      this.svg = this.sanitizer.bypassSecurityTrustHtml(this.profileProvider.active.image);

      let loadCtrlInst = await this.loadingCtrl.create({
        message: this.translate.instant("PLEASEWAIT")
      });
      await loadCtrlInst.present();

      // create pdf
      let doc = new jsPDF({unit: "pt"});
      const element2 = document.getElementById('svgImage');
      console.log(element2);
      if (element2.children && element2.children.length > 0) {
        await doc.svg(element2.children[0], {x: 30, y: 200, width: 700, height: 700});

        var img = new Image()
        img.src = 'assets/imgs/logo_variobend.png';
        doc.addImage(img, 'png', 100, 50, 387, 62);

        //doc.addFont('ArialMS', 'Arial', 'normal');
        //doc.setFont('Arial', 'bold');
        // doc.setFontType("bold");
        doc.setFontSize(28);
        doc.text(this.profileProvider.active.name, 50, 185, /*{align: "center", }*/);
        doc.setFontSize(20);

        autoTable(doc, {
          head: [[this.translate.instant('MATERIAL'),
            /*'Farbposition',*/
            this.translate.instant('COLOR'),
            this.translate.instant('CUT'),
            this.translate.instant('MATERIALTHICKNESS'),
            this.translate.instant('SHEETLENGTH'),
            this.translate.instant('NUMBEROFPIECES')]],
          body: [
            [this.profileProvider.active.materialName,
              /*this.profileProvider.active.paintPosition,*/
              this.profileProvider.active.paintColor,
              this.profileProvider.active.cut,
              this.profileProvider.active.materialThickness,
              this.profileProvider.active.width[0],
              this.profileProvider.active.numberOfPiecesToManufacture],
          ],
          startY: 200
        })

        let name = this.profileProvider.active.name;
        if (CanvasBoardComponent.sharePDF && doc) {
          // convert pdf to jpg and share
          let navShare = navigator as any;
          if (navShare.share || this.platform.is('cordova')) {
            let pdfBlob = doc.output('arraybuffer');

            // setup pdfjs
            pdfjsLib.GlobalWorkerOptions.workerSrc = "./assets/pdfjs/build/pdf.worker.js";

            // fetch the first page from pdf
            let pdf = await pdfjsLib.getDocument(pdfBlob);
            let page = await pdf.getPage(1);
            var scale = 1.5;
            var viewport = page.getViewport(scale);

            // prepare canvas using pdf page dimensions
            var canvasPDF = document.getElementById('canvasPDF') as HTMLCanvasElement;
            var context = canvasPDF.getContext('2d');
            canvasPDF.height = viewport.height;
            canvasPDF.width = viewport.width;

            // render pdf page into canvas context and get jpg
            await page.render({canvasContext: context, viewport: viewport});
            let jpg = canvasPDF.toDataURL('image/jpeg');

            // prepare share object
            let file = new File([jpg], name + ".jpg", {type: 'image/jpeg'});
            let filesArray = [file];
            let shareObj = {
              title: name,
              text: "",
              files: filesArray
            };

            // finally share the profile
            if(this.platform.is('cordova'))
              this.socialSharing.share("", name, jpg);
            else if (navShare.canShare(shareObj)) {
              let shareRes = navShare.share(shareObj);
              //console.log(shareRes);
            } else
              console.log('This file cannot be shared!');
          } else
            console.log("No share menu available");
        } else if (CanvasBoardComponent.exportPDF && doc) {
          // load pdf
          if(this.platform.is('ios'))
            this.openPDF(doc, name);
          else
            doc.save(name + ".pdf");
        }

      }
    }

    CanvasBoardComponent.sharePDF = false;
    this.showSVG = false;
    this.loadingCtrl.dismiss();
    this.popoverCtrl.dismiss();
  }

  setInit() {
    let max = this.platform.height();
    if (this.platform.width() > this.platform.height()) {
      max = this.platform.width();
    }
    this.zoomStart = Math.round(5000 / max);
    this.zoomLevelMax = Math.round(25000 / max);
    this.radiusStart = 1;
    this.gridSize = 10;
    this.strokeWidth = 0.2;
    this.strokeWidthPlus = 0.2;
    this.cornerRadius = 2;
    this.scaleX = 0.2;
    this.scaleY = 0.2;
    this.txtScale = 0.2;
    this.distancePP = 15;
    this.pullpointWidth = -2;
    this.sizeTyp = 3;
    this.strokeWidthGrid = 0.1;

    this.lengthFactor = this.zoomLevelMin * this.dashboardSize / this.platform.width();
    // this.setRalColor();

    this.objGroup = new fabric.Group([], {
      hasControls: false,
      hasBorders: false,
      selectable: false
    });
    this.canvas.add(this.objGroup);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if(Math.abs(this.canvas.width - this.platform.width()) > 2) {
      // ignore change in height, because it can be triggered by showing/hiding info view
      this.canvas.remove(this.gridPath);

      this.ngAfterViewInit();
      return;
    }
  }


  /*******************
   ********************
   ********************
   ACTIONS
   ********************
   ********************
   *******************/

  async mouseDownDraw() {
    this.iterator++;
    this.isDown = true;
    this.mouseMove = false;
    this.setZoomLevel();
    if (!this.starting) {
      this.drawStartPoint();
    } else {
      this.drawLine();
    }
  }

  mouseDownPointer() {
    let obj = this.canvas.getActiveObject();
    let find = false;
    this.changeValues = null;
    this.changeValues2 = null;
    this.currentValue = null;
    this.currentValue2 = null;
    this.currentValue3 = null;
    if (obj != null) {
      for (let i = 1; i <= this.angleObjs.length; i++) {
        if (this.angleObjs[i] == obj) {
          this.changeValues = true;
          this.currentIterator = i;
          find = true;
          if (
            this.points[this.currentIterator] &&
            this.points[this.currentIterator].angle &&
            this.points[this.currentIterator].angle.stehfalz !== undefined &&
            this.points[this.currentIterator].angle.stehfalz >= 0
          ) {
            this.currentValue = this.points[
              this.currentIterator
              ].angle.stehfalz;
          } else if (
            this.points[this.currentIterator] &&
            this.points[this.currentIterator].angle
          ) {
            this.currentValue = this.points[
              this.currentIterator
              ].angle.angleRound;
          }
          setTimeout(() => {
            if (this.angle)
              this.angle.setFocus();
            else
              setTimeout(() => {
                if (this.angle)
                  this.angle.setFocus();
              }, 200);
          }, 200);
        }
      }
      if (!find) {
        for (let i = 1; i <= this.txtObjs.length; i++) {
          if (this.txtObjs[i] == obj) {
            this.changeValues2 = true;
            this.currentIterator = i;
            find = true;
            this.currentValue2 = this.pointsTxt[i].distance.distance;
            if (this.pointsTxt[i].distance.conicity && this.pointsTxt[i].distance.conicity !== null
              && this.pointsTxt[i].distance.conicity !== 0) {
              this.currentValue3 = this.pointsTxt[i].distance.conicity;
            } else {
              this.currentValue3 = 0;
            }
            setTimeout(() => {
              if(this.distance)
                this.distance.setFocus();
              else
                setTimeout(() => {
                  if(this.distance)
                    this.distance.setFocus();
                }, 200);
            }, 200);
          }
        }
      }
      if (!find) {
        this.changeValues = null;
        this.changeValues2 = null;
        this.currentValue = null;
        this.currentValue2 = null;
        this.currentValue3 = null;
      }
    }
    return obj != null;
  }

  async mouseMoveDraw(round = false, render = true) {
    this.mouseMove = true;
    if (!this.isDown) return;
    if (!this.starting) {
      this.moveStartPoint();
    } else {
      this.moveLine(round, render);
    }
  }

  async mouseUpDraw(save = true, render = true, round = false) {
    this.isDown = false;
    this.starting = true;
    this.drawFlullLine(render);
    if (!this.mouseMove) {
      await this.moveLine(round);
      this.reDraw();
    }
    this.mouseMove = false;
    if (save) {
      this.save();
    }
    if(!this.isRedrawing && this.calcCutCallback)
        this.calcCutCallback();
  }

  mouseWeel(opt) {
    var delta = opt.e.deltaY;
    if (delta != 0) {
      var point = new fabric.Point(opt.e.offsetX, opt.e.offsetY);
      if (delta > 0) {
        this.zoomLevel -= 0.5;
        this.zoomOut(point);
      } else if (delta < 0) {
        this.zoomLevel += 0.5;
        this.zoomIn(point);
      }
       // this.sortItems();
       // this.canvas.renderAll();
    }
  }

  pinchZoom(opt) {
    if (opt.e.touches && opt.e.touches.length == 2) {
      var point = new fabric.Point(opt.self.x, opt.self.y);
      if(Math.abs(opt.self.rotation) < 25 && this.lastRotation === undefined) {
        if (!this.pinch) {
          this.pinch = opt.self.scale;
        }
        if (Math.abs(this.pinch - opt.self.scale) > 0.04) {
          if (this.pinch > opt.self.scale) {
            this.zoomLevel -= 0.5;
            this.zoomOut(point);
          } else {
            this.zoomLevel += 0.5;
            this.zoomIn(point);
          }
          this.pinch = opt.self.scale;
        }
      } else {
        let currentRotation = Math.round(opt.self.rotation);
        if(!this.lastRotation) {
          this.lastRotation = Math.sign(currentRotation) * 20;
        }
        if(Math.abs(currentRotation - this.lastRotation) > 0.9) {
          this.rotatePoints(currentRotation - this.lastRotation);
          this.lastRotation = currentRotation;
        }
      }
    }
  }

  rotatePoints(angle: number) {
    if(this.points && this.points.length > 1) {
      let p0 = this.points[0];
      let p1 = this.points[1];
      let deltaX = p1.x - p0.x;
      let deltaY = p1.y - p0.y;

      angle *= Math.PI / 180;

      let newX = p0.x + Math.cos(angle) * deltaX - Math.sin(angle) * deltaY;
      let newY = p0.y + Math.sin(angle) * deltaX + Math.cos(angle) * deltaY;

      this.points[1].x = newX;
      this.points[1].y = newY;

      this.reDraw();
      this.save();
      this.zoomToPath();
      this.redrawTimer = setTimeout(() => {
        this.reDraw();
      }, 50);
    }
  }

  dragTouch(opt) {
    if (undefined != opt.self.x && undefined != opt.self.y && !this.isDragging && !(opt.e.touches && (opt.e.touches.length > 2 || opt.e.touches.length != this.fingerCount)) && undefined != this.lastXTouch && undefined != this.lastYTouch) {
      let currentX = opt.self.x;
      let currentY = opt.self.y;
      let xChange = currentX - this.lastXTouch;
      let yChange = currentY - this.lastYTouch;

      if (Math.abs(xChange) > 5 ||
          Math.abs(yChange) > 5 ) {
        this.isDragging = true;
        let maxChange = this.fingerCount > 1 ? 100 : 300;
        if (Math.abs(xChange) <= maxChange &&
            Math.abs(yChange) <= maxChange ) {
          var delta = new fabric.Point(xChange, yChange);
          this.canvas.relativePan(delta);

          this.lastXTouch = currentX;
          this.lastYTouch = currentY;
          this.keepPositionInBounds();
        }
        this.isDragging = false;
        // this.sortItems();
        // this.canvas.renderAll();
        }
    }
    else if(opt.e.touches && ((undefined == this.lastXTouch && undefined == this.lastYTouch) || this.fingerCount != opt.e.touches.length))
    {
      this.lastXTouch = opt.self.x;
      this.lastYTouch = opt.self.y;
      this.fingerCount = opt.e.touches.length;
    }
  }

  /*******************
   ********************
   ********************
   DRAW METHOS
   ********************
   ********************
   *******************/

  drawStartPoint() {
    if (!this.startPoint) {
      this.startPoint = new fabric.Circle({
        left: this.pointer.x,
        top: this.pointer.y,
        originX: "left",
        originY: "top",
        radius: this.radiusStart,
        fill: (this.modus === 'draw') ? '#008acf' : 'transparent',
        hasControls: false,
        selectable: false,
        hasBorders: false,
        hoverCursor: "default"
      });
      this.canvas.add(this.startPoint);
      this.pointObjs[this.iterator] = this.startPoint;
      //this.objGroup.add(this.startPoint);
      this.points[0] = {
        x: (this.pointer.x),
        y: (this.pointer.y)
      };
      this.showUndo = true;
    }
  }

  moveStartPoint() {
    this.startPoint.set({
      left: (this.pointer.x),
      top: (this.pointer.y)
    });
    this.points[0] = {
      x: (this.pointer.x),
      y: (this.pointer.y)
    };
    this.canvas.requestRenderAll();
  }

  drawLine() {
    this.points[this.points.length] = { x: this.pointer.x, y: this.pointer.y };
    let A = this.points[this.points.length - 2];
    let B = this.points[this.points.length - 1];
    // let txtXY = this.calcMiddlePointLine(A, B);
    let calc = this.calcDistance(A, B);
    this.currentTxt = new fabric.IText(calc.distance.toString(), {
      // left: txtXY.x,
      // top: txtXY.y,
      originX: "center",
      originY: "center",
      fontSize: this.fontsize,
      fill: "transparent",
      scaleX: this.txtScale,
      scaleY: this.txtScale,
      editable: false,
      backgroundColor: "transparent",
      padding: this.lengthTxtPadding,
      hasControls: false,
      hasBorders: true,
      borderColor: "#008acf",
      borderScaleFactor: 1,
      selectable: true,
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingFlip: true,
      lockScalingX: true,
      lockScalingY: true,
      hoverCursor: "default"
    });
    // this.currentTxt.top -= 100;
    this.canvas.add(this.currentTxt);
    this.txtObjs[this.iterator] = this.currentTxt;

    //this.objGroup.add(this.currentTxt);
    if (this.points.length > 2) {
      this.drawAnglePoint();
      this.currentAngleTxt = new fabric.IText("", {
        left: B.x,
        top: B.y,
        originX: "center",
        originY: "center",
        fontSize: this.anglesize,
        fill: "#000",
        editable: false,
        scaleX: this.txtScale,
        scaleY: this.txtScale,
        textBackgroundColor: "",
        hasControls: false,
        hasBorders: true,
        borderColor: "#008acf",
        borderScaleFactor: 1,
        padding: this.angleTxtPadding,
        selectable: true,
        lockMovementX: true,
        lockMovementY: true,
        lockRotation: true,
        lockScalingFlip: true,
        lockScalingX: true,
        lockScalingY: true,
        hoverCursor: "default"
      });
      this.canvas.add(this.currentAngleTxt);
      this.angleObjs[this.iterator] = this.currentAngleTxt;
      //this.objGroup.add(this.currentAngleTxt);
    }
  }

  drawAnglePoint() {
    this.currentCorner = new fabric.Circle({
      left: this.points[this.points.length - 2].x,
      top: this.points[this.points.length - 2].y,
      startAngle: 0,
      endAngle: 0,
      radius: this.cornerRadius,
      fill: "transparent",
      originX: "center",
      originY: "center",
      selectable: false,
      hasControls: false,
      hasBorders: false,
      hoverCursor: "default"
    });
    this.cornerObjs[this.iterator] = this.currentCorner;
    //this.objGroup.add(this.currentCorner);
    this.canvas.add(this.currentCorner);
  }

  moveLine(round = false, render = true): Promise<any> {
    let counter = this.points.length - 1;

    return new Promise((resolve, reject) => {
      this.points[counter] = {x: this.pointer.x,y: this.pointer.y};
      this.points[counter].pointerX = this.pointer.x;
      this.points[counter].pointerY = this.pointer.y;
      let conicity = null;
      if (this.currentTxt != null) {
        this.currentTxt.scaleX = this.txtScale;
        this.currentTxt.scaleY = this.txtScale;
      }
      if (this.points.length > 2) {
        let A = this.points[this.points.length - 3];
        let B = this.points[this.points.length - 2];
        let C = this.points[counter];
        if (B.pP) {
          A = B.pP;
        }
        let angle = this.calcAngle(A, B, C, true, round);
        if (
          this.oldPoints &&
          this.oldPoints.length > 0 &&
          this.oldPoints[this.iterator].angle
        ) {
          angle = this.oldPoints[this.iterator].angle;
          angle.angle = Math.round(angle.angle * 10) / 10;
          angle.angleRound = angle.angle;
          angle.isCopy = true;
        }

        this.points[this.iterator].angle = angle;
        let calc = this.calcDistance(B, C);
        this.points[this.iterator].distance = calc;
        if (
          this.oldPoints &&
          this.oldPoints.length > 0 &&
          this.oldPoints[this.iterator].distance
        ) {
          this.oldPoints[this.iterator].distance.distance = Math.round(this.oldPoints[this.iterator].distance.distance * 10) / 10;
          this.points[this.iterator].distance = calc = this.oldPoints[this.iterator].distance;
          if (this.oldPoints[this.iterator].distance.conicity &&
            this.oldPoints[this.iterator].distance.conicity !== null &&
            this.oldPoints[this.iterator].distance.conicity !== 0) {
            this.points[this.iterator].distance.conicity = this.oldPoints[this.iterator].distance.conicity;
            conicity = this.points[this.iterator].distance.conicity;
          }
        }
        C = this.findNewPoint(A, B, angle.angleRaw, calc.distanceRaw);
        let txtXY = this.calcMiddlePointLine(B, C);
        this.points[counter].x = C.x;
        this.points[counter].y = C.y;
        this.points[counter].pP = null;
        if (this.currentTxt != null) {
          this.currentTxt.set({
            lineHeight: 0.8,
            fill: "#FFFFFF",
            textAlign: "center",
            backgroundColor: this.profileColor,
            text: conicity !== null ? calc.distance.toString() + "\n(" + (conicity>0?"+":"") + conicity + ')' : calc.distance.toString(),
            left: Math.round(txtXY.x),
            top: Math.round(txtXY.y),
            scaleX: this.txtScale,
            scaleY: this.txtScale
          });
          if (calc.distance / this.lengthFactor / 2 < this.currentTxt.width * this.txtScale ||
            calc.distance / this.lengthFactor / 2 < this.currentTxt.height * this.txtScale) {
            this.currentTxt.set({fill: "transparent", backgroundColor: "transparent"});
          }
        }
        let drawRadius = true;
        if (calc.distance / this.lengthFactor / 2 < this.cornerRadius) {
          drawRadius = false;
        }
        if (drawRadius && this.iterator > 1) {
          let calc2 = this.points[this.iterator - 1].distance;
          if (calc2.distance / this.lengthFactor / 2 < this.cornerRadius) {
            drawRadius = false;
          }
        }
        if (angle.angle > 140) {

          let alpha = (180 - Math.abs(angle.angle)) * Math.PI / 180.0;
          let pPRaw = {x: -1 + Math.sin(alpha) + Math.cos(alpha), y: 1 + Math.cos(alpha) - Math.sin(alpha)};
          pPRaw.x *= this.stehAngleDistance;
          pPRaw.y *= this.stehAngleDistance;
          let startToEnd = {x: this.points[this.iterator].x - this.points[this.iterator-1].x, y: this.points[this.iterator].y - this.points[this.iterator-1].y};
          let rotAngle = Math.atan2(startToEnd.y, startToEnd.x) + (angle.angleRaw/2) * Math.PI / 180.0;
          let pP = {x: pPRaw.x * Math.cos(rotAngle) - pPRaw.y * Math.sin(-rotAngle), y: -pPRaw.x * Math.sin(-rotAngle) - pPRaw.y * Math.cos(rotAngle)};
          let delta = {x: pP.x, y: pP.y};
          pP.x += this.points[this.iterator - 1].x;
          pP.y += this.points[this.iterator - 1].y;

          if (angle.angleRaw < 180) {
            let getCAngle = this.getStehAngle(this.points[this.iterator], 1);
            let newPointC2 = this.findNewPoint(A,B,180,B.distance.distanceRaw);
            this.points[this.points.length - 1].pP = pP;
            let middlepP = this.calcMiddlePointLine(B, pP);
            this.points[this.iterator].stehfalz = 0;
            this.points[this.iterator].stehfalz = this.drawSetStehfalzTxt(angle,middlepP);
            let pPOpposit = this.calcParallelPoint(A,newPointC2,-1 * (this.distancePP * angle.pP));
            let middlepPOpposit = this.calcMiddlePointLine(A, pPOpposit);
            let pullPoint = this.calcCornerPoint(middlepPOpposit,middlepP,this.pullpointWidth);
            if (angle.stehfalz != 0 /*|| Math.abs(getCAngle) == 3 || Math.abs(getCAngle) == 357*/) {
              this.points[counter].x += delta.x;
              this.points[counter].y += delta.y;
              pullPoint.x=this.points[counter].x - delta.x/2;
              pullPoint.y=this.points[counter].y - delta.y/2;
              let newC= {x: this.points[counter].x, y: this.points[counter].y};
              let txtXY = this.calcMiddlePointLine(pP, newC);
              this.drawLineWithStehfalz(B,pullPoint,pP,angle.angle,{ x: newC.x, y: newC.y },drawRadius);
              this.startPoint.set({left: newC.x,top: newC.y,originX: "center",originY: "center"});
              if(this.currentTxt !== null){
                this.currentTxt.left = txtXY.x;
                this.currentTxt.top = txtXY.y;
              }
            } else {
              let newC = this.findNewPoint(A, B, getCAngle, calc.distanceRaw);

              let pPDiff = Math.abs(pP.y - B.y) * 2;
              let pullDiff = Math.abs(pullPoint.y - B.y) * 2;
              if (B.x < A.x) {
                if (A.y == B.y) {
                  pP.y -= pPDiff;
                  pullPoint.y -= pullDiff;
                } else if (A.y < B.y && B.y > newC.y) {
                  //console.log('A2');
                } else if (A.y < B.y && B.y < newC.y && angle.pP == 1) {
                  pP.y += pPDiff;
                  pullPoint.y += pullDiff;
                } else if (A.y > B.y && B.y < newC.y && angle.pP == 1) {
                  //console.log('A4');
                } else if (A.y > B.y && B.y >= newC.y && angle.pP == -1) {
                  pP.y -= pPDiff;
                  pullPoint.y -= pullDiff;
                } else {
                  //console.log('Au');
                }
              }
              newC = this.points[counter];
              let txtXY = this.calcMiddlePointLine(pP, newC);
              this.points[counter].x = newC.x;
              this.points[counter].y = newC.y;
              this.drawLineWithStehfalz(B,pullPoint,pP,angle.angle,{ x: newC.x, y: newC.y },drawRadius);
              this.startPoint.set({left: newC.x,top: newC.y,originX: "center",originY: "center"});
              if(this.currentTxt !== null){
                this.currentTxt.left = txtXY.x;
                this.currentTxt.top = txtXY.y;
              }
            }
            this.pointsTxt[this.iterator] = txtXY;
            this.pointsTxt[this.iterator].distance = this.points[this.iterator].distance;
          } else {
            let getCAngle = this.getStehAngle(this.points[this.iterator], 2);
            let newPointC2 = this.findNewPoint(A,B,180,B.distance.distanceRaw);
            this.points[counter].pP = pP;
            let middlepP = this.calcMiddlePointLine(B, pP);
            this.points[this.iterator].stehfalz = 0;
            this.points[this.iterator].stehfalz = this.drawSetStehfalzTxt(angle,middlepP);
            let pPOpposit = this.calcParallelPoint(A,newPointC2,-1 * (this.distancePP * angle.pP));
            let middlepPOpposit = this.calcMiddlePointLine(A, pPOpposit);
            let pullPoint = this.calcCornerPoint(middlepPOpposit,middlepP,this.pullpointWidth);
            if (angle.stehfalz != 0 /*|| Math.abs(getCAngle) == 357 || Math.abs(getCAngle) == 3*/) {
              this.points[counter].x += delta.x;
              this.points[counter].y += delta.y;
              pullPoint.x=this.points[counter].x - delta.x/2;
              pullPoint.y=this.points[counter].y - delta.y/2;
              let newC= {x: this.points[counter].x, y: this.points[counter].y};
              let txtXY = this.calcMiddlePointLine(pP, newC);
              this.drawLineWithStehfalz(B,pullPoint,pP,angle.angle,{ x: newC.x, y: newC.y },drawRadius);
              this.startPoint.set({left: newC.x,top: newC.y,originX: "center",originY: "center"});
              if(this.currentTxt !== null){
                this.currentTxt.left = txtXY.x;
                this.currentTxt.top = txtXY.y;
              }
            } else {
              let newC = this.findNewPoint(A, B, getCAngle, calc.distanceRaw);
              let pPDiff = Math.abs(pP.y - B.y) * 2;
              let pullDiff = Math.abs(pullPoint.y - B.y) * 2;
              if (B.x < A.x) {
                if (A.y == B.y) {
                  pP.y += pPDiff;
                  pullPoint.y += pullDiff;
                  //console.log('Bp');
                } else if (A.y < B.y && B.y < newC.y && angle.pP == 1) {
                  pP.y += pPDiff;
                  pullPoint.y += pullDiff;
                  //console.log('B2');
                } else if (A.y < B.y && B.y < newC.y && angle.pP == -1) {
                  //console.log('B2');
                } else {
                  //console.log('Bu');
                }
              }
              let txtXY = this.calcMiddlePointLine(pP, newC);
              this.drawLineWithStehfalz(B,pullPoint,pP,angle.angle,{ x: newC.x, y: newC.y },drawRadius);
              this.startPoint.set({left: newC.x,top: newC.y,originX: "center",originY: "center"});
              if(this.currentTxt !== null){
                this.currentTxt.left = txtXY.x;
                this.currentTxt.top = txtXY.y;
              }
            }
            this.pointsTxt[this.iterator] = txtXY;
            this.pointsTxt[this.iterator].distance = this.points[this.iterator].distance;
          }
        } else {
          let AB = this.calcCornerPoint(A,B,this.cornerRadius + this.cornerRadius / 90);
          let BC = this.calcCornerPoint(C,B,this.cornerRadius + this.cornerRadius / 90);
          this.drawAngleTrapez(AB, BC, B, drawRadius);
          AB = this.calcCornerPoint(A, B, this.cornerRadius);
          BC = this.calcCornerPoint(C, B, this.cornerRadius);
          this.drawAngle(AB, B, BC, drawRadius);
          this.drawLineWithStehfalz(null, null, null, 0, B, C, drawRadius);
          this.startPoint.set({left: C.x,top: C.y,originX: "center",originY: "center"});
          this.currentAngleTxt.set({text: angle.angleRound.toString(),fontSize: this.fontsize});
          this.pointsTxt[this.iterator] = txtXY;
          this.pointsTxt[this.iterator].distance = this.points[
            this.iterator
            ].distance;
        }
      } else {
        let B = this.points[this.points.length - 2];
        let C = this.points[counter];
        if (B != null && C != null) {
          let A = {x: B.x - 200, y: B.y};
          let angle = this.calcAngle(A, B, C, false, round);
          this.points[this.points.length - 1].angle = angle;
          let calc = this.calcDistance(B, C);
          this.points[this.iterator].distance = calc;
          if (
            this.oldPoints &&
            this.oldPoints.length > 0 &&
            this.oldPoints[this.iterator].distance
          ) {
            this.points[this.iterator].distance = calc = this.oldPoints[this.iterator].distance;
            if (this.oldPoints[this.iterator].distance.conicity &&
              this.oldPoints[this.iterator].distance.conicity !== null &&
              this.oldPoints[this.iterator].distance.conicity !== 0) {
              this.points[this.iterator].distance.conicity = this.oldPoints[this.iterator].distance.conicity;
              conicity = this.points[this.iterator].distance.conicity;
            }
          }
          C = this.findNewPoint(A, B, angle.angleRaw, calc.distanceRaw);
          this.points[counter].x = C.x;
          this.points[counter].y = C.y;
          let txtXY = this.calcMiddlePointLine(B, C );
          if (this.currentTxt != null) {
            this.currentTxt.set({
              lineHeight: 0.8,
              fill: "#FFFFFF",
              textAlign: "center",
              backgroundColor: this.profileColor,
              text: conicity !== null ? calc.distance.toString() + "\n(" + (conicity>0?"+":"") + conicity + ')' : calc.distance.toString(),
              left: Math.round(txtXY.x),
              top: Math.round(txtXY.y)
            });
            if (calc.distance / 2 < this.currentTxt.width * this.txtScale ||
              calc.distance / 2 < this.currentTxt.height * this.txtScale) {
              this.currentTxt.set({fill: "transparent", backgroundColor: "transparent"});
            }
          }
          this.drawLineWithStehfalz(null, null, null, 0, B, {x: C.x, y: C.y});
          this.startPoint.set({left: C.x, top: C.y, originX: "center", originY: "center"});
          this.pointsTxt[this.iterator] = txtXY;
          this.pointsTxt[this.iterator].distance = this.points[this.iterator].distance;
        }
      }
      this.sortItems();
      if(render)
        this.canvas.requestRenderAll();
      resolve(true);
    });
  }

  drawGrid() {
    let options = {
      distance: this.gridSize,
      width: this.canvas.width,
      height: this.canvas.height
    };
    let gridLen = options.width / options.distance;
    let gridHeight = options.height / options.distance;

    let path =
      "M " +
      0 +
      " " +
      0 +
      " L " +
      0 +
      ", " +
      this.canvas.height;

    let distance = 0;
    for (var i = 0; i < gridLen; i++) {
      path += " L " + distance + "," + this.canvas.height;
      path += " L " + distance + "," + 0;
      distance = i * options.distance;
      path += " L " + distance + "," + 0;
    }

    path += " L " + 0 + "," + 0;
    distance = 0;
    for (let i = 0; i < gridHeight; i++) {
      path += " L " + this.canvas.width + "," + distance;
      path += " L " + 0 + "," + distance;
      distance = i * options.distance;
      path += " L " + 0 + "," + distance;
    }

    this.gridPath = new fabric.Path(path, {
      fill: "",
      stroke: "#fff",
      strokeWidth: this.strokeWidthGrid,
      selectable: false,
      borderColor: "red",
      hoverCursor: "default"
    });
    this.canvas.add(this.gridPath);
  }

  drawLineWithStehfalz(
    A = null,
    B = null,
    C = null,
    angle,
    Y = null,
    X = null,
    drawAngle = true
  ) {
    this.points[this.iterator].round = false;
    this.points[this.iterator].current = null;
    this.points[this.iterator].roundPoint1 = null;
    this.points[this.iterator].roundPoint2 = null;

    if (this.currentStehfalz) {
      this.canvas.remove(this.currentStehfalz);
      this.canvas.remove(this.pathShadow);
      this.currentStehfalz = null;
    }

    if (angle >= 140) {
      let path =
        "M " +
        A.x +
        " " +
        A.y +
        " A " +
        this.stehAngleDistance +
        ", " +
        this.stehAngleDistance +
        ", 0, 0 " +
        (this.points[this.iterator].angle.pP >= 0 ? "1, " : "0, ") +
        C.x +
        ", " +
        C.y +
        "";
      path += " L " + Y.x + " " + Y.y;

      if (this.currentCorner) {
        this.currentCorner.set({ fill: "transparent", stroke: "" });
      }
      if (this.trapezObjs[this.iterator]) {
        this.trapezObjs[this.iterator].set({
          fill: "transparent",
          stroke: "transparent"
        });
      }

      this.points[this.iterator].round = true;
      this.points[this.iterator].current = A;
      this.points[this.iterator].roundPoint1 = B;
      this.points[this.iterator].roundPoint2 = C;
      this.points[this.iterator].x = Y.x;
      this.points[this.iterator].y = Y.y;

      this.currentStehfalz = new fabric.Path(path, {
        fill: "",
        stroke: this.profileColor,
        strokeWidth: this.strokeWidth,
        selectable: false,
        hoverCursor: "default"
      });
      this.canvas.add(this.currentStehfalz);
    } else {
      let path =
        "M " +
        Y.x +
        ", " +
        Y.y +
        " L " +
        X.x +
        ", " +
        X.y;
      if (drawAngle) {
        if (this.currentCorner) {
          this.currentCorner.set({ fill: "#ccc", stroke: "#ccc" });
        }
        if (this.trapezObjs[this.iterator]) {
          this.trapezObjs[this.iterator].set({
            fill: "#ccc",
            stroke: "transparent"
          });
        }
      }

      this.currentStehfalz = new fabric.Path(path, {
        fill: "",
        stroke: this.profileColor,
        strokeWidth: this.strokeWidth,
        selectable: false,
        hoverCursor: "default"
      });
      this.canvas.add(this.currentStehfalz);
    }
  }

  getStehAngle(point, typ) {
    let angle = point.angle.angleRaw;
    let angleFinal = 0;
    if (typ == 2) {
      angle = angle - 180;
    } else {
      angle = 180 - angle;
    }

    if (angle > 177) { // >= 178 175
      angleFinal  = 0;
    } else if (angle > 171) { // >= 171 165
      angleFinal =  5;
    } else if (angle > 163) { //  >= 163 160
      angleFinal =  15;
    } else if (angle > 158) { // >= 158 155
      angleFinal =  20;
    } else if (angle > 151) { // >= 151 147
      angleFinal =  25;
    } else if (angle > 146) { // >= 146 143
      angleFinal =  33;
    } else { // 141 - 142
      angleFinal = 37;
    }

    /*if (angle >= 175) { //0,1
      angleFinal = 3;
    } else if (angle >= 165) { // 2
      angleFinal = 16;
    } else if (angle >= 160) { // 3
      angleFinal = 21;
    } else if (angle >= 155) { // 4
      angleFinal = 26;
    } else if (angle >= 147) { // 5
      angleFinal = 34;
    } else if (angle >= 143) { // 6
      angleFinal = 38;
    } else {
      angleFinal = 40; //7
    }*/

    if (point.angle.isCopy) {
      return point.angle.stehfalzRet;
    }

    if (typ == 2) {
      point.angle.stehfalzRet = 360 - angleFinal;
      point.angle.angleRaw = 360 - angleFinal;
      angleFinal = 360 - angleFinal;
      return angleFinal;
    } else {
      point.angle.stehfalzRet = angleFinal;
      point.angle.angleRaw = angleFinal;
      return angleFinal;
    }
  }

  drawSetStehfalzTxt(angle, A) {
    let text = 0;
    if (angle.stehfalz == 0) {
      text = 0;
    } else if (angle.angle > 177) { // >= 178 175
      text = 1;
      angle.stehfalz = 1;
    } else if (angle.angle > 171) { // >= 171 165
      text = 2;
      angle.stehfalz = 2;
    } else if (angle.angle > 163) { //  >= 163 160
      text = 3;
      angle.stehfalz = 3;
    } else if (angle.angle > 158) { // >= 158 155
      text = 4;
      angle.stehfalz = 4;
    } else if (angle.angle > 151) { // >= 151 147
      text = 5;
      angle.stehfalz = 5;
    } else if (angle.angle > 146) { // >= 146 143
      text = 6;
      angle.stehfalz = 6;
    } else { // 141 - 142
      text = 7;
      angle.stehfalz = 7;
    }
    /*if (angle.stehfalz == 0) {
      text = 0;
    } else if (angle.angle >= 175) { // >= 178 175
      text = 1;
      angle.stehfalz = 1;
    } else if (angle.angle >= 165) { // >= 171 165
      text = 2;
      angle.stehfalz = 2;
    } else if (angle.angle >= 160) { //  >= 163 160
      text = 3;
      angle.stehfalz = 3;
    } else if (angle.angle >= 155) { // >= 158 155
      text = 4;
      angle.stehfalz = 4;
    } else if (angle.angle >= 147) { // >= 151 147
      text = 5;
      angle.stehfalz = 5;
    } else if (angle.angle >= 143) { // >= 146 143
      text = 6;
      angle.stehfalz = 6;
    } else { // 141 - 142
      text = 7;
      angle.stehfalz = 7;
    }*/
    this.currentAngleTxt.set({
      text: text.toString(),
      fill: "#000",
      left: A.x,
      top: A.y,
      fontSize: this.anglesize + 1
    });
    this.currentAngleTxt.scaleX = this.txtScale;
    this.currentAngleTxt.scaleY = this.txtScale;
    return text;
  }

  drawFlullLine(render = true) {
    let path = "";
    let path2 = "";
    if (this.currentPath) {
      this.canvas.remove(this.currentPath);
    }
    if (this.currentStehfalz) {
      this.currentStehfalz.set({ stroke: "" });
      this.canvas.remove(this.currentStehfalz);
    }

    let pointsAll  = [];
    pointsAll = this.points;

    path += "M " + (pointsAll[0].x) + " " + (pointsAll[0].y) + "";
    path2 += "M " + (pointsAll[0].x * (this.width / this.canvas.width)) + " " + (pointsAll[0].y * (this.width / this.canvas.width)) + "";
    for (let i = 1; i < this.points.length; i++) {
      if (pointsAll[i].round && pointsAll[i].current && pointsAll[i].pP) {
        path += " L " + (pointsAll[i].current.x) + " " + (pointsAll[i].current.y) + " A " + this.stehAngleDistance + ", " + this.stehAngleDistance + ", 0, 0 " + (pointsAll[i].angle.pP >= 0 ? "1, " : "0, ") + (pointsAll[i].pP.x) + ", " + (pointsAll[i].pP.y) /*(pointsAll[i].roundPoint2.x) + ", " + (pointsAll[i].roundPoint2.y)*/ + "";
        path2 += " L " + (pointsAll[i].current.x * (this.width / this.canvas.width)) + " " + (pointsAll[i].current.y * (this.width / this.canvas.width))
        + " A 1, 1, 0, 0 " + (pointsAll[i].angle.pP > 0 ? "1, " : "0, ") + (pointsAll[i].pP.x * (this.width / this.canvas.width)) + ", " + (pointsAll[i].pP.y * (this.width / this.canvas.width)) /*( pointsAll[i].roundPoint2.x * (this.width / this.canvas.width)) + ", " + (pointsAll[i].roundPoint2.y * (this.width / this.canvas.width))*/ + "";
      }
      path += " L" + (pointsAll[i].x) + " " + (pointsAll[i].y) + "";
      path2 += " L" + (pointsAll[i].x * (this.width / this.canvas.width)) + " " + (pointsAll[i].y * (this.width / this.canvas.width)) + "";
    }

    // transform profile for compatibility
    if(this.profileProvider.active.paintPosition == "top" || this.profileProvider.active.paintPosition == "bottom"){
      if(this.profileProvider.active.paintColor == undefined || this.profileProvider.active.paintColor == null)
        this.profileProvider.active.paintColor = this.profileProvider.active.paint;
    }
    else if(this.profileProvider.active.paint == "top" || this.profileProvider.active.paint == "bottom"){
      this.profileProvider.active.paintColor = this.profileProvider.active.paintPosition;
      this.profileProvider.active.paintPosition = this.profileProvider.active.paint;
      this.profileProvider.active.paint = undefined;
    }
    // end transform
    if (pointsAll.length >= 2 && this.profileProvider.active.paintPosition
      && this.profileProvider.active.paintColor && this.profileProvider.active.paintColor != "none") {

      let last = pointsAll.length - 1;
      if (pointsAll[last].distance !== undefined) {
        if (pointsAll[last].distance.distance < 30) {
          last -= 1;
        }
        /*if (pointsAll.length >= 3 && pointsAll[last].distance.distance < 30) {
          last -= 1;
        }*/
        if (last < 1) {
          last = 1;
        }
        let Y = pointsAll[last - 1];
        let X = pointsAll[last];
        if (X.round) {
          Y = X.roundPoint2;
        }
        let C = {x: X.x - 200, y: X.y};
        let angle = this.calcAngle(Y, X, C, false);
        let pP, pPOpposit, pointMiddle, anglePoint;

        let point = this.calcCornerPoint(X, Y,
          (pointsAll[last].distance.distanceRaw / 4) * 3
        );

        let paintPosition = this.profileProvider.active.paintPosition;
        let pathColor = this.profileProvider.active.paintColor; //this.ralColor[9005]; // default black
        if(!pathColor || pathColor=="none")
          pathColor="#000000";
        else {
          if(this.colors && this.colors.length > 0 && this.colors.find(x=>x.name == pathColor)) {
            pathColor = this.colors.find(x=>x.name == pathColor).rgb;
          }
        }
        if (paintPosition == "top") {
            pP = this.calcParallelPoint(Y, X, 5.0/this.zoomLevel);
            pPOpposit = this.calcParallelPoint(X, Y, -5.0/this.zoomLevel);
            pointMiddle = this.calcParallelPoint(point, Y, -2.0 * this.triangleDif/this.zoomLevel);
            anglePoint = angle.angleRaw - 180;
        } else if (paintPosition == "down") {
            pP = this.calcParallelPoint(Y, X, -5.0/this.zoomLevel);
            pPOpposit = this.calcParallelPoint(X, Y, 5.0/this.zoomLevel);
            pointMiddle = this.calcParallelPoint(point, Y, 2.0*this.triangleDif/this.zoomLevel);
            anglePoint = angle.angleRaw;
        }

        if (pP) {
          let pathShadow = "M " + (pP.x) + ", " + (pP.y);
          pathShadow +=
            " L " + (pPOpposit.x) + ", " + (pPOpposit.y);

          this.canvas.remove(this.pathShadow);
          this.pathShadow = new fabric.Path(pathShadow, {
            selectable: false,
            hasControls: false,
            hasBorders: false,
            hoverCursor: "default",
            fill: "",
            stroke: pathColor,
            strokeWidth: this.strokeWidth + this.strokeWidthPlus,
            strokeLineJoin: "round"
          });
          this.canvas.add(this.pathShadow);

          this.canvas.remove(this.triangle);
          this.triangle = new fabric.Triangle({
            width: this.triangleWidth,
            height: this.triangleHeight,
            fill: pathColor,
            left: pointMiddle.x,
            top: pointMiddle.y,
            angle: anglePoint
          });

          this.canvas.add(this.triangle);
        }
      }
    }

    this.currentPath = new fabric.Path(path, {
      selectable: false,
      hasControls: false,
      hasBorders: false,
      hoverCursor: "default",
      fill: "",
      stroke: this.profileColor,
      strokeWidth: this.strokeWidth + this.strokeWidthPlus,
      strokeLineJoin: "round"
    });
    this.profileProvider.active.path = path2;

    this.canvas.add(this.currentPath);
    //this.objGroup.add(this.currentPath);
    this.sortItems();
    if(this.startPoint) {
      this.startPoint.set({
        left: (pointsAll[this.points.length - 1].x),
        top: (pointsAll[this.points.length - 1].y),
        originX: "center",
        originY: "center"
      });
    }

    if(render)
      this.canvas.renderAll();
  }

  drawAngleTrapez(AB, BC, B, drawRadius = true) {
    if (this.trapezObjs[this.iterator]) {
      this.canvas.remove(this.trapezObjs[this.iterator]);
    }
    var polygon = new fabric.Polygon(
      [
        new fabric.Point(AB.x, AB.y),
        new fabric.Point(BC.x, BC.y),
        new fabric.Point((B.x), (B.y))
      ],
      {
        fill: "#ccc",
        stroke: "transparent",
        strokeWidth: "0",
        strokeLineCap: "round"
      }
    );
    this.trapezObjs[this.iterator] = polygon;
    this.currentTrapez = polygon;
    this.canvas.add(this.currentTrapez);

    if (!drawRadius) {
      this.currentTrapez.set({ fill: "transparent", stroke: "transparent" });
      this.currentCorner.set({ fill: "transparent", stroke: "transparent" });
      this.currentAngleTxt.set({ fill: "transparent" });
    } else {
      this.currentTrapez.set({ fill: "#ccc", stroke: "transparent" });
      this.currentCorner.set({ fill: "#ccc", stroke: "transparent" });
      this.currentAngleTxt.set({ fill: "#000" });
    }

    /*let points = [B, AB, BC];
    let path = "";
    if (this.trapezObjs[this.iterator]) {
      this.canvas.remove(this.trapezObjs[this.iterator]);

    }
    path += "M " + Math.round(points[0].x) + " " + Math.round(points[0].y) + "";
    for (let i = 1; i < points.length; i++) {
      path +=
        " L " + Math.round(points[i].x) + " " + Math.round(points[i].y) + "";
    }

    this.currentTrapez = this.trapezObjs[this.iterator] = new fabric.Path(
      path,
      {
        selectable: false,
        hasControls: false,
        hasBorders: false,
        hoverCursor: "default"
      }
    );


    this.canvas.add(this.currentTrapez); */
    //this.objGroup.add(this.currentTrapez);
  }

  drawParallelPoint(pP) {
    this.currentParallelPoint = new fabric.Circle({
      left: pP.x,
      top: pP.y,
      radius: 3,
      fill: "black",
      originX: "center",
      originY: "center",
      hasControls: false,
      selectable: true,
      hasBorders: false,
      lockMovementX: true,
      lockMovementY: true,
      hoverCursor: "default"
    });

    this.canvas.add(this.currentParallelPoint);
    //this.objGroup.add(this.currentParallelPoint);
  }

  setZoomLevel() {
    if (this.zoomLevel < this.zoomLevelMin) {
      this.zoomLevel = this.zoomLevelMin;
    }

    this.distancePP = 20 / this.zoomLevel;
    if (this.distancePP < 1) {
      this.distancePP = 1;
    }
    this.cornerRadius = 35 / this.zoomLevel;

    this.radiusStart = 5 / this.zoomLevel;
    this.stehAngleDistance = 8 / this.zoomLevel;

    this.txtScale = 2 / this.zoomLevel;
    this.strokeWidth = 5 / this.zoomLevel;
    this.triangleWidth = 5 / this.zoomLevel;
    this.triangleDif = 5 / this.zoomLevel;
    this.triangleHeight = 10 / this.zoomLevel;

    if (this.triangleWidth < 1.5) {
      this.triangleWidth = 1.5;
    }
    if (this.triangleDif < 1.5) {
      this.triangleDif = 1.5;
    }
    if (this.triangleHeight < 3) {
      this.triangleHeight = 3;
    }
    let pP = 0;
    //let pPWidth = 0;
    if (this.platform.width() > 1024) {
      pP = 70;
      if (this.triangleWidth < 3) {
        this.triangleWidth = 3;
      }
      if (this.triangleDif < 3) {
        this.triangleDif = 3;
      }
      if (this.triangleHeight < 6) {
        this.triangleHeight = 6;
      }
      //pPWidth = 50;
    } else if (this.platform.width() > 960) {
      pP = 65;
      if (this.triangleWidth < 2.5) {
        this.triangleWidth = 2.5;
      }
      if (this.triangleDif < 2.5) {
        this.triangleDif = 2.5;
      }
      if (this.triangleHeight < 5) {
        this.triangleHeight = 5;
      }
      //pPWidth = 45;
    } else if (this.platform.width() > 720) {
      pP = 60;
      //pPWidth = 40;
    } else if (this.platform.width() > 540) {
      pP = 55;
      //pPWidth = 40;
    } else {
      pP = 50;
      //pPWidth = 35;
    }

    this.distancePP = Math.ceil(this.platform.width() / this.zoomLevel / pP);
    this.pullpointWidth =
      -1 * Math.ceil(this.platform.width() / this.zoomLevel / pP);

    if (this.distancePP > 0 && this.distancePP < 2) {
      this.distancePP = 2;
    }
    if (this.distancePP < 0 && this.distancePP > -2) {
      this.distancePP = -2;
    }
    if (this.distancePP > 10) {
      this.distancePP = 10;
    }
    if (this.distancePP < -10) {
      this.distancePP = -10;
    }
  }

  sortItems() {
    this.canvas.bringToFront(this.currentPath);
    for (let i = 0; i < this.txtObjs.length; i++) {
      this.canvas.bringToFront(this.txtObjs[i]);
    }
    for (let i = 0; i < this.angleObjs.length; i++) {
      this.canvas.bringToFront(this.angleObjs[i]);
    }
    this.canvas.bringToFront(this.startPoint);
    this.setCoords();
  }

  cleanCanvas() {
    this.deleteAllItems();
    this.starting = false;
    this.showUndo = false;
    this.iterator = -1;
    this.points = [];
  }

  convertXMLtoPoints(jsonProfile: any): any {
    const scaleMmToPixel=1;
    let angleSum=0;
    let profilePoints = [];
    let startPoint = {x: this.platform.width() / 2, y: this.platform.height() / 2};
    profilePoints.push(startPoint);
    let idx = 0;
    for (let section of jsonProfile.ProfileSection) {
      let edge = section.ProfileEdge[0];
      let lastPoint = profilePoints[profilePoints.length - 1];
      let angle = -edge.Angle[0];
      if (idx == 0) {
        angle = 90 + angle;
      }
      angleSum +=  angle * Math.PI/180;
      let x = Math.round(lastPoint.x + Math.sin(angleSum) * edge.Length[0] * scaleMmToPixel);
      let y = Math.round(lastPoint.y - Math.cos(angleSum) * edge.Length[0] * scaleMmToPixel);
      //let angleOriginal = {angle: +edge.Angle[0], angleRaw : +edge.Angle[0] > 0 ? +edge.Angle[0] + 180 : 180 - edge.Angle[0]};
      // the angle value will be fixed manually later
      let distance = {distance: +edge.Length[0], distanceRaw: +edge.Length[0], conicity: +section.Conicity[0]};
      let xyPoint = {x: x, y: y, /*angle: {angleRaw: edge.Angle[0]},*/ distance: distance, test: Number(edge.Angle[0]), IsWaterFold:edge.IsWaterFold[0]};
      profilePoints.push(xyPoint);
      idx++;
    }
    return profilePoints;
  }

  reDraw(simulate?: boolean) {
    if(!this.isRedrawing)
    {
      // let time = +new Date();
      this.isRedrawing = true;
      this.deleteAllItems();
      let points = (this.oldPoints = this.points);
      this.points = [];

      if (points && points.length > 1) {
        for (let i = 0; i < 2; i++) {
          this.pointer.x = points[i].x;
          this.pointer.y = points[i].y;
          this.mouseDownDraw();
          this.mouseMoveDraw(false, false);
          this.mouseMoveDraw(false, false);
          this.mouseUpDraw(false, false);
        }

        for (let i = 2; i < points.length; i++) {
          let A = this.points[i - 2];
          let B = this.points[i - 1];
          let C = null;
          if (simulate) {
            C = points[i];
          } else {
            C = this.findNewPoint(A, B, points[i].angle.angleRaw, points[i].distance.distanceRaw);
          }

          this.pointer.x = C.x;
          this.pointer.y = C.y;
          this.mouseDownDraw();
          this.mouseMoveDraw(false, false);
          this.mouseMoveDraw(false, false);
          this.mouseUpDraw(false, false);
        }
      } else if (points && points.length == 1) {
        for (let i = 0; i < 1; i++) {
          this.pointer.x = points[i].x;
          this.pointer.y = points[i].y;
          this.mouseDownDraw();
          this.mouseMoveDraw(false, false);
          this.mouseMoveDraw(false, false);
          this.mouseUpDraw(false, false);
        }
      }

      this.canvas.renderAll();
      this.oldPoints = [];
      if(this.calcCutCallback)
        this.calcCutCallback();
      this.isRedrawing = false;
      // console.log(+new Date() - time);
    } else{
      clearTimeout(this.redrawTimer);
      this.redrawTimer = setTimeout(function(){this.reDraw();}.bind(this), 100);
    }
  }

  reversePoints() {
    let degree = 0;

    let points = [];
    let x = 0;
    for (let i = this.points.length - 1; i >= 0; i--) {
      points[i] = { x: this.points[x].x, y: this.points[x].y };
      x++;
    }

    if(this.points[this.points.length-1].pP) {
      points[1].x = this.points[this.points.length-1].pP.x;
      points[1].y = this.points[this.points.length-1].pP.y;
    }
    x = 1;
    for (let i = this.points.length - 1; i >= 1; i--) {
      points[x].distance = this.points[i].distance;
      x++;
    }
    x = 2;
    for (let i = this.points.length - 1; i >= 2; i--) {

      points[x].angle = this.points[i].angle;
      points[x].angle.angleRaw =  360 - this.points[i].angle.angleRaw;
      points[x].angle.pP = points[x].angle.pP * -1;
      points[x].angle.stehfalzRet = degree - this.points[i].angle.stehfalzRet;

      x++;
    }
    let angleObj = [];
    x = 2;
    for (let i = this.angleObjs.length - 1; i >= 2; i--) {
      angleObj[x] = this.angleObjs[i];
      x++;
    }
    this.angleObjs = angleObj;

    this.points = points;

    let paint = this.profileProvider.active.paintPosition;
    if(paint === "top")
    {
      this.profileProvider.active.paintPosition = "down";
    }
    else if(paint === "down")
    {
      this.profileProvider.active.paintPosition = "top";
    }

    this.reDraw();
    this.save();
  }


  mirrorPoints() {
    let degree = 0;

    for (let i = 1; i < this.points.length; i++) {
      this.points[i].angle.angleRaw =  360 - this.points[i].angle.angleRaw;
      this.points[i].angle.pP *= -1;
      this.points[i].angle.stehfalzRet = degree - this.points[i].angle.stehfalzRet;
    }

    let paint = this.profileProvider.active.paintPosition;
    if(paint === "top")
      this.profileProvider.active.paintPosition = "down";
    else if(paint === "down")
      this.profileProvider.active.paintPosition = "top";

    this.reDraw();
    this.save();
    this.zoomToPath();
    this.redrawTimer = setTimeout(()=>{
      this.reDraw();
    }, 50);
  }

  deleteAllItems() {
    for (let i = 0; i < this.txtObjs.length; i++) {
      this.canvas.remove(this.txtObjs[i]);
    }
    for (let i = 0; i < this.angleObjs.length; i++) {
      this.canvas.remove(this.angleObjs[i]);
    }

    for (let i = 0; i < this.cornerObjs.length; i++) {
      this.canvas.remove(this.cornerObjs[i]);
    }

    for (let i = 0; i < this.trapezObjs.length; i++) {
      this.canvas.remove(this.trapezObjs[i]);
    }

    for (let i = 0; i < this.pointObjs.length; i++) {
      this.canvas.remove(this.pointObjs[i]);
    }

    for (let i = 0; i < this.lineObjs.length; i++) {
      this.canvas.remove(this.lineObjs[i]);
    }

    this.canvas.remove(this.startPoint);
    this.canvas.remove(this.currentCorner);
    this.canvas.remove(this.currentPath);
    this.canvas.remove(this.currentTxt);
    this.canvas.remove(this.currentTrapez);
    this.canvas.remove(this.currentParallelPoint);
    this.canvas.remove(this.currentStehfalz);
    this.canvas.remove(this.currentAngleTxt);
    this.canvas.remove(this.triangle);
    this.canvas.remove(this.pathShadow);

    this.trapezObjs = [];
    this.angleObjs = [];
    this.cornerObjs = [];
    this.pointObjs = [];
    this.lineObjs = [];
    this.txtObjs = [];

    this.startPoint = null;
    this.currentParallelPoint = null;
    this.currentCorner = null;
    this.currentTrapez = null;
    this.currentTxt = null;
    this.currentPath = null;
    this.currentStehfalz = null;
    this.currentAngleTxt = null;

    this.starting = false;
    this.showUndo = false;

    this.iterator = -1;
  }

  /*******************
   ********************
   ********************
   DRAWBOARD FUNC
   ********************
   ********************
   *******************/

  async showActionMenu(ev?){

    let buttons = [];
    buttons.push({
      text: this.translate.instant("Toggle3D"),
      handler: () => {
        this.threeDPreview.togglePreview(ev);
        this.popoverCtrl.dismiss();
      }
    });
    if(!this.isAnonymousUser)
    {
      if (!this.platform.is('cordova')) // pdf export only for PWA
        buttons.push({
          text: this.translate.instant("EXPORTASPIC"),
          handler: () => {
            CanvasBoardComponent.exportPDF = true;
            this.showSVG = true;
            try {
              this.exportProfile();
            } catch {
              CanvasBoardComponent.exportPDF = false;
              this.showSVG = false;
              this.loadingCtrl.dismiss();
              this.popoverCtrl.dismiss();
            }
          }
        });
      buttons.push({
        text: this.translate.instant("SHARE-IMG"),
        handler: () => {
          CanvasBoardComponent.sharePDF = true;
          this.showSVG = true;
          try {
            this.exportProfile();
          } catch {
            CanvasBoardComponent.sharePDF = false;
            this.showSVG = false;
            this.loadingCtrl.dismiss();
            this.popoverCtrl.dismiss();
          }
        }
      });
    }
    buttons.push({
      text: this.translate.instant("MIRROR"),
      handler: () => {
        this.mirrorPoints();
        this.popoverCtrl.dismiss();
      }
    });
    // buttons.push({
    //   text: this.translate.instant("MULTIPLY"),
    //   handler: () => {
    //     this.popoverCtrl.dismiss();
    //   }
    // });

    let contextMenu = await this.popoverCtrl.create({
      component: MoreOptionsComponent,
      event: ev,
      cssClass: 'more-options',
      componentProps: {
        buttons: buttons
      },
      translucent: true,
    });

    return contextMenu.present();
  }

  async changeModus(modus, ev?) {
    if (this.showPopover) {
      if (this.modus === 'edit') {
        await this.tooltipProvider.showPopover(TooltipProvider.EDIT_TOOLTIPS, 'DRAW', ev);
        this.showPopover = false;
      } else if (this.modus === 'draw') {
         this.showPopover = await this.tooltipProvider.showPopover(TooltipProvider.DRAW_TOOLTIPS,'EDIT', ev );
        if (this.showPopover) {
          this.showTooltip.emit({showPopover: this.showPopover, nextElement: 'cancelBtn'});
        }
      }
    } else {
      if ((this.canvasStatus == 1 && this.profileProvider.active.changes == 0) || this.canvasStatus == 3) {
        this.activeChanges();
      } else {
        this.modus = modus;
        this.changeValues = false;
        this.changeValues2 = false;
        this.editbar = 3;
        this.reDraw();
      }
      this.canvas.defaultCursor = "default";
    }
  }

  editBarAction(value) {
    /*this.editbar = value;
    this.setGroupObj(false);
    if (value == 2) {
      this.setGroupObj(true);
      this.canvas.setActiveObject(this.canvas.item(0));
    }
    if (value == 0 || value == 3) {
      this.changeValues = false;
      this.changeValues2 = false;
    }*/
    if (value == 4) {
      this.reversePoints();
    }
  }

  async activeChanges() {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant("CHANGES"),
      subHeader: this.translate.instant("ASKAPPLYCHANGES"),
      buttons: [
        {
          text: this.translate.instant("NO"),
          handler: data => {}
        },
        {
          text: this.translate.instant("YES"),
          handler: data => {
            if (this.canvasStatus == 3) {
              if (this.profileProvider.active.code !== undefined && this.profileProvider.active.code !== null) {
                if (this.code !== undefined && this.code !== null && this.code === this.profileProvider.active.code) {
                  this.profileProvider.active.status = 0;
                  this.canvasStatus = 0;
                }
              } else {
                this.profileProvider.active.status = 0;
                this.canvasStatus = 0;
              }
            }
            this.profileProvider.active.changes = 1;
            this.profileProvider.save();
            this.modus = "draw";
            this.changeValues = false;
            this.changeValues2 = false;
            this.editbar = 3;
            this.reDraw();
          }
        }
      ]
    });
    await alert.present();
  }

  async errorAlert(message: string) {
    const alert = await this.alertCtrl.create({
      header: this.translate.instant("ATTENTION"),
      subHeader: this.translate.instant("UNEXPECTEDERROR"),
      message: message,
      buttons: ['Cancel']
    });
    await alert.present();
  }

  backTurn() {
    this.points.pop();
    this.canvas.remove(this.pathShadow);
    this.iterator--;
    this.reDraw();
    this.save();
  }

  save() {
    let x = [];
    let y = [];
    if (this.points && this.points.length > 0) {
      for (let i = 0; i < this.points.length; i++) {
        this.points[i].saveX =
          this.points[i].x * (this.width / this.canvas.width);
        this.points[i].saveY =
          this.points[i].y * (this.height / this.canvas.height);
        x.push(parseInt(this.points[i].x));
        y.push(parseInt(this.points[i].y));
      }
    }
    this.profileProvider.active.data = this.points;
    /* threeD drawing */
    this.threeDPreview.animate();
  }

  /*******************
   ********************
   ********************
   EDIT FUNC
   ********************
   ********************
   *******************/

  /*checkCollision() {
    this.changeValues2 = false;
    this.changeValues = false;

    if (this.angleObjs && this.angleObjs.length > 0) {
      this.angleObjs.forEach(obj => {
        if (obj && obj.top) {
          let check = obj.containsPoint(this.pointer);
        }
      })
    }
  } */

  closeInput(){
    setTimeout(() => {
      if(this.currentValue)
        this.changeAngle();
      if(this.currentValue2)
        this.changeDistance();
      if(this.currentValue3)
        this.changeConicity();
      this.changeValues = false;
      this.changeValues2 = false;
    }, 200);
  }

  changeAngle() {
    if (this.currentValue != undefined && this.isNumeric(this.currentValue)) {

      if(this.currentValue == this.points[this.currentIterator].angle.stehfalz ||
         this.currentValue == this.points[this.currentIterator].angle.angleRound)
        return;

      let value = parseFloat(this.currentValue);
      if (value < 0)
      { // flip bending direction
        value = Math.abs(value);
        if (this.points[this.currentIterator].angle.angleRaw > 180)
          this.points[this.currentIterator].angle.angleRaw = 179;
        else
          this.points[this.currentIterator].angle.angleRaw = 181;
        this.points[this.currentIterator].angle.pP = -this.points[this.currentIterator].angle.pP;
      }
      if (value != 180) {
        value = value % 180;
      }

      this.currentValue = value;
      if (
        this.points[this.currentIterator].angle.stehfalz >= 0 &&
        this.currentValue < 8
      ) {
        if (this.currentValue == 7) {
          value = 143; //141
          this.points[this.currentIterator].angle.stehfalz = 7;
        } else if (this.currentValue == 6) {
          value = 147; //143
          this.points[this.currentIterator].angle.stehfalz = 6;
        } else if (this.currentValue == 5) {
          value = 155; //147
          this.points[this.currentIterator].angle.stehfalz = 5;
        } else if (this.currentValue == 4) {
          this.points[this.currentIterator].angle.stehfalz = 4;
          value = 160; //155
        } else if (this.currentValue == 3) {
          this.points[this.currentIterator].angle.stehfalz = 3;
          value = 165; //160
        } else if (this.currentValue == 2) {
          this.points[this.currentIterator].angle.stehfalz = 2;
          value = 175; //165
        } else if (this.currentValue == 1 || this.currentValue == 0) {
          this.points[this.currentIterator].angle.stehfalz = this.currentValue;
          value = 180; //175
        }
      } else {
        this.points[this.currentIterator].angle.stehfalz = -1;
      }

      if (this.points[this.currentIterator].angle.angleRaw > 180) {
        this.points[this.currentIterator].angle.angle = value;
        this.points[this.currentIterator].angle.angleRound = value;
        this.points[this.currentIterator].angle.stehfalzRet = 180 + value;
        /*if (value == 0) {
          value += 0.1;
        }*/
        this.points[this.currentIterator].angle.angleRaw = 180 + value;
      } else {
        this.points[this.currentIterator].angle.angleRaw = 180 - value;
        this.points[this.currentIterator].angle.stehfalzRet = 180 - value;
        this.points[this.currentIterator].angle.angle = value;
        this.points[this.currentIterator].angle.angleRound = value;
      }

      for (let i = 2; i < this.points.length; i++) {
        let A = this.points[i - 2];
        let B = this.points[i - 1];
        let C = this.points[i];
        let newC = this.findNewPoint(
          A,
          B,
          C.angle.angleRaw,
          C.distance.distanceRaw
        );
        this.points[i].x = newC.x;
        this.points[i].y = newC.y;
      }
      this.reDraw();
    }
  }

  changeDistance() {
    if (this.currentValue2 && this.currentValue2 > 0 && this.isNumeric(this.currentValue2)) {
      if (this.currentValue2 > 1250) {
        this.currentValue2 = 1250;
      }

      if(this.currentValue2 == this.points[this.currentIterator].distance.distance)
        return;

      this.points[this.currentIterator].distance.override = true;
      this.points[this.currentIterator].distance.distance = parseFloat(this.currentValue2 );
      this.points[this.currentIterator].distance.distanceRaw = parseFloat(this.currentValue2) / this.lengthFactor;

      if(this.points[this.currentIterator].distance.conicity != undefined &&
        this.points[this.currentIterator].distance.conicity <= 0 - this.points[this.currentIterator].distance.distance) {
        this.currentValue3 = 0 - this.points[this.currentIterator].distance.distance;
        this.changeConicity();
      }
      else{
        this.reDraw();
      }
    }
  }

  changeConicity() {
    if ( (this.currentValue3 || this.currentValue3 === 0) && this.isNumeric(this.currentValue3)) {
      if (this.currentValue3 <= 0 - this.points[this.currentIterator].distance.distance) {
        this.currentValue3 = 0 - this.points[this.currentIterator].distance.distance;
      }

      if (this.currentValue3 == this.points[this.currentIterator].distance.conicity)
        return;

      this.points[this.currentIterator].distance.conicity = parseFloat(this.currentValue3);
      this.points[this.currentIterator].distance.conicityRaw = parseFloat(this.currentValue3) / this.lengthFactor;
      this.reDraw();
    }
  }

  flipSign(){
    this.currentValue = -this.currentValue;
  }

  flipSign3(){
    this.currentValue3 = -this.currentValue3;
  }

  isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  /*******************
   ********************
   ********************
   ZOOM FUNC
   ********************
   ********************
   *******************/

  zoomIn(point) {
    clearTimeout(this.redrawTimer);
    this.setZoomLevel();
    if (this.zoomLevel <= this.zoomLevelMax) {
      this.canvas.zoomToPoint(point, this.zoomLevel);
      this.keepPositionInBounds();
    } else {
      this.zoomLevel = this.zoomLevelMax;
    }
    this.redrawTimer = setTimeout(function(){this.reDraw();}.bind(this), 300);
  }

  zoomOut(point) {
    clearTimeout(this.redrawTimer);
    this.setZoomLevel();
    if (this.zoomLevel >= this.zoomLevelMin) {
      this.canvas.zoomToPoint(point, this.zoomLevel);
      this.keepPositionInBounds();
    } else {
      this.zoomLevel = this.zoomLevelMin;
    }
    this.redrawTimer = setTimeout(function(){this.reDraw();}.bind(this), 300);
  }

  keepPositionInBounds() {
    var zoom = this.canvas.getZoom();
    var xMin = ((2 - zoom) * this.canvas.width) / 2;
    var xMax = ((0 + zoom) * this.canvas.width) / 2;
    var yMin = ((2 - zoom) * this.canvas.height) / 2;
    var yMax = ((0 + zoom) * this.canvas.height) / 2;

    var point = new fabric.Point(
      this.canvas.width / 2,
      this.canvas.height / 2
    );
    var center = fabric.util.transformPoint(
      point,
      this.canvas.viewportTransform
    );

    var clampedCenterX = this.clamp(center.x, xMin, xMax);
    var clampedCenterY = this.clamp(center.y, yMin, yMax);

    var diffX = clampedCenterX - center.x;
    var diffY = clampedCenterY - center.y;

    if (diffX != 0 || diffY != 0) {
      this.canvas.relativePan(new fabric.Point(diffX, diffY));
    }
  }

  clamp(value, min, max) {
    return Math.max(min, Math.min(value, max));
  }

  getCenterFromProfilePoints(usePlatform:boolean = true): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!(this.points.length > 0)) {
        resolve(false);
      }

      let minMax = this.minMax(usePlatform);
      let vector1 = new THREE.Vector2(minMax.minX, minMax.minY);
      let vector2 = new THREE.Vector2(minMax.maxX, minMax.maxY);
      let result = new THREE.Vector2();
      let box = new THREE.Box2(vector1, vector2);
      box.getCenter(result);

      resolve({ x: result.x, y: result.y, zoomFactor: minMax.zoomFactor, offsetY: minMax.offsetY });
    });
  }

  async zoomToPath(usePlatform: boolean = true) {
    let center = await this.getCenterFromProfilePoints(usePlatform);
    this.zoomLevel = center.zoomFactor;
    // console.log("zoom" + this.zoomLevel);
    this.setZoomLevel();
    this.canvas.setZoom(this.zoomLevel);
    this.canvas.absolutePan({x:(center.x * this.zoomLevel),y:(center.y * this.zoomLevel + center.offsetY / 2 - 35)}); // compensate for info  at top and toolbar at bottom
    this.canvas.relativePan({x:this.centerPoint.x ,y:this.centerPoint.y });
  }

  async zoomWithButton(value, ev?) {
    let zoomPoint = await this.getCenterFromProfilePoints();
    if (zoomPoint && zoomPoint.x && zoomPoint.y) {
      let point = new fabric.Point(zoomPoint.x, zoomPoint.y);
      if (!value) {
        this.zoomLevel -= 0.5;
        this.zoomOut(point);
      } else {
        this.zoomLevel += 0.5;
        this.zoomIn(point);
      }
    } else {
      if (!value) {
        this.zoomLevel -= 0.5;
        this.zoomOut(this.centerPoint);
      } else {
        this.zoomLevel += 0.5;
        this.zoomIn(this.centerPoint);
      }
    }
  }

  /*******************
   ********************
   ********************
   MATH METHOS
   ********************
   ********************
   *******************/

  calcAngle(A = null, B = null, C = null, drawAngle = true, round = false) {
    let final = 0;
    let typAB = 0;
    let typBC = 0;
    let alpha = 0;

    //console.log("--------calcAngle------------");
    //console.log("A:" + A.x + " " + A.y);
    //console.log("B:" + B.x + " " + B.y);
    //console.log("C:" + C.x + " " + C.y);

    if (A.x <= B.x && A.y >= B.y) {
      alpha = (Math.atan((B.y - A.y) / (B.x - A.x)) * 360) / (2 * Math.PI);
      //console.log("von links unten nach rechts oben");
      typAB = 1;
    } else if (A.x <= B.x && A.y <= B.y) {
      alpha = (Math.atan((B.y - A.y) / (B.x - A.x)) * 360) / (2 * Math.PI);
      typAB = 2;
      //console.log("von links oben nach rechts unten");
    } else if (A.x > B.x && A.y > B.y) {
      //console.log("von rechts unten nach links oben");
      alpha =
        (Math.atan((B.y - A.y) / (B.x - A.x)) * 360) / (2 * Math.PI) + 180;
      typAB = 3;
    } else if (A.x > B.x && A.y < B.y) {
      //console.log("von rechts oben nach links unten");
      alpha =
        (Math.atan((B.y - A.y) / (B.x - A.x)) * 360) / (2 * Math.PI) + 180;
      typAB = 4;
    }
    let gamma = (Math.atan((B.y - C.y) / (B.x - C.x)) * 360) / (2 * Math.PI);

    typBC = 0;
    if (B.x < C.x && B.y > C.y) {
      //console.log("von links unten nach rechts oben - BC");
      typBC = 1;
    } else if (B.x < C.x && B.y <= C.y) {
      typBC = 2;
      //console.log("von links oben nach rechts unten - BC");
    } else if (B.x >= C.x && B.y > C.y) {
      //console.log("von rechts unten nach links oben - BC");
      typBC = 3;
    } else if (B.x >= C.x && B.y <= C.y) {
      //console.log("von rechts oben nach links unten - BC");
      typBC = 4;
    }

    /*console.log("---------TYP---------");
    console.log(typAB);
    console.log(typBC);
    console.log("---------------------"); */

    let addWinkel = 0;
    let addWinkel2 = 0;
    let multi = 1;
    if (typAB == 4 && typBC < 3) {
      addWinkel = 180;
    }

    if (typAB == 4 || typBC > 2) {
      addWinkel2 = 180;
    }
    if (typAB == 2) {
      multi = -1;
    }

    final = gamma + (addWinkel + addWinkel2) * multi + 180 - alpha;

    if (A.y == B.y && this.points.length > 2 && A.x >= B.x) {
      final += 180;
    }
    if (final < 0) {
      final = 360 + final;
    }
    if (final > 360) {
      final = final % 360;
    }

    //PARALELL POINT
    let pP = 1;
    if (final < 180) {
      pP = -1;
    }
    final = 360 - final;

    //Fix new calculation v1.0.44
    //let finalOld = final;
    if (typAB == 4 && alpha == 180) {
      if (final > 180) {
        final = final - 360;
      }
      final += 180;
      final = final % 360;
      //console.log('fix final: ' + finalOld + ' -> ' + final);
    }

    let finalText = final;
    if (finalText > 180) {
      finalText = 360 - finalText;
    }
    finalText = 180 - finalText;

    let angleRound = Math.round(finalText / 5) * 5;
    if(round) {
      final = Math.round(final / 5) * 5;
      finalText = Math.round(finalText / 5) * 5;
    }

    //console.log(final + ' / AB: ' + typAB + ' BC: ' +typBC);

    let ret = {
      pP: pP,
      angle: finalText,
      angleRaw: final,
      angleRound: angleRound,
      stehfalz: -1,
      stehfalzRet: null,
      isCopy: false
    };

    return ret;
  }

  calcAngle3(p0,p1,c): number {
    var p0c = Math.sqrt(Math.pow(c.x-p0.x,2)+
      Math.pow(c.y-p0.y,2));
    var p1c = Math.sqrt(Math.pow(c.x-p1.x,2)+
      Math.pow(c.y-p1.y,2));
    var p0p1 = Math.sqrt(Math.pow(p1.x-p0.x,2)+
      Math.pow(p1.y-p0.y,2));
    var angle = Math.acos((p1c*p1c+p0c*p0c-p0p1*p0p1)/(2*p1c*p0c));
    return angle * (180 / Math.PI);
  }

  find_disconnected_angle(p0,c0, p1,c1): number {
    return this.calcAngle3({x:p0.x-c0.x+c1.x,y:p0.y-c0.y+c1.y},p1,c1);
  }

  calcAngle2(originX, originY, targetX, targetY): number {
    var dx = originX - targetX;
    var dy = originY - targetY;

    var theta = Math.atan2(-dy, -dx); // [0, Ⲡ] then [-Ⲡ, 0]; clockwise; 0° = east
    theta *= 180 / Math.PI;           // [0, 180] then [-180, 0]; clockwise; 0° = east
    if (theta < 0) theta += 360;      // [0, 360]; clockwise; 0° = east

    return theta;
  }


  calcMiddlePointLine(A, B, point = 0.5) {
    var midX = A.x + (B.x - A.x) * point;
    var midY = A.y + (B.y - A.y) * point;

    return { x: midX, y: midY };
  }

  calcParallelPoint(A, B, d = 40) {
    let x =
      A.x +
      ((B.y - A.y) * d) /
        Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
    let y =
      A.y +
      (-1 * (B.x - A.x) * d) /
      Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
    return { x: x, y: y };
  }

  calcParallelPointHorizontal(A, B, d = 40) {
    let x =
      A.x +
      ((B.y - A.y) * d) /
      Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
    let y =
      A.y +
      ((B.x - A.x) * d) /
      Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
    return { x: x, y: y };
  }

  calcDistance(A, B) {
    var dist = Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
    let distRound = dist * this.lengthFactor;
    distRound = Math.round(distRound / 5) * 5;
    dist = distRound / this.lengthFactor;
    return { distance: distRound, distanceRaw: dist };
  }

  calcCornerPoint(A, B, radius) {
    var dist = Math.sqrt(Math.pow(B.x - A.x, 2) + Math.pow(B.y - A.y, 2));
    let x = ((A.x - B.x) / dist) * radius + B.x;
    let y = ((A.y - B.y) / dist) * radius + B.y;
    return { x: x, y: y };
  }

  drawAngle(A, B, C, drawRadius) {
    var nx1 = A.x - B.x;
    var ny1 = A.y - B.y;
    var nx2 = C.x - B.x;
    var ny2 = C.y - B.y;

    var lineAngle1 = Math.atan2(ny1, nx1);
    var lineAngle2 = Math.atan2(ny2, nx2);

    if (nx1 * ny2 - ny1 * nx2 < 0) {
      // wrong way around swap direction
      const t = lineAngle2;
      lineAngle2 = lineAngle1;
      lineAngle1 = t;
    }

    // if angle 1 is behind then move ahead
    if (lineAngle1 < lineAngle2) {
      lineAngle1 += Math.PI * 2;
    }

    const mx =
      (-Math.cos((lineAngle1 + lineAngle2) / 2) * this.cornerRadius) / 1.5 +
      B.x;
    const my =
      (-Math.sin((lineAngle1 + lineAngle2) / 2) * this.cornerRadius) / 1.5 +
      B.y;

    this.currentAngleTxt.set({ left: mx, top: my });
    this.currentAngleTxt.scaleX = this.txtScale;
    this.currentAngleTxt.scaleY = this.txtScale;

    if (!drawRadius) {
      this.currentCorner.set({
        startAngle: lineAngle1,
        endAngle: lineAngle2,
        fill: "transparent",
        stroke: "transparent"
      });
    } else {
      this.currentCorner.set({
        startAngle: lineAngle1,
        endAngle: lineAngle2,
        fill: "#ccc",
        stroke: "#ccc"
      });
    }
  }

  findNewPoint(A, B, a, d) {
    let angle = a;
    let distance = d;

    // new Version
    angle=angle * Math.PI/180;
    let lenBA = Math.sqrt(Math.pow(B.x-A.x,2) + Math.pow(B.y-A.y,2));
    let newX = B.x - distance * ((B.x-A.x) * Math.cos(angle) + (B.y-A.y) * Math.sin(angle)) / lenBA;
    let newY = B.y + distance * ((B.x-A.x) * Math.sin(angle) - (B.y-A.y) * Math.cos(angle)) / lenBA;

    return { x: newX, y: newY };
  }

  minMax(usePlatform: boolean = true) {
    let x = [];
    let y = [];
    for (let i = 0; i < this.points.length; i++) {
      this.points[i].saveX =
        this.points[i].x * (this.width / this.canvas.width);
      this.points[i].saveY =
        this.points[i].y * (this.height / this.canvas.height);
      x.push(parseInt(this.points[i].x));
      y.push(parseInt(this.points[i].y));
    }

    let maxX = Math.max.apply(null, x);// + this.canvas.width /40;
    let maxY = Math.max.apply(null, y);// + this.canvas.height /40;

    let minX = Math.min.apply(null, x);// - this.canvas.width /40;
    let minY = Math.min.apply(null, y);// - this.canvas.height /40;

    let width = Math.max(1, 1.1 * (maxX - minX));
    let height = Math.max(1, 1.1 * (maxY - minY));

    let offsetY = 0;
    const element2 = document.getElementsByClassName('canvas-container');
    if(element2[0] && (element2[0] as HTMLElement).offsetTop)
      offsetY = (element2[0] as HTMLElement).offsetTop;

    let cWidth = Math.floor(this.platform.width() / width) / 2;
    let cHeight = Math.floor(Math.max(100, this.platform.height() - offsetY) / height) / 2;

    if(!usePlatform)
    {
      cWidth = Math.floor(this.canvas.width / width) - 0.1;
      cHeight = Math.floor(Math.max(100, this.canvas.height) / height) - 0.1;
    }

    let zoomFactor = Math.min(cHeight, cWidth);

    return {
      minX: minX,
      maxX: maxX,
      minY: minY,
      maxY: maxY,
      width: width,
      height: height,
      cWidth: cWidth,
      cHeight: cHeight,
      zoomFactor: zoomFactor,
      offsetY: offsetY
    };
  }

  setCoords() {
    this.canvas.defaultCursor = "default";
    this.canvas.forEachObject(o => {
      o.setCoords();
    });
  }

  goTo() {
    //let navExtra: NavigationExtras = {state:{ close: 1, export: 1 }};
    //this.navCtrl.navigateBack('profiles', navExtra);
    this.navCtrl.back();
  }

  /*******************
   ********************
   ********************
   KEY ACTIONS
   ********************
   ********************
   *******************/
  gestures() {
    this.canvas.on({
      "mouse:wheel": opt => {
        let temp = this.canvas.getPointer(opt.e);
        if(isNaN(temp.x))
          return;
        this.pointer = temp;
        this.pointer.x = Math.round(this.pointer.x);
        this.pointer.y = Math.round(this.pointer.y);
        this.mouseWeel(opt);
      },
      "mouse:down": opt => {
        let temp = this.canvas.getPointer(opt.e);
        if(isNaN(temp.x))
          return;
        let delta = this.pointer != undefined ? {x: this.pointer.y - temp.y, y: this.pointer.y - temp.y} : {x: 1000, y: 1000};
        this.pointer = temp;
        this.pointer.x = Math.round(this.pointer.x);
        this.pointer.y = Math.round(this.pointer.y);
        this.lastXTouch = opt.e.touches ? undefined : opt.e.offsetX;
        this.lastYTouch = opt.e.touches ? undefined : opt.e.offsetY;

        let changes = this.changeValues || this.changeValues2;
        if(!this.mouseDownPointer()) {
          if (this.modus == "draw" && !changes) {
            this.mouseDownDraw();
            this.mouseMoveDraw(true);
          } else if (this.modus == "edit") {
            let downTime = +new Date();
            if (downTime - this.lastDownTime < 300 && !this.isDragging && !this.isRedrawing && Math.abs(delta.x) < 30 && Math.abs(delta.y) < 30) {
              this.zoomToPath();
              this.redrawTimer = setTimeout(function () {
                this.reDraw();
              }.bind(this), 50);
            }
            this.lastDownTime = downTime;
          }
        }
      },
      "mouse:move": opt => {
        let temp = this.canvas.getPointer(opt.e);
        if(isNaN(temp.x))
          return;
        this.pointer = temp;
        this.pointer.x = Math.round(this.pointer.x);
        this.pointer.y = Math.round(this.pointer.y);
        if (this.modus == "draw") {
          this.mouseMoveDraw(true);
        }
      },
      "mouse:up": opt => {
        this.lastXTouch = undefined;
        this.lastYTouch = undefined;
        this.lastRotation = undefined;
        if (this.modus == "draw" && this.isDown) {
          this.mouseUpDraw(true, true, true);
        }
        if(!this.isProfileEditingActive()){
          if(this.modus == 'draw') {
            this.showDrawingPopover();
          }
        }
      },
      "touch:gesture": opt => {
        if (this.modus == "edit") {
          this.pinchZoom(opt);
          // this.setCoords();
        }
      },
      "touch:drag": opt => {
        if (this.modus == "edit") {
          this.dragTouch(opt);
          //this.setCoords();
        }
      }
    });
  }

  private isProfileEditingActive() : boolean {
    return    this.changeValues != null
           || this.changeValues2 != null
           || this.currentValue != null
           || this.currentValue2 != null
           || this.currentValue3 != null;
  }

  private async showDrawingPopover() {
    if (!this.points)
      return;

    switch (this.points.length) {
      case 1:
        this.showPopover = await this.tooltipProvider.showPopover(TooltipProvider.DRAW_TOOLTIPS, 'FIRSTEDGE');
        break;
      case 2:
        this.showPopover = await this.tooltipProvider.showPopover(TooltipProvider.DRAW_TOOLTIPS, 'FIRSTANGLE');
        break;
      case 3:
        this.showPopover = await this.tooltipProvider.showPopover(TooltipProvider.DRAW_TOOLTIPS, 'VALUE');
        if (this.showPopover) {
          setTimeout(()=>{this.editDrawBtn.el.click();},20000);
        }
        break;
    }
  }

  onSelections(){
    this.canvas.on({
      "selection:created": () => {
        this.activeObject = this.canvas.getActiveObject();
        this.lastActiveObject = this.activeObject;
        this.activeObject.set({
          fill: '#008acf',
          dirty: true
        });
        this.canvas.requestRenderAll();
      },
      "selection:cleared": () => {
        if (this.lastActiveObject.padding == this.angleTxtPadding)
        {
          this.lastActiveObject.set({
            fill: 'black',
            dirty: true
          });
        }
        else {
          this.lastActiveObject.set({
            fill: 'white',
            dirty: true
          });
        }
        this.canvas.requestRenderAll();
      },
      "selection:updated": () => {
        this.activeObject = this.canvas.getActiveObject();
        this.activeObject.set({
          fill: '#008acf',
          dirty: true
        });

        if (this.lastActiveObject.padding == this.angleTxtPadding)
        {
          this.lastActiveObject.set({
            fill: 'black',
            dirty: true
          });
        }
        else {
          this.lastActiveObject.set({
            fill: 'white',
            dirty: true
          });
        }
        this.canvas.requestRenderAll();
        this.lastActiveObject = this.activeObject;
      }
    });
  }


  async getProfilePng(): Promise<string> {
    if(this.currentPath) {
      const pixels=800;
      this.canvas.setHeight(pixels)
      this.canvas.setWidth(pixels)
      this.centerPoint = new fabric.Point(
        pixels / 2,
        pixels / 2
      );
      this.zoomToPath(false);
      this.reDraw();
      this.canvas.renderAll();
      await new Promise(f => setTimeout(f, 200));
      this.zoomToPath(false);
      this.reDraw();
      this.canvas.renderAll();
      await new Promise(f => setTimeout(f, 400));
      return this.canvas.toDataURL();
      //return this.canvas.toDataURL({
      //  format: "png",
      //  multiplier: 1,
      //  quality: 1,
      //  left: -this.canvas.width/2,
      //  top: -this.canvas.height/2,
      //  width: this.canvas.width,
      //  height: this.canvas.height,
      //});
    }
    return '';
  }

  showPleaseWait(timeout?: number) {
    this.loadingCtrl.create({
      message: this.translate.instant("PLEASEWAIT")
    }).then((loading) => {
      loading.present();
      if (timeout)
        setTimeout(() => {
          loading.dismiss();
        }, timeout);
    });
  }

  async handleThreedView(_event: { showThreedBoard: boolean, width: number, height: number }) {
    if (_event.showThreedBoard && this.profileProvider.active && this.currentPath) {
      this.profileProvider.active.image = this.canvas.toSVG({
        width: _event.width,
        height: _event.height,
        suppressPreamble: true,
        viewbox: {
          x: (this.currentPath.left - this.currentPath.width * 1.5) / this.zoomLevel,
          y: (this.currentPath.top - this.currentPath.height * 1.5) / this.zoomLevel,
          width: (this.currentPath.width * 4) / this.zoomLevel,
          height: (this.currentPath.height * 4) / this.zoomLevel,
        }
      });
      this.svg = this.sanitizer.bypassSecurityTrustHtml(this.profileProvider.active.image);
      this.threeDPreview.setPreviewImg(this.svg);
    }
  }

  private async openPDF(doc: any, name: string) {
    if (doc !== null) {
      console.log("EXPORT AS PDF");
      // show it in a new window, don't automatically save as file
      const blob = doc.output('blob');
      if (this.platform.is('desktop') || this.platform.is('android')) {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, name);
      } else {
        const file = new Blob([blob], {type: 'pdf'});
        const modal = await this.modalCtrl.create({
          component: PdfViewComponent, componentProps: {data: {file}}, cssClass: 'pdf-modal'
        });
        modal.present();
      }
    }
  }

}
