import {Component, Injector } from '@angular/core';
import {AlertController, Platform, ToastController } from '@ionic/angular';
import {TranslateService } from "@ngx-translate/core";
import {RequestProvider } from "../../shared/providers/request/request";
import {Storage } from '@ionic/storage';
import {QRScanner, QRScannerStatus} from '@awesome-cordova-plugins/barcode-scanner/ngx';
import {QrCodeProvider} from '../../shared/providers/qrcode/qrcode';
import {environment} from '../../environments/environment';
import {IMachineData, MachinesProvider} from '../../shared/providers/machines/machines';
import * as moment from 'moment';

@Component({
  selector: "page-machines",
  templateUrl: "machines.html",
  styleUrls: ['./machines.scss']
})
export class MachinesPage {

  public machinesList: any[] = [];
  public development: boolean = false;
  public isMaster: boolean = false;
  public isScanning: boolean = false;
  public whoami: any = null;
  private qrScanner: QRScanner = null;
  private backHandler: any = null;
  private scanSub: any = null;
  private machinesData: IMachineData[] = [];

  constructor(
    private injector: Injector,
    private alertCtrl: AlertController,
    private platform: Platform,
    private request: RequestProvider,
    private storage: Storage,
    private toastController: ToastController,
    private translate: TranslateService,
    private qrCodeProvider: QrCodeProvider,
    private machinesProvider: MachinesProvider
  ) {
    try{
      if(this.platform.is('cordova')) {
        this.qrScanner = injector.get<QRScanner>(QRScanner);
      }
      this.development = !environment.production;
    } catch (e) {
      this.debug(e);
    }
  }

  async ionViewWillEnter() {
    const dev = await this.storage.get('deviceMaster');
    if (dev && dev > 0) {
      this.isMaster = true;
      this.storage.get('whoami').then(async (whoami) => {
        if (whoami && whoami.id) {
          this.whoami = whoami;
          this.machinesProvider.loadMachinesData(whoami).subscribe(
            async (_machines) => {
              this.machinesData = [..._machines]
              this.getMachines();
            });
        }
      });
    }
  }

  ionViewDidLeave(){
    window.removeEventListener('popstate', this.backHandler, true);
    document.removeEventListener("backbutton", this.backHandler, false);

    if(this.qrScanner)
      this.qrScanner.destroy();
  }

  async getMachines(){
    if(!this.isMaster)
      return;
    let machines = await this.getMachinesRequest();
    if(machines && Array.isArray(machines)){
      this.machinesList = [];
      machines.forEach((m)=>{
        if(m && m.id && m.friendly_name) {
          const machineData = this.machinesData.find((md) => md.id === m.id);
          const activeProfile = machineData && machineData.activeProfile ? machineData.activeProfile : null;
          const machine = {
            id:m.id, friendlyName: m.friendly_name,
            online: machineData ? machineData.isOnline : false,
            activeMode: machineData ? machineData.activeMode : '',
            numberOfPiecesToBeDone: activeProfile !== null && activeProfile.numberOfPiecesToBeDone > 0 ? activeProfile.numberOfPiecesDone / activeProfile.numberOfPiecesToBeDone : null,
            geometry: activeProfile !== null && activeProfile.geometry.length > 0 ? activeProfile.geometry : null,
            timeStamp: machineData ? moment(new Date(machineData.timeStamp)) : null
          };
          console.log('machine', machine);
          this.machinesList.push(machine);
        }
      });
    }
  }

  getMachinesRequest(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.request.post('auth/listMachines', {},true).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async deleteMachine(id:number) {
    let machine = this.machinesList.find((machine)=>{return machine.id==id;})
    if(machine.id == id)
      await this.deleteMachineRequest(id, machine.friendlyName);
    this.getMachines();
  }

  deleteMachineRequest(id:number, name: string): Promise<any>{
    return new Promise((resolve, reject) => {
      this.request.post('auth/removeMachine', {id: id, friendlyName: name},true).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  scanQR() {
    if(!this.isMaster)
      return;
    if(!navigator.onLine)
      return;
    if(this.qrScanner) {
      this.qrScanner.getStatus().then((status)=>{
        console.log("QRScanner status:");
        console.log(status);
      });

      this.qrScanner.prepare()
        .then((status: QRScannerStatus) => {
          console.log("QRScanner prepared:");
          console.log(status);
          if (status.denied || status.restricted) {
            // camera permission was permanently denied
            // you must use QRScanner.openSettings() method to guide the user to the settings page
            // then they can grant the permission from there
            console.log('Permission denied. Open settings?');
            this.qrCodeProvider.acquirePermission(this.qrScanner);
          } else /*if (status.authorized)*/ {
            // camera permission was granted
            this.isScanning = true;
            if(!this.backHandler) {
              this.backHandler = this.closeCamera.bind(this);
            }
            window.removeEventListener('popstate', this.backHandler, true);
            window.addEventListener('popstate', this.backHandler, true);
            document.removeEventListener("backbutton", this.backHandler, false);
            document.addEventListener("backbutton", this.backHandler, false);

            // start scanning
            this.scanSub = this.qrScanner.scan().subscribe((text: string) => {
              console.log('Scanned something', text);
              this.closeCamera();
              if(text.startsWith('[') && text.endsWith(']')){
                let friendlyName = text.substring(1, text.lastIndexOf(','));
                let id = text.substring(text.lastIndexOf(',') + 1, text.length - 1);
                this.addScannedMachineDialog(friendlyName, id);
              } else {
                console.log('invalid qr code');
                this.qrCodeProvider.showInvalidQR();
              }
            });

            this.hideForCamera(true);
            this.hideForCamera(true);
            this.qrScanner.resumePreview();
            this.qrScanner.show();

          }
          // else  {
          //   // permission was denied, but not permanently. You can ask for permission again at a later time.
          //   console.log('Permission denied. Will ask again when required.');
          //   console.log(status);
          // }
        })
        .catch((e: any) => console.log('Error is', e));
    } else {
      if (this.development) {
        // for web testing !!!
        this.addScannedMachineDialog('Testmachine 3', 'qLuv723%Ukai330?3');
      } else {
        this.qrCodeProvider.showStores();
      }
    }
  }

  closeCamera() {
    this.isScanning = false;
    this.hideForCamera(false);

    if (this.scanSub)
      this.scanSub.unsubscribe(); // stop scanning
    if (this.qrScanner) {
      this.qrScanner.pausePreview(); // hide camera preview
      this.qrScanner.hide();
    }

    window.removeEventListener('popstate', this.backHandler, true);
    document.removeEventListener("backbutton", this.backHandler, false);
  }

  hideForCamera(hide: boolean) {
    const hideContent = document.getElementsByClassName("hideForCamera");
    for (let n = 0; n < hideContent.length; n++) {
      const el = <HTMLElement>hideContent[n];
      el.style.opacity = hide ? "0" : "1";
    }
  }

  async addScannedMachineDialog(friendlyName: string, id: string) {
    const confirm = await this.alertCtrl.create({
      header: this.translate.instant("ADDMACHINE"),
      message: this.translate.instant("ADDMACHINECONFIRM", {friendlyName: friendlyName}),
      buttons: [
        {
          text: this.translate.instant("OK"),
          handler: async () => {
            console.log('Allowing machine access to account.');

            let result = await this.addScannedMachineRequest(friendlyName, id);
            if(result.success){
              this.getMachines();
              await( await this.toastController
                .create({
                  message: this.translate.instant("ADDEDMACHINE") + " " + friendlyName,
                  duration: 5000,
                  position: "bottom"
                }))
                .present();
            } else {
              console.log(result);
              await( await this.toastController
                .create({
                  message: this.translate.instant("ERRORADDINGMACHINE") + " " + friendlyName,
                  duration: 5000,
                  position: "bottom"
                }))
                .present();
            }
          }
        },
        {
          text: this.translate.instant("CANCEL"),
          handler: () => {
            console.log('Operation cancelled.')
          }
        }
      ]
    });
    await confirm.present();
  }

  addScannedMachineRequest(friendlyName: string, id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.request.post('auth/addMachine', { friendlyName: friendlyName, machineId: id }, true).subscribe(data => {
        resolve(data);
      }, err => {
        resolve(err);
      });
    });
  }

  async debug(text: string){
    const confirm = await this.alertCtrl.create({
      header: 'DEBUG',
      message: text,
      buttons: [
        {
          text: this.translate.instant("OK"),
          handler: () => {
          }
        }
      ]
    });
    await confirm.present();
  }

}
