<ion-header class="ion-no-border">
  <ion-toolbar color="dark">
    <ion-buttons slot="start" class="ion-no-margin">
      <ion-menu-button fill="solid" color="light"></ion-menu-button>
    </ion-buttons>
    <ion-title>{{'STATISTICS'| translate}}</ion-title>
  </ion-toolbar>
  <ion-grid *ngIf="!isB2B()" class="filterbox grid-padding">
    <ion-row class="ion-align-items-center">
      <ion-col size="6" size-sm="6" size-md="2" size-lg="2" class="form-col">
        <ion-label>{{'STATISTICS-FROM'| translate | uppercase}}</ion-label>
        <ion-datetime min="2023" max="{{now}}"
                      [(ngModel)]="dateFrom" (ngModelChange)="filterStatistic(1)" [disabled]="!changeFilter"
                      cancelText="{{'ABORT'| translate}}" doneText="{{'SELECT'| translate}}">
        </ion-datetime>
      </ion-col>
      <ion-col size="6" size-sm="6" size-md="2" size-lg="2" class="form-col">
        <ion-label>{{'STATISTICS-TO'| translate | uppercase}}</ion-label>
        <ion-datetime min="2023" max="{{now}}"
                      [(ngModel)]="dateTo" (ngModelChange)="filterStatistic(2)" [disabled]="!changeFilter"
                      cancelText="{{'ABORT'| translate}}" doneText="{{'SELECT'| translate}}">
        </ion-datetime>
      </ion-col>
      <ion-col size="6" size-sm="6" size-md="4" size-lg="2" class="form-col">
        <ion-label>{{'STATISTICS-INTERVAL'| translate | uppercase}}</ion-label>
        <ion-select  [(ngModel)]="intervalId" (ngModelChange)="filterStatistic(3)" [disabled]="!changeFilter">
          <ion-select-option value="DAY">{{'STATISTICS-INTERVAL-DAY'| translate}}</ion-select-option>
          <ion-select-option value="WEEK">{{'STATISTICS-INTERVAL-WEEK'| translate}}</ion-select-option>
          <ion-select-option value="MONTH">{{'STATISTICS-INTERVAL-MONTH'| translate}}</ion-select-option>
        </ion-select>
      </ion-col>
      <ion-col size="6" size-sm="6" size-md="4" size-lg="6" class="form-col">
        <ion-label>{{'STATISTICS-PROJECTS'| translate | uppercase}}</ion-label>
        <ion-select  [(ngModel)]="project" (ngModelChange)="filterStatistic(4)" [disabled]="!changeFilter">
          <ion-select-option value="{{null}}">{{'STATISTICS-ALL'| translate}}</ion-select-option>
          <ion-select-option [value]="p" *ngFor="let p of projects">
            {{ p.name }}
          </ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-header>

<ion-content class="ion-padding ion-margin ion-align-items-center">

  <ion-card *ngIf="statistics && statistics.length > 0">
    <ion-card-header>
      <ion-item lines="none">
        <ion-label>{{'STATISTICS-PRODUCTION'| translate}}</ion-label>
      </ion-item>
    </ion-card-header>
    <ion-card-content>
      <line-chart #productionStepsChart
                  [metric]="0"
                  [interval]="intervalId"
                  [statisticData]="statistics"
                  [statisticDataSets]="productionSteps"
                  [project]="project">
      </line-chart>
    </ion-card-content>
  </ion-card>

  <ion-card *ngIf="statistics && statistics.length > 0">
    <ion-card-header>
      <ion-item lines="none">
        <ion-label>{{'STATISTICS-MATERIAL'| translate}}</ion-label>
        <ion-select [(ngModel)]="materialFilter" (ngModelChange)="chartFilter(1)"
                    placeholder="{{'SELECT'| translate}}"  [okText]="'OK' | translate" [cancelText]="'ABORT' | translate">
          <ion-select-option [value]="null">{{ 'STATISTICS-ALL' | translate }}</ion-select-option>
          <ion-select-option [value]="material.name" *ngFor="let material of materials">
            {{ material.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-card-header>
    <ion-card-content>
      <line-chart #processedMaterialChart
                  [metric]="1"
                  [interval]="intervalId"
                  [statisticData]="statistics"
                  [statisticDataSets]="processedMaterial"
                  [project]="project"
                  [filter]="materialFilter">
      </line-chart>
    </ion-card-content>
  </ion-card>

</ion-content>

<ion-footer>
  <ion-toolbar color="dark" class="ion-no-padding ion-no-margin">
    <ion-buttons slot="start" class="ion-no-padding ion-no-margin">
      <ion-button *ngIf="!isProduction()" fill="solid" color="primary" class="ion-no-margin" (click)="generateMachineData(100)">+100 Testdaten (Check UID in assets/data/machineData)</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
